<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>
    <table class="picklist-header">
      <tr>
        <td class="picklist-left">
          <mat-form-field class="lvs-input-wide" appearance="fill">
            <mat-label>Picklist Id</mat-label>
            <input matInput type="text" size="50" [(ngModel)]="picklistId" appearance="outline">
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="picklist-left">
          <mat-form-field class="lvs-input-wide" appearance="fill">
            <mat-label>Account Id</mat-label>
            <input matInput type="text" size="50" [(ngModel)]="accountId" appearance="outline">
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="picklist-left">
          <mat-form-field class="lvs-input-wide" appearance="fill">
            <mat-label>Include Hospital Codes</mat-label>
            <mat-select [(ngModel)]="includeHospitalCodes" appearance="outline">
              <mat-option *ngFor="let includeHospitalCode of [true, false]" [value]="includeHospitalCode">{{includeHospitalCode}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="picklist-left">
          <mat-form-field class="lvs-input-wide" appearance="fill">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="type" appearance="outline">
              <mat-option *ngFor="let theType of types" [value]="theType">{{theType}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td class="picklist-right">
          <button mat-raised-button (click)="update()">Update</button>
        </td>
      </tr>
      <tr>
        <td class="picklist-left">
          <mat-label>Response code: {{statusCode}}</mat-label>
        </td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
