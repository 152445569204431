import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-event-hooks',
  templateUrl: './event-hooks.component.html',
  styleUrls: ['./event-hooks.component.scss'],
})
export class EventHooksComponent implements OnInit {
  discoveryResponse: any = null;
  cdsHookCallResponse: any = null;
  error: any = undefined;
  title: string;
  hookId: string;
  loading = true;
  mode : string = "discover";

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    this.title = routerOutlet.activatedRouteData.title;
  }

  ngOnInit() {
    this.discover();
  }

  callSmartOnFhirLaunchHook() {
    this.hookId = "SmartOnFhir Launch EventHook";
    let endpoint = `/dev-tools/event-hooks/smart-on-fhir-launch-cds-hook-cards`;
    this.loading = true;
    this.mode = "call-cds-hook"
    this.cdsHookCallResponse = null;
    this.error = null;
    try {
      this.dataService.postEndpointData(endpoint, {}).subscribe((cdsHookCallResponse: any) => {
        this.cdsHookCallResponse = cdsHookCallResponse;
        this.loading = false;
      }, (error) => {
        this.error = JSON.stringify(error, null, 2);
        this.loading = false;
      });
    } catch (error) {
      this.error = JSON.stringify(error, null, 2);
      this.loading = false;
    }
  }

  callCdsHook(hookId) {
    let endpoint = `/dev-tools/event-hooks/cds-hooks-mapping/${hookId}`;
    this.loading = true;
    this.mode = "call-cds-hook"
    this.cdsHookCallResponse = null;
    this.error = null;
    this.hookId = `${hookId} CDS Hook`;
    try {
      this.dataService.postEndpointData(endpoint, {}).subscribe((cdsHookCallResponse: any) => {
        this.cdsHookCallResponse = cdsHookCallResponse;
        this.loading = false;
      }, (error) => {
        this.error = JSON.stringify(error, null, 2);
        this.loading = false;
      });
    } catch (error) {
      this.error = JSON.stringify(error, null, 2);
      this.loading = false;
    }
  }

  discover() {
    this.mode = "discover";
    this.loading = true;
    this.discoveryResponse = null;
    this.error = null;
    let endpoint = `/dev-tools/event-hooks/cds-hooks-mapping`;
    try {
      this.dataService.getEndpointData(endpoint).subscribe((discoveryResponse: any) => {
        this.discoveryResponse = discoveryResponse;
        this.loading = false;
      }, (error) => {
        this.error = JSON.stringify(error, null, 2);
        this.loading = false;
      });
    } catch (error) {
      this.error = JSON.stringify(error, null, 2);
      this.loading = false;
    }
  }
}
