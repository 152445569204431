import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataService} from "./data.service";
import {Resource} from "fhir/r4";
import {getFirstTag, getProcessor, getSource} from "../../utils/resource-utils";

export type OnSuccessFn = (resource: Resource) => void;
export type OnFailFn = (error: any) => void;

export interface LcaHttpParams {
  [param: string]: string | string[];
}

const PRACTITIONER_TAGS_ENDPOINT = '/tags/practitioner';
const GET_RESOURCE_ENDPOINT = '/data/Resource';

@Injectable({providedIn: 'root'})
export class PractitionerTagsService {

  constructor(private http: HttpClient,
              private dataService: DataService) {
  }

  isBookmarked(resource: Resource): boolean {
    return this.getTag('bookmarked', resource) === 'true';
  }

  toggleBookmarked(resource: Resource, onComplete: EventEmitter<Resource>, onFail: EventEmitter<any>): void {
    const newValue = !(this.getTag('bookmarked', resource) === 'true');
    this.setTag('bookmarked', resource, JSON.stringify(newValue), onComplete, onFail);
  }

  public getTag(name: String, resource: Resource): string {
    const stringValue = getFirstTag(resource, `http://labcorp.com/fhir/meta/tag/practitioner/${name}`);
    return stringValue;
  }

  public setTag(name: string, resource: Resource, value: string, onComplete: EventEmitter<Resource>, onFail: EventEmitter<any>): void {
    const params = {
      sourceSystem: getSource(resource),
      processor: getProcessor(resource),
      resourceType: resource.resourceType,
      id: resource.id,
      name: name,
      value: value
    };
    if (!value) {
      this.dataService.deleteEndpointData(PRACTITIONER_TAGS_ENDPOINT, params).subscribe(
        (data: {}) => {
          onComplete?.emit(resource);
        },
        (error: {}) => {
          onFail?.emit(error);
        }
      );
    } else {
      this.dataService.putEndpointData(PRACTITIONER_TAGS_ENDPOINT, {}, params).subscribe(
        (data: {}) => {
          onComplete?.emit(resource);
        },
        (error: {}) => {
          onFail?.emit(error);
        }
      );
    }
  }
}
