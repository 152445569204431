import {
  Bundle,
  CodeableConcept,
  Coding,
  DiagnosticReport,
  DomainResource,
  Observation,
  Quantity,
  Resource
} from "fhir/r4";
import {periodToDisplayDate, dateTimeToDisplayDate} from "./date-utils";
import {Panel} from "../app/models/Panel";
import {Result} from "../app/models/Result";

// get source for any fhir resource with http://labcorp.com/fhir/meta/tag/lvs-core/source-system meta tag

export function getSourceAndIdKey(resource: Resource): string {
  return `${getSource(resource)}/${resource.resourceType}/${resource.id}`;
}

export function getSource(resource: Resource): string {
  return getFirstTag(resource, 'http://labcorp.com/fhir/meta/tag/lvs-core/source-system');
}

export function getProcessor(resource: Resource): string {
  return getFirstTag(resource, 'http://labcorp.com/fhir/meta/tag/lvs-core/processor');
}

export function getCodeGroups(resource: Resource): string[] {
  return getTags(resource, 'http://labcorp.com/fhir/meta/tag/lvs-core/code-group');
}

export function isSearchMatch(resource: Resource): boolean {
  return getFirstTag(resource, 'http://labcorp.com/fhir/meta/tag/lvs-core/search-match') === 'true';
}

export function isNew(resource: Resource): boolean {
  return getFirstTag(resource, 'http://labcorp.com/fhir/meta/tag/lvs-core/new') === 'true';
}

export function isAbnormal(resource: Resource): boolean {
  return getTags(resource, 'http://labcorp.com/fhir/meta/tag/lvs-core/flags')
    .filter(flag => flag === 'abnormal')
    .length > 0;
}

export function getFirstTag(resource: Resource, system: string): string {
  const tags = resource?.meta?.tag;
  if (Array.isArray(tags) && system) {
    for (const tag of tags) {
      if (tag?.system === system) {
        return tag.code;
      }
    }
  }
  return null;
}

export function getTags(resource: Resource, system: string): string[] {
  const returnValue: string[] = [];
  const tags = resource?.meta?.tag;
  if (Array.isArray(tags) && system) {
    for (const tag of tags) {
      if (tag?.system === system) {
        returnValue.push(tag.code);
      }
    }
  }
  return returnValue;
}

export function getLoincCodes(result: Result): Coding[] {
  const returnValue: Coding[] = [];
  if (Array.isArray(result?.observation?.code?.coding)) {
    for (const coding of result.observation.code.coding) {
      if (isLoinc(coding)) {
        returnValue.push(coding);
      }
    }
  }
  return returnValue;
}

export function isLoinc(coding: Coding) {
  return coding?.system === 'http://loinc.org' && coding.code;
}


export function codingDisplayName(coding: Coding): string {
  return coding?.display ? coding.display : coding?.code;
}

export function panelDisplayName(resource: DiagnosticReport | Observation): string {
  if (resource?.code?.text) {
    return resource.code.text;
  } else if (Array.isArray(resource?.code?.coding)) {
    return resource.code.coding.find(c => c.display).display;
  }
  return "unknown";
}


export function panelDisplayDate(resource: DiagnosticReport | Observation): string {
  if (resource?.effectivePeriod) {
    return periodToDisplayDate(resource.effectivePeriod);
  } else {
    return dateTimeToDisplayDate(resource?.effectiveDateTime);
  }
}

export interface Duplicate {
  reference: string;
  sourceSystem: string;
}

export function getDuplicates(resource: DomainResource): Duplicate[] {
  const returnValue: Duplicate[] = [];
  if (Array.isArray(resource?.extension)) {
    for (const extension of resource.extension) {
      if (extension?.url === 'http://labcorp.com/fhir/extensions/lvs-core/duplicates' && Array.isArray(extension.extension)) {
        const reference = extension.extension.find(element => {
          return element?.url === "reference";
        })?.valueReference?.reference;
        const sourceSystem = extension.extension.find(element => {
          return element?.url === "source-system";
        })?.valueString;
        returnValue.push({reference, sourceSystem});
      }
    }
  }
  return returnValue;
}

export function updatePanelsAndResults(panels: Panel[], bundle: Bundle) {
  panels.length = 0;
  if (Array.isArray(bundle?.entry)) {
    const results = [];

    bundle.entry.forEach(entry => {
      if (entry && entry.resource) {
        const resource = entry.resource;
        if ('Observation' === resource.resourceType) {
          results.push(new Result(resource));
        } else if ('DiagnosticReport' === resource.resourceType) {
          panels.push(new Panel(resource));
        } else {
          // TODO: Expose other results?
        }
      }
    });
    addResultsToPanels(panels, results);

  }
}

function hasResult(panel: Panel, result: Result): boolean {
  if ((panel?.source === result?.source)
    && result.observation.id
    && Array.isArray(panel.diagnosticReport.result)) {
    for (const diagnosticReportResult of panel.diagnosticReport.result) {
      if (diagnosticReportResult.reference === `Observation/${result.observation.id}`) {
        return true;
      }
    }
  }
  return false;
}

function addResultsToPanels(panels: Panel[], results: Result[]) {
  panels.forEach(panel => {
    results.forEach(result => {
      if (hasResult(panel, result)) {
        panel.results.push(result);
      }
    });
  });
}

export function observationValue(observation: Observation): string {
  // https://www.hl7.org/fhir/observation.html
  return observation.valueQuantity ? valueQuantity(observation.valueQuantity) :
    observation.valueCodeableConcept ? valueCodeableConcept(observation.valueCodeableConcept) :
      observation.valueString ? observation.valueString :
        observation.valueBoolean ? JSON.stringify(observation.valueBoolean) :
          observation.valueInteger ? JSON.stringify(observation.valueInteger) :
            observation.valueRange ? JSON.stringify(observation.valueRange) :
              observation.valueRatio ? JSON.stringify(observation.valueRatio) :
                observation.valueSampledData ? JSON.stringify(observation.valueSampledData) :
                  observation.valueTime ? observation.valueTime :
                    observation.valueDateTime ? observation.valueDateTime :
                      observation.valuePeriod ? JSON.stringify(observation.valuePeriod) :
                        null;
}

function valueQuantity(quantity: Quantity): string {
  return `${quantity.value} ${quantity.comparator ? quantity.comparator : ''} ${quantity.unit}`
}


function valueCodeableConcept(codeableConcept: CodeableConcept): string {
  const buf: string[] = [];
  if (codeableConcept.text) {
    buf.push(codeableConcept.text);
  }
  codeableConcept.coding.forEach(coding => {
    if (coding.display) {
      buf.push(coding.display);
    } else if (coding.code) {
      buf.push(coding.code);
    }
  });
  return buf.join();
}
