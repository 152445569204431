import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";

@Component({
    selector: 'app-picklist',
    templateUrl: './picklist.component.html',
    styleUrls: ['./picklist.component.scss'],
})
export class PicklistComponent implements OnInit {
    data: any = null;
    title: string;
    endpoint: string;
    loading = false;
    picklistId: string = "8690";
    accountId: string = "10966707";
    includeHospitalCodes: boolean = true;
    types: string[] = ["TEST", "DIAGNOSIS"];
    type: string = "TEST";
    statusCode?: number;

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private routerOutlet: RouterOutlet) {
        this.title = routerOutlet.activatedRouteData['title'];
        this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    }

    ngOnInit(): void {
      // do nothing
    }

    update() {
        this.getEndpointData();
    }

    getEndpointData() {
        this.data = null;
        const endpoint = `${this.endpoint}/${this.picklistId}?accountId=${this.accountId}&includeHospitalCodes=${this.includeHospitalCodes}&type=${this.type}`;
        try {
          this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
              this.data = data || {};
              this.statusCode = 200;
              this.loading = false;
          }, (error) => {
              this.data = (error && error.error) || error || {};
              this.statusCode = (error && error.status) || undefined;
              this.loading = false;
          });
        } catch (error) {
          this.data = (error && error.message) || error || {};
        }
    }
}
