import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";

@Component({
    selector: 'app-diagnosis-validate',
    templateUrl: './diagnosis-validate.component.html',
    styleUrls: ['./diagnosis-validate.component.scss'],
})
export class DiagnosisValidateComponent implements OnInit {
    data: any = null;
    title: string;
    endpoint: string;
    loading = false;
    statusCode?: number;
    payload: string = JSON.stringify({
      "dob": "2020-09-08",
      "dos": "2024-02-13T12:59:00-05:00",
      "dxCodes": [
        "D72.818"
      ],
      "gender": "F"
    }, null, 2);

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private routerOutlet: RouterOutlet) {
        this.title = routerOutlet.activatedRouteData['title'];
        this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    }

    ngOnInit(): void {
      // do nothing
    }

    update() {
        this.postEndpointData();
    }

    postEndpointData() {
        this.data = null;
        try {
          const payload = JSON.stringify(JSON.parse(this.payload));
          this.dataService.postEndpointData(this.endpoint, payload).subscribe((data: {}) => {
              this.data = data || {};
              this.statusCode = 200;
              this.loading = false;
          }, (error) => {
              this.data = (error && error.error) || error || {};
              this.statusCode = (error && error.status) || undefined;
              this.loading = false;
          });
        } catch (error) {
          this.data = (error && error.message) || error || {};
        }
    }
}
