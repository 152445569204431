import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import jwtDecode from "jwt-decode";
import {AuthService} from "../../services/auth.service";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-json-change-popup',
  templateUrl: './jwt-change-popup.component.html',
  styleUrls: ['./jwt-change-popup.component.scss'],
})
export class JwtChangePopupComponent {
  lvsRestJwt: any = null;
  lvsRestJwtTrimmed:any = null;
  lvsRestJwtOriginal: any = null;
  decodedJwt: any = null;
  error: string;
  callbackFn: any;

  jwtChangeStatus: string = 'Token has not changed';
  saveDisabled: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public matDialogData,
              private authService: AuthService,
              private dataService: DataService,
              private matDialog: MatDialog) {
    this.lvsRestJwt = matDialogData.lvsRestJwt;
    this.lvsRestJwtTrimmed = matDialogData.lvsRestJwt;
    this.callbackFn = matDialogData.callbackFn;
    this.lvsRestJwtOriginal = this.lvsRestJwt;
    if(this.lvsRestJwt) {
      this.decodedJwt = jwtDecode(this.lvsRestJwt);
    }

  }

  testJwt() {
    this.decodedJwt = null;
    this.error = null;

    try {
      this.lvsRestJwtTrimmed = trimJwtBase64(this.lvsRestJwt);
      try {
        if(this.lvsRestJwtTrimmed) {
          this.decodedJwt = jwtDecode(this.lvsRestJwtTrimmed);
        }
      } catch(errorIgnored){}

      if(this.lvsRestJwtTrimmed === this.lvsRestJwtOriginal) {
        this.jwtChangeStatus = 'Token has not changed';
        this.saveDisabled = true;
      } else if (!this.decodedJwt) {
        this.jwtChangeStatus = 'Invalid Token';
        this.saveDisabled = true;
      } else {
        this.jwtChangeStatus = 'Looks good! Click Change JWT to update your token';
        this.saveDisabled = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  save() {
    this.testJwt();
    if (this.decodedJwt) {
      try {
        if(!this.authService.getLvsAuthInfo()) {
          this.authService.setLvsAuthInfo({});
        }
        const lvsAuthInfo: any = {};
        lvsAuthInfo.roles = this.decodedJwt.roles;
        lvsAuthInfo.parameters = {na: "na parameters are not valid when you replace the jwt"};
        lvsAuthInfo.jwt = this.lvsRestJwtTrimmed;
        this.authService.setLvsAuthInfo(lvsAuthInfo);
        this.dataService.setElvisRestAPI(this.decodedJwt.iss);
        this.callbackFn(lvsAuthInfo);
        this.matDialog.closeAll();
      } catch (error) {
        this.error = JSON.stringify(error, null, 2);
      }
    }
  }

  /*
  {
    "jwt": "eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvbHZzLXJlc3QiLCJzdWIiOiJsYWJDb3JwVXNlciIsImF1ZCI6IndlYiIsInJvbGVzIjpbIlJPTEVfVVNFUiIsIlJPTEVfUEFUSUVOVF9WSUVXRVIiXSwiaWF0IjoxNjQ3Mjg1MjAxLCJleHAiOjE2NDcyODg3OTl9.2Htqi7CxO59E4mAQFyZWy9E3yCC9f_q6hZkOO9AjThVxcuWoE91JkGN9-BS8HAhP9hfwPKCcsdkbr-rbRNKcWg",
    "contentSecurityPolicy": {
      "frame-ancestors": "https://eeh-emr-hub-ui-dev.ald6h.cws.labcorp.com/"
    },
    "parameters": {
      "patient": [
        "485f3473-9d42-4201-803b-90938900d3b6"
      ]
    }
  }
   */
  onLvsRestJwtChange($event: any) {
    this.lvsRestJwt = $event;
    this.testJwt();
  }
}

// we will accept a base64 optionally preceeded by "Bearer", quotes or whitespace
// and optionally followed by quotes or whitespace
// these decorations are trimmmed and removed
function trimJwtBase64(source: string) {
  return source.replace("Authorization:", "")
    .replace("Authorization", "")
    .replace("Bearer", "")
    .replace(/\s'"/, "");
}
