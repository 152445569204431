import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {JwtDetailsComponent} from './components/jwt-details/jwt-details.component';
import {ShowEndpointComponent} from './components/show-endpoint/show-endpoint.component';
import {ShowPdfComponent} from './components/show-pdf/show-pdf.component';
import {CodeGroupsComponent} from './components/code-groups/code-groups.component';
import {SettingsComponent} from './components/settings/settings.component';
import {ShowCachedDataComponent} from './components/show-cached-data/show-cached-data.component';
import {CacheManagerComponent} from './components/cache-manager/cache-manager.component';
import {ShowPagedDataComponent} from './components/show-paged-data/show-paged-data.component';
import {PractitionerTagsComponent} from './components/practitioner-tags/practitioner-tags.component';
import {ClinicalReasoningByContextComponent} from './components/clinical-reasoning-by-context/clinical-reasoning-by-context.component';
import {ClinicalReasoningByPlanDefinitionComponent} from './components/clinical-reasoning-by-plan-definition/clinical-reasoning-by-plan-definition.component';
import {ReadResourcesComponent} from './components/read-resources/read-resources.component';
import {ReadResourceComponent} from './components/read-resource/read-resource.component';
import {StatisticsComponent} from './components/statistics/statistics.component';
import {LvsAuthComponent} from './components/lvs-auth/lvs-auth.component';
import {EapResultsComponent} from "./components/eap-results/eap-results.component";
import {ErsDirectComponent} from "./components/ers-direct/ers-direct.component";
import {CtraGuidanceComponent} from './components/ctra-guidance/ctra-guidance.component';
import {AssessmentResultsComponent} from "./components/assessment-results/assessment-results.component";
import {EventHooksComponent} from "./components/event-hooks/event-hooks.component";
import {CtraPractitionerRoleComponent} from "./components/ctra-practitioner-role/ctra-practitioner-role.component";
import {CtraResourcePathComponent} from "./components/ctra-resource-path/ctra-resource-path.component";
import {CtraResourceQueryComponent} from "./components/ctra-resource-query/ctra-resource-query.component";
import {LvsUiPanelViewComponent} from "./components/lvs-ui-panel-view/lvs-ui-panel-view.component";
import {LvsUiClinicalViewComponent} from "./components/lvs-ui-clinical-view/lvs-ui-clinical-view.component";
import {LvsUiPanelViewPortComponent} from "./components/lvs-ui/legacy/lvs-ui-panel-view-port/lvs-ui-panel-view-port.component";
import {
  LvsUiChemistryPanelPortComponent
} from "./components/lvs-ui/legacy/lvs-ui-clinical-view-port/lvs-ui-chemistry-panel-port.component";
import {
  LvsUiTablePanelPortComponent
} from "./components/lvs-ui/legacy/lvs-ui-table-panel-port/lvs-ui-table-panel-port.component";
import {PatientSearchComponent} from "./components/patient-search/patient-search.component";
import {
  LvsUiNoStreamPanelViewProgressComponent
} from "./components/lvs-ui-no-stream-panel-view-progress/lvs-ui-no-stream-panel-view-progress.component";
import {
  LvsUiResultTypeProgressComponent
} from "./components/lvs-ui-result-type-progress/lvs-ui-result-type-progress.component";
import { OncologyDataComponent } from './components/oncology-data/oncology-data.component';
import { ClinicalReasoningDashboardComponent } from './components/clinical-reasoning-dashboard/clinical-reasoning-dashboard.component';
import { ClinicalReasoningByPlanDefinitionNamedQueriesComponent } from './components/clinical-reasoning-by-plan-definition-named-queries/clinical-reasoning-by-plan-definition-named-queries.component';
import { AccessionValidateComponent } from "./components/add-on-testing/accession-validate/accession-validate.component";
import { AddonsCreateComponent } from "./components/add-on-testing/addons-create/addons-create.component";
import { DiagnosisSearchComponent } from "./components/add-on-testing/diagnosis-search/diagnosis-search.component";
import { DiagnosisValidateComponent } from "./components/add-on-testing/diagnosis-validate/diagnosis-validate.component";
import { PicklistComponent } from "./components/add-on-testing/picklist/picklist.component";
import { SaveLinkUserComponent } from "./components/add-on-testing/save-link-user/save-link-user.component";
import { ShowAddOnComponent } from "./components/add-on-testing/show-add-on/show-add-on.component";
import { TestMenuDetailComponent } from "./components/add-on-testing/test-menu-detail/test-menu-detail.component";
import { TestMenuSearchComponent } from "./components/add-on-testing/test-menu-search/test-menu-search.component";
import { TestsNotAllowedComponent } from "./components/add-on-testing/tests-not-allowed/tests-not-allowed.component";
import { UserAccountsComponent } from "./components/add-on-testing/user-accounts/user-accounts.component";
import { OrdersPanelViewComponent } from "./components/order-tracking/orders-panel-view/orders-panel-view.component";
import { OrdersSearchComponent } from "./components/order-tracking/orders-search/orders-search.component";
import { OrderDetailComponent } from "./components/order-tracking/order-detail/order-detail.component";

const routes: Routes = [
  {
    path: 'assessment-results',
    component: AssessmentResultsComponent,
    data: {
      title: 'Assessment Results',
      endpoint: '/dev-tools/assessment-results'
    }
  },
  {
    path: 'build-info',
    component: ShowEndpointComponent,
    data: {
      title: 'Build Info',
      endpoint: '/buildInfo'
    }
  },
  {
    path: 'cache-manager',
    component: CacheManagerComponent
  },
  {
    path: 'connection-health',
    component: ShowEndpointComponent,
    data: {
      title: 'Connection Health',
      endpoint: '/healthEx'
    }
  },
  {
    path: 'ctra-guidance',
    component: CtraGuidanceComponent,
    data: {
      title: 'Ctra Guidance',
      endpoint: '/ctra/GuidanceResponse?timeoutMillis=3000'
    }
  },
  {
    path: 'ctra-organizations',
    component: ShowPagedDataComponent,
    data: {
      title: 'Organizations',
      endpoint: '/Organization'
    }
  },
  {
    path: 'ctra-practitionerrole',
    component: CtraPractitionerRoleComponent,
    data: {
      title: 'Practitioner Role',
      endpoint: '/PractitionerRole'
    }
  },
  {
    path: 'ctra-researchstudies',
    component: ShowPagedDataComponent,
    data: {
      title: 'ResearchStudies',
      endpoint: '/ResearchStudy'
    }
  },
  {
    path: 'ctra-researchsubjects',
    component: ShowPagedDataComponent,
    data: {
      title: 'ResearchSubjects',
      endpoint: '/ResearchSubject'
    }
  },
  {
    path: 'ctra-resource-by-path',
    component: CtraResourcePathComponent,
    data: {
      title: 'Resource by path parameters',
      endpoint: '/'
    }
  },
  {
    path: 'ctra-resource-by-query',
    component: CtraResourceQueryComponent,
    data: {
      title: 'Resource by query parameters',
      endpoint: '/'
    }
  },
  {
    path: 'ctra-valueset',
    component: ShowPagedDataComponent,
    data: {
      title: 'ValueSet covance therapeutic area',
      endpoint: '/ValueSet/covance-therapeutic-area-value-set'
    }
  },
  {
    path: 'conditions',
    component: ShowPagedDataComponent,
    data: {
      title: 'Conditions',
      endpoint: '/data/Condition'
    }
  },
  {
    path: 'patient-search',
    component: PatientSearchComponent
  },
    {
      path: 'standard-report',
      component: OncologyDataComponent,
      data: {
        title: 'Standard Report (Oncology)',
        endpoint: '/data/standardReport'
      }
    },
  {
    path: 'diagnostic-reports-combined',
    component: ShowPagedDataComponent,
    data: {
      title: 'Diagnostic Reports (Combined)',
      endpoint: '/data/DiagnosticReport'
    }
  },
  {
    path: 'diagnostic-reports-emr',
    component: ShowPagedDataComponent,
    data: {
      title: 'Diagnostic Reports (EMR)',
      endpoint: '/data/emr/DiagnosticReport'
    }
  },
  {
    path: 'diagnostic-reports-labcorp',
    component: ShowPagedDataComponent,
    data: {
      title: 'Diagnostic Reports (LabCorp)',
      endpoint: '/data/lca/DiagnosticReport'
    }
  },
  {
    path: 'eap-results',
    component: EapResultsComponent,
    data: {
      title: 'EAP Results',
      endpoint: '/dev-tools/eap-results'
    }
  },
  {
    path: 'ers-direct',
    component: ErsDirectComponent,
    data: {
      title: 'ERS Direct',
      endpoint: '/dev-tools/ers-direct'
    }
  },
  {
    path: 'encounters',
    component: ShowPagedDataComponent,
    data: {
      title: 'Encounters',
      endpoint: '/data/Encounter'
    }
  },
  {
    path: 'event-hooks',
    component: EventHooksComponent,
    data: {
      title: 'Event Hooks'
    }
  },
  {
    path: 'immunizations',
    component: ShowPagedDataComponent,
    data: {
      title: 'Immunizations',
      endpoint: '/data/Immunization'
    }
  },
  {
    path: 'jwt-details',
    component: JwtDetailsComponent
  },
  {
    path: 'lvs-auth',
    component: LvsAuthComponent
  },
  {
    path: 'lvs-ui-clinical-view',
    component: LvsUiClinicalViewComponent,
    data: {
      title: 'Clinical View Emulator',
      endpoint: '/data/Observation'
    }
  },
  {
    path: 'lvs-ui-no-stream-panel-view-progress',
    component: LvsUiNoStreamPanelViewProgressComponent
  },
  {
    path: 'lvs-ui-panel-view',
    component: LvsUiPanelViewComponent,
    data: {
      title: 'Panel View Emulator',
      endpoint: '/data/DiagnosticReport'
    }
  },
  {
    path: 'lvs-ui-chemistry-panel-port',
    component: LvsUiChemistryPanelPortComponent,
    data: {
      title: 'Chemistry Panel Port Emulator',
      endpoint: '/lvs-ui/legacy/chemistry-panel'
    }
  },
  {
    path: 'lvs-ui-result-type-progress',
    component: LvsUiResultTypeProgressComponent
  },
  {
    path: 'lvs-ui-table-panel-port',
    component: LvsUiTablePanelPortComponent,
    data: {
      title: 'Chemistry Panel Port Emulator',
      endpoint: '/lvs-ui/legacy/table-panel'
    }
  },
  {
    path: 'lvs-ui-legacy-panel-view-port',
    component: LvsUiPanelViewPortComponent,
    data: {
      title: 'Panel View Port Emulator',
      endpoint: '/lvs-ui/legacy/panel-view'
    }
  },
  {
    path: 'lvs-ui-clinical-group',
    component: ShowEndpointComponent,
    data: {
      title: 'Clinical Groups',
      endpoint: '/lvs-ui/legacy/clinical-group'
    }
  },
  {
    path: 'medication-administrations',
    component: ShowPagedDataComponent,
    data: {
      title: 'Medication Administrations',
      endpoint: '/data/MedicationAdministration'
    }
  },
  {
    path: 'medication-dispenses',
    component: ShowPagedDataComponent,
    data: {
      title: 'Medication Dispenses',
      endpoint: '/data/MedicationDispense'
    }
  },
  {
    path: 'medication-requests',
    component: ShowPagedDataComponent,
    data: {
      title: 'Medication Requests',
      endpoint: '/data/MedicationRequest'
    }
  },
  {
    path: 'medication-statements',
    component: ShowPagedDataComponent,
    data: {
      title: 'Medication Statements',
      endpoint: '/data/MedicationStatement'
    }
  },
  {
    path: 'observations-combined',
    component: ShowPagedDataComponent,
    data: {
      title: 'Observations (Combined)',
      endpoint: '/data/Observation'
    }
  },
  {
    path: 'observations-emr',
    component: ShowPagedDataComponent,
    data: {
      title: 'Observations (EMR)',
      endpoint: '/data/emr/Observation'
    }
  },
  {
    path: 'observations-labcorp',
    component: ShowPagedDataComponent,
    data: {
      title: 'Observations (LabCorp)',
      endpoint: '/data/lca/Observation'
    }
  },
  {
    path: 'patient-info',
    component: ShowEndpointComponent,
    data: {
      title: 'Patient Info',
      endpoint: '/data/Patient'
    }
  },
  {
    path: 'phoenix-patient-info',
    component: ShowEndpointComponent,
    data: {
      title: 'Phoenix Patient Info',
      endpoint: '/data/PhoenixPatient'
    }
  },
  {
    path: 'service-requests',
    component: ShowEndpointComponent,
    data: {
      title: 'Service Requests',
      endpoint: '/data/ServiceRequest'
    }
  },
  {
    path: 'pdf-report',
    component: ShowPdfComponent,
    data: {
      title: 'PDF Report',
      documentReferenceEndpoint: '/pdf/DocumentReference',
      binaryEndpoint: '/pdf/binary',
      diagnosticReportEndpoint: '/data/DiagnosticReport'
    }
  },
  {
    path: 'pdf-document-reference',
    component: ShowEndpointComponent,
    data: {
      title: 'PDF'
    }
  },
  {
    path: 'procedures',
    component: ShowPagedDataComponent,
    data: {
      title: 'Procedures',
      endpoint: '/data/Procedure'
    }

  },
  {
    path: 'practitioner-loinc-group-order',
    component: ShowEndpointComponent,
    data: {
      title: 'Practitioner Loinc Group Order',
      endpoint: '/loincGroups/PractitionerLoincGroupOrder'
    }

  },
  {
    path: 'read-resource',
    component: ReadResourceComponent,
    data: {
      title: 'Read Resource',
      endpoint: '/data/Resource'
    }
  },
  {
    path: 'read-resources',
    component: ReadResourcesComponent,
    data: {
      title: 'Read Resources',
      endpoint: '/data/Resources'
    }
  },
  {
    path: 'user-info',
    component: ShowEndpointComponent,
    data: {
      title: 'User Info',
      endpoint: '/data/UserInfo'
    }

  },
  {
    path: 'show-cached-data',
    component: ShowCachedDataComponent
  },
  {
    path: 'clinical-reasoning-dashboard',
    component: ClinicalReasoningDashboardComponent,
    data: {
      title: 'By Context',
      endpoint: '/clinical-reasoning/cds-dashboard/GuidanceResponse'
    }
  },
  {
    path: 'clinical-reasoning-by-context',
    component: ClinicalReasoningByContextComponent,
    data: {
      title: 'By Context',
      endpoint: '/clinical-reasoning/GuidanceResponse'
    }
  },
  {
    path: 'clinical-reasoning-by-plan-definition-named-queries',
    component: ClinicalReasoningByPlanDefinitionNamedQueriesComponent,
    data: {
      title: 'By Context',
      endpoint: '/clinical-reasoning/NamedPlanDefinitionQueries'
    }
  },
  {
    path: 'clinical-reasoning-by-plan-definition',
    component: ClinicalReasoningByPlanDefinitionComponent,
    data: {
      title: 'By Context',
      endpoint: '/clinical-reasoning/PlanDefinition'
    }
  },
  {
    path: 'code-groups',
    component: CodeGroupsComponent,
    data: {
      title: 'Code Groups',
      endpoint: '/code-groups/code-group-order'
    }
  },
  {
    path: 'code-group-order-verbose',
    component: ShowEndpointComponent,
    data: {
      title: 'Code Group Order (Verbose)',
      summary: 'This shows the results of adding includeTarget=true parameter to /lvs-rest/code-groups/code-group-order endpoint',
      endpoint: '/code-groups/code-group-order?includeTarget=true'
    }
  },
  {
    path: 'practitioner-tags',
    component: PractitionerTagsComponent,
    data: {
      title: 'Practitioner Tags',
      endpoint: '/tags/practitioner'
    }
  },
  {
    path: 'settings-app',
    component: SettingsComponent,
    data: {
      title: 'App Settings',
      summary: `App Settings apply to all Practitioners using this app, for this customer.
              Clients can view whitelisted app settings, but connot modify them.
              App Settings are typically controlled via customer portal.`,
      endpoint: '/settings/app-settings',
      allowEdits: false
    }
  },
  {
    path: 'settings-practitioner',
    component: SettingsComponent,
    data: {
      title: 'Practitioner Settings',
      summary: `Practitioner Settings apply to the CURRENT Practitioner, for ALL Patients, using this app,
            for this customer.
            Clients can view and modify whitelisted Practitioner Settings.`,
      endpoint: '/settings/practitioner-settings',
      allowEdits: true
    }
  },
  {
    path: 'settings-practitioner-patient',
    component: SettingsComponent,
    data: {
      title: 'Practitioner Patient Settings',
      summary: `Practitioner Patient Settings apply to the CURRENT Practitioner and the CURRENT Patient,
            using this app, for this customer.
            Clients can view and modify whitelisted Practitioner Patient Settings.`,
      endpoint: '/settings/practitioner-patient-settings',
      allowEdits: true
    }
  },
  {
    path: 'accession-validate',
    component: AccessionValidateComponent,
    data: {
      title: 'Accession Validate',
      endpoint: '/lvs-ui/legacy/addons/accession/validate'
    }
  },
  {
    path: 'addons-create',
    component: AddonsCreateComponent,
    data: {
      title: 'Addons Create',
      endpoint: '/lvs-ui/legacy/addons/create'
    }
  },
  {
    path: 'diagnosis-search',
    component: DiagnosisSearchComponent,
    data: {
      title: 'Diagnosis Search',
      endpoint: '/lvs-ui/legacy/diagnosis/search'
    }
  },
  {
    path: 'diagnosis-validate',
    component: DiagnosisValidateComponent,
    data: {
      title: 'Diagnosis Validate',
      endpoint: '/lvs-ui/legacy/diagnosis/validate'
    }
  },
  {
    path: 'picklist',
    component: PicklistComponent,
    data: {
      title: 'Picklist',
      endpoint: '/lvs-ui/legacy/picklist'
    }
  },
  {
    path: 'save-link-user',
    component: SaveLinkUserComponent,
    data: {
      title: 'Save Link User',
      endpoint: '/lvs-ui/legacy/saveLinkUser'
    }
  },
  {
    path: 'show-add-on',
    component: ShowAddOnComponent,
    data: {
      title: 'Show Add On',
      endpoint: '/lvs-ui/legacy/showAddOn'
    }
  },
  {
    path: 'test-menu-detail',
    component: TestMenuDetailComponent,
    data: {
      title: 'Test-menu Detail',
      endpoint: '/lvs-ui/legacy/test-menu/detail'
    }
  },
  {
    path: 'test-menu-search',
    component: TestMenuSearchComponent,
    data: {
      title: 'Test-menu Search',
      endpoint: '/lvs-ui/legacy/test-menu/search'
    }
  },
  {
    path: 'tests-not-allowed',
    component: TestsNotAllowedComponent,
    data: {
      title: 'Tests Not Allowed',
      endpoint: '/lvs-ui/legacy/addons/tests/not-allowed'
    }
  },
  {
    path: 'user-accounts',
    component: UserAccountsComponent,
    data: {
      title: 'User Accounts',
      endpoint: '/lvs-ui/legacy/user/accounts'
    }
  },
  {
    path: 'orders-search',
    component: OrdersSearchComponent,
    data: {
      title: 'Orders Search',
      endpoint: '/orders'
    }
  },
  {
    path: 'orders-panel-view',
    component: OrdersPanelViewComponent,
    data: {
      title: 'Orders Panel View',
      endpoint: '/lvs-ui/orders/orders-panel-view'
    }
  },
  {
    path: 'order-detail',
    component: OrderDetailComponent,
    data: {
      title: 'Order Detail',
      endpoint: '/lvs-ui/orders'
    }
  },
  {
    path: 'statistics',
    component: StatisticsComponent,
    data: {
      title: 'Statistics'
    }
  },
  {
    path: '',
    component: HomeComponent
  },
  // {
  //     path: '',
  //     redirectTo: '/welcome',
  //     pathMatch: 'full'
  // },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
