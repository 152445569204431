import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {JsonEditorOptions} from 'ang-jsoneditor';
import {JsonWidgetStateService} from "../../services/json-widget-state.service";
import {BaseSubscriber} from "../../../utils/base-subscriber";

@Component({
  selector: 'app-json-widget',
  templateUrl: './json-widget.component.html',
  styleUrls: ['./json-widget.component.scss'],
})
export class JsonWidgetComponent extends BaseSubscriber implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  data: any;

  mode: string;

  @Input()
  readonly = true;

  @Input()
  saveFn: any;

  @ViewChild('testName', {static: false})
  editor: any; // JsonEditorComponent;

  jsonEditorOptions: JsonEditorOptions;

  constructor(private jsonWidgetStateService: JsonWidgetStateService) {
    super();
    this.mode = this.jsonWidgetStateService.mode;
  }

  ngAfterViewInit(): void {
  }

  onModeChange() {
    this.jsonWidgetStateService.mode = this.mode;
  }

  getEditorLabel() {
    return this.readonly ? 'JSON Viewer' : 'JSON Editor';
  }

  ngOnInit() {
    this.jsonEditorOptions = this.makeOptions();
  }

  makeOptions() {
    const x = new JsonEditorOptions();
    x.mode = this.readonly ? 'view' : 'tree';
    x.enableSort = false;
    x.enableTransform = false;
    x.statusBar = false;
    x.onChangeJSON = () => this.onChangeJSON;
    x.onChangeText = () => this.onChangeText();
    return x;
  }

  onSave() {
    console.log('on save', arguments);
    if (this.saveFn) {
      this.saveFn(arguments);
    }
  }

  onChangeJSON() {
  }

  onChangeText() {
  }

  showJson($event) {

  }

}
