import {Period} from "fhir/r4";


export function periodToDisplayDate(period: Period): string {
  if (period?.start) {
    return dateTimeToDisplayDate(period.start);
  } else if (period?.end) {
    return dateTimeToDisplayDate(period.end);
  }
  return "unknown";
}

export function dateTimeToDisplayDate(date: string): string {
  return date ? date : "unknown";
}
