import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-show-cached-data.component',
  templateUrl: './show-cached-data.component.html',
  styleUrls: ['./show-cached-data.component.scss'],
})
export class ShowCachedDataComponent implements OnInit {
  cachedDataString = '';
  error: any = null;
  key: string = null;
  loading = false;
  pageNumber = 1;
  pageSize = 9999999;
  scopes = ['patient', 'practitionerPatient', 'practitioner'];
  scope = 'practitionerPatient';

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }

  ngOnInit() {
  }

  hasKey(): boolean {
    return (typeof this.key === 'string' && this.key.length > 0);
  }

  canPut(): boolean {
    try {
      if (this.hasKey() && this.cachedDataString) {
        const cachedData = JSON.parse(this.cachedDataString);
        return typeof cachedData === 'object';
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  private getEndpoint(): string {
    return `/cache/data?key=${encodeURI(this.key)}&scope=${this.scope}`;
  }

  putData() {
    if (this.hasKey()) {
      this.error = null;
      try {
        this.loading = true;
        const dataIn = JSON.parse(this.cachedDataString);
        this.dataService.putEndpointData(this.getEndpoint(), dataIn).subscribe((data) => {
          this.loading = false;
        }, (error) => {
          this.error = (error && error.error) || error || {};
          if (this.error instanceof Object) {
            this.error = JSON.stringify(this.error, null, 2);
          }
          this.loading = false;
        });
      } catch (error) {
        this.error = (error && error.error) || error || {};
        if (this.error instanceof Object) {
          this.error = JSON.stringify(this.error, null, 2);
        }
        this.loading = false;
      }
    }
  }

  getData() {
    if (this.hasKey()) {
      this.cachedDataString = '';
      this.error = null;
      try {
        this.loading = true;
        this.dataService.getEndpointData(this.getEndpoint()).subscribe((data) => {
          this.cachedDataString = JSON.stringify(data, null, 2);
          this.loading = false;
        }, (error) => {
          this.error = (error && error.error) || error || {};
          if (this.error instanceof Object) {
            this.error = JSON.stringify(this.error, null, 2);
          }
          this.loading = false;
        });
      } catch (error) {
        this.error = (error && error.error) || error || {};
        if (this.error instanceof Object) {
          this.error = JSON.stringify(this.error, null, 2);
        }
        this.loading = false;
      }
    }
  }

  deleteData() {
    if (this.hasKey()) {
      this.cachedDataString = '';
      this.error = null;
      try {
        this.loading = true;
        this.dataService.deleteEndpointData(this.getEndpoint()).subscribe((data) => {
          this.cachedDataString = JSON.stringify(data, null, 2);
          this.loading = false;
        }, (error) => {
          this.error = (error && error.error) || error || {};
          if (this.error instanceof Object) {
            this.error = JSON.stringify(this.error, null, 2);
          }
          this.loading = false;
        });
      } catch (error) {
        this.error = (error && error.error) || error || {};
        if (this.error instanceof Object) {
          this.error = JSON.stringify(this.error, null, 2);
        }
        this.loading = false;
      }
    }
  }
}
