<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>

    <table class="show-paged-data-header">
      <tr>
        <td class="show-paged-data-left">
          <mat-form-field appearance="fill">
            <mat-label>Page Size</mat-label>
            <mat-select [(ngModel)]="pageSize" appearance="outline">
              <mat-option *ngFor="let pageSizeCur of pageSizes" [value]="pageSizeCur">{{pageSizeCur}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label >Page Number</mat-label>
            <input matInput type="number" size="4" [value]="pageNumber" appearance="outline">
          </mat-form-field>
        </td>
        <td class="show-paged-data-right">
          {{count}}&nbsp;results <button mat-raised-button (click)="update()">Update</button>
        </td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
