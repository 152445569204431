<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>
    <table class="tests-not-allowed-header">
      <tr>
        <td class="tests-not-allowed-right">
          <button mat-raised-button (click)="update()">Update</button>
        </td>
      </tr>
      <tr>
        <td class="tests-not-allowed-left">
          <mat-label>Response code: {{statusCode}}</mat-label>
        </td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
