import {Result} from './Result';
import {getSource, panelDisplayDate, panelDisplayName} from '../../utils/resource-utils';
import {DiagnosticReport} from "fhir/r4";

export class Panel {
  name: string;
  date: string;
  status: string;
  source: string;
  results: Result[] = [];

  expanded: boolean = false;
  diagnosticReport: DiagnosticReport;

  constructor(diagnosticReport: DiagnosticReport) {
    this.diagnosticReport = diagnosticReport;
    if (Array.isArray(this.diagnosticReport.meta)) {
      this.diagnosticReport.meta.forEach(meta => {
        if (meta?.system === 'http://labcorp.com/fhir/meta/tag/lvs-core/source-system') {
          this.source = meta.code;
        }
      });
    }
    this.source = getSource(this.diagnosticReport);
    this.name = panelDisplayName(this.diagnosticReport);
    this.date = panelDisplayDate(this.diagnosticReport);
    this.status = this.diagnosticReport.status;
  }

  getSource(): string {
    return getSource(this.diagnosticReport);
  }
}
