<mat-card>
    <mat-card-title>Search For Plan Definitions By Named Queries</mat-card-title>
    <mat-card-content>
        <div>
            <mat-form-field class="lvs-input-wide" appearance="fill">
                <mat-label>Select NamedQueries</mat-label>
                <mat-select [(ngModel)]="selectedPlanDefinitionNamedQueries" [compareWith]="compareNamedQueries" appearance="outline" multiple>
                    <mat-option *ngFor="let planDefinitionNamedQuery of planDefinitionNamedQueries"
                        [value]="planDefinitionNamedQuery">{{planDefinitionNamedQuery.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-raised-button (click)="getPlanDefinitions()">
                <mat-icon>search</mat-icon>
                Get Plan Definitions
            </button>
        </div>
    </mat-card-content>
</mat-card>

<app-search-plan-definition-by-coding [planDefinitions]="planDefinitions"></app-search-plan-definition-by-coding>