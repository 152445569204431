import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {Observable} from 'rxjs/Observable';


/**
 * Simple base class that handles subscriptions in a common way,
 */

// wrapper that logs any exceptions within the measurementSubscription's handler.
// function subscriptionErrorHandler(fn: any) {
//     return () => {
//         try {
//             fn.apply(this, arguments);
//         } catch (e) {
//             console.error(e);
//             console.error(e.stack);
//         }
//     };
// }

@Component({
  template: ''
})
export class BaseSubscriber implements OnDestroy {
    private subscriptions: Subscription[] = [];

    protected addSubscription(observable: Observable<any>, fn: any) {
        this.subscriptions.push(observable.subscribe(fn));
    }

    ngOnDestroy() {
        this.unsubscribeAll();
    }

    protected unsubscribeAll() {
        if (this.subscriptions) {
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }
        this.subscriptions = [];
    }
}
