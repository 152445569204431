<mat-card>
  <mat-card-title>Welcome</mat-card-title>
  <mat-card-content>
    <p>This app demonstrates use of the lvs-rest API</p>
    <p>It is designed primarily to assist in debugging DxA UI issues, and integrating with different EMRs</p>
    <button mat-raised-button (click)="showDialog('Build Info', buildInfo)">
      <mat-icon>code</mat-icon>
      Build Info
    </button>
  </mat-card-content>
  <mat-card-content>
    <span>version: {{version}}</span>
  </mat-card-content>
</mat-card>
