<mat-card>
  <mat-card-title>no stream panel view progress</mat-card-title>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div class="display-type-container">
      Mode:&nbsp;<select [(ngModel)]="mode">
      <option value="initial">initial</option>
      <option value="subsequent">subsequent</option>
      <option value="mergedOnly">mergedOnly</option>
    </select> &nbsp;
      <div class="update-button">
        <button mat-raised-button (click)="getEndpointData()"
                matTooltip="Make a new call to POST /lvs-rest/data/DiagnosticReport using the above pagination options">
          Update
        </button>&nbsp;&nbsp;&nbsp;&nbsp;<mat-checkbox [(ngModel)]="autoReload" (change)="toggleAutoReload()">Auto Reload</mat-checkbox>
      </div>
    </div>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-title>Load info</mat-card-title>
  <mat-card-content>
    <div *ngFor="let loadLog of loadLogs">{{loadLog}}</div>
    <div class="display-type-container">
      Count:&nbsp;{{loadCount}}
    </div>
  </mat-card-content>
</mat-card>
