<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>
    <p *ngIf="summary">{{summary}}</p>
    <p>
      The showHidden checkbox allows you to see properties not normally available to UI clients.
      These are available only if devTools is enabled, and should not be used in production.
    </p>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <mat-error class="error" *ngIf="error">
      <pre>{{error}}</pre>
    </mat-error>
    <div class="buttons">
      <span *ngIf="mode==='list'">
        <button *ngIf="allowEdits" mat-raised-button (click)="showAddSetting()">Add Setting</button>
        <button mat-raised-button (click)="showJsonView()">Json View</button>
      </span>
      <span *ngIf="mode!=='list' && mode != 'json-view'">
        <button mat-raised-button (click)="mode = 'list'">Back</button>
      </span>
      <span *ngIf="mode==='json-view'">
        <button mat-raised-button (click)="mode = 'list'">Table View</button>
      </span>
      <mat-checkbox (change)="toggleShowHidden()">Show Hidden Settings</mat-checkbox>
    </div>
    <div *ngIf="mode==='json-view'">
      <app-json-widget [readonly]="false" [data]="data"></app-json-widget>
    </div>
    <div *ngIf="mode==='list'">
      <table>
        <tr *ngFor="let key of keys(data)" [class]="allowEdits ? 'clickable' : ''" (click)="showSetting(key)">
          <td>{{key}}</td>
          <td class="list-view-right">
            <pre>{{data[key] | json}}</pre>
          </td>
        </tr>
      </table>
    </div>
    <div *ngIf="mode==='show-setting'">
      <table>
        <tr>
          <th colspan="2">Edit {{title}} ({{key}})</th>
        </tr>
        <tr>
          <textarea [(ngModel)]='curSetting' rows="30" cols="120"></textarea>
        </tr>
      </table>
      <button mat-raised-button (click)="updateSetting()">Update</button>
      <button mat-raised-button (click)="deleteSetting()">Delete</button>
    </div>
    <div *ngIf="mode==='show-add-setting'">
      <table>
        <tr>
          <th colspan="2">Add {{title}}</th>
        </tr>
        <tr>
          <input type="text" [(ngModel)]='key' rows="30" cols="120">
        </tr>
        <tr>
          <textarea [(ngModel)]='curSetting' rows="30" cols="120"></textarea>
        </tr>
      </table>
      <button mat-raised-button (click)="updateSetting()">Add setting</button>
      <button mat-raised-button (click)="mode = 'list'">Cancel</button>
    </div>
  </mat-card-content>
</mat-card>
