// Observation default filter data
export function getObservationDefaultFilterData() {
  return {
    // Observation filters
    abnormal: null,
    addedByLabcorp: null,
    codeGroups: null,
    codes: null,
    diagnosticReport: {
      practitionerTags: null
    },
    newResource: null,
    sourceSystemNames: null,
    text: null,
    startDate: null,
    endDate: null,
    // pagination options
    // pagination options
    pagination: {
      pageNumber: 1,
      pageSize: null,
      countDuplicatesAsOneResult: true
    },
    includeDiagnosticReports: true
  }
}

// DiagnosticReport default filter data
export function getDiagnosticReportDefaultFilterData() {
  return {
    lpids: null,
    addedByLabcorp: null,
    codes: null,
    labcorpOnly: null,
    newResource: null,
    observation: {
      abnormal: null,
      codeGroups: null,
      codes: null,
      text: null
    },
    practitionerTags: null,
    sourceSystemNames: null,
    text: null,
    startDate: null,
    endDate: null,
    // pagination options
    pagination: {
      pageNumber: 1,
      pageSize: 10,
      countDuplicatesAsOneResult: true
    },
    includeObservations: true
  }
}

export function getClinicalViewPortDefaultFilterData() {
  return {
    "requestParams": {
      "cdsEnabled": true,
      "favoritesData": {
        "onlyDisplayFavorites": false,
        "favoriteClinicalGroups": [
          "Lipid Tests",
          "Anemia Tests",
          "UA - Micro"
        ]
      },
      "filterCriteria": {
        "includeDiagnosticReports": true,
        "pagination": {
          "pageNumber": 1,
          "pageSize": null,
          "countDuplicatesAsOneResult": true
        }
      },
      "pagination": {
        "pageNumber": 1,
        "pageSize": 5
      },
      "selectedGroups": [
        "Eoin"
      ]
    },
    "codeGroups": [
      {
        "code": "Eoin",
        "type": "practitioner-defined",
        "target": [
          {
            "code": "721-1",
            "system": "http://loinc.org",
            "display": "Free Hemoglobin"
          },
          {
            "code": "4635-9",
            "system": "http://loinc.org",
            "display": "Free Hemoglobin"
          },
          {
            "code": "17856-6",
            "system": "http://loinc.org",
            "display": "HbA1c/Total Hemoglobin"
          },
          {
            "code": "718-7",
            "system": "http://loinc.org",
            "display": "Hemoglobin"
          },
          {
            "code": "30313-1",
            "system": "http://loinc.org",
            "display": "Hemoglobin"
          },
          {
            "code": "4548-4",
            "system": "http://loinc.org",
            "display": "Hemoglobin A1c"
          }
        ]
      },
      {
        "code": "GI/Liver Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "1742-6",
            "system": "http://loinc.org",
            "display": "Alanine aminotransferase [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "1920-8",
            "system": "http://loinc.org",
            "display": "Aspartate aminotransferase [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "1751-7",
            "system": "http://loinc.org",
            "display": "Albumin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1975-2",
            "system": "http://loinc.org",
            "display": "Bilirubin.total [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2885-2",
            "system": "http://loinc.org",
            "display": "Protein [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "6768-6",
            "system": "http://loinc.org",
            "display": "Alkaline phosphatase [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "1968-7",
            "system": "http://loinc.org",
            "display": "Bilirubin.direct [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1971-1",
            "system": "http://loinc.org",
            "display": "Bilirubin.indirect [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3040-3",
            "system": "http://loinc.org",
            "display": "Lipase [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "1798-8",
            "system": "http://loinc.org",
            "display": "Amylase [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "2324-2",
            "system": "http://loinc.org",
            "display": "Gamma glutamyl transferase [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "22763-7",
            "system": "http://loinc.org",
            "display": "Ammonia [Mass/volume] in Plasma"
          },
          {
            "code": "16362-6",
            "system": "http://loinc.org",
            "display": "Ammonia [Moles/volume] in Plasma"
          },
          {
            "code": "2064-4",
            "system": "http://loinc.org",
            "display": "Ceruloplasmin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1825-9",
            "system": "http://loinc.org",
            "display": "Alpha 1 antitrypsin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "15015-1",
            "system": "http://loinc.org",
            "display": "Alkaline phosphatase.liver/Alkaline phosphatase.total in Serum or Plasma"
          },
          {
            "code": "15014-4",
            "system": "http://loinc.org",
            "display": "Alkaline phosphatase.intestinal/Alkaline phosphatase.total in Serum or Plasma"
          },
          {
            "code": "1779-8",
            "system": "http://loinc.org",
            "display": "Alkaline phosphatase.liver [Enzymatic activity/volume] in Serum or Plasma"
          }
        ]
      },
      {
        "code": "Routine Chemistry",
        "type": "emr-defined",
        "target": [
          {
            "code": "2823-3",
            "system": "http://loinc.org",
            "display": "Potassium [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "2345-7",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2951-2",
            "system": "http://loinc.org",
            "display": "Sodium [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "2028-9",
            "system": "http://loinc.org",
            "display": "Carbon dioxide, total [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "2075-0",
            "system": "http://loinc.org",
            "display": "Chloride [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "17861-6",
            "system": "http://loinc.org",
            "display": "Calcium [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2339-0",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Blood"
          },
          {
            "code": "10466-1",
            "system": "http://loinc.org",
            "display": "Anion gap 3 in Serum or Plasma"
          },
          {
            "code": "2777-1",
            "system": "http://loinc.org",
            "display": "Phosphate [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2601-3",
            "system": "http://loinc.org",
            "display": "Magnesium [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "4548-4",
            "system": "http://loinc.org",
            "display": "Hemoglobin A1c/Hemoglobin.total in Blood"
          },
          {
            "code": "19123-9",
            "system": "http://loinc.org",
            "display": "Magnesium [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "6298-4",
            "system": "http://loinc.org",
            "display": "Potassium [Moles/volume] in Blood"
          },
          {
            "code": "33037-3",
            "system": "http://loinc.org",
            "display": "Anion gap in Serum or Plasma"
          },
          {
            "code": "1959-6",
            "system": "http://loinc.org",
            "display": "Bicarbonate [Moles/volume] in Blood"
          },
          {
            "code": "2947-0",
            "system": "http://loinc.org",
            "display": "Sodium [Moles/volume] in Blood"
          },
          {
            "code": "1994-3",
            "system": "http://loinc.org",
            "display": "Calcium.ionized [Moles/volume] in Blood"
          },
          {
            "code": "3084-1",
            "system": "http://loinc.org",
            "display": "Urate [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1995-0",
            "system": "http://loinc.org",
            "display": "Calcium.ionized [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "27353-2",
            "system": "http://loinc.org",
            "display": "Glucose mean value [Mass/volume] in Blood Estimated from glycated hemoglobin"
          },
          {
            "code": "17856-6",
            "system": "http://loinc.org",
            "display": "Hemoglobin A1c/Hemoglobin.total in Blood by HPLC"
          },
          {
            "code": "29265-6",
            "system": "http://loinc.org",
            "display": "Calcium [Moles/volume] corrected for albumin in Serum or Plasma"
          },
          {
            "code": "2069-3",
            "system": "http://loinc.org",
            "display": "Chloride [Moles/volume] in Blood"
          },
          {
            "code": "2692-2",
            "system": "http://loinc.org",
            "display": "Osmolality of Serum or Plasma"
          },
          {
            "code": "1558-6",
            "system": "http://loinc.org",
            "display": "Fasting glucose [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2524-7",
            "system": "http://loinc.org",
            "display": "Lactate [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "1863-0",
            "system": "http://loinc.org",
            "display": "Anion gap 4 in Serum or Plasma"
          },
          {
            "code": "17864-0",
            "system": "http://loinc.org",
            "display": "Calcium.ionized [Mass/volume] in Serum or Plasma by Ion-selective membrane electrode (ISE)"
          },
          {
            "code": "32133-1",
            "system": "http://loinc.org",
            "display": "Lactate [Moles/volume] in Plasma venous"
          },
          {
            "code": "2513-0",
            "system": "http://loinc.org",
            "display": "Ketones [Presence] in Serum or Plasma"
          },
          {
            "code": "18182-6",
            "system": "http://loinc.org",
            "display": "Osmolality of Serum or Plasma by calculation"
          },
          {
            "code": "6873-4",
            "system": "http://loinc.org",
            "display": "Beta hydroxybutyrate [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "2597-3",
            "system": "http://loinc.org",
            "display": "Magnesium [Moles/volume] in Red Blood Cells"
          }
        ]
      },
      {
        "code": "Blood Gases",
        "type": "emr-defined",
        "target": [
          {
            "code": "11555-0",
            "system": "http://loinc.org",
            "display": "Base excess in Blood"
          },
          {
            "code": "11557-6",
            "system": "http://loinc.org",
            "display": "Carbon dioxide [Partial pressure] in Blood"
          },
          {
            "code": "11556-8",
            "system": "http://loinc.org",
            "display": "Oxygen [Partial pressure] in Blood"
          },
          {
            "code": "2713-6",
            "system": "http://loinc.org",
            "display": "Oxygen saturation.calculated from oxygen partial pressure in Blood"
          },
          {
            "code": "11558-4",
            "system": "http://loinc.org",
            "display": "pH of Blood"
          },
          {
            "code": "20565-8",
            "system": "http://loinc.org",
            "display": "Carbon dioxide, total [Moles/volume] in Blood"
          },
          {
            "code": "2753-2",
            "system": "http://loinc.org",
            "display": "pH of Serum or Plasma"
          },
          {
            "code": "3151-8",
            "system": "http://loinc.org",
            "display": "Inhaled oxygen flow rate"
          },
          {
            "code": "2744-1",
            "system": "http://loinc.org",
            "display": "pH of Arterial blood"
          },
          {
            "code": "30313-1",
            "system": "http://loinc.org",
            "display": "Hemoglobin [Mass/volume] in Arterial blood"
          },
          {
            "code": "2703-7",
            "system": "http://loinc.org",
            "display": "Oxygen [Partial pressure] in Arterial blood"
          },
          {
            "code": "2019-8",
            "system": "http://loinc.org",
            "display": "Carbon dioxide [Partial pressure] in Arterial blood"
          },
          {
            "code": "1960-4",
            "system": "http://loinc.org",
            "display": "Bicarbonate [Moles/volume] in Arterial blood"
          },
          {
            "code": "3150-0",
            "system": "http://loinc.org",
            "display": "Inhaled oxygen concentration (FIO2)"
          },
          {
            "code": "1925-7",
            "system": "http://loinc.org",
            "display": "Base excess in Arterial blood"
          },
          {
            "code": "20564-1",
            "system": "http://loinc.org",
            "display": "Oxygen saturation in Blood"
          },
          {
            "code": "2708-6",
            "system": "http://loinc.org",
            "display": "Oxygen saturation in Arterial blood"
          },
          {
            "code": "30318-0",
            "system": "http://loinc.org",
            "display": "Base deficit in Blood"
          },
          {
            "code": "32693-4",
            "system": "http://loinc.org",
            "display": "Lactate [Moles/volume] in Blood"
          },
          {
            "code": "1922-4",
            "system": "http://loinc.org",
            "display": "Base deficit in Arterial blood"
          },
          {
            "code": "2746-6",
            "system": "http://loinc.org",
            "display": "pH of Venous blood"
          },
          {
            "code": "2021-4",
            "system": "http://loinc.org",
            "display": "Carbon dioxide [Partial pressure] in Venous blood"
          },
          {
            "code": "19993-5",
            "system": "http://loinc.org",
            "display": "Oxygen/Inspired gas Inhaled gas by Gas dilution.rebreath"
          },
          {
            "code": "34705-4",
            "system": "http://loinc.org",
            "display": "Carbon dioxide [Partial pressure] adjusted to patients actual temperature in Blood"
          },
          {
            "code": "19254-2",
            "system": "http://loinc.org",
            "display": "Oxygen [Partial pressure] adjusted to patients actual temperature in Blood"
          },
          {
            "code": "2705-2",
            "system": "http://loinc.org",
            "display": "Oxygen [Partial pressure] in Venous blood"
          },
          {
            "code": "33254-4",
            "system": "http://loinc.org",
            "display": "pH of Arterial blood adjusted to patients actual temperature"
          },
          {
            "code": "48391-7",
            "system": "http://loinc.org",
            "display": "Carbon dioxide, total [Moles/volume] in Venous blood by calculation"
          },
          {
            "code": "14627-4",
            "system": "http://loinc.org",
            "display": "Bicarbonate [Moles/volume] in Venous blood"
          },
          {
            "code": "2614-6",
            "system": "http://loinc.org",
            "display": "Methemoglobin/Hemoglobin.total in Blood"
          },
          {
            "code": "2745-8",
            "system": "http://loinc.org",
            "display": "pH of Capillary blood"
          },
          {
            "code": "33022-5",
            "system": "http://loinc.org",
            "display": "Carbon dioxide [Partial pressure] in Capillary blood by Transcutaneous CO2 monitor"
          },
          {
            "code": "20563-3",
            "system": "http://loinc.org",
            "display": "Carboxyhemoglobin/Hemoglobin.total in Blood"
          },
          {
            "code": "19941-4",
            "system": "http://loinc.org",
            "display": "Oxygen gas flow Oxygen delivery system"
          },
          {
            "code": "2026-3",
            "system": "http://loinc.org",
            "display": "Carbon dioxide, total [Moles/volume] in Arterial blood"
          },
          {
            "code": "2714-4",
            "system": "http://loinc.org",
            "display": "Fractional oxyhemoglobin in Arterial blood"
          },
          {
            "code": "1927-3",
            "system": "http://loinc.org",
            "display": "Base excess in Venous blood"
          },
          {
            "code": "28637-7",
            "system": "http://loinc.org",
            "display": "Base deficit in Venous cord blood"
          },
          {
            "code": "28647-6",
            "system": "http://loinc.org",
            "display": "pH of Venous cord blood"
          },
          {
            "code": "1961-2",
            "system": "http://loinc.org",
            "display": "Bicarbonate [Moles/volume] in Capillary blood"
          },
          {
            "code": "28646-8",
            "system": "http://loinc.org",
            "display": "pH of Arterial cord blood"
          },
          {
            "code": "33437-5",
            "system": "http://loinc.org",
            "display": "Oxygen [Partial pressure] in Capillary blood by Transcutaneous O2 monitor"
          },
          {
            "code": "2615-3",
            "system": "http://loinc.org",
            "display": "Methemoglobin/Hemoglobin.total in Arterial blood"
          },
          {
            "code": "1924-0",
            "system": "http://loinc.org",
            "display": "Base deficit in Venous blood"
          },
          {
            "code": "28645-0",
            "system": "http://loinc.org",
            "display": "Carbon dioxide [Partial pressure] in Venous cord blood"
          },
          {
            "code": "28649-2",
            "system": "http://loinc.org",
            "display": "Oxygen [Partial pressure] in Venous cord blood"
          },
          {
            "code": "28641-9",
            "system": "http://loinc.org",
            "display": "Bicarbonate [Moles/volume] in Venous cord blood"
          },
          {
            "code": "28644-3",
            "system": "http://loinc.org",
            "display": "Carbon dioxide [Partial pressure] in Arterial cord blood"
          },
          {
            "code": "28648-4",
            "system": "http://loinc.org",
            "display": "Oxygen [Partial pressure] in Arterial cord blood"
          },
          {
            "code": "49701-6",
            "system": "http://loinc.org",
            "display": "pH of Blood adjusted to patients actual temperature"
          },
          {
            "code": "28640-1",
            "system": "http://loinc.org",
            "display": "Bicarbonate [Moles/volume] in Arterial cord blood"
          },
          {
            "code": "28643-5",
            "system": "http://loinc.org",
            "display": "Oxygen saturation (SO2) in Venous cord blood"
          },
          {
            "code": "2518-9",
            "system": "http://loinc.org",
            "display": "Lactate [Moles/volume] in Arterial blood"
          },
          {
            "code": "28642-7",
            "system": "http://loinc.org",
            "display": "Oxygen saturation (SO2) in Arterial cord blood"
          },
          {
            "code": "19942-2",
            "system": "http://loinc.org",
            "display": "Oxygen gas flow setting Oxymizer"
          },
          {
            "code": "59417-6",
            "system": "http://loinc.org",
            "display": "Oxygen saturation in Arterial blood by Pulse oximetry --resting"
          },
          {
            "code": "59412-7",
            "system": "http://loinc.org",
            "display": "Oxygen saturation in Arterial blood by Pulse oximetry --post exercise"
          },
          {
            "code": "2032-1",
            "system": "http://loinc.org",
            "display": "Carboxyhemoglobin/Hemoglobin.total in Venous blood"
          },
          {
            "code": "11559-2",
            "system": "http://loinc.org",
            "display": "Fractional oxyhemoglobin in Blood"
          },
          {
            "code": "2030-5",
            "system": "http://loinc.org",
            "display": "Carboxyhemoglobin/Hemoglobin.total in Arterial blood"
          },
          {
            "code": "59408-5",
            "system": "http://loinc.org",
            "display": "Oxygen saturation in Arterial blood by Pulse oximetry"
          },
          {
            "code": "2711-0",
            "system": "http://loinc.org",
            "display": "Oxygen saturation (SO2) in Venous blood"
          },
          {
            "code": "1926-5",
            "system": "http://loinc.org",
            "display": "Base excess in Capillary blood"
          },
          {
            "code": "2716-9",
            "system": "http://loinc.org",
            "display": "Fractional oxyhemoglobin (HbO2) in Venous blood"
          },
          {
            "code": "2027-1",
            "system": "http://loinc.org",
            "display": "Carbon dioxide, total [Moles/volume] in Venous blood"
          },
          {
            "code": "30350-3",
            "system": "http://loinc.org",
            "display": "Hemoglobin [Mass/volume] in Venous blood"
          }
        ]
      },
      {
        "code": "Renal Function Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "2160-0",
            "system": "http://loinc.org",
            "display": "Creatinine [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3094-0",
            "system": "http://loinc.org",
            "display": "Urea nitrogen [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "33914-3",
            "system": "http://loinc.org",
            "display": "Glomerular filtration rate/1.73 sq M.predicted by Creatinine-based formula (MDRD)"
          },
          {
            "code": "48642-3",
            "system": "http://loinc.org",
            "display": "Glomerular filtration rate/1.73 sq M predicted among non-blacks by Creatinine-based formula (MDRD)"
          },
          {
            "code": "48643-1",
            "system": "http://loinc.org",
            "display": "Glomerular filtration rate/1.73 sq M predicted among blacks by Creatinine-based formula (MDRD)"
          },
          {
            "code": "3097-3",
            "system": "http://loinc.org",
            "display": "Urea nitrogen/Creatinine [Mass ratio] in Serum or Plasma"
          },
          {
            "code": "38483-4",
            "system": "http://loinc.org",
            "display": "Creatinine [Mass/volume] in Blood"
          },
          {
            "code": "6299-2",
            "system": "http://loinc.org",
            "display": "Urea nitrogen [Mass/volume] in Blood"
          },
          {
            "code": "35591-7",
            "system": "http://loinc.org",
            "display": "Creatinine renal clearance predicted by Cockcroft-Gault formula"
          },
          {
            "code": "62238-1",
            "system": "http://loinc.org",
            "display": "Glomerular filtration rate/1.73 sq M.predicted"
          },
          {
            "code": "88293-6",
            "system": "http://loinc.org",
            "display": "Glomerular filtration rate/​1.73 sq M predicted among blacks [Volume Rate/​Area] in Serum, Plasma or Blood by Creatinine-based formula (CKD-EPI)"
          },
          {
            "code": "98979-8",
            "display": "Glomerular filtration rate/1.73 sq M.predicted [Volume Rate/Area] in Serum, Plasma or Blood by Creatinine-based formula (CKD-EPI 2021)"
          },
          {
            "code": "88294-4",
            "system": "http://loinc.org",
            "display": "eGFR If NonAfricn Am"
          }
        ]
      },
      {
        "code": "Chemistry - other",
        "type": "emr-defined",
        "target": [
          {
            "code": "2532-0",
            "system": "http://loinc.org",
            "display": "Lactate dehydrogenase [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "31100-1",
            "system": "http://loinc.org",
            "display": "Hematocrit [Volume Fraction] of Blood by Impedance"
          },
          {
            "code": "14338-8",
            "system": "http://loinc.org",
            "display": "Prealbumin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2639-3",
            "system": "http://loinc.org",
            "display": "Myoglobin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "4542-7",
            "system": "http://loinc.org",
            "display": "Haptoglobin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1761-6",
            "system": "http://loinc.org",
            "display": "Aldolase [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "2742-5",
            "system": "http://loinc.org",
            "display": "Angiotensin converting enzyme [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "1952-1",
            "system": "http://loinc.org",
            "display": "Beta-2-Microglobulin [Mass/volume] in Serum"
          },
          {
            "code": "2357-2",
            "system": "http://loinc.org",
            "display": "Glucose-6-Phosphate dehydrogenase [Enzymatic activity/volume] in Red Blood Cells"
          }
        ]
      },
      {
        "code": "Lipid Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "2093-3",
            "system": "http://loinc.org",
            "display": "Cholesterol [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2571-8",
            "system": "http://loinc.org",
            "display": "Triglyceride [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2085-9",
            "system": "http://loinc.org",
            "display": "Cholesterol in HDL [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "13457-7",
            "system": "http://loinc.org",
            "display": "Cholesterol in LDL [Mass/volume] in Serum or Plasma by calculation"
          },
          {
            "code": "13458-5",
            "system": "http://loinc.org",
            "display": "Cholesterol in VLDL [Mass/volume] in Serum or Plasma by calculation"
          },
          {
            "code": "9830-1",
            "system": "http://loinc.org",
            "display": "Cholesterol.total/Cholesterol in HDL [Mass ratio] in Serum or Plasma"
          },
          {
            "code": "2089-1",
            "system": "http://loinc.org",
            "display": "Cholesterol in LDL [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "11054-4",
            "system": "http://loinc.org",
            "display": "Cholesterol in LDL/Cholesterol in HDL [Mass ratio] in Serum or Plasma"
          },
          {
            "code": "2091-7",
            "system": "http://loinc.org",
            "display": "Cholesterol in VLDL [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "18262-6",
            "system": "http://loinc.org",
            "display": "Cholesterol in LDL [Mass/volume] in Serum or Plasma by Direct assay"
          },
          {
            "code": "43396-1",
            "system": "http://loinc.org",
            "display": "Cholesterol non HDL [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2095-8",
            "system": "http://loinc.org",
            "display": "Cholesterol in HDL/Cholesterol.total [Mass ratio] in Serum or Plasma"
          },
          {
            "code": "10835-7",
            "system": "http://loinc.org",
            "display": "Lipoprotein a [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "47213-4",
            "system": "http://loinc.org",
            "display": "Cholesterol in LDL real size pattern [Identifier] in Serum or Plasma"
          },
          {
            "code": "2087-5",
            "system": "http://loinc.org",
            "display": "Cholesterol in IDL [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "50194-0",
            "system": "http://loinc.org",
            "display": "Cholesterol in IDL+Cholesterol in VLDL 3 [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "46986-6",
            "system": "http://loinc.org",
            "display": "Cholesterol in VLDL 3 [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "49062-3",
            "system": "http://loinc.org",
            "display": "Lipid risk factors [Finding]"
          },
          {
            "code": "1871-3",
            "system": "http://loinc.org",
            "display": "Apolipoprotein B-100 [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1884-6",
            "system": "http://loinc.org",
            "display": "Apolipoprotein B [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1869-7",
            "system": "http://loinc.org",
            "display": "Apolipoprotein A-I [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "43583-4",
            "system": "http://loinc.org",
            "display": "Lipoprotein a [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "3043-7",
            "system": "http://loinc.org",
            "display": "Triglyceride [Mass/volume] in Blood"
          },
          {
            "code": "13462-7",
            "system": "http://loinc.org",
            "display": "Apolipoprotein A-I/Apolipoprotein B [Mass ratio] in Serum or Plasma"
          }
        ]
      },
      {
        "code": "Heme - CBC",
        "type": "emr-defined",
        "target": [
          {
            "code": "718-7",
            "system": "http://loinc.org",
            "display": "Hemoglobin [Mass/volume] in Blood"
          },
          {
            "code": "789-8",
            "system": "http://loinc.org",
            "display": "Erythrocytes [#/volume] in Blood by Automated count"
          },
          {
            "code": "786-4",
            "system": "http://loinc.org",
            "display": "Erythrocyte mean corpuscular hemoglobin concentration [Mass/volume] by Automated count"
          },
          {
            "code": "785-6",
            "system": "http://loinc.org",
            "display": "Erythrocyte mean corpuscular hemoglobin [Entitic mass] by Automated count"
          },
          {
            "code": "4544-3",
            "system": "http://loinc.org",
            "display": "Hematocrit [Volume Fraction] of Blood by Automated count"
          },
          {
            "code": "6690-2",
            "system": "http://loinc.org",
            "display": "Leukocytes[#/volume] in Blood by Automated count"
          },
          {
            "code": "787-2",
            "system": "http://loinc.org",
            "display": "Erythrocyte mean corpuscular volume [Entitic volume] by Automated count"
          },
          {
            "code": "777-3",
            "system": "http://loinc.org",
            "display": "Platelets [#/volume] in Blood by Automated count"
          },
          {
            "code": "788-0",
            "system": "http://loinc.org",
            "display": "Erythrocyte distribution width [Ratio] by Automated count"
          },
          {
            "code": "20570-8",
            "system": "http://loinc.org",
            "display": "Hematocrit [Volume Fraction] of Blood"
          },
          {
            "code": "26515-7",
            "system": "http://loinc.org",
            "display": "Platelets [#/volume] in Blood"
          },
          {
            "code": "26464-8",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/volume] in Blood"
          },
          {
            "code": "30428-7",
            "system": "http://loinc.org",
            "display": "Erythrocyte mean corpuscular volume [Entitic volume]"
          },
          {
            "code": "32623-1",
            "system": "http://loinc.org",
            "display": "Platelet mean volume [Entitic volume] in Blood by Automated count"
          },
          {
            "code": "21000-5",
            "system": "http://loinc.org",
            "display": "Erythrocyte distribution width [Entitic volume] by Automated count"
          },
          {
            "code": "4679-7",
            "system": "http://loinc.org",
            "display": "Reticulocytes/100 erythrocytes in Blood"
          },
          {
            "code": "4545-0",
            "system": "http://loinc.org",
            "display": "Hematocrit [Volume Fraction] of Blood by Centrifugation"
          },
          {
            "code": "14196-0",
            "system": "http://loinc.org",
            "display": "Reticulocytes [#/volume] in Blood"
          },
          {
            "code": "17849-1",
            "system": "http://loinc.org",
            "display": "Reticulocytes/100 erythrocytes in Blood by Automated count"
          },
          {
            "code": "32207-3",
            "system": "http://loinc.org",
            "display": "Platelet distribution width [Entitic volume] in Blood by Automated count"
          },
          {
            "code": "42810-2",
            "system": "http://loinc.org",
            "display": "Hemoglobin [Entitic mass] in Reticulocytes"
          },
          {
            "code": "31112-6",
            "system": "http://loinc.org",
            "display": "Reticulocytes/100 erythrocytes in Blood by Manual"
          }
        ]
      },
      {
        "code": "Heme - Diff/Morphology",
        "type": "emr-defined",
        "target": [
          {
            "code": "770-8",
            "system": "http://loinc.org",
            "display": "Neutrophils/100 leukocytes in Blood by Automated count"
          },
          {
            "code": "704-7",
            "system": "http://loinc.org",
            "display": "Basophils [#/volume] in Blood by Automated count"
          },
          {
            "code": "731-0",
            "system": "http://loinc.org",
            "display": "Lymphocytes [#/volume] in Blood by Automated count"
          },
          {
            "code": "26485-3",
            "system": "http://loinc.org",
            "display": "Monocytes/100 leukocytes in Blood"
          },
          {
            "code": "736-9",
            "system": "http://loinc.org",
            "display": "Lymphocytes/100 leukocytes in Blood by Automated count"
          },
          {
            "code": "706-2",
            "system": "http://loinc.org",
            "display": "Basophils/100 leukocytes in Blood by Automated count"
          },
          {
            "code": "713-8",
            "system": "http://loinc.org",
            "display": "Eosinophils/100 leukocytes in Blood by Automated count"
          },
          {
            "code": "5905-5",
            "system": "http://loinc.org",
            "display": "Monocytes/100 leukocytes in Blood by Automated count"
          },
          {
            "code": "26478-8",
            "system": "http://loinc.org",
            "display": "Lymphocytes/100 leukocytes in Blood"
          },
          {
            "code": "751-8",
            "system": "http://loinc.org",
            "display": "Neutrophils [#/volume] in Blood by Automated count"
          },
          {
            "code": "26450-7",
            "system": "http://loinc.org",
            "display": "Eosinophils/100 leukocytes in Blood"
          },
          {
            "code": "711-2",
            "system": "http://loinc.org",
            "display": "Eosinophils [#/volume] in Blood by Automated count"
          },
          {
            "code": "742-7",
            "system": "http://loinc.org",
            "display": "Monocytes [#/volume] in Blood by Automated count"
          },
          {
            "code": "30180-4",
            "system": "http://loinc.org",
            "display": "Basophils/100 leukocytes in Blood"
          },
          {
            "code": "26499-4",
            "system": "http://loinc.org",
            "display": "Neutrophils [#/volume] in Blood"
          },
          {
            "code": "26484-6",
            "system": "http://loinc.org",
            "display": "Monocytes [#/volume] in Blood"
          },
          {
            "code": "26449-9",
            "system": "http://loinc.org",
            "display": "Eosinophils [#/volume] in Blood"
          },
          {
            "code": "26474-7",
            "system": "http://loinc.org",
            "display": "Lymphocytes [#/volume] in Blood"
          },
          {
            "code": "26511-6",
            "system": "http://loinc.org",
            "display": "Neutrophils/100 leukocytes in Blood"
          },
          {
            "code": "738-5",
            "system": "http://loinc.org",
            "display": "Macrocytes [Presence] in Blood by Light microscopy"
          },
          {
            "code": "741-9",
            "system": "http://loinc.org",
            "display": "Microcytes [Presence] in Blood by Light microscopy"
          },
          {
            "code": "18314-5",
            "system": "http://loinc.org",
            "display": "Morphology [interpretation] in Blood Narrative"
          },
          {
            "code": "728-6",
            "system": "http://loinc.org",
            "display": "Hypochromia [Presence] in Blood by Light microscopy"
          },
          {
            "code": "26444-0",
            "system": "http://loinc.org",
            "display": "Basophils [#/volume] in Blood"
          },
          {
            "code": "6742-1",
            "system": "http://loinc.org",
            "display": "Erythrocyte morphology finding [Identifier] in Blood"
          },
          {
            "code": "764-1",
            "system": "http://loinc.org",
            "display": "Neutrophils.band form/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "9317-9",
            "system": "http://loinc.org",
            "display": "Platelets [Presence] in Blood by Light microscopy"
          },
          {
            "code": "735-1",
            "system": "http://loinc.org",
            "display": "Lymphocytes Variant/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "26508-2",
            "system": "http://loinc.org",
            "display": "Neutrophils.band form/100 leukocytes in Blood"
          },
          {
            "code": "11282-1",
            "system": "http://loinc.org",
            "display": "Cells Counted Total [#] in Blood"
          },
          {
            "code": "737-7",
            "system": "http://loinc.org",
            "display": "Lymphocytes/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "10378-8",
            "system": "http://loinc.org",
            "display": "Polychromasia [Presence] in Blood by Light microscopy"
          },
          {
            "code": "26507-4",
            "system": "http://loinc.org",
            "display": "Neutrophils.band form [#/volume] in Blood"
          },
          {
            "code": "744-3",
            "system": "http://loinc.org",
            "display": "Monocytes/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "769-0",
            "system": "http://loinc.org",
            "display": "Neutrophils.segmented/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "714-6",
            "system": "http://loinc.org",
            "display": "Eosinophils/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "702-1",
            "system": "http://loinc.org",
            "display": "Anisocytosis [Presence] in Blood by Light microscopy"
          },
          {
            "code": "707-0",
            "system": "http://loinc.org",
            "display": "Basophils/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "774-0",
            "system": "http://loinc.org",
            "display": "Ovalocytes [Presence] in Blood by Light microscopy"
          },
          {
            "code": "11125-2",
            "system": "http://loinc.org",
            "display": "Platelet morphology finding [Identifier] in Blood"
          },
          {
            "code": "15180-3",
            "system": "http://loinc.org",
            "display": "Hypochromia [Presence] in Blood by Automated count"
          },
          {
            "code": "15150-6",
            "system": "http://loinc.org",
            "display": "Anisocytosis [Presence] in Blood by Automated count"
          },
          {
            "code": "15198-5",
            "system": "http://loinc.org",
            "display": "Macrocytes [Presence] in Blood by Automated count"
          },
          {
            "code": "15199-3",
            "system": "http://loinc.org",
            "display": "Microcytes [Presence] in Blood by Automated count"
          },
          {
            "code": "779-9",
            "system": "http://loinc.org",
            "display": "Poikilocytosis [Presence] in Blood by Light microscopy"
          },
          {
            "code": "740-1",
            "system": "http://loinc.org",
            "display": "Metamyelocytes/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "28541-1",
            "system": "http://loinc.org",
            "display": "Metamyelocytes/100 leukocytes in Blood"
          },
          {
            "code": "58413-6",
            "system": "http://loinc.org",
            "display": "Nucleated erythrocytes/100 leukocytes [Ratio] in Blood by Automated count"
          },
          {
            "code": "7791-7",
            "system": "http://loinc.org",
            "display": "Dacrocytes [Presence] in Blood by Light microscopy"
          },
          {
            "code": "763-3",
            "system": "http://loinc.org",
            "display": "Neutrophils.band form [#/volume] in Blood by Manual count"
          },
          {
            "code": "11156-7",
            "system": "http://loinc.org",
            "display": "Leukocyte morphology finding [Identifier] in Blood"
          },
          {
            "code": "800-3",
            "system": "http://loinc.org",
            "display": "Schistocytes [Presence] in Blood by Light microscopy"
          },
          {
            "code": "749-2",
            "system": "http://loinc.org",
            "display": "Myelocytes/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "26498-6",
            "system": "http://loinc.org",
            "display": "Myelocytes/100 leukocytes in Blood"
          },
          {
            "code": "17790-7",
            "system": "http://loinc.org",
            "display": "Leukocytes Left Shift [Presence] in Blood by Automated count"
          },
          {
            "code": "10381-2",
            "system": "http://loinc.org",
            "display": "Target cells [Presence] in Blood by Light microscopy"
          },
          {
            "code": "31160-5",
            "system": "http://loinc.org",
            "display": "Polymorphonuclear cells/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "7790-9",
            "system": "http://loinc.org",
            "display": "Burr cells [Presence] in Blood by Light microscopy"
          },
          {
            "code": "33255-1",
            "system": "http://loinc.org",
            "display": "Cell Fractions/Differential [interpretation] in Blood"
          },
          {
            "code": "743-5",
            "system": "http://loinc.org",
            "display": "Monocytes [#/volume] in Blood by Manual count"
          },
          {
            "code": "803-7",
            "system": "http://loinc.org",
            "display": "Toxic granules [Presence] in Blood by Light microscopy"
          },
          {
            "code": "739-3",
            "system": "http://loinc.org",
            "display": "Metamyelocytes [#/volume] in Blood by Manual count"
          },
          {
            "code": "772-4",
            "system": "http://loinc.org",
            "display": "Nucleated erythrocytes [#/volume] in Blood by Manual count"
          },
          {
            "code": "30446-9",
            "system": "http://loinc.org",
            "display": "Myelocytes [#/volume] in Blood"
          },
          {
            "code": "748-4",
            "system": "http://loinc.org",
            "display": "Myelocytes [#/volume] in Blood by Manual count"
          },
          {
            "code": "703-9",
            "system": "http://loinc.org",
            "display": "Basophilic stippling [Presence] in Blood by Light microscopy"
          },
          {
            "code": "802-9",
            "system": "http://loinc.org",
            "display": "Spherocytes [Presence] in Blood by Light microscopy"
          },
          {
            "code": "49121-7",
            "system": "http://loinc.org",
            "display": "Erythrocyte inclusion bodies [Identifier] in Blood"
          },
          {
            "code": "709-6",
            "system": "http://loinc.org",
            "display": "Blasts/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "26446-5",
            "system": "http://loinc.org",
            "display": "Blasts/100 leukocytes in Blood"
          },
          {
            "code": "7792-5",
            "system": "http://loinc.org",
            "display": "Dohle body [Presence] in Blood by Light microscopy"
          },
          {
            "code": "13046-8",
            "system": "http://loinc.org",
            "display": "Lymphocytes Variant/100 leukocytes in Blood"
          },
          {
            "code": "38908-0",
            "system": "http://loinc.org",
            "display": "Poikilocytosis [Presence] in Blood by Automated count"
          },
          {
            "code": "783-1",
            "system": "http://loinc.org",
            "display": "Promyelocytes/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "26524-9",
            "system": "http://loinc.org",
            "display": "Promyelocytes/100 leukocytes in Blood"
          },
          {
            "code": "773-2",
            "system": "http://loinc.org",
            "display": "Nucleated erythrocytes/100 erythrocytes in Blood by Manual count"
          },
          {
            "code": "14912-0",
            "system": "http://loinc.org",
            "display": "Smudge cells/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "30376-8",
            "system": "http://loinc.org",
            "display": "Blasts [#/volume] in Blood"
          },
          {
            "code": "801-1",
            "system": "http://loinc.org",
            "display": "Sickle cells [Presence] in Blood by Light microscopy"
          },
          {
            "code": "32146-3",
            "system": "http://loinc.org",
            "display": "Platelets Large [Presence] in Blood by Light microscopy"
          },
          {
            "code": "26523-1",
            "system": "http://loinc.org",
            "display": "Promyelocytes [#/volume] in Blood"
          },
          {
            "code": "7793-3",
            "system": "http://loinc.org",
            "display": "Howell-Jolly bodies [Presence] in Blood by Light microscopy"
          },
          {
            "code": "11274-8",
            "system": "http://loinc.org",
            "display": "Elliptocytes [Presence] in Blood by Light microscopy"
          },
          {
            "code": "7798-2",
            "system": "http://loinc.org",
            "display": "Smudge cells [Presence] in Blood by Light microscopy"
          },
          {
            "code": "7789-1",
            "system": "http://loinc.org",
            "display": "Acanthocytes [Presence] in Blood by Light microscopy"
          },
          {
            "code": "23761-0",
            "system": "http://loinc.org",
            "display": "Neutrophils/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "26471-3",
            "system": "http://loinc.org",
            "display": "Leukocytes other/100 leukocytes in Blood"
          },
          {
            "code": "771-6",
            "system": "http://loinc.org",
            "display": "Nucleated erythrocytes [#/volume] in Blood by Automated count"
          },
          {
            "code": "18319-4",
            "system": "http://loinc.org",
            "display": "Neutrophils.vacuolated [Presence] in Blood by Light microscopy"
          },
          {
            "code": "34524-9",
            "system": "http://loinc.org",
            "display": "Neutrophils.band form [Presence] in Blood by Automated count"
          },
          {
            "code": "730-2",
            "system": "http://loinc.org",
            "display": "Leukocytes other/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "5909-7",
            "system": "http://loinc.org",
            "display": "Blood smear finding [Identifier] in Blood by Light microscopy"
          },
          {
            "code": "13047-6",
            "system": "http://loinc.org",
            "display": "Plasma cells/100 leukocytes in Blood"
          },
          {
            "code": "781-5",
            "system": "http://loinc.org",
            "display": "Promyelocytes [#/volume] in Blood by Manual count"
          },
          {
            "code": "12227-5",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/volume] corrected for nucleated erythrocytes in Blood"
          },
          {
            "code": "15197-7",
            "system": "http://loinc.org",
            "display": "Lymphocytes Fissured/100 leukocytes in Blood by Manual count"
          },
          {
            "code": "5908-9",
            "system": "http://loinc.org",
            "display": "Platelets Giant [Presence] in Blood by Light microscopy"
          },
          {
            "code": "15192-8",
            "system": "http://loinc.org",
            "display": "Lymphocytes Variant [Presence] in Blood by Automated count"
          },
          {
            "code": "34165-1",
            "system": "http://loinc.org",
            "display": "Granulocytes Immature [Presence] in Blood by Automated count"
          },
          {
            "code": "17788-1",
            "system": "http://loinc.org",
            "display": "Large unstained cells/100 leukocytes in Blood by Automated count"
          },
          {
            "code": "24103-4",
            "system": "http://loinc.org",
            "display": "Plasma cells [#/volume] in Blood by Manual count"
          },
          {
            "code": "7796-6",
            "system": "http://loinc.org",
            "display": "Platelet clump [Presence] in Blood by Light microscopy"
          },
          {
            "code": "7797-4",
            "system": "http://loinc.org",
            "display": "Rouleaux [Presence] in Blood by Light microscopy"
          },
          {
            "code": "765-8",
            "system": "http://loinc.org",
            "display": "Neutrophils.hypersegmented [Presence] in Blood by Light microscopy"
          },
          {
            "code": "7795-8",
            "system": "http://loinc.org",
            "display": "Pappenheimer bodies [Presence] in Blood by Light microscopy"
          },
          {
            "code": "33215-5",
            "system": "http://loinc.org",
            "display": "Neutrophils.agranular [Presence] in Blood by Light microscopy"
          },
          {
            "code": "10380-4",
            "system": "http://loinc.org",
            "display": "Stomatocytes [Presence] in Blood by Light microscopy"
          },
          {
            "code": "33216-3",
            "system": "http://loinc.org",
            "display": "Platelets agranular [Presence] in Blood by Light microscopy"
          },
          {
            "code": "18311-1",
            "system": "http://loinc.org",
            "display": "Pelger Huet cells [Presence] in Blood by Light microscopy"
          },
          {
            "code": "11281-3",
            "system": "http://loinc.org",
            "display": "Auer rods [Presence] in Blood by Light microscopy"
          },
          {
            "code": "716-1",
            "system": "http://loinc.org",
            "display": "Heinz bodies [Presence] in Blood by Light microscopy"
          },
          {
            "code": "30394-1",
            "system": "http://loinc.org",
            "display": "Granulocytes [#/volume] in Blood"
          },
          {
            "code": "18312-9",
            "system": "http://loinc.org",
            "display": "Platelet satellitism [Presence] in Blood by Light microscopy"
          },
          {
            "code": "33256-9",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/volume] corrected for nucleated erythrocytes in Blood by Automated count"
          },
          {
            "code": "708-8",
            "system": "http://loinc.org",
            "display": "Blasts [#/volume] in Blood by Manual count"
          },
          {
            "code": "18309-5",
            "system": "http://loinc.org",
            "display": "Nucleated erythrocytes/100 leukocytes [Ratio] in Blood by Manual count"
          }
        ]
      },
      {
        "code": "Anemia Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "2498-4",
            "system": "http://loinc.org",
            "display": "Iron [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2132-9",
            "system": "http://loinc.org",
            "display": "Cobalamin (Vitamin B12) [Mass/volume] in Serum"
          },
          {
            "code": "2276-4",
            "system": "http://loinc.org",
            "display": "Ferritin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2500-7",
            "system": "http://loinc.org",
            "display": "Iron binding capacity [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2284-8",
            "system": "http://loinc.org",
            "display": "Folate [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2502-3",
            "system": "http://loinc.org",
            "display": "Iron saturation [Mass Fraction] in Serum or Plasma"
          },
          {
            "code": "2501-5",
            "system": "http://loinc.org",
            "display": "Iron binding capacity.unsaturated [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2505-6",
            "system": "http://loinc.org",
            "display": "Iron/Iron binding capacity.total [Mass ratio] in Serum or Plasma"
          },
          {
            "code": "13964-2",
            "system": "http://loinc.org",
            "display": "Methylmalonate [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "2283-0",
            "system": "http://loinc.org",
            "display": "Folate [Mass/volume] in Red Blood Cells"
          },
          {
            "code": "3034-6",
            "system": "http://loinc.org",
            "display": "Transferrin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "15061-5",
            "system": "http://loinc.org",
            "display": "Erythropoietin (EPO) [Units/volume] in Serum or Plasma"
          },
          {
            "code": "2282-2",
            "system": "http://loinc.org",
            "display": "Folate [Mass/volume] in Blood"
          },
          {
            "code": "32546-4",
            "system": "http://loinc.org",
            "display": "Glucose-6-Phosphate dehydrogenase [Enzymatic activity/mass] in Red Blood Cells"
          }
        ]
      },
      {
        "code": "Heme - Other",
        "type": "emr-defined",
        "target": [
          {
            "code": "4537-7",
            "system": "http://loinc.org",
            "display": "Erythrocyte sedimentation rate by Westergren method"
          },
          {
            "code": "30341-2",
            "system": "http://loinc.org",
            "display": "Erythrocyte sedimentation rate"
          },
          {
            "code": "721-1",
            "system": "http://loinc.org",
            "display": "Free Hemoglobin [Mass/volume] in Plasma"
          },
          {
            "code": "4635-9",
            "system": "http://loinc.org",
            "display": "Free Hemoglobin [Mass/volume] in Serum"
          }
        ]
      },
      {
        "code": "Cardiac Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "2157-6",
            "system": "http://loinc.org",
            "display": "Creatine kinase [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "13969-1",
            "system": "http://loinc.org",
            "display": "Creatine kinase.MB [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "10839-9",
            "system": "http://loinc.org",
            "display": "Troponin I.cardiac [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1988-5",
            "system": "http://loinc.org",
            "display": "C reactive protein [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "30934-4",
            "system": "http://loinc.org",
            "display": "Natriuretic peptide B [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "49136-5",
            "system": "http://loinc.org",
            "display": "Creatine kinase.MB/Creatine kinase.total [Ratio] in Serum or Plasma"
          },
          {
            "code": "6598-7",
            "system": "http://loinc.org",
            "display": "Troponin T.cardiac [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "20569-0",
            "system": "http://loinc.org",
            "display": "Creatine kinase.MB/Creatine kinase.total in Serum or Plasma"
          },
          {
            "code": "30522-7",
            "system": "http://loinc.org",
            "display": "C reactive protein [Mass/volume] in Serum or Plasma by High sensitivity method"
          },
          {
            "code": "13965-9",
            "system": "http://loinc.org",
            "display": "Homocysteine [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "32673-6",
            "system": "http://loinc.org",
            "display": "Creatine kinase.MB [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "49563-0",
            "system": "http://loinc.org",
            "display": "Troponin I.cardiac [Mass/volume] in Serum or Plasma by Detection limit = 0.01 ng/mL"
          },
          {
            "code": "33762-6",
            "system": "http://loinc.org",
            "display": "Natriuretic peptide.B prohormone [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "42637-9",
            "system": "http://loinc.org",
            "display": "Natriuretic peptide B [Mass/volume] in Blood"
          },
          {
            "code": "11039-5",
            "system": "http://loinc.org",
            "display": "C reactive protein [Presence] in Serum or Plasma"
          },
          {
            "code": "2428-1",
            "system": "http://loinc.org",
            "display": "Homocysteine [Mass/volume] in Serum or Plasma"
          }
        ]
      },
      {
        "code": "Thyroid Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "11579-0",
            "system": "http://loinc.org",
            "display": "Thyrotropin [Units/volume] in Serum or Plasma by Detection limit <= 0.05 mIU/L"
          },
          {
            "code": "3016-3",
            "system": "http://loinc.org",
            "display": "Thyrotropin [Units/volume] in Serum or Plasma"
          },
          {
            "code": "3024-7",
            "system": "http://loinc.org",
            "display": "Thyroxine (T4) free [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3026-2",
            "system": "http://loinc.org",
            "display": "Thyroxine (T4) [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "11580-8",
            "system": "http://loinc.org",
            "display": "Thyrotropin [Units/volume] in Serum or Plasma by Detection limit <= 0.005 mIU/L"
          },
          {
            "code": "3050-2",
            "system": "http://loinc.org",
            "display": "Triiodothyronine resin uptake (T3RU) in Serum or Plasma"
          },
          {
            "code": "32215-6",
            "system": "http://loinc.org",
            "display": "Thyroxine (T4) free index in Serum or Plasma"
          },
          {
            "code": "3053-6",
            "system": "http://loinc.org",
            "display": "Triiodothyronine (T3) [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3051-0",
            "system": "http://loinc.org",
            "display": "Triiodothyronine (T3) Free [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3013-0",
            "system": "http://loinc.org",
            "display": "Thyroglobulin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3052-8",
            "system": "http://loinc.org",
            "display": "Triiodothyronine (T3).reverse [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "30166-3",
            "system": "http://loinc.org",
            "display": "Thyroid stimulating immunoglobulins actual/normal in Serum"
          },
          {
            "code": "38505-4",
            "system": "http://loinc.org",
            "display": "Thyroglobulin recovery in Serum or Plasma"
          },
          {
            "code": "6892-4",
            "system": "http://loinc.org",
            "display": "Thyroxine (T4) free [Mass/volume] in Serum or Plasma by Dialysis"
          }
        ]
      },
      {
        "code": "Endocrine",
        "type": "emr-defined",
        "target": [
          {
            "code": "2986-8",
            "system": "http://loinc.org",
            "display": "Testosterone [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "15067-2",
            "system": "http://loinc.org",
            "display": "Follitropin [Units/volume] in Serum or Plasma"
          },
          {
            "code": "2243-4",
            "system": "http://loinc.org",
            "display": "Estradiol (E2) [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2731-8",
            "system": "http://loinc.org",
            "display": "Parathyrin.intact [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "10501-5",
            "system": "http://loinc.org",
            "display": "Lutropin [Units/volume] in Serum or Plasma"
          },
          {
            "code": "2842-3",
            "system": "http://loinc.org",
            "display": "Prolactin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2839-9",
            "system": "http://loinc.org",
            "display": "Progesterone [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2991-8",
            "system": "http://loinc.org",
            "display": "Testosterone Free [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2143-6",
            "system": "http://loinc.org",
            "display": "Cortisol [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "20448-7",
            "system": "http://loinc.org",
            "display": "Insulin [Units/volume] in Serum or Plasma"
          },
          {
            "code": "2191-5",
            "system": "http://loinc.org",
            "display": "Dehydroepiandrosterone sulfate (DHEA-S) [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2484-4",
            "system": "http://loinc.org",
            "display": "Insulin-like growth factor-I [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "13967-5",
            "system": "http://loinc.org",
            "display": "Sex hormone binding globulin [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "1986-9",
            "system": "http://loinc.org",
            "display": "C peptide [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "15432-8",
            "system": "http://loinc.org",
            "display": "Testosterone Free/Testosterone.total in Serum or Plasma"
          },
          {
            "code": "1763-2",
            "system": "http://loinc.org",
            "display": "Aldosterone [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2141-0",
            "system": "http://loinc.org",
            "display": "Corticotropin [Mass/volume] in Plasma"
          },
          {
            "code": "2915-7",
            "system": "http://loinc.org",
            "display": "Renin [Enzymatic activity/volume] in Plasma"
          },
          {
            "code": "2193-1",
            "system": "http://loinc.org",
            "display": "Dehydroepiandrosterone (DHEA) [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "9813-7",
            "system": "http://loinc.org",
            "display": "Cortisol [Mass/volume] in Serum or Plasma --morning specimen"
          },
          {
            "code": "1668-3",
            "system": "http://loinc.org",
            "display": "17-Hydroxyprogesterone [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2254-1",
            "system": "http://loinc.org",
            "display": "Estrogen [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "15069-8",
            "system": "http://loinc.org",
            "display": "Fructosamine [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "2963-7",
            "system": "http://loinc.org",
            "display": "Somatotropin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2483-6",
            "system": "http://loinc.org",
            "display": "Insulin-like growth factor binding protein 3 [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2258-2",
            "system": "http://loinc.org",
            "display": "Estrone (E1) [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "6891-6",
            "system": "http://loinc.org",
            "display": "Testosterone.bioavailable/Testosterone.total in Serum or Plasma"
          },
          {
            "code": "1854-9",
            "system": "http://loinc.org",
            "display": "Androstenedione [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "25489-6",
            "system": "http://loinc.org",
            "display": "Normetanephrine [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "21365-2",
            "system": "http://loinc.org",
            "display": "Leptin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2837-3",
            "system": "http://loinc.org",
            "display": "Pregnenolone [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2333-3",
            "system": "http://loinc.org",
            "display": "Gastrin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "24125-7",
            "system": "http://loinc.org",
            "display": "Androgen free Index in Serum or Plasma"
          },
          {
            "code": "25474-8",
            "system": "http://loinc.org",
            "display": "Metanephrines [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "1848-1",
            "system": "http://loinc.org",
            "display": "Androstanolone [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "38476-8",
            "system": "http://loinc.org",
            "display": "Mullerian inhibiting substance [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1992-7",
            "system": "http://loinc.org",
            "display": "Calcitonin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "26528-0",
            "system": "http://loinc.org",
            "display": "Cortisol [Mass/volume] in Serum or Plasma --1 hour post dose corticotropin"
          },
          {
            "code": "26530-6",
            "system": "http://loinc.org",
            "display": "Cortisol [Mass/volume] in Serum or Plasma --30 minutes post dose corticotropin"
          },
          {
            "code": "15013-6",
            "system": "http://loinc.org",
            "display": "Alkaline phosphatase.bone/Alkaline phosphatase.total in Serum or Plasma"
          },
          {
            "code": "2669-0",
            "system": "http://loinc.org",
            "display": "Normetanephrine [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "25987-9",
            "system": "http://loinc.org",
            "display": "Testosterone Free [Moles/volume] in Serum or Plasma by Radioimmunoassay (RIA)"
          },
          {
            "code": "49041-7",
            "system": "http://loinc.org",
            "display": "Testosterone [Mass/volume] in Serum or Plasma by Detection limit = 1.0 ng/dL"
          },
          {
            "code": "49042-5",
            "system": "http://loinc.org",
            "display": "Testosterone Free [Mass/volume] in Serum or Plasma by Detection limit = 1.0 ng/dL"
          },
          {
            "code": "2216-0",
            "system": "http://loinc.org",
            "display": "Dopamine [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "38494-1",
            "system": "http://loinc.org",
            "display": "Metanephrine Free [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "25473-0",
            "system": "http://loinc.org",
            "display": "Metanephrine [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "1777-2",
            "system": "http://loinc.org",
            "display": "Alkaline phosphatase.bone [Enzymatic activity/volume] in Serum or Plasma"
          },
          {
            "code": "9812-9",
            "system": "http://loinc.org",
            "display": "Cortisol [Mass/volume] in Serum or Plasma --evening specimen"
          },
          {
            "code": "6901-3",
            "system": "http://loinc.org",
            "display": "Insulin Free [Units/volume] in Serum or Plasma"
          },
          {
            "code": "53835-5",
            "system": "http://loinc.org",
            "display": "1,5-Anhydroglucitol [Mass/volume] in Serum or Plasma"
          }
        ]
      },
      {
        "code": "Vitamin Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "1989-3",
            "system": "http://loinc.org",
            "display": "Calcidiol [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1990-1",
            "system": "http://loinc.org",
            "display": "Cholecalciferol (Vit D3) [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2236-8",
            "system": "http://loinc.org",
            "display": "Calciferol (Vit D2) [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "35365-6",
            "system": "http://loinc.org",
            "display": "Vitamin D+Metabolites [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1649-3",
            "system": "http://loinc.org",
            "display": "Calcitriol [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "62292-8",
            "system": "http://loinc.org",
            "display": "25-Hydroxyvitamin D2+25-Hydroxyvitamin D3 [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "49054-0",
            "system": "http://loinc.org",
            "display": "25-Hydroxycalciferol [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2900-9",
            "system": "http://loinc.org",
            "display": "Pyridoxine (Vitamin B6) [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2998-3",
            "system": "http://loinc.org",
            "display": "Thiamine [Mass/volume] in Blood"
          },
          {
            "code": "32554-8",
            "system": "http://loinc.org",
            "display": "Thiamine [Moles/volume] in Blood"
          },
          {
            "code": "2999-1",
            "system": "http://loinc.org",
            "display": "Thiamine [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "1903-4",
            "system": "http://loinc.org",
            "display": "Ascorbate [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "20468-5",
            "system": "http://loinc.org",
            "display": "Thiamine [Moles/volume] in Serum or Plasma"
          }
        ]
      },
      {
        "code": "Coagulation",
        "type": "emr-defined",
        "target": [
          {
            "code": "5902-2",
            "system": "http://loinc.org",
            "display": "Prothrombin time (PT) in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "5902-2",
            "system": "http://loinc.org",
            "display": "Prothrombin time (PT)"
          },
          {
            "code": "6301-6",
            "system": "http://loinc.org",
            "display": "INR in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "3173-2",
            "system": "http://loinc.org",
            "display": "Activated partial thromboplastin time (aPTT) in Blood by Coagulation assay"
          },
          {
            "code": "14979-9",
            "system": "http://loinc.org",
            "display": "Activated partial thromboplastin time (aPTT) in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "34714-6",
            "system": "http://loinc.org",
            "display": "INR in Blood by Coagulation assay"
          },
          {
            "code": "3255-7",
            "system": "http://loinc.org",
            "display": "Fibrinogen [Mass/volume] in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "3184-9",
            "system": "http://loinc.org",
            "display": "Activated clotting time in Blood by Coagulation assay"
          },
          {
            "code": "48065-7",
            "system": "http://loinc.org",
            "display": "Fibrin D-dimer FEU [Mass/volume] in Platelet poor plasma"
          },
          {
            "code": "48058-2",
            "system": "http://loinc.org",
            "display": "Fibrin D-dimer DDU [Mass/volume] in Platelet poor plasma by Immunoassay"
          },
          {
            "code": "48066-5",
            "system": "http://loinc.org",
            "display": "Fibrin D-dimer DDU [Mass/volume] in Platelet poor plasma"
          },
          {
            "code": "34701-3",
            "system": "http://loinc.org",
            "display": "Platelet Ab.heparin induced [Presence] in Serum"
          },
          {
            "code": "3243-3",
            "system": "http://loinc.org",
            "display": "Thrombin time"
          },
          {
            "code": "3243-3",
            "system": "http://loinc.org",
            "display": "Thrombin time in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "5892-5",
            "system": "http://loinc.org",
            "display": "Protein S [Units/volume] in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "27811-9",
            "system": "http://loinc.org",
            "display": "Antithrombin actual/normal in Platelet poor plasma by Chromogenic method"
          },
          {
            "code": "3209-4",
            "system": "http://loinc.org",
            "display": "Coagulation factor VIII activity actual/normal in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "13590-5",
            "system": "http://loinc.org",
            "display": "Activated protein C resistance [Time Ratio] in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "27819-2",
            "system": "http://loinc.org",
            "display": "Protein C actual/normal in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "6002-0",
            "system": "http://loinc.org",
            "display": "Platelet factor 4 [Units/volume] in Platelet poor plasma"
          },
          {
            "code": "6014-5",
            "system": "http://loinc.org",
            "display": "von Willebrand factor (vWf) ristocetin cofactor actual/normal in Platelet poor plasma by Aggregation"
          },
          {
            "code": "48344-6",
            "system": "http://loinc.org",
            "display": "Kaolin activated time in Platelet poor plasma"
          },
          {
            "code": "27822-6",
            "system": "http://loinc.org",
            "display": "Protein S actual/normal in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "21032-8",
            "system": "http://loinc.org",
            "display": "Thrombin time [interpretation] in Blood"
          },
          {
            "code": "20991-6",
            "system": "http://loinc.org",
            "display": "Antithrombin [interpretation] in Platelet poor plasma"
          },
          {
            "code": "33594-3",
            "system": "http://loinc.org",
            "display": "Platelet factor 4 [Presence] in Platelet poor plasma"
          },
          {
            "code": "27816-8",
            "system": "http://loinc.org",
            "display": "von Willebrand factor (vWf) Ag actual/normal in Platelet poor plasma by Immunologic method"
          },
          {
            "code": "27818-4",
            "system": "http://loinc.org",
            "display": "Protein C actual/normal in Platelet poor plasma by Chromogenic method    *NOTE: enzymatic method"
          },
          {
            "code": "3174-0",
            "system": "http://loinc.org",
            "display": "Antithrombin [Units/volume] in Platelet poor plasma by Chromogenic method"
          },
          {
            "code": "6007-9",
            "system": "http://loinc.org",
            "display": "Protein C [Units/volume] in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "3256-5",
            "system": "http://loinc.org",
            "display": "Fibrinogen Ag [Mass/volume] in Platelet poor plasma by Immunologic method"
          },
          {
            "code": "31102-7",
            "system": "http://loinc.org",
            "display": "Protein S actual/normal in Platelet poor plasma by Chromogenic method    *NOTE: enzymatic method"
          },
          {
            "code": "6009-5",
            "system": "http://loinc.org",
            "display": "Protein C Ag [Units/volume] in Platelet poor plasma by Immunologic method"
          },
          {
            "code": "27820-0",
            "system": "http://loinc.org",
            "display": "Protein C Ag actual/normal in Platelet poor plasma by Immunologic method"
          },
          {
            "code": "6012-9",
            "system": "http://loinc.org",
            "display": "von Willebrand factor (vWf) Ag [Units/volume] in Platelet poor plasma by Immunologic method"
          },
          {
            "code": "33984-6",
            "system": "http://loinc.org",
            "display": "Coagulation factor X activity actual/normal in Platelet poor plasma by Chromogenic method"
          },
          {
            "code": "27823-4",
            "system": "http://loinc.org",
            "display": "Protein S Ag actual/normal in Platelet poor plasma by Immunologic method"
          },
          {
            "code": "27821-8",
            "system": "http://loinc.org",
            "display": "Protein S Free Ag actual/normal in Platelet poor plasma by Immunologic method"
          },
          {
            "code": "3175-7",
            "system": "http://loinc.org",
            "display": "Antithrombin Ag [Units/volume] in Platelet poor plasma by Immunologic method"
          },
          {
            "code": "24378-2",
            "system": "http://loinc.org",
            "display": "Platelet aggregation epinephrine induced [Presence] in Platelet rich plasma"
          },
          {
            "code": "29280-5",
            "system": "http://loinc.org",
            "display": "Fibrin D-dimer [Presence] in Platelet poor plasma by Latex agglutination"
          },
          {
            "code": "3193-0",
            "system": "http://loinc.org",
            "display": "Coagulation factor V activity actual/normal in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "3187-2",
            "system": "http://loinc.org",
            "display": "Coagulation factor IX activity actual/normal in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "3198-9",
            "system": "http://loinc.org",
            "display": "Coagulation factor VII activity actual/normal in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "13589-7",
            "system": "http://loinc.org",
            "display": "Activated protein C resistance [Presence] in Blood by Probe & target amplification method"
          },
          {
            "code": "21027-8",
            "system": "http://loinc.org",
            "display": "Platelet aggregation [interpretation] in Platelet poor plasma"
          },
          {
            "code": "3218-5",
            "system": "http://loinc.org",
            "display": "Coagulation factor X activity actual/normal in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "49058-1",
            "system": "http://loinc.org",
            "display": "Activated partial thromboplastin time (aPTT) in Blood drawn from CRRT circuit by Coagulation assay"
          },
          {
            "code": "32217-2",
            "system": "http://loinc.org",
            "display": "von Willebrand factor (vWf) multimers [Presence] in Platelet poor plasma"
          }
        ]
      },
      {
        "code": "Coag - Lupus Anticoagulant",
        "type": "emr-defined",
        "target": [
          {
            "code": "3181-5",
            "system": "http://loinc.org",
            "display": "Cardiolipin IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "3182-3",
            "system": "http://loinc.org",
            "display": "Cardiolipin IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "75882-1",
            "system": "http://loinc.org",
            "display": "Lupus anticoagulant three screening tests W Reflex [interpretation]"
          },
          {
            "code": "6303-2",
            "system": "http://loinc.org",
            "display": "dRVVT LA screen"
          },
          {
            "code": "3284-7",
            "system": "http://loinc.org",
            "display": "Lupus anticoagulant neutralization platelet [Time] in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "9327-8",
            "system": "http://loinc.org",
            "display": "Phosphatidylserine IgM Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5076-5",
            "system": "http://loinc.org",
            "display": "Cardiolipin IgA Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "33673-5",
            "system": "http://loinc.org",
            "display": "Thrombin time.factor substitution in Platelet poor plasma by Coagulation assay --immediately after addition of protamine sulfate"
          },
          {
            "code": "32032-5",
            "system": "http://loinc.org",
            "display": "Phosphatidylserine IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "15359-3",
            "system": "http://loinc.org",
            "display": "dRVVT actual/​normal (normalized LA screen)"
          },
          {
            "code": "15191-0",
            "system": "http://loinc.org",
            "display": "Lupus anticoagulant neutralization dilute phospholipid [Presence] in Platelet poor plasma"
          },
          {
            "code": "3282-1",
            "system": "http://loinc.org",
            "display": "aPTT W excess hexagonal phase phospholipid in Platelet poor plasma by Coagulation assay"
          },
          {
            "code": "32031-7",
            "system": "http://loinc.org",
            "display": "Phosphatidylserine IgA Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "5946-9",
            "system": "http://loinc.org",
            "display": "aPTT.factor substitution in Platelet poor plasma by Coagulation assay --immediately after addition of normal plasma"
          },
          {
            "code": "20425-5",
            "system": "http://loinc.org",
            "display": "Cardiolipin IgM Ab [interpretation] in Serum"
          },
          {
            "code": "20424-8",
            "system": "http://loinc.org",
            "display": "Cardiolipin IgG Ab [interpretation] in Serum"
          },
          {
            "code": "9326-0",
            "system": "http://loinc.org",
            "display": "Phosphatidylserine IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "14246-3",
            "system": "http://loinc.org",
            "display": "Phosphatidylserine IgM Ab [Units/volume] in Serum"
          },
          {
            "code": "43734-3",
            "system": "http://loinc.org",
            "display": "aPTT in Platelet poor plasma by Coagulation 1:1 saline"
          },
          {
            "code": "75513-2",
            "system": "http://loinc.org",
            "display": "dRVVT with 1:1 PNP (LA mix)"
          },
          {
            "code": "5959-2",
            "system": "http://loinc.org",
            "display": "Prothrombin time (PT) factor substitution in Platelet poor plasma by Coagulation assay --immediately after addition of normal plasma"
          },
          {
            "code": "32033-3",
            "system": "http://loinc.org",
            "display": "Phosphatidylserine IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "34571-0",
            "system": "http://loinc.org",
            "display": "aPTT.lupus sensitive (LA screen)"
          },
          {
            "code": "48022-8",
            "system": "http://loinc.org",
            "display": "aPTT.lupus sensitive actual/​normal (normalized LA screen)"
          },
          {
            "code": "75506-6",
            "system": "http://loinc.org",
            "display": "aPTT.lupus sensitive W excess phospholipid (LA confirm)"
          },
          {
            "code": "75508-2",
            "system": "http://loinc.org",
            "display": "aPTT.lupus sensitive W excess phospholipid actual/​Normal (normalized LA confirm)"
          },
          {
            "code": "75507-4",
            "system": "http://loinc.org",
            "display": "aPTT.lupus sensitive W excess phospholipid percent correction"
          },
          {
            "code": "75510-8",
            "system": "http://loinc.org",
            "display": "aPTT.lupus sensitive with 1:1 PNP (LA mix)"
          },
          {
            "code": "75509-0",
            "system": "http://loinc.org",
            "display": "aPTT.lupus sensitive with 1:1 PNP actual/​Normal (normalized LA mix)"
          },
          {
            "code": "75884-7",
            "system": "http://loinc.org",
            "display": "aPTT.lupus sensitive/​aPTT.lupus sensitive W excess phospholipid (screen to confirm ratio)"
          },
          {
            "code": "68916-6",
            "system": "http://loinc.org",
            "display": "dRVVT W excess phospholipid actual/​normal (normalized LA confirm)"
          },
          {
            "code": "75512-4",
            "system": "http://loinc.org",
            "display": "dRVVT with 1:1 PNP actual/​normal (normalized LA mix)"
          },
          {
            "code": "50410-0",
            "system": "http://loinc.org",
            "display": "dRVVT/​dRVVT W excess phospholipid (screen to confirm ratio)"
          },
          {
            "code": "75511-6",
            "system": "http://loinc.org",
            "display": "dRVVT W excess phospholipid percent correction"
          },
          {
            "code": "57838-5",
            "system": "http://loinc.org",
            "display": "dRVVT W excess phospholipid (LA confirm)"
          },
          {
            "code": "6683-7",
            "system": "http://loinc.org",
            "display": "Reptilase time"
          },
          {
            "code": "68326-8",
            "system": "http://loinc.org",
            "display": "Reptilase time actual/​Normal"
          },
          {
            "code": "68325-0",
            "system": "http://loinc.org",
            "display": "Thrombin time actual/​Normal"
          },
          {
            "code": "5894-1",
            "system": "http://loinc.org",
            "display": "Prothrombin time (PT) actual/​Normal"
          },
          {
            "code": "75881-3",
            "system": "http://loinc.org",
            "display": "Lupus anticoagulant aPTT, dRVVT and PT screening panel W Reflex"
          },
          {
            "code": "75515-7",
            "system": "http://loinc.org",
            "display": "Lupus anticoagulant aPTT and dRVVT screening panel W Reflex"
          }
        ]
      },
      {
        "code": "Urinalysis",
        "type": "emr-defined",
        "target": [
          {
            "code": "5802-4",
            "system": "http://loinc.org",
            "display": "Nitrite [Presence] in Urine by Test strip"
          },
          {
            "code": "5778-6",
            "system": "http://loinc.org",
            "display": "Color of Urine"
          },
          {
            "code": "5803-2",
            "system": "http://loinc.org",
            "display": "pH of Urine by Test strip"
          },
          {
            "code": "5770-3",
            "system": "http://loinc.org",
            "display": "Bilirubin [Presence] in Urine by Test strip"
          },
          {
            "code": "5799-2",
            "system": "http://loinc.org",
            "display": "Leukocyte esterase [Presence] in Urine by Test strip"
          },
          {
            "code": "5767-9",
            "system": "http://loinc.org",
            "display": "Appearance of Urine"
          },
          {
            "code": "5811-5",
            "system": "http://loinc.org",
            "display": "Specific gravity of Urine by Test strip"
          },
          {
            "code": "5794-3",
            "system": "http://loinc.org",
            "display": "Hemoglobin [Presence] in Urine by Test strip"
          },
          {
            "code": "5792-7",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Urine by Test strip"
          },
          {
            "code": "5804-0",
            "system": "http://loinc.org",
            "display": "Protein [Mass/volume] in Urine by Test strip"
          },
          {
            "code": "5797-6",
            "system": "http://loinc.org",
            "display": "Ketones [Mass/volume] in Urine by Test strip"
          },
          {
            "code": "20454-5",
            "system": "http://loinc.org",
            "display": "Protein [Presence] in Urine by Test strip"
          },
          {
            "code": "2514-8",
            "system": "http://loinc.org",
            "display": "Ketones [Presence] in Urine by Test strip"
          },
          {
            "code": "3107-0",
            "system": "http://loinc.org",
            "display": "Urobilinogen [Mass/volume] in Urine"
          },
          {
            "code": "2349-9",
            "system": "http://loinc.org",
            "display": "Glucose [Presence] in Urine"
          },
          {
            "code": "20405-7",
            "system": "http://loinc.org",
            "display": "Urobilinogen [Mass/volume] in Urine by Test strip"
          },
          {
            "code": "2965-2",
            "system": "http://loinc.org",
            "display": "Specific gravity of Urine"
          },
          {
            "code": "5818-0",
            "system": "http://loinc.org",
            "display": "Urobilinogen [Presence] in Urine by Test strip"
          },
          {
            "code": "19161-9",
            "system": "http://loinc.org",
            "display": "Urobilinogen [Units/volume] in Urine by Test strip"
          },
          {
            "code": "1978-6",
            "system": "http://loinc.org",
            "display": "Bilirubin [Mass/volume] in Urine"
          },
          {
            "code": "33903-6",
            "system": "http://loinc.org",
            "display": "Ketones [Presence] in Urine"
          },
          {
            "code": "19244-3",
            "system": "http://loinc.org",
            "display": "Character of Urine"
          },
          {
            "code": "25428-4",
            "system": "http://loinc.org",
            "display": "Glucose [Presence] in Urine by Test strip"
          },
          {
            "code": "2756-5",
            "system": "http://loinc.org",
            "display": "pH of Urine"
          },
          {
            "code": "1977-8",
            "system": "http://loinc.org",
            "display": "Bilirubin [Presence] in Urine"
          },
          {
            "code": "20505-4",
            "system": "http://loinc.org",
            "display": "Bilirubin [Mass/volume] in Urine by Test strip"
          },
          {
            "code": "32167-9",
            "system": "http://loinc.org",
            "display": "Clarity of Urine"
          },
          {
            "code": "5809-9",
            "system": "http://loinc.org",
            "display": "Reducing substances [Presence] in Urine"
          },
          {
            "code": "2640-1",
            "system": "http://loinc.org",
            "display": "Myoglobin [Presence] in Urine"
          },
          {
            "code": "2350-7",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Urine"
          },
          {
            "code": "32147-1",
            "system": "http://loinc.org",
            "display": "Reducing substances [Mass/volume] in Urine"
          },
          {
            "code": "2272-3",
            "system": "http://loinc.org",
            "display": "Fat [Presence] in Urine"
          }
        ]
      },
      {
        "code": "UA - Micro",
        "type": "emr-defined",
        "target": [
          {
            "code": "5821-4",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "5769-5",
            "system": "http://loinc.org",
            "display": "Bacteria [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "13945-1",
            "system": "http://loinc.org",
            "display": "Erythrocytes [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "20409-9",
            "system": "http://loinc.org",
            "display": "Erythrocytes [#/volume] in Urine by Test strip"
          },
          {
            "code": "8247-9",
            "system": "http://loinc.org",
            "display": "Mucus [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "11277-1",
            "system": "http://loinc.org",
            "display": "Epithelial cells.squamous [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "20453-7",
            "system": "http://loinc.org",
            "display": "Epithelial cells [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5808-1",
            "system": "http://loinc.org",
            "display": "Erythrocytes [#/volume] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "5782-8",
            "system": "http://loinc.org",
            "display": "Crystals [type] in Urine sediment by Light microscopy"
          },
          {
            "code": "20408-1",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/volume] in Urine by Test strip"
          },
          {
            "code": "5787-7",
            "system": "http://loinc.org",
            "display": "Epithelial cells [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "11279-7",
            "system": "http://loinc.org",
            "display": "Urine sediment comments by Light microscopy Narrative"
          },
          {
            "code": "25162-9",
            "system": "http://loinc.org",
            "display": "Hyaline casts [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "30405-5",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/volume] in Urine"
          },
          {
            "code": "5796-8",
            "system": "http://loinc.org",
            "display": "Hyaline casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "12454-5",
            "system": "http://loinc.org",
            "display": "Urate crystals amorphous [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "798-9",
            "system": "http://loinc.org",
            "display": "Erythrocytes [#/volume] in Urine by Automated count"
          },
          {
            "code": "12258-0",
            "system": "http://loinc.org",
            "display": "Epithelial cells.squamous [Presence] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "33051-4",
            "system": "http://loinc.org",
            "display": "Erythrocytes [Presence] in Urine"
          },
          {
            "code": "9842-6",
            "system": "http://loinc.org",
            "display": "Casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "32356-8",
            "system": "http://loinc.org",
            "display": "Yeast [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "12235-8",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Urine sediment by Light microscopy"
          },
          {
            "code": "5783-6",
            "system": "http://loinc.org",
            "display": "Unidentified crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "8246-1",
            "system": "http://loinc.org",
            "display": "Amorphous sediment [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "30089-7",
            "system": "http://loinc.org",
            "display": "Transitional cells [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "25145-4",
            "system": "http://loinc.org",
            "display": "Bacteria [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "26052-1",
            "system": "http://loinc.org",
            "display": "Epithelial cells.renal [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "33768-3",
            "system": "http://loinc.org",
            "display": "Leukocyte clumps [#/volume] in Urine by Automated count"
          },
          {
            "code": "5822-2",
            "system": "http://loinc.org",
            "display": "Yeast [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "25160-3",
            "system": "http://loinc.org",
            "display": "Granular casts [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "33804-6",
            "system": "http://loinc.org",
            "display": "RBC casts [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5774-5",
            "system": "http://loinc.org",
            "display": "Calcium oxalate crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5793-5",
            "system": "http://loinc.org",
            "display": "Granular casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "8248-7",
            "system": "http://loinc.org",
            "display": "Spermatozoa [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5813-1",
            "system": "http://loinc.org",
            "display": "Trichomonas vaginalis [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "12248-1",
            "system": "http://loinc.org",
            "display": "Epithelial cells.renal [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "9439-1",
            "system": "http://loinc.org",
            "display": "Casts [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "21033-6",
            "system": "http://loinc.org",
            "display": "Yeast.budding [Presence] in Urine sediment"
          },
          {
            "code": "11276-3",
            "system": "http://loinc.org",
            "display": "Tubular cells [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "46420-6",
            "system": "http://loinc.org",
            "display": "Leukocyte clumps [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "5791-9",
            "system": "http://loinc.org",
            "display": "Fungi.yeastlike [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "5817-2",
            "system": "http://loinc.org",
            "display": "Urate crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "25156-1",
            "system": "http://loinc.org",
            "display": "Eosinophils [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "33393-0",
            "system": "http://loinc.org",
            "display": "Coarse Granular Casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "5785-1",
            "system": "http://loinc.org",
            "display": "Eosinophils [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "32680-1",
            "system": "http://loinc.org",
            "display": "Fine Granular Casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "8249-5",
            "system": "http://loinc.org",
            "display": "Transitional cells [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "25157-9",
            "system": "http://loinc.org",
            "display": "Epithelial casts [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "28545-2",
            "system": "http://loinc.org",
            "display": "Mucus [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "5820-6",
            "system": "http://loinc.org",
            "display": "WBC casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "38996-5",
            "system": "http://loinc.org",
            "display": "Neutrophils [Presence] in Urine by Light microscopy"
          },
          {
            "code": "49839-4",
            "system": "http://loinc.org",
            "display": "Eosinophils [Presence] in Urine sediment by Wright stain"
          },
          {
            "code": "33242-9",
            "system": "http://loinc.org",
            "display": "Fungi.filamentous [Presence] in Urine by Computer assisted method"
          },
          {
            "code": "5814-9",
            "system": "http://loinc.org",
            "display": "Triple phosphate crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "12210-1",
            "system": "http://loinc.org",
            "display": "Eosinophils/100 leukocytes in Urine sediment by Manual count"
          },
          {
            "code": "25148-8",
            "system": "http://loinc.org",
            "display": "Calcium oxalate crystals [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "20456-0",
            "system": "http://loinc.org",
            "display": "Fungi.yeastlike [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5819-8",
            "system": "http://loinc.org",
            "display": "Waxy casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "5807-3",
            "system": "http://loinc.org",
            "display": "RBC casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "38995-7",
            "system": "http://loinc.org",
            "display": "Mixed cellular casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "46138-4",
            "system": "http://loinc.org",
            "display": "Urate crystals [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "25154-6",
            "system": "http://loinc.org",
            "display": "Unidentified crystals [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "5788-5",
            "system": "http://loinc.org",
            "display": "Oval fat bodies (globules) [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "5786-9",
            "system": "http://loinc.org",
            "display": "Epithelial casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "5784-4",
            "system": "http://loinc.org",
            "display": "Cystine crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5775-2",
            "system": "http://loinc.org",
            "display": "Calcium phosphate crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5789-3",
            "system": "http://loinc.org",
            "display": "Fatty casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "5773-7",
            "system": "http://loinc.org",
            "display": "Calcium carbonate crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5798-4",
            "system": "http://loinc.org",
            "display": "Leucine crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5815-6",
            "system": "http://loinc.org",
            "display": "Tyrosine crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5766-1",
            "system": "http://loinc.org",
            "display": "Ammonium urate crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "25149-6",
            "system": "http://loinc.org",
            "display": "Calcium phosphate crystals [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "25158-7",
            "system": "http://loinc.org",
            "display": "Oval fat bodies (globules) [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "18487-9",
            "system": "http://loinc.org",
            "display": "Broad casts [#/area] in Urine sediment by Microscopy low power field"
          },
          {
            "code": "20457-8",
            "system": "http://loinc.org",
            "display": "Fungi.filamentous [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5812-3",
            "system": "http://loinc.org",
            "display": "Sulfonamide crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5771-1",
            "system": "http://loinc.org",
            "display": "Bilirubin crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "25147-0",
            "system": "http://loinc.org",
            "display": "Calcium carbonate crystals [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "5777-8",
            "system": "http://loinc.org",
            "display": "Cholesterol crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "20455-2",
            "system": "http://loinc.org",
            "display": "Leukocytes [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "33905-1",
            "system": "http://loinc.org",
            "display": "Trichomonas sp [#/area] in Urine sediment by Microscopy high power field"
          },
          {
            "code": "5795-0",
            "system": "http://loinc.org",
            "display": "Hippurate crystals [Presence] in Urine sediment by Light microscopy"
          },
          {
            "code": "5776-0",
            "system": "http://loinc.org",
            "display": "Calcium sulfate crystals [Presence] in Urine sediment by Light microscopy"
          }
        ]
      },
      {
        "code": "Urine Chemistries",
        "type": "emr-defined",
        "target": [
          {
            "code": "2161-8",
            "system": "http://loinc.org",
            "display": "Creatinine [Mass/volume] in Urine"
          },
          {
            "code": "14957-5",
            "system": "http://loinc.org",
            "display": "Microalbumin [Mass/volume] in Urine"
          },
          {
            "code": "58448-2",
            "system": "http://loinc.org",
            "display": "Microalbumin ug/min [Mass/time] in 24 hour Urine"
          },
          {
            "code": "14959-1",
            "system": "http://loinc.org",
            "display": "Microalbumin/Creatinine [Mass ratio] in Urine"
          },
          {
            "code": "2888-6",
            "system": "http://loinc.org",
            "display": "Protein [Mass/volume] in Urine"
          },
          {
            "code": "35674-1",
            "system": "http://loinc.org",
            "display": "Creatinine [Mass/volume] in unspecified time Urine"
          },
          {
            "code": "2955-3",
            "system": "http://loinc.org",
            "display": "Sodium [Moles/volume] in Urine"
          },
          {
            "code": "2162-6",
            "system": "http://loinc.org",
            "display": "Creatinine [Mass/time] in 24 hour Urine"
          },
          {
            "code": "2889-4",
            "system": "http://loinc.org",
            "display": "Protein [Mass/time] in 24 hour Urine"
          },
          {
            "code": "2828-2",
            "system": "http://loinc.org",
            "display": "Potassium [Moles/volume] in Urine"
          },
          {
            "code": "2890-2",
            "system": "http://loinc.org",
            "display": "Protein/Creatinine [Mass ratio] in Urine"
          },
          {
            "code": "2695-5",
            "system": "http://loinc.org",
            "display": "Osmolality of Urine"
          },
          {
            "code": "2164-2",
            "system": "http://loinc.org",
            "display": "Creatinine renal clearance in 24 hour Urine"
          },
          {
            "code": "35663-4",
            "system": "http://loinc.org",
            "display": "Protein [Mass/volume] in unspecified time Urine"
          },
          {
            "code": "3095-7",
            "system": "http://loinc.org",
            "display": "Urea nitrogen [Mass/volume] in Urine"
          },
          {
            "code": "35678-2",
            "system": "http://loinc.org",
            "display": "Sodium [Moles/volume] in unspecified time Urine"
          },
          {
            "code": "2078-4",
            "system": "http://loinc.org",
            "display": "Chloride [Moles/volume] in Urine"
          },
          {
            "code": "17862-4",
            "system": "http://loinc.org",
            "display": "Calcium [Mass/volume] in Urine"
          },
          {
            "code": "6874-2",
            "system": "http://loinc.org",
            "display": "Calcium [Mass/time] in 24 hour Urine"
          },
          {
            "code": "35676-6",
            "system": "http://loinc.org",
            "display": "Chloride [Moles/volume] in unspecified time Urine"
          },
          {
            "code": "18488-7",
            "system": "http://loinc.org",
            "display": "Calcium [Mass/volume] in 24 hour Urine"
          },
          {
            "code": "2778-9",
            "system": "http://loinc.org",
            "display": "Phosphate [Mass/volume] in Urine"
          },
          {
            "code": "2956-1",
            "system": "http://loinc.org",
            "display": "Sodium [Moles/time] in 24 hour Urine"
          },
          {
            "code": "6687-8",
            "system": "http://loinc.org",
            "display": "Citrate [Mass/time] in 24 hour Urine"
          },
          {
            "code": "12195-4",
            "system": "http://loinc.org",
            "display": "Creatinine renal clearance/1.73 sq M in 24 hour Urine"
          },
          {
            "code": "14956-7",
            "system": "http://loinc.org",
            "display": "Microalbumin [Mass/time] in 24 hour Urine"
          },
          {
            "code": "3087-4",
            "system": "http://loinc.org",
            "display": "Urate [Mass/time] in 24 hour Urine"
          },
          {
            "code": "35675-8",
            "system": "http://loinc.org",
            "display": "Calcium [Mass/volume] in unspecified time Urine"
          },
          {
            "code": "3086-6",
            "system": "http://loinc.org",
            "display": "Urate [Mass/volume] in Urine"
          },
          {
            "code": "21525-1",
            "system": "http://loinc.org",
            "display": "Sodium [Moles/volume] in 24 hour Urine"
          },
          {
            "code": "2779-7",
            "system": "http://loinc.org",
            "display": "Phosphate [Mass/time] in 24 hour Urine"
          },
          {
            "code": "2701-1",
            "system": "http://loinc.org",
            "display": "Oxalate [Mass/time] in 24 hour Urine"
          },
          {
            "code": "14862-7",
            "system": "http://loinc.org",
            "display": "Oxalate [Moles/time] in 24 hour Urine"
          },
          {
            "code": "21482-5",
            "system": "http://loinc.org",
            "display": "Protein [Mass/volume] in 24 hour Urine"
          },
          {
            "code": "3096-5",
            "system": "http://loinc.org",
            "display": "Urea nitrogen [Mass/time] in 24 hour Urine"
          },
          {
            "code": "13538-4",
            "system": "http://loinc.org",
            "display": "Carbon dioxide, total [Moles/volume] in Urine"
          },
          {
            "code": "2700-3",
            "system": "http://loinc.org",
            "display": "Oxalate [Mass/volume] in Urine"
          },
          {
            "code": "30003-8",
            "system": "http://loinc.org",
            "display": "Microalbumin [Mass/volume] in 24 hour Urine"
          },
          {
            "code": "20624-3",
            "system": "http://loinc.org",
            "display": "Creatinine [Mass/volume] in 24 hour Urine"
          },
          {
            "code": "14958-3",
            "system": "http://loinc.org",
            "display": "Microalbumin/Creatinine [Mass ratio] in 24 hour Urine"
          },
          {
            "code": "9318-7",
            "system": "http://loinc.org",
            "display": "Alb/Creat Ratio"
          }
        ]
      },
      {
        "code": "Urine Studies",
        "type": "emr-defined",
        "target": [
          {
            "code": "13362-9",
            "system": "http://loinc.org",
            "display": "Collection duration of Urine"
          },
          {
            "code": "3167-4",
            "system": "http://loinc.org",
            "display": "Volume of 24 hour Urine"
          },
          {
            "code": "19153-6",
            "system": "http://loinc.org",
            "display": "Volume of unspecified time Urine"
          },
          {
            "code": "2147-7",
            "system": "http://loinc.org",
            "display": "Cortisol Free [Mass/time] in 24 hour Urine"
          },
          {
            "code": "14115-0",
            "system": "http://loinc.org",
            "display": "Collagen crosslinked N-telopeptide/Creatinine [Molar ratio] in Urine"
          },
          {
            "code": "2671-6",
            "system": "http://loinc.org",
            "display": "Normetanephrine [Mass/time] in 24 hour Urine"
          },
          {
            "code": "2232-7",
            "system": "http://loinc.org",
            "display": "Epinephrine [Mass/time] in 24 hour Urine"
          },
          {
            "code": "2668-2",
            "system": "http://loinc.org",
            "display": "Norepinephrine [Mass/time] in 24 hour Urine"
          },
          {
            "code": "2218-6",
            "system": "http://loinc.org",
            "display": "Dopamine [Mass/time] in 24 hour Urine"
          },
          {
            "code": "19049-6",
            "system": "http://loinc.org",
            "display": "Metanephrine [Mass/time] in 24 hour Urine"
          },
          {
            "code": "2609-6",
            "system": "http://loinc.org",
            "display": "Metanephrines [Mass/time] in 24 hour Urine"
          },
          {
            "code": "3122-9",
            "system": "http://loinc.org",
            "display": "Vanillylmandelate [Mass/time] in 24 hour Urine"
          },
          {
            "code": "27939-8",
            "system": "http://loinc.org",
            "display": "Collagen crosslinked N-telopeptide [Moles/volume] in Urine"
          },
          {
            "code": "1695-6",
            "system": "http://loinc.org",
            "display": "5-Hydroxyindoleacetate [Mass/time] in 24 hour Urine"
          },
          {
            "code": "11040-3",
            "system": "http://loinc.org",
            "display": "Cortisol Free [Mass/volume] in Urine"
          },
          {
            "code": "28009-9",
            "system": "http://loinc.org",
            "display": "Volume of Urine"
          },
          {
            "code": "19050-4",
            "system": "http://loinc.org",
            "display": "Metanephrines [Mass/volume] in 24 hour Urine"
          },
          {
            "code": "19088-4",
            "system": "http://loinc.org",
            "display": "Collection of urine specimen start date"
          },
          {
            "code": "19089-2",
            "system": "http://loinc.org",
            "display": "Collection of urine specimen start time"
          },
          {
            "code": "19086-8",
            "system": "http://loinc.org",
            "display": "Collection of urine specimen end date"
          },
          {
            "code": "19087-6",
            "system": "http://loinc.org",
            "display": "Collection of urine specimen end time"
          },
          {
            "code": "21422-1",
            "system": "http://loinc.org",
            "display": "Normetanephrine [Mass/volume] in 24 hour Urine"
          },
          {
            "code": "2217-8",
            "system": "http://loinc.org",
            "display": "Dopamine [Mass/volume] in Urine"
          },
          {
            "code": "11046-0",
            "system": "http://loinc.org",
            "display": "Epinephrine [Mass/volume] in Urine"
          },
          {
            "code": "2667-4",
            "system": "http://loinc.org",
            "display": "Norepinephrine [Mass/volume] in Urine"
          },
          {
            "code": "9624-8",
            "system": "http://loinc.org",
            "display": "Vanillylmandelate [Mass/volume] in Urine"
          }
        ]
      },
      {
        "code": "Urine Electrophoresis",
        "type": "emr-defined",
        "target": [
          {
            "code": "13438-7",
            "system": "http://loinc.org",
            "display": "Protein Fractions [interpretation] in Urine by Electrophoresis"
          },
          {
            "code": "13992-3",
            "system": "http://loinc.org",
            "display": "Albumin/Protein.total in Urine by Electrophoresis"
          },
          {
            "code": "13990-7",
            "system": "http://loinc.org",
            "display": "Alpha 1 globulin/Protein.total in Urine by Electrophoresis"
          },
          {
            "code": "6942-7",
            "system": "http://loinc.org",
            "display": "Albumin [Mass/volume] in Urine by Electrophoresis"
          },
          {
            "code": "13987-3",
            "system": "http://loinc.org",
            "display": "Alpha 2 globulin/Protein.total in 24 hour Urine by Electrophoresis"
          },
          {
            "code": "13989-9",
            "system": "http://loinc.org",
            "display": "Gamma globulin/Protein.total in 24 hour Urine by Electrophoresis"
          },
          {
            "code": "13994-9",
            "system": "http://loinc.org",
            "display": "Beta globulin/Protein.total in Urine by Electrophoresis"
          },
          {
            "code": "13988-1",
            "system": "http://loinc.org",
            "display": "Beta globulin/Protein.total in 24 hour Urine by Electrophoresis"
          },
          {
            "code": "49047-4",
            "system": "http://loinc.org",
            "display": "Globulin [Mass/volume] in Urine by Electrophoresis"
          },
          {
            "code": "13993-1",
            "system": "http://loinc.org",
            "display": "Alpha 2 globulin/Protein.total in Urine by Electrophoresis"
          },
          {
            "code": "13995-6",
            "system": "http://loinc.org",
            "display": "Gamma globulin/Protein.total in Urine by Electrophoresis"
          },
          {
            "code": "13986-5",
            "system": "http://loinc.org",
            "display": "Albumin/Protein.total in 24 hour Urine by Electrophoresis"
          },
          {
            "code": "13984-0",
            "system": "http://loinc.org",
            "display": "Alpha 1 globulin/Protein.total in 24 hour Urine by Electrophoresis"
          },
          {
            "code": "42484-6",
            "system": "http://loinc.org",
            "display": "Protein.monoclonal/Protein.total in 24 hour Urine by Electrophoresis"
          },
          {
            "code": "42483-8",
            "system": "http://loinc.org",
            "display": "Protein.monoclonal/Protein.total in Urine by Electrophoresis"
          },
          {
            "code": "17819-4",
            "system": "http://loinc.org",
            "display": "Albumin/Protein.total in unspecified time Urine by Electrophoresis"
          },
          {
            "code": "17811-1",
            "system": "http://loinc.org",
            "display": "Alpha 1 globulin/Protein.total in unspecified time Urine by Electrophoresis"
          },
          {
            "code": "17813-7",
            "system": "http://loinc.org",
            "display": "Alpha 2 globulin/Protein.total in unspecified time Urine by Electrophoresis"
          },
          {
            "code": "17815-2",
            "system": "http://loinc.org",
            "display": "Beta globulin/Protein.total in unspecified time Urine by Electrophoresis"
          },
          {
            "code": "17817-8",
            "system": "http://loinc.org",
            "display": "Gamma globulin/Protein.total in unspecified time Urine by Electrophoresis"
          }
        ]
      },
      {
        "code": "Hepatitis Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "5196-1",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus surface Ag [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5195-3",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus surface Ag [Presence] in Serum"
          },
          {
            "code": "5198-7",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "51656-7",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus Ab Signal/Cutoff [Ratio] in Body fluid"
          },
          {
            "code": "13950-1",
            "system": "http://loinc.org",
            "display": "Hepatitis A virus IgM Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "48159-8",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus Ab Signal/Cutoff [Ratio] in Serum or Plasma by Immunoassay"
          },
          {
            "code": "5194-6",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus surface Ab [Units/volume] in Serum by Radioimmunoassay (RIA)"
          },
          {
            "code": "24113-3",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus core IgM Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "22322-2",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus surface Ab [Presence] in Serum"
          },
          {
            "code": "13955-0",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "16128-1",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus Ab [Presence] in Serum"
          },
          {
            "code": "13952-7",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus core Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "65633-0",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus surface Ag [Presence] in Serum by Confirmatory method"
          },
          {
            "code": "16935-9",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus surface Ab [Units/volume] in Serum"
          },
          {
            "code": "5193-8",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus surface Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "11011-4",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus RNA [Units/volume] (viral load) in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "13951-9",
            "system": "http://loinc.org",
            "display": "Hepatitis A virus Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5185-4",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus core IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "22330-5",
            "system": "http://loinc.org",
            "display": "Hepatitis D virus Ab [Units/volume] in Serum"
          },
          {
            "code": "22314-9",
            "system": "http://loinc.org",
            "display": "Hepatitis A virus IgM Ab [Presence] in Serum"
          },
          {
            "code": "11259-9",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus RNA [Presence] in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "38180-6",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus RNA [log units/volume] (viral load) in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "31204-1",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus core IgM Ab [Presence] in Serum"
          },
          {
            "code": "13953-5",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus e Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "13954-3",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus e Ag [Presence] in Serum by Immunoassay"
          },
          {
            "code": "10900-9",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus surface Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "32286-7",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus genotype [Identifier] in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "5199-5",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "24011-9",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus Ab band pattern [interpretation] in Serum by Immunoblot (IB)"
          },
          {
            "code": "5187-0",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus core Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "11258-1",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus DNA [Units/volume] in Serum"
          },
          {
            "code": "5181-3",
            "system": "http://loinc.org",
            "display": "Hepatitis A virus IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "31844-4",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus e Ag [Presence] in Serum"
          },
          {
            "code": "29615-2",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus DNA [#/volume] (viral load) in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "5183-9",
            "system": "http://loinc.org",
            "display": "Hepatitis A virus Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "5191-2",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus e Ag [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "7905-3",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus surface Ag [Presence] in Serum by Neutralization test"
          },
          {
            "code": "47440-3",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus core Ab [Presence] in Serum from donor"
          },
          {
            "code": "49846-9",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus Ag [Presence] in Blood or Marrow from donor"
          },
          {
            "code": "47364-5",
            "system": "http://loinc.org",
            "display": "Hepatitis B virus surface Ag [Presence] in Serum from donor by Immunoassay"
          },
          {
            "code": "47441-1",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus Ab [Presence] in Serum from donor"
          },
          {
            "code": "23871-7",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus NS5 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9610-7",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus c33c Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9609-9",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus 22-3 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "20416-4",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus RNA [#/volume] (viral load) in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "47252-2",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus RNA [Log #/volume] (viral load) in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "22315-6",
            "system": "http://loinc.org",
            "display": "Hepatitis A virus IgM Ab [Units/volume] in Serum"
          },
          {
            "code": "23870-9",
            "system": "http://loinc.org",
            "display": "Hepatitis C virus 100+5-1-1 Ab [Presence] in Serum by Immunoblot (IB)"
          }
        ]
      },
      {
        "code": "HIV Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "48345-3",
            "system": "http://loinc.org",
            "display": "HIV 1+O+2 Ab [Presence] in Serum or Plasma"
          },
          {
            "code": "48346-1",
            "system": "http://loinc.org",
            "display": "HIV 1+O+2 Ab [Units/volume] in Serum or Plasma"
          },
          {
            "code": "31201-7",
            "system": "http://loinc.org",
            "display": "HIV 1+2 Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "7918-6",
            "system": "http://loinc.org",
            "display": "HIV 1+2 Ab [Presence] in Serum"
          },
          {
            "code": "20447-9",
            "system": "http://loinc.org",
            "display": "HIV 1 RNA [#/volume] (viral load) in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "29541-0",
            "system": "http://loinc.org",
            "display": "HIV 1 RNA [Log #/volume] (viral load) in Plasma by Probe & target amplification method"
          },
          {
            "code": "25836-8",
            "system": "http://loinc.org",
            "display": "HIV 1 RNA [#/volume] (viral load) in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "24012-7",
            "system": "http://loinc.org",
            "display": "HIV 1 Ag [Presence] in Serum"
          },
          {
            "code": "5222-5",
            "system": "http://loinc.org",
            "display": "HIV 1 Ag [Presence] in Serum by Immunoassay"
          },
          {
            "code": "24013-5",
            "system": "http://loinc.org",
            "display": "HIV 1 RNA [interpretation] in Serum"
          },
          {
            "code": "42768-2",
            "system": "http://loinc.org",
            "display": "HIV 1 & 2 Ab [interpretation] in Serum Narrative"
          },
          {
            "code": "29893-5",
            "system": "http://loinc.org",
            "display": "HIV 1 Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "9660-2",
            "system": "http://loinc.org",
            "display": "HIV 1 gp160 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9668-5",
            "system": "http://loinc.org",
            "display": "HIV 1 p55 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9667-7",
            "system": "http://loinc.org",
            "display": "HIV 1 p51 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9664-4",
            "system": "http://loinc.org",
            "display": "HIV 1 p24 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9661-0",
            "system": "http://loinc.org",
            "display": "HIV 1 gp120 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9666-9",
            "system": "http://loinc.org",
            "display": "HIV 1 p31 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "13499-9",
            "system": "http://loinc.org",
            "display": "HIV 1 Ab band pattern [interpretation] in Serum by Immunoblot (IB)"
          },
          {
            "code": "12856-1",
            "system": "http://loinc.org",
            "display": "HIV 1 p65 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9662-8",
            "system": "http://loinc.org",
            "display": "HIV 1 gp41 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "12859-5",
            "system": "http://loinc.org",
            "display": "HIV 1 p18 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "35452-2",
            "system": "http://loinc.org",
            "display": "HIV 1 gp40 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "30361-0",
            "system": "http://loinc.org",
            "display": "HIV 2 Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5221-7",
            "system": "http://loinc.org",
            "display": "HIV 1 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "49580-4",
            "system": "http://loinc.org",
            "display": "HIV 1+2 Ab [Presence] in Unspecified specimen by Rapid test"
          },
          {
            "code": "7917-8",
            "system": "http://loinc.org",
            "display": "HIV 1 Ab [Presence] in Serum"
          },
          {
            "code": "25835-0",
            "system": "http://loinc.org",
            "display": "HIV 1 RNA [Presence] in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "44533-8",
            "system": "http://loinc.org",
            "display": "HIV 1+2 Ab [Presence] in Serum from donor"
          },
          {
            "code": "23876-6",
            "system": "http://loinc.org",
            "display": "HIV 1 RNA [Units/volume] (viral load) in Plasma by Probe & signal amplification method"
          },
          {
            "code": "29539-4",
            "system": "http://loinc.org",
            "display": "HIV 1 RNA [Log #/volume] (viral load) in Plasma by Probe & signal amplification method"
          },
          {
            "code": "44607-0",
            "system": "http://loinc.org",
            "display": "HIV 1 [interpretation] in Serum by Immunoassay"
          }
        ]
      },
      {
        "code": "Syphilis Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "20507-0",
            "system": "http://loinc.org",
            "display": "Reagin Ab [Presence] in Serum by RPR"
          },
          {
            "code": "31147-2",
            "system": "http://loinc.org",
            "display": "Reagin Ab [Titer] in Serum by RPR"
          },
          {
            "code": "6561-5",
            "system": "http://loinc.org",
            "display": "Treponema pallidum IgG Ab [Presence] in Serum"
          },
          {
            "code": "47238-1",
            "system": "http://loinc.org",
            "display": "Treponema pallidum IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "22587-0",
            "system": "http://loinc.org",
            "display": "Treponema pallidum Ab [Presence] in Serum"
          },
          {
            "code": "5393-4",
            "system": "http://loinc.org",
            "display": "Treponema pallidum Ab [Presence] in Serum by Immunofluorescence"
          },
          {
            "code": "5292-8",
            "system": "http://loinc.org",
            "display": "Reagin Ab [Presence] in Serum by VDRL"
          },
          {
            "code": "22463-4",
            "system": "http://loinc.org",
            "display": "Reagin Ab [Presence] in Serum from donor"
          },
          {
            "code": "24312-1",
            "system": "http://loinc.org",
            "display": "Treponema pallidum Ab [Presence] in Serum by Agglutination"
          },
          {
            "code": "41163-7",
            "system": "http://loinc.org",
            "display": "Treponema pallidum DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          }
        ]
      },
      {
        "code": "Chlamydia/GC/Trich Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "24111-7",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "21613-5",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "53927-0",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae rRNA [Presence] in Urethra by Probe & target amplification method"
          },
          {
            "code": "60256-5",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae rRNA [Presence] in Urine by Probe & target amplification method"
          },
          {
            "code": "53925-4",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis rRNA [Presence] in Urethra by Probe & target amplification method"
          },
          {
            "code": "43304-5",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis rRNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "43305-2",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae rRNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "50387-0",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis rRNA [Presence] in Cervix by Probe & target amplification method"
          },
          {
            "code": "50388-8",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae rRNA [Presence] in Cervix by Probe & target amplification method"
          },
          {
            "code": "42931-6",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis rRNA [Presence] in Urine by Probe & target amplification method"
          },
          {
            "code": "36903-3",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis+Neisseria gonorrhoeae DNA [Identifier] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "5028-6",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae rRNA [Presence] in Unspecified specimen by DNA probe"
          },
          {
            "code": "6568-0",
            "system": "http://loinc.org",
            "display": "Trichomonas vaginalis rRNA [Presence] in Genital specimen by DNA probe"
          },
          {
            "code": "4993-2",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis rRNA [Presence] in Unspecified specimen by DNA probe"
          },
          {
            "code": "46154-1",
            "system": "http://loinc.org",
            "display": "Trichomonas vaginalis rRNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "6357-8",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis DNA [Presence] in Urine by Probe & target amplification method"
          },
          {
            "code": "21190-4",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis DNA [Presence] in Cervix by Probe and target amplification method"
          },
          {
            "code": "32198-4",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae rRNA [Presence] in Cervix by DNA probe"
          },
          {
            "code": "6565-6",
            "system": "http://loinc.org",
            "display": "Trichomonas vaginalis [Identifier] in Genital specimen by Wet preparation"
          },
          {
            "code": "32766-8",
            "system": "http://loinc.org",
            "display": "Trichomonas vaginalis [Presence] in Unspecified specimen by Wet preparation"
          },
          {
            "code": "21416-3",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae DNA [Presence] in Urine by Probe & target amplification method"
          },
          {
            "code": "47387-6",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae DNA [Presence] in Genital specimen by Probe & target amplification method"
          },
          {
            "code": "45068-4",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis+Neisseria gonorrhoeae DNA [Presence] in Cervix by Probe and target amplification method"
          },
          {
            "code": "14463-4",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis [Presence] in Cervix by Organism specific culture"
          },
          {
            "code": "45094-0",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis [Presence] in Conjunctival specimen by Organism specific culture"
          },
          {
            "code": "80367-6",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis [Presence] in Rectum by Organism specific culture"
          },
          {
            "code": "14465-9",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis [Presence] in Urethra by Organism specific culture"
          },
          {
            "code": "14464-2",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis [Presence] in Vaginal fluid by Organism specific culture"
          },
          {
            "code": "6351-1",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis Ag [Presence] in Conjunctival specimen by Immunofluorescence"
          },
          {
            "code": "45084-1",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis and Neisseria gonorrhoeae rRNA panel - Vaginal fluid by Probe and target amplification method"
          },
          {
            "code": "45086-6",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis DNA [Presence] in Nasopharynx by Probe and target amplification method"
          },
          {
            "code": "80363-5",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis DNA [Presence] in Rectum by Probe and target amplification method"
          },
          {
            "code": "57288-3",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis rRNA [Presence] in Nasopharynx by Probe and target amplification method"
          },
          {
            "code": "80364-3",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis rRNA [Presence] in Rectum by Probe and target amplification method"
          },
          {
            "code": "53926-2",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis rRNA [Presence] in Vaginal fluid by Probe and target amplification method"
          },
          {
            "code": "70164-9",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis+Neisseria gonorrhoeae DNA [Presence] in Urine by Probe and target amplification method"
          },
          {
            "code": "80361-9",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis+Neisseria gonorrhoeae rRNA [Presence] in Cervix by Probe and target amplification method"
          },
          {
            "code": "80365-0",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis+Neisseria gonorrhoeae rRNA [Presence] in Rectum by Probe and target amplification method"
          },
          {
            "code": "80360-1",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis+Neisseria gonorrhoeae rRNA [Presence] in Urine by Probe and target amplification method"
          },
          {
            "code": "80362-7",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis+Neisseria gonorrhoeae rRNA [Presence] in Vaginal fluid by Probe and target amplification method"
          },
          {
            "code": "688-2",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae [Presence] in Cervix by Organism specific culture"
          },
          {
            "code": "30099-6",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae [Presence] in Conjunctival specimen by Organism specific culture"
          },
          {
            "code": "80368-4",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae [Presence] in Rectum by Organism specific culture"
          },
          {
            "code": "697-3",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae [Presence] in Urethra by Organism specific culture"
          },
          {
            "code": "693-2",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae [Presence] in Vaginal fluid by Organism specific culture"
          },
          {
            "code": "32705-6",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae Ag [Presence] in Genital specimen"
          },
          {
            "code": "53879-3",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae Ag [Presence] in Urethra"
          },
          {
            "code": "21415-5",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae DNA [Presence] in Urethra by Probe and target amplification method"
          },
          {
            "code": "57289-1",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae rRNA [Presence] in Nasopharynx by Probe and target amplification method"
          },
          {
            "code": "80366-8",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae rRNA [Presence] in Rectum by Probe and target amplification method"
          },
          {
            "code": "80369-2",
            "system": "http://loinc.org",
            "display": "Neisseria sp identified in Rectum by Organism specific culture"
          },
          {
            "code": "43384-7",
            "system": "http://loinc.org",
            "display": "Neisseria sp identified in Urethra by Organism specific culture"
          }
        ]
      },
      {
        "code": "Infectious Disease",
        "type": "emr-defined",
        "target": [
          {
            "code": "30167-1",
            "system": "http://loinc.org",
            "display": "Human papilloma virus 16+18+31+33+35+39+45+51+52+56+58+59+68 DNA [Presence] in Cervix by Probe & signal amplification method"
          },
          {
            "code": "56598-6",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus early IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "10701-1",
            "system": "http://loinc.org",
            "display": "Ova+Parasites identified in Stool by Concentration"
          },
          {
            "code": "35691-5",
            "system": "http://loinc.org",
            "display": "XXX microorganism DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "5334-8",
            "system": "http://loinc.org",
            "display": "Rubella virus IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "18481-2",
            "system": "http://loinc.org",
            "display": "Streptococcus pyogenes Ag [Presence] in Throat"
          },
          {
            "code": "19162-7",
            "system": "http://loinc.org",
            "display": "Varicella zoster virus IgG Ab [Presence] in Serum"
          },
          {
            "code": "34148-7",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi IgG+IgM Ab [Units/volume] in Serum"
          },
          {
            "code": "34713-8",
            "system": "http://loinc.org",
            "display": "Clostridium difficile toxin A+B [Presence] in Stool"
          },
          {
            "code": "5176-3",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "5209-2",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 2 IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "12232-5",
            "system": "http://loinc.org",
            "display": "Measles virus Ag [Presence] in Unspecified specimen by Immunofluorescence"
          },
          {
            "code": "5403-1",
            "system": "http://loinc.org",
            "display": "Varicella zoster virus IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "20761-3",
            "system": "http://loinc.org",
            "display": "Clostridium difficile [Presence] in Stool by Agglutination"
          },
          {
            "code": "29891-9",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori [Presence] in Stomach by urea breath test"
          },
          {
            "code": "19107-2",
            "system": "http://loinc.org",
            "display": "Histoplasma capsulatum Ag [Units/volume] in Serum by Radioimmunoassay (RIA)"
          },
          {
            "code": "5064-1",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "11006-4",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi Ab [Presence] in Serum"
          },
          {
            "code": "5206-8",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 1 IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "13503-8",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi Ab.IgM band pattern [interpretation] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9587-7",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 41kD IgM Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "42481-2",
            "system": "http://loinc.org",
            "display": "Human papilloma virus 6+11+42+43+44 DNA [Presence] in Cervix by Probe & signal amplification method"
          },
          {
            "code": "13502-0",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi Ab.IgG band pattern [interpretation] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9597-6",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 93kD IgG Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "31418-7",
            "system": "http://loinc.org",
            "display": "Heterophile Ab [Presence] in Serum"
          },
          {
            "code": "9593-5",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 41kD IgG Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9590-1",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 28kD IgG Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9594-3",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 45kD IgG Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9589-3",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 23kD IgG Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9591-9",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 30kD IgG Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9592-7",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 39kD IgG Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9599-2",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 39kD IgM Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9598-4",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 23kD IgM Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9595-0",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 58kD IgG Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "9596-8",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 66kD IgG Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "47000-5",
            "system": "http://loinc.org",
            "display": "Candida sp rRNA [Presence] in Vaginal fluid by DNA probe"
          },
          {
            "code": "9588-5",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi 18kD IgG Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "44357-2",
            "system": "http://loinc.org",
            "display": "Galactomannan Ag [Units/volume] in Serum or Plasma by Immunoassay"
          },
          {
            "code": "6410-5",
            "system": "http://loinc.org",
            "display": "Gardnerella vaginalis rRNA [Presence] in Genital specimen by DNA probe"
          },
          {
            "code": "21262-1",
            "system": "http://loinc.org",
            "display": "Escherichia coli shiga-like [Presence] in Stool by Immunoassay"
          },
          {
            "code": "6420-4",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori IgA Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "7886-5",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus capsid IgM Ab [Units/volume] in Serum"
          },
          {
            "code": "5159-9",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus capsid IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "7885-7",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus capsid IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "5157-3",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus capsid IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "5244-9",
            "system": "http://loinc.org",
            "display": "Measles virus IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "10704-5",
            "system": "http://loinc.org",
            "display": "Ova+Parasites identified in Stool by Light microscopy"
          },
          {
            "code": "41477-1",
            "system": "http://loinc.org",
            "display": "Bacterial sialidase [Presence] in Unspecified specimen"
          },
          {
            "code": "5124-3",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "31374-2",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus nuclear IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "30083-0",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus nuclear IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "34468-9",
            "system": "http://loinc.org",
            "display": "Clostridium difficile toxin A+B [Presence] in Stool by Immunoassay"
          },
          {
            "code": "21440-3",
            "system": "http://loinc.org",
            "display": "Human papilloma virus 16+18+31+33+35+45+51+52+56 DNA [Presence] in Cervix by DNA probe"
          },
          {
            "code": "40752-8",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus early IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5332-2",
            "system": "http://loinc.org",
            "display": "Rubella virus Ab [Presence] in Serum by Latex agglutination"
          },
          {
            "code": "5862-8",
            "system": "http://loinc.org",
            "display": "Influenza virus A Ag [Presence] in Unspecified specimen by Immunoassay"
          },
          {
            "code": "32764-3",
            "system": "http://loinc.org",
            "display": "Clue cells [Presence] in Unspecified specimen by Wet preparation"
          },
          {
            "code": "7900-4",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori Ab [Units/volume] in Serum"
          },
          {
            "code": "5370-2",
            "system": "http://loinc.org",
            "display": "Streptolysin O Ab [Units/volume] in Serum"
          },
          {
            "code": "17859-0",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "22496-4",
            "system": "http://loinc.org",
            "display": "Rubella virus Ab [Presence] in Serum"
          },
          {
            "code": "7966-5",
            "system": "http://loinc.org",
            "display": "Mumps virus IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "20444-6",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 1+2 DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "5866-9",
            "system": "http://loinc.org",
            "display": "Influenza virus B Ag [Presence] in Unspecified specimen by Immunoassay"
          },
          {
            "code": "16131-5",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 2 DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "41399-7",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 1+2 IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "6412-1",
            "system": "http://loinc.org",
            "display": "Giardia lamblia Ag [Presence] in Stool by Immunoassay"
          },
          {
            "code": "21003-9",
            "system": "http://loinc.org",
            "display": "Fungus identified in Unspecified specimen by Fungus stain"
          },
          {
            "code": "5177-1",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "5213-4",
            "system": "http://loinc.org",
            "display": "Heterophile Ab [Presence] in Serum by Latex agglutination"
          },
          {
            "code": "5388-4",
            "system": "http://loinc.org",
            "display": "Toxoplasma gondii IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "27948-9",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 1+2 IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "32765-0",
            "system": "http://loinc.org",
            "display": "Yeast [Presence] in Unspecified specimen by Wet preparation"
          },
          {
            "code": "5876-8",
            "system": "http://loinc.org",
            "display": "Respiratory syncytial virus Ag [Presence] in Unspecified specimen by Immunoassay"
          },
          {
            "code": "28008-1",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus DNA [Presence] in Blood by Probe & signal amplification method"
          },
          {
            "code": "22131-7",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi IgG+IgM Ab [Presence] in Serum"
          },
          {
            "code": "5404-9",
            "system": "http://loinc.org",
            "display": "Varicella zoster virus IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "17780-8",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori Ag [Presence] in Stool by Immunoassay"
          },
          {
            "code": "5034-4",
            "system": "http://loinc.org",
            "display": "Streptococcus agalactiae rRNA [Presence] in Unspecified specimen by DNA probe"
          },
          {
            "code": "35383-9",
            "system": "http://loinc.org",
            "display": "Galactomannan Ag [Units/volume] in Serum or Plasma"
          },
          {
            "code": "11266-4",
            "system": "http://loinc.org",
            "display": "Streptococcus agalactiae Ag [Presence] in Unspecified specimen"
          },
          {
            "code": "5126-8",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "8014-3",
            "system": "http://loinc.org",
            "display": "Rubella virus IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "20573-2",
            "system": "http://loinc.org",
            "display": "Histoplasma capsulatum mycelial phase Ab [Titer] in Serum by Complement fixation"
          },
          {
            "code": "42176-8",
            "system": "http://loinc.org",
            "display": "1,3 beta glucan [Mass/volume] in Serum"
          },
          {
            "code": "32637-1",
            "system": "http://loinc.org",
            "display": "Urease [Presence] in Tissue"
          },
          {
            "code": "46248-1",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi IgG & IgM [interpretation] in Serum by Immunoassay"
          },
          {
            "code": "20475-0",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus IgG Ab [interpretation] in Serum"
          },
          {
            "code": "33006-8",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus DNA [#/volume] (viral load) in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "22415-4",
            "system": "http://loinc.org",
            "display": "Mumps virus IgG Ab [Presence] in Serum"
          },
          {
            "code": "6476-6",
            "system": "http://loinc.org",
            "display": "Mumps virus IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5274-6",
            "system": "http://loinc.org",
            "display": "Parvovirus B19 IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "5273-8",
            "system": "http://loinc.org",
            "display": "Parvovirus B19 IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "40750-2",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus capsid IgG Ab [Presence] in Serum by Immunofluorescence"
          },
          {
            "code": "16126-5",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori IgG Ab [Presence] in Serum"
          },
          {
            "code": "9586-9",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi Ab [interpretation] in Serum"
          },
          {
            "code": "78012-2",
            "system": "http://loinc.org",
            "display": "Streptococcus pyogenes Ag [Presence] in Throat by Rapid immunoassay"
          },
          {
            "code": "5158-1",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus capsid IgG Ab [Titer] in Serum by Immunofluorescence"
          },
          {
            "code": "19108-0",
            "system": "http://loinc.org",
            "display": "Histoplasma capsulatum Ag [Presence] in Serum"
          },
          {
            "code": "44525-4",
            "system": "http://loinc.org",
            "display": "Histoplasma capsulatum Ag [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5095-5",
            "system": "http://loinc.org",
            "display": "Coccidioides immitis Ab [Presence] in Serum by Immune diffusion (ID)"
          },
          {
            "code": "22310-7",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori Ab [Presence] in Serum"
          },
          {
            "code": "17851-7",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 2 IgG Ab [Presence] in Serum"
          },
          {
            "code": "43180-9",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 2 IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5218-3",
            "system": "http://loinc.org",
            "display": "Histoplasma capsulatum Ab [Presence] in Serum by Immune diffusion (ID)"
          },
          {
            "code": "17850-9",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 1 IgG Ab [Presence] in Serum"
          },
          {
            "code": "51916-5",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 1 IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5160-7",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus capsid IgM Ab [Titer] in Serum by Immunofluorescence"
          },
          {
            "code": "20423-0",
            "system": "http://loinc.org",
            "display": "Beta lactamase organism identified in Isolate"
          },
          {
            "code": "34712-0",
            "system": "http://loinc.org",
            "display": "Clostridium difficile [Presence] in Stool"
          },
          {
            "code": "5390-0",
            "system": "http://loinc.org",
            "display": "Toxoplasma gondii IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "20479-2",
            "system": "http://loinc.org",
            "display": "Measles virus IgG Ab [Presence] in Serum"
          },
          {
            "code": "35275-7",
            "system": "http://loinc.org",
            "display": "Measles virus IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "41222-1",
            "system": "http://loinc.org",
            "display": "Yeast [Presence] in Body fluid by Light microscopy"
          },
          {
            "code": "593-4",
            "system": "http://loinc.org",
            "display": "Legionella sp identified in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "48683-7",
            "system": "http://loinc.org",
            "display": "Streptococcus agalactiae DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "20574-0",
            "system": "http://loinc.org",
            "display": "Histoplasma capsulatum yeast phase Ab [Titer] in Serum by Complement fixation"
          },
          {
            "code": "49539-0",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus IgM Ab [Presence] in Serum by Immunofluorescence"
          },
          {
            "code": "5127-6",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus IgM Ab [Titer] in Serum by Immunofluorescence"
          },
          {
            "code": "41499-5",
            "system": "http://loinc.org",
            "display": "Legionella pneumophila 1 Ag [Presence] in Urine by Immunoassay"
          },
          {
            "code": "5053-4",
            "system": "http://loinc.org",
            "display": "Aspergillus sp Ab [Titer] in Serum by Complement fixation"
          },
          {
            "code": "5880-0",
            "system": "http://loinc.org",
            "display": "Rotavirus Ag [Presence] in Stool by Immunoassay"
          },
          {
            "code": "9783-2",
            "system": "http://loinc.org",
            "display": "Ehrlichia chaffeensis IgG Ab [Titer] in Serum"
          },
          {
            "code": "46082-4",
            "system": "http://loinc.org",
            "display": "Influenza virus A Ag [Presence] in Nasopharynx by Immunoassay"
          },
          {
            "code": "46083-2",
            "system": "http://loinc.org",
            "display": "Influenza virus B Ag [Presence] in Nasopharynx by Immunoassay"
          },
          {
            "code": "20458-6",
            "system": "http://loinc.org",
            "display": "Rubella virus IgG Ab [interpretation] in Serum"
          },
          {
            "code": "23877-4",
            "system": "http://loinc.org",
            "display": "Anaplasma phagocytophilum IgG Ab [Titer] in Serum by Immunofluorescence"
          },
          {
            "code": "9784-0",
            "system": "http://loinc.org",
            "display": "Ehrlichia chaffeensis IgM Ab [Titer] in Serum"
          },
          {
            "code": "23878-2",
            "system": "http://loinc.org",
            "display": "Anaplasma phagocytophilum IgM Ab [Titer] in Serum by Immunofluorescence"
          },
          {
            "code": "9490-4",
            "system": "http://loinc.org",
            "display": "Aspergillus flavus Ab [Presence] in Serum"
          },
          {
            "code": "85991-8",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 14 IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "85992-6",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 14 IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "5057-5",
            "system": "http://loinc.org",
            "display": "Blastomyces dermatitidis Ab [Titer] in Serum by Complement fixation"
          },
          {
            "code": "7984-8",
            "system": "http://loinc.org",
            "display": "Parvovirus B19 IgM Ab [Units/volume] in Serum"
          },
          {
            "code": "30340-4",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus capsid IgM Ab [Presence] in Serum"
          },
          {
            "code": "24115-8",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus capsid IgM Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "21441-1",
            "system": "http://loinc.org",
            "display": "Human papilloma virus 6+11+42+43+44 DNA [Presence] in Cervix by DNA probe"
          },
          {
            "code": "5863-6",
            "system": "http://loinc.org",
            "display": "Influenza virus A Ag [Presence] in Unspecified specimen by Immunofluorescence"
          },
          {
            "code": "31797-4",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus Ag [Presence] in Unspecified specimen"
          },
          {
            "code": "6379-2",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus Ag [Presence] in Unspecified specimen by Immunoassay"
          },
          {
            "code": "30339-6",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus capsid IgG Ab [Presence] in Serum"
          },
          {
            "code": "24114-1",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus capsid IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "27395-3",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 18C IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "40913-6",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 18C IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "86024-7",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 19F IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "86021-3",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 19F IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "86064-3",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 23F IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "86061-9",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 23F IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "86107-0",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 4 IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "86108-8",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 4 IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "30153-1",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 9V IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "40926-8",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 9V IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "588-4",
            "system": "http://loinc.org",
            "display": "Legionella pneumophila Ag [Presence] in Unspecified specimen by Immunofluorescence"
          },
          {
            "code": "22086-3",
            "system": "http://loinc.org",
            "display": "Aspergillus niger Ab [Presence] in Serum"
          },
          {
            "code": "27118-9",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 6B IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "40905-2",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 6B IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "86080-9",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 3 IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "86081-7",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 3 IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "25296-5",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 7F IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "40911-0",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 7F IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "86147-6",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 8 IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "86148-4",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 8 IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "86169-0",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 9N IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "86166-6",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 9N IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "85954-6",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 1 IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "85955-3",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 1 IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "41763-4",
            "system": "http://loinc.org",
            "display": "Rubella virus IgG Ab [Titer] in Serum"
          },
          {
            "code": "85977-7",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 12F IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "85974-4",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 12F IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "48560-7",
            "system": "http://loinc.org",
            "display": "Human papilloma virus genotype [Identifier] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "16130-7",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 1 DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "9820-2",
            "system": "http://loinc.org",
            "display": "Cryptococcus sp Ag [Titer] in Serum by Latex agglutination"
          },
          {
            "code": "22296-8",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus nuclear Ab [Presence] in Serum"
          },
          {
            "code": "20449-5",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "7983-0",
            "system": "http://loinc.org",
            "display": "Parvovirus B19 IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "25631-3",
            "system": "http://loinc.org",
            "display": "Parvovirus B19 IgM Ab [Titer] in Serum"
          },
          {
            "code": "32585-2",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus DNA [#/volume] (viral load) in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "15410-4",
            "system": "http://loinc.org",
            "display": "Varicella zoster virus IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5036-9",
            "system": "http://loinc.org",
            "display": "Streptococcus pyogenes rRNA [Presence] in Unspecified specimen by DNA probe"
          },
          {
            "code": "40974-8",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 19A IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "40915-1",
            "system": "http://loinc.org",
            "display": "Streptococcus pneumoniae Danish serotype 19A IgG Ab [Mass/volume] in Serum by Immunoassay"
          },
          {
            "code": "41479-7",
            "system": "http://loinc.org",
            "display": "BK virus DNA [#/volume] (viral load) in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "21260-5",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus nuclear Ab [Titer] in Serum by Immunofluorescence"
          },
          {
            "code": "35270-8",
            "system": "http://loinc.org",
            "display": "Candida sp Ab [Presence] in Serum by Immune diffusion (ID)"
          },
          {
            "code": "30247-1",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus DNA [#/volume] (viral load) in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "44528-8",
            "system": "http://loinc.org",
            "display": "Histoplasma capsulatum M Ab [Presence] in Serum"
          },
          {
            "code": "35732-7",
            "system": "http://loinc.org",
            "display": "Histoplasma capsulatum H Ab [Presence] in Serum by Immune diffusion (ID)"
          },
          {
            "code": "14207-5",
            "system": "http://loinc.org",
            "display": "Streptococcal DNAse B [Titer] in Serum"
          },
          {
            "code": "44547-8",
            "system": "http://loinc.org",
            "display": "Human papilloma virus DNA [Presence] in Unspecified specimen by Probe & signal amplification method"
          },
          {
            "code": "7902-0",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "7816-2",
            "system": "http://loinc.org",
            "display": "Blastomyces dermatitidis Ab [Presence] in Serum"
          },
          {
            "code": "22297-6",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus nuclear Ab [Titer] in Serum"
          },
          {
            "code": "41476-3",
            "system": "http://loinc.org",
            "display": "Rickettsia rickettsii IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "23301-5",
            "system": "http://loinc.org",
            "display": "Mycoplasma sp DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "5256-3",
            "system": "http://loinc.org",
            "display": "Mycoplasma pneumoniae IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "16117-4",
            "system": "http://loinc.org",
            "display": "Babesia microti IgG Ab [Titer] in Serum"
          },
          {
            "code": "41475-5",
            "system": "http://loinc.org",
            "display": "Rickettsia rickettsii IgM Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "24119-0",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus IgM Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5255-5",
            "system": "http://loinc.org",
            "display": "Mycoplasma pneumoniae IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "13947-7",
            "system": "http://loinc.org",
            "display": "Coccidioides immitis IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "13948-5",
            "system": "http://loinc.org",
            "display": "Coccidioides immitis IgM Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "16118-2",
            "system": "http://loinc.org",
            "display": "Babesia microti IgM Ab [Titer] in Serum"
          },
          {
            "code": "14083-0",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus early Ab [Titer] in Serum by Immunofluorescence"
          },
          {
            "code": "41279-1",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi IgG Ab/IgM Ab [Ratio] in Serum"
          },
          {
            "code": "7883-2",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus nuclear IgG Ab [Presence] in Serum"
          },
          {
            "code": "8047-3",
            "system": "http://loinc.org",
            "display": "Varicella zoster virus IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "5834-7",
            "system": "http://loinc.org",
            "display": "Adenovirus Ag [Presence] in Unspecified specimen by Immunofluorescence"
          },
          {
            "code": "22203-4",
            "system": "http://loinc.org",
            "display": "Clostridium tetani IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "5202-7",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "29901-6",
            "system": "http://loinc.org",
            "display": "HTLV 1+2 Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "22110-1",
            "system": "http://loinc.org",
            "display": "Bartonella henselae IgG Ab [Titer] in Serum"
          },
          {
            "code": "6448-5",
            "system": "http://loinc.org",
            "display": "Legionella pneumophila Ag [Presence] in Urine by Radioimmunoassay (RIA)"
          },
          {
            "code": "44538-7",
            "system": "http://loinc.org",
            "display": "HTLV 1+2 Ab [Presence] in Serum from donor"
          },
          {
            "code": "5877-6",
            "system": "http://loinc.org",
            "display": "Respiratory syncytial virus Ag [Presence] in Unspecified specimen by Immunofluorescence"
          },
          {
            "code": "9632-1",
            "system": "http://loinc.org",
            "display": "Aspergillus fumigatus Ab [Presence] in Serum"
          },
          {
            "code": "5000-5",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "13327-2",
            "system": "http://loinc.org",
            "display": "Parainfluenza virus Ag [Presence] in Unspecified specimen by Immunofluorescence"
          },
          {
            "code": "6367-7",
            "system": "http://loinc.org",
            "display": "Clostridium tetani IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "41480-5",
            "system": "http://loinc.org",
            "display": "BK virus DNA [#/volume] (viral load) in Urine by Probe & target amplification method"
          },
          {
            "code": "31788-3",
            "system": "http://loinc.org",
            "display": "Cryptococcus sp Ag [Presence] in Cerebral spinal fluid"
          },
          {
            "code": "31843-6",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori Ag [Presence] in Stool"
          },
          {
            "code": "13227-4",
            "system": "http://loinc.org",
            "display": "Corynebacterium diphtheriae IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "58787-3",
            "system": "http://loinc.org",
            "display": "Corynebacterium diphtheriae IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "32284-2",
            "system": "http://loinc.org",
            "display": "BK virus DNA [Units/volume] (viral load) in Serum or Plasma by Probe & target amplification method"
          },
          {
            "code": "25630-5",
            "system": "http://loinc.org",
            "display": "Parvovirus B19 IgG Ab [Titer] in Serum"
          },
          {
            "code": "20446-1",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus IgG Ab [interpretation] in Serum by Immunoassay"
          },
          {
            "code": "25435-9",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus IgM Ab [Presence] in Serum"
          },
          {
            "code": "5096-3",
            "system": "http://loinc.org",
            "display": "Coccidioides immitis Ab [Titer] in Serum by Complement fixation"
          },
          {
            "code": "5052-6",
            "system": "http://loinc.org",
            "display": "Aspergillus sp Ab [Presence] in Serum by Immune diffusion (ID)"
          },
          {
            "code": "29675-6",
            "system": "http://loinc.org",
            "display": "Parvovirus B19 IgG Ab [Presence] in Serum"
          },
          {
            "code": "29660-8",
            "system": "http://loinc.org",
            "display": "Parvovirus B19 IgG Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "7981-4",
            "system": "http://loinc.org",
            "display": "Parvovirus B19 IgM Ab [Presence] in Serum"
          },
          {
            "code": "40658-7",
            "system": "http://loinc.org",
            "display": "Parvovirus B19 IgM Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "22111-9",
            "system": "http://loinc.org",
            "display": "Bartonella henselae IgM Ab [Titer] in Serum"
          },
          {
            "code": "22362-8",
            "system": "http://loinc.org",
            "display": "HTLV 1+2 Ab [Presence] in Serum"
          },
          {
            "code": "42621-3",
            "system": "http://loinc.org",
            "display": "Mycoplasma hominis DNA [Presence] in Blood by Probe & target amplification method"
          },
          {
            "code": "41487-0",
            "system": "http://loinc.org",
            "display": "Cryptosporidium parvum Ag [Presence] in Stool by Immunoassay"
          },
          {
            "code": "25418-5",
            "system": "http://loinc.org",
            "display": "Mumps virus IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "7901-2",
            "system": "http://loinc.org",
            "display": "Helicobacter pylori IgA Ab [Units/volume] in Serum"
          },
          {
            "code": "550-4",
            "system": "http://loinc.org",
            "display": "Bordetella pertussis Ag [Presence] in Unspecified specimen by Immunofluorescence"
          },
          {
            "code": "5005-4",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "8015-0",
            "system": "http://loinc.org",
            "display": "Rubella virus IgM Ab [Units/volume] in Serum"
          },
          {
            "code": "5116-9",
            "system": "http://loinc.org",
            "display": "Corynebacterium diphtheriae Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "22568-0",
            "system": "http://loinc.org",
            "display": "Streptolysin O Ab [Titer] in Serum"
          },
          {
            "code": "9360-9",
            "system": "http://loinc.org",
            "display": "Bartonella quintana IgG Ab [Titer] in Serum"
          },
          {
            "code": "4991-6",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "9361-7",
            "system": "http://loinc.org",
            "display": "Bartonella quintana IgM Ab [Titer] in Serum"
          },
          {
            "code": "20781-1",
            "system": "http://loinc.org",
            "display": "Cryptosporidium sp [Presence] in Stool by Acid fast stain"
          },
          {
            "code": "22412-1",
            "system": "http://loinc.org",
            "display": "Saccharopolyspora rectivirgula Ab [Presence] in Serum"
          },
          {
            "code": "5869-3",
            "system": "http://loinc.org",
            "display": "Parainfluenza virus 1 Ag [Presence] in Unspecified specimen by Immunofluorescence"
          },
          {
            "code": "50758-2",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 1 IgM Ab [Titer] in Serum by Immunofluorescence"
          },
          {
            "code": "26927-4",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus 2 IgM Ab [Titer] in Serum by Immunofluorescence"
          },
          {
            "code": "29591-5",
            "system": "http://loinc.org",
            "display": "Enterovirus RNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "16982-1",
            "system": "http://loinc.org",
            "display": "HTLV 1+2 Ab [Presence] in Serum by Immunoblot (IB)"
          },
          {
            "code": "29559-2",
            "system": "http://loinc.org",
            "display": "Haemophilus ducreyi DNA [Presence] in Unspecified specimen by Probe & target amplification method"
          },
          {
            "code": "5335-5",
            "system": "http://loinc.org",
            "display": "Rubella virus IgM Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "7817-0",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "5062-5",
            "system": "http://loinc.org",
            "display": "Borrelia burgdorferi IgG Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "24015-0",
            "system": "http://loinc.org",
            "display": "Influenza virus A+B Ag [Presence] in Unspecified specimen"
          },
          {
            "code": "6437-8",
            "system": "http://loinc.org",
            "display": "Influenza virus A+B Ag [Presence] in Unspecified specimen by Immunoassay"
          },
          {
            "code": "40729-6",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus IgM Ab [Presence] in Serum by Immunoassay"
          },
          {
            "code": "5156-5",
            "system": "http://loinc.org",
            "display": "Epstein Barr virus nuclear IgG Ab [Presence] in Serum by Immunoassay"
          }
        ]
      },
      {
        "code": "Serum Electrophoresis/Immunofix",
        "type": "emr-defined",
        "target": [
          {
            "code": "1759-0",
            "system": "http://loinc.org",
            "display": "Albumin/Globulin [Mass ratio] in Serum or Plasma"
          },
          {
            "code": "10834-0",
            "system": "http://loinc.org",
            "display": "Globulin [Mass/volume] in Serum by calculation"
          },
          {
            "code": "2336-6",
            "system": "http://loinc.org",
            "display": "Globulin [Mass/volume] in Serum"
          },
          {
            "code": "12851-2",
            "system": "http://loinc.org",
            "display": "Protein Fractions [interpretation] in Serum or Plasma by Electrophoresis"
          },
          {
            "code": "2862-1",
            "system": "http://loinc.org",
            "display": "Albumin [Mass/volume] in Serum or Plasma by Electrophoresis"
          },
          {
            "code": "2871-2",
            "system": "http://loinc.org",
            "display": "Beta globulin [Mass/volume] in Serum or Plasma by Electrophoresis"
          },
          {
            "code": "2865-4",
            "system": "http://loinc.org",
            "display": "Alpha 1 globulin [Mass/volume] in Serum or Plasma by Electrophoresis"
          },
          {
            "code": "2868-8",
            "system": "http://loinc.org",
            "display": "Alpha 2 globulin [Mass/volume] in Serum or Plasma by Electrophoresis"
          },
          {
            "code": "2874-6",
            "system": "http://loinc.org",
            "display": "Gamma globulin [Mass/volume] in Serum or Plasma by Electrophoresis"
          },
          {
            "code": "14895-7",
            "system": "http://loinc.org",
            "display": "Protein Fractions [interpretation] in Serum or Plasma by Immunofixation"
          },
          {
            "code": "33358-3",
            "system": "http://loinc.org",
            "display": "Protein.monoclonal [Mass/volume] in Serum or Plasma by Electrophoresis"
          },
          {
            "code": "36916-5",
            "system": "http://loinc.org",
            "display": "Immunoglobulin light chains.kappa.free [Mass/volume] in Serum"
          },
          {
            "code": "15189-4",
            "system": "http://loinc.org",
            "display": "Immunoglobulin light chains.kappa/Immunoglobulin light chains.lambda [Mass ratio] in Serum"
          },
          {
            "code": "13440-3",
            "system": "http://loinc.org",
            "display": "Immunofixation [interpretation] for Urine"
          },
          {
            "code": "11050-2",
            "system": "http://loinc.org",
            "display": "Immunoglobulin light chains.kappa [Mass/volume] in Serum"
          },
          {
            "code": "13169-8",
            "system": "http://loinc.org",
            "display": "Immunoelectrophoresis [interpretation] for Serum or Plasma"
          },
          {
            "code": "48378-4",
            "system": "http://loinc.org",
            "display": "Immunoglobulin light chains.kappa.free/Immunoglobulin light chains.lambda.free [Mass Ratio] in Serum"
          },
          {
            "code": "25700-6",
            "system": "http://loinc.org",
            "display": "Immunofixation [interpretation] for Serum or Plasma"
          },
          {
            "code": "11051-0",
            "system": "http://loinc.org",
            "display": "Immunoglobulin light chains.lambda [Mass/volume] in Serum"
          },
          {
            "code": "17793-1",
            "system": "http://loinc.org",
            "display": "Immunoglobulin light chains [Mass/volume] in 24 hour Urine"
          },
          {
            "code": "33647-9",
            "system": "http://loinc.org",
            "display": "Protein.monoclonal/Protein.total in Serum or Plasma by Electrophoresis"
          }
        ]
      },
      {
        "code": "Immunology",
        "type": "emr-defined",
        "target": [
          {
            "code": "2458-8",
            "system": "http://loinc.org",
            "display": "IgA [Mass/volume] in Serum"
          },
          {
            "code": "2465-3",
            "system": "http://loinc.org",
            "display": "IgG [Mass/volume] in Serum"
          },
          {
            "code": "2472-9",
            "system": "http://loinc.org",
            "display": "IgM [Mass/volume] in Serum"
          },
          {
            "code": "4485-9",
            "system": "http://loinc.org",
            "display": "Complement C3 [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "4498-2",
            "system": "http://loinc.org",
            "display": "Complement C4 [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "19113-0",
            "system": "http://loinc.org",
            "display": "IgE [Units/volume] in Serum"
          },
          {
            "code": "33944-0",
            "system": "http://loinc.org",
            "display": "Immunoglobulin light chains.lambda.free [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "4532-8",
            "system": "http://loinc.org",
            "display": "Complement total hemolytic CH50 [Units/volume] in Serum or Plasma"
          },
          {
            "code": "2466-1",
            "system": "http://loinc.org",
            "display": "IgG subclass 1 [Mass/volume] in Serum"
          },
          {
            "code": "2469-5",
            "system": "http://loinc.org",
            "display": "IgG subclass 4 [Mass/volume] in Serum"
          },
          {
            "code": "2467-9",
            "system": "http://loinc.org",
            "display": "IgG subclass 2 [Mass/volume] in Serum"
          },
          {
            "code": "2468-7",
            "system": "http://loinc.org",
            "display": "IgG subclass 3 [Mass/volume] in Serum"
          },
          {
            "code": "5117-7",
            "system": "http://loinc.org",
            "display": "Cryoglobulin [Presence] in Serum"
          },
          {
            "code": "15174-6",
            "system": "http://loinc.org",
            "display": "Cryocrit of Serum by Spun Westergren"
          },
          {
            "code": "4477-6",
            "system": "http://loinc.org",
            "display": "Complement C1 esterase inhibitor [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "14116-8",
            "system": "http://loinc.org",
            "display": "IgG synthesis rate [Mass/time] in Serum & CSF by calculation"
          },
          {
            "code": "14117-6",
            "system": "http://loinc.org",
            "display": "IgG index in Serum & CSF"
          },
          {
            "code": "13088-0",
            "system": "http://loinc.org",
            "display": "Complement total hemolytic CH100 [Units/volume] in Serum or Plasma"
          },
          {
            "code": "12201-0",
            "system": "http://loinc.org",
            "display": "Cryoglobulin [Presence] in Serum by 1 day cold incubation"
          }
        ]
      },
      {
        "code": "Cell markers",
        "type": "emr-defined",
        "target": [
          {
            "code": "54218-3",
            "system": "http://loinc.org",
            "display": "CD3+CD4+ (T4 helper) cells/CD3+CD8+ (T8 suppressor cells) cells [# Ratio] in Blood"
          },
          {
            "code": "8123-2",
            "system": "http://loinc.org",
            "display": "CD3+CD4+ (T4 helper) cells/100 cells in Blood"
          },
          {
            "code": "8124-0",
            "system": "http://loinc.org",
            "display": "CD3 cells/100 cells in Blood"
          },
          {
            "code": "8101-8",
            "system": "http://loinc.org",
            "display": "CD3+CD8+ (T8 suppressor cells) cells/100 cells in Blood"
          },
          {
            "code": "8122-4",
            "system": "http://loinc.org",
            "display": "CD3 cells [#/volume] in Blood"
          },
          {
            "code": "14135-8",
            "system": "http://loinc.org",
            "display": "CD3+CD8+ (T8 suppressor cells) cells [#/volume] in Blood"
          },
          {
            "code": "24467-3",
            "system": "http://loinc.org",
            "display": "CD3+CD4+ (T4 helper) cells [#/volume] in Blood"
          },
          {
            "code": "32515-9",
            "system": "http://loinc.org",
            "display": "CD3+CD4+ (T4 helper) cells [#/volume] in Unspecified specimen"
          },
          {
            "code": "8117-4",
            "system": "http://loinc.org",
            "display": "CD19 cells/100 cells in Blood"
          },
          {
            "code": "8112-5",
            "system": "http://loinc.org",
            "display": "CD3-CD16+CD56+ (Natural killer) cells/100 cells in Blood"
          },
          {
            "code": "8130-7",
            "system": "http://loinc.org",
            "display": "CD45 (Lymphs) cells/100 cells in Blood"
          },
          {
            "code": "8116-6",
            "system": "http://loinc.org",
            "display": "CD19 cells [#/volume] in Blood"
          },
          {
            "code": "20593-0",
            "system": "http://loinc.org",
            "display": "CD19 cells/100 cells in Unspecified specimen"
          },
          {
            "code": "18267-5",
            "system": "http://loinc.org",
            "display": "CD16+CD56+ cells/100 cells in Blood"
          },
          {
            "code": "20402-4",
            "system": "http://loinc.org",
            "display": "CD16+CD56+ cells [#/volume] in Blood"
          },
          {
            "code": "8118-2",
            "system": "http://loinc.org",
            "display": "CD2 cells/100 cells in Blood"
          },
          {
            "code": "9557-0",
            "system": "http://loinc.org",
            "display": "CD2 cells [#/volume] in Blood"
          },
          {
            "code": "17122-3",
            "system": "http://loinc.org",
            "display": "CD19+Kappa+ cells/100 cells in Blood"
          },
          {
            "code": "17123-1",
            "system": "http://loinc.org",
            "display": "CD19+Lambda+ cells/100 cells in Blood"
          },
          {
            "code": "13337-1",
            "system": "http://loinc.org",
            "display": "CD8+HLA-DR+ cells/100 cells in Blood"
          },
          {
            "code": "49835-2",
            "system": "http://loinc.org",
            "display": "CD19+IgD+ cells/100 cells in Unspecified specimen"
          },
          {
            "code": "27071-0",
            "system": "http://loinc.org",
            "display": "CD45 cells [#/volume] in Blood"
          }
        ]
      },
      {
        "code": "Tumor Markers",
        "type": "emr-defined",
        "target": [
          {
            "code": "2857-1",
            "system": "http://loinc.org",
            "display": "Prostate specific Ag [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2039-6",
            "system": "http://loinc.org",
            "display": "Carcinoembryonic Ag [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "10334-1",
            "system": "http://loinc.org",
            "display": "Cancer Ag 125 [Units/volume] in Serum or Plasma"
          },
          {
            "code": "12841-3",
            "system": "http://loinc.org",
            "display": "Prostate Specific Ag Free/Prostate specific Ag.total in Serum or Plasma"
          },
          {
            "code": "10886-0",
            "system": "http://loinc.org",
            "display": "Prostate Specific Ag Free [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "17842-6",
            "system": "http://loinc.org",
            "display": "Cancer Ag 27-29 [Units/volume] in Serum or Plasma"
          },
          {
            "code": "24108-3",
            "system": "http://loinc.org",
            "display": "Cancer Ag 19-9 [Units/volume] in Serum or Plasma"
          },
          {
            "code": "6875-9",
            "system": "http://loinc.org",
            "display": "Cancer Ag 15-3 [Units/volume] in Serum or Plasma"
          },
          {
            "code": "53962-7",
            "system": "http://loinc.org",
            "display": "Alpha-1-fetoprotein.tumor marker [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "2006-5",
            "system": "http://loinc.org",
            "display": "Cancer Ag 125 [Presence] in Serum or Plasma"
          },
          {
            "code": "35741-8",
            "system": "http://loinc.org",
            "display": "Prostate specific Ag [Mass/volume] in Serum or Plasma by Detection limit = 0.01 ng/mL"
          },
          {
            "code": "9811-1",
            "system": "http://loinc.org",
            "display": "Chromogranin A [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "19201-3",
            "system": "http://loinc.org",
            "display": "Prostate Specific Ag Free [Units/volume] in Serum or Plasma"
          },
          {
            "code": "20420-6",
            "system": "http://loinc.org",
            "display": "Prostatic acid phosphatase [Mass/volume] in Serum"
          }
        ]
      },
      {
        "code": "Therapeutic Drug Monitoring",
        "type": "emr-defined",
        "target": [
          {
            "code": "11253-2",
            "system": "http://loinc.org",
            "display": "Tacrolimus [Mass/volume] in Blood"
          },
          {
            "code": "3968-5",
            "system": "http://loinc.org",
            "display": "Phenytoin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "10535-3",
            "system": "http://loinc.org",
            "display": "Digoxin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "4092-3",
            "system": "http://loinc.org",
            "display": "Vancomycin [Mass/volume] in Serum or Plasma --trough"
          },
          {
            "code": "4086-5",
            "system": "http://loinc.org",
            "display": "Valproate [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3520-4",
            "system": "http://loinc.org",
            "display": "Cyclosporine [Mass/volume] in Blood"
          },
          {
            "code": "29247-4",
            "system": "http://loinc.org",
            "display": "Sirolimus [Mass/volume] in Blood"
          },
          {
            "code": "14334-7",
            "system": "http://loinc.org",
            "display": "Lithium [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "3432-2",
            "system": "http://loinc.org",
            "display": "Carbamazepine [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3948-7",
            "system": "http://loinc.org",
            "display": "Phenobarbital [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3665-7",
            "system": "http://loinc.org",
            "display": "Gentamicin [Mass/volume] in Serum or Plasma --trough"
          },
          {
            "code": "14836-1",
            "system": "http://loinc.org",
            "display": "Methotrexate [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "4090-7",
            "system": "http://loinc.org",
            "display": "Vancomycin [Mass/volume] in Serum or Plasma --peak"
          },
          {
            "code": "6948-4",
            "system": "http://loinc.org",
            "display": "Lamotrigine [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3663-2",
            "system": "http://loinc.org",
            "display": "Gentamicin [Mass/volume] in Serum or Plasma --peak"
          },
          {
            "code": "30471-7",
            "system": "http://loinc.org",
            "display": "Levetiracetam [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3719-2",
            "system": "http://loinc.org",
            "display": "Lithium [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "4049-3",
            "system": "http://loinc.org",
            "display": "Theophylline [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "35668-3",
            "system": "http://loinc.org",
            "display": "Gentamicin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "4059-2",
            "system": "http://loinc.org",
            "display": "Tobramycin [Mass/volume] in Serum or Plasma --trough"
          },
          {
            "code": "4057-6",
            "system": "http://loinc.org",
            "display": "Tobramycin [Mass/volume] in Serum or Plasma --peak"
          },
          {
            "code": "3969-3",
            "system": "http://loinc.org",
            "display": "Phenytoin Free [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "23905-3",
            "system": "http://loinc.org",
            "display": "Mycophenolate [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "17713-9",
            "system": "http://loinc.org",
            "display": "Topiramate [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "35670-9",
            "system": "http://loinc.org",
            "display": "Tobramycin [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3714-3",
            "system": "http://loinc.org",
            "display": "Lidocaine [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "20578-1",
            "system": "http://loinc.org",
            "display": "Vancomycin [Mass/volume] in Serum or Plasma"
          }
        ]
      },
      {
        "code": "Drug/Tox",
        "type": "emr-defined",
        "target": [
          {
            "code": "5671-3",
            "system": "http://loinc.org",
            "display": "Lead [Mass/volume] in Blood"
          },
          {
            "code": "5643-2",
            "system": "http://loinc.org",
            "display": "Ethanol [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3298-7",
            "system": "http://loinc.org",
            "display": "Acetaminophen [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "4073-3",
            "system": "http://loinc.org",
            "display": "Tricyclic antidepressants [Presence] in Serum or Plasma"
          },
          {
            "code": "4024-6",
            "system": "http://loinc.org",
            "display": "Salicylates [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3376-1",
            "system": "http://loinc.org",
            "display": "Barbiturates [Presence] in Serum, Plasma or Blood"
          },
          {
            "code": "3389-4",
            "system": "http://loinc.org",
            "display": "Benzodiazepines [Presence] in Serum or Plasma"
          },
          {
            "code": "5640-8",
            "system": "http://loinc.org",
            "display": "Ethanol [Mass/volume] in Blood"
          },
          {
            "code": "5763-8",
            "system": "http://loinc.org",
            "display": "Zinc [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "5639-0",
            "system": "http://loinc.org",
            "display": "Ethanol [Presence] in Blood"
          },
          {
            "code": "3297-9",
            "system": "http://loinc.org",
            "display": "Acetaminophen [Presence] in Serum or Plasma"
          },
          {
            "code": "4023-8",
            "system": "http://loinc.org",
            "display": "Salicylates [Presence] in Serum or Plasma"
          },
          {
            "code": "35597-4",
            "system": "http://loinc.org",
            "display": "Salicylates [Mass/volume] in Serum or Plasma by Screen method"
          },
          {
            "code": "8191-9",
            "system": "http://loinc.org",
            "display": "Cocaine [Presence] in Serum or Plasma by Screen method"
          },
          {
            "code": "8149-7",
            "system": "http://loinc.org",
            "display": "Amphetamines [Presence] in Serum or Plasma by Screen method"
          },
          {
            "code": "8234-7",
            "system": "http://loinc.org",
            "display": "Phencyclidine [Presence] in Meconium by Screen method"
          },
          {
            "code": "5568-1",
            "system": "http://loinc.org",
            "display": "Acetone [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "8187-7",
            "system": "http://loinc.org",
            "display": "Benzoylecgonine [Presence] in Meconium"
          },
          {
            "code": "8146-3",
            "system": "http://loinc.org",
            "display": "Amphetamines [Presence] in Meconium by Screen method"
          },
          {
            "code": "8169-5",
            "system": "http://loinc.org",
            "display": "Tetrahydrocannabinol [Presence] in Meconium by Screen method"
          },
          {
            "code": "8216-4",
            "system": "http://loinc.org",
            "display": "Opiates [Presence] in Meconium by Screen method"
          },
          {
            "code": "5631-7",
            "system": "http://loinc.org",
            "display": "Copper [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "10912-4",
            "system": "http://loinc.org",
            "display": "Lead [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "5685-3",
            "system": "http://loinc.org",
            "display": "Mercury [Mass/volume] in Blood"
          },
          {
            "code": "5693-7",
            "system": "http://loinc.org",
            "display": "Methanol [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "8214-9",
            "system": "http://loinc.org",
            "display": "Opiates [Presence] in Meconium"
          },
          {
            "code": "31080-5",
            "system": "http://loinc.org",
            "display": "Cannabinoids [Presence] in Meconium by Screen method"
          },
          {
            "code": "40527-4",
            "system": "http://loinc.org",
            "display": "Cocaine [Presence] in Meconium"
          },
          {
            "code": "8144-8",
            "system": "http://loinc.org",
            "display": "Amphetamines [Presence] in Meconium"
          },
          {
            "code": "31019-3",
            "system": "http://loinc.org",
            "display": "10-Hydroxycarbazepine [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "3422-3",
            "system": "http://loinc.org",
            "display": "Caffeine [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "5669-7",
            "system": "http://loinc.org",
            "display": "Isopropanol [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "5646-5",
            "system": "http://loinc.org",
            "display": "Ethylene glycol [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "5724-0",
            "system": "http://loinc.org",
            "display": "Selenium [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "35331-8",
            "system": "http://loinc.org",
            "display": "Oxcarbazepine [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "35603-0",
            "system": "http://loinc.org",
            "display": "Clonazepam [Mass/volume] in Serum or Plasma by Screen method"
          },
          {
            "code": "5583-0",
            "system": "http://loinc.org",
            "display": "Arsenic [Mass/volume] in Blood"
          },
          {
            "code": "35622-0",
            "system": "http://loinc.org",
            "display": "Nordiazepam [Mass/volume] in Serum or Plasma by Screen method"
          },
          {
            "code": "20469-3",
            "system": "http://loinc.org",
            "display": "Acetone [Presence] in Serum or Plasma by Screen method"
          },
          {
            "code": "49578-8",
            "system": "http://loinc.org",
            "display": "Aminocaproate cutoff [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "35595-8",
            "system": "http://loinc.org",
            "display": "Acetaminophen [Mass/volume] in Serum or Plasma by Screen method"
          }
        ]
      },
      {
        "code": "Urine Drug/Tox",
        "type": "emr-defined",
        "target": [
          {
            "code": "3879-4",
            "system": "http://loinc.org",
            "display": "Opiates [Presence] in Urine"
          },
          {
            "code": "3390-2",
            "system": "http://loinc.org",
            "display": "Benzodiazepines [Presence] in Urine"
          },
          {
            "code": "3377-9",
            "system": "http://loinc.org",
            "display": "Barbiturates [Presence] in Urine"
          },
          {
            "code": "3349-8",
            "system": "http://loinc.org",
            "display": "Amphetamines [Presence] in Urine"
          },
          {
            "code": "18282-4",
            "system": "http://loinc.org",
            "display": "Cannabinoids [Presence] in Urine by Screen method"
          },
          {
            "code": "19659-2",
            "system": "http://loinc.org",
            "display": "Phencyclidine [Presence] in Urine by Screen method"
          },
          {
            "code": "3393-6",
            "system": "http://loinc.org",
            "display": "Benzoylecgonine [Presence] in Urine"
          },
          {
            "code": "3397-7",
            "system": "http://loinc.org",
            "display": "Cocaine [Presence] in Urine"
          },
          {
            "code": "3936-2",
            "system": "http://loinc.org",
            "display": "Phencyclidine [Presence] in Urine"
          },
          {
            "code": "3426-4",
            "system": "http://loinc.org",
            "display": "Tetrahydrocannabinol [Presence] in Urine"
          },
          {
            "code": "3773-9",
            "system": "http://loinc.org",
            "display": "Methadone [Presence] in Urine"
          },
          {
            "code": "19312-8",
            "system": "http://loinc.org",
            "display": "Tricyclic antidepressants [Presence] in Urine by Screen method"
          },
          {
            "code": "5569-9",
            "system": "http://loinc.org",
            "display": "Acetone [Presence] in Urine"
          },
          {
            "code": "18390-5",
            "system": "http://loinc.org",
            "display": "Opiates [Presence] in Urine by Confirmatory method"
          },
          {
            "code": "11004-9",
            "system": "http://loinc.org",
            "display": "Tricyclic antidepressants [Presence] in Urine"
          },
          {
            "code": "19550-3",
            "system": "http://loinc.org",
            "display": "Methadone [Presence] in Urine by Screen method"
          },
          {
            "code": "3779-6",
            "system": "http://loinc.org",
            "display": "Methamphetamine [Presence] in Urine"
          },
          {
            "code": "19343-3",
            "system": "http://loinc.org",
            "display": "Amphetamine [Presence] in Urine by Screen method"
          },
          {
            "code": "19554-5",
            "system": "http://loinc.org",
            "display": "Methamphetamine [Presence] in Urine by Screen method"
          },
          {
            "code": "10366-3",
            "system": "http://loinc.org",
            "display": "Cotinine [Mass/volume] in Urine"
          },
          {
            "code": "19270-8",
            "system": "http://loinc.org",
            "display": "Barbiturates [Presence] in Urine by Screen method"
          },
          {
            "code": "14314-9",
            "system": "http://loinc.org",
            "display": "Benzoylecgonine [Presence] in Urine by Screen method"
          },
          {
            "code": "3299-5",
            "system": "http://loinc.org",
            "display": "Acetaminophen [Presence] in Urine"
          },
          {
            "code": "26760-9",
            "system": "http://loinc.org",
            "display": "Cannabinoids [Units/volume] in Urine"
          },
          {
            "code": "3854-7",
            "system": "http://loinc.org",
            "display": "Nicotine [Mass/volume] in Urine"
          },
          {
            "code": "3414-0",
            "system": "http://loinc.org",
            "display": "Buprenorphine [Presence] in Urine"
          },
          {
            "code": "10998-3",
            "system": "http://loinc.org",
            "display": "Oxycodone [Presence] in Urine"
          },
          {
            "code": "10976-9",
            "system": "http://loinc.org",
            "display": "6-Monoacetylmorphine (6-MAM) [Presence] in Urine"
          },
          {
            "code": "5645-7",
            "system": "http://loinc.org",
            "display": "Ethanol [Mass/volume] in Urine"
          },
          {
            "code": "19141-1",
            "system": "http://loinc.org",
            "display": "Propoxyphene [Presence] in Urine"
          },
          {
            "code": "19415-9",
            "system": "http://loinc.org",
            "display": "Tetrahydrocannabinol [Presence] in Urine by Screen method"
          },
          {
            "code": "19295-5",
            "system": "http://loinc.org",
            "display": "Opiates [Presence] in Urine by Screen method"
          },
          {
            "code": "12286-1",
            "system": "http://loinc.org",
            "display": "Drugs identified in Urine by Screen method"
          },
          {
            "code": "19296-3",
            "system": "http://loinc.org",
            "display": "Opiates tested for in Urine by Screen method Nominal"
          },
          {
            "code": "3746-5",
            "system": "http://loinc.org",
            "display": "Meperidine [Presence] in Urine"
          },
          {
            "code": "14316-4",
            "system": "http://loinc.org",
            "display": "Benzodiazepines [Presence] in Urine by Screen method"
          },
          {
            "code": "3507-1",
            "system": "http://loinc.org",
            "display": "Codeine [Presence] in Urine"
          },
          {
            "code": "3830-7",
            "system": "http://loinc.org",
            "display": "Morphine [Presence] in Urine"
          },
          {
            "code": "8150-5",
            "system": "http://loinc.org",
            "display": "Amphetamines [Mass/volume] in Urine"
          },
          {
            "code": "9426-8",
            "system": "http://loinc.org",
            "display": "Barbiturates [Mass/volume] in Urine"
          },
          {
            "code": "9428-4",
            "system": "http://loinc.org",
            "display": "Benzodiazepines [Mass/volume] in Urine"
          },
          {
            "code": "33915-0",
            "system": "http://loinc.org",
            "display": "Anabasine [Mass/volume] in Urine"
          },
          {
            "code": "16250-3",
            "system": "http://loinc.org",
            "display": "Codeine [Mass/volume] in Urine by Confirmatory method"
          },
          {
            "code": "19429-0",
            "system": "http://loinc.org",
            "display": "Propoxyphene [Presence] in Urine by Screen method"
          },
          {
            "code": "16251-1",
            "system": "http://loinc.org",
            "display": "Morphine [Mass/volume] in Urine by Confirmatory method"
          },
          {
            "code": "3545-1",
            "system": "http://loinc.org",
            "display": "Propoxyphene [Mass/volume] in Urine"
          },
          {
            "code": "19261-7",
            "system": "http://loinc.org",
            "display": "Amphetamines [Presence] in Urine by Screen method"
          },
          {
            "code": "11235-9",
            "system": "http://loinc.org",
            "display": "Fentanyl [Presence] in Urine"
          },
          {
            "code": "19710-3",
            "system": "http://loinc.org",
            "display": "Tramadol [Presence] in Urine by Screen method"
          },
          {
            "code": "12308-3",
            "system": "http://loinc.org",
            "display": "Hydrocodone [Presence] in Urine"
          },
          {
            "code": "9834-3",
            "system": "http://loinc.org",
            "display": "Hydromorphone [Presence] in Urine"
          },
          {
            "code": "16249-5",
            "system": "http://loinc.org",
            "display": "Oxycodone [Mass/volume] in Urine by Confirmatory method"
          },
          {
            "code": "19643-6",
            "system": "http://loinc.org",
            "display": "Oxycodone [Presence] in Urine by Confirmatory method"
          },
          {
            "code": "18325-1",
            "system": "http://loinc.org",
            "display": "Oxymorphone [Presence] in Urine by Confirmatory method"
          },
          {
            "code": "17395-5",
            "system": "http://loinc.org",
            "display": "Oxymorphone [Mass/volume] in Urine by Confirmatory method"
          },
          {
            "code": "19593-3",
            "system": "http://loinc.org",
            "display": "6-Monoacetylmorphine (6-MAM) [Mass/volume] in Urine by Confirmatory method"
          },
          {
            "code": "5644-0",
            "system": "http://loinc.org",
            "display": "Ethanol [Presence] in Urine"
          },
          {
            "code": "33917-6",
            "system": "http://loinc.org",
            "display": "Nornicotine [Mass/volume] in Urine"
          },
          {
            "code": "40464-0",
            "system": "http://loinc.org",
            "display": "Drugs identified in Urine by Confirmatory method"
          },
          {
            "code": "19287-2",
            "system": "http://loinc.org",
            "display": "Cannabinoids tested for in Urine by Screen method Nominal"
          },
          {
            "code": "16201-6",
            "system": "http://loinc.org",
            "display": "Oxazepam [Mass/volume] in Urine by Confirmatory method"
          },
          {
            "code": "8220-6",
            "system": "http://loinc.org",
            "display": "Opiates [Mass/volume] in Urine"
          },
          {
            "code": "16228-9",
            "system": "http://loinc.org",
            "display": "Nordiazepam [Mass/volume] in Urine by Confirmatory method"
          },
          {
            "code": "3786-1",
            "system": "http://loinc.org",
            "display": "Methaqualone [Presence] in Urine"
          },
          {
            "code": "3861-2",
            "system": "http://loinc.org",
            "display": "Nordiazepam [Presence] in Urine"
          },
          {
            "code": "12361-2",
            "system": "http://loinc.org",
            "display": "Oxazepam [Presence] in Urine"
          },
          {
            "code": "3436-3",
            "system": "http://loinc.org",
            "display": "Carboxy tetrahydrocannabinol [Mass/volume] in Urine"
          },
          {
            "code": "16195-0",
            "system": "http://loinc.org",
            "display": "Benzodiazepines [Presence] in Urine by Confirmatory method"
          }
        ]
      },
      {
        "code": "Pregnancy Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "2106-3",
            "system": "http://loinc.org",
            "display": "Choriogonadotropin (Pregnancy test) [Presence] in Urine"
          },
          {
            "code": "19080-1",
            "system": "http://loinc.org",
            "display": "Choriogonadotropin [Units/volume] in Serum or Plasma"
          },
          {
            "code": "2111-3",
            "system": "http://loinc.org",
            "display": "Choriogonadotropin.beta subunit [Moles/volume] in Serum or Plasma"
          },
          {
            "code": "21198-7",
            "system": "http://loinc.org",
            "display": "Choriogonadotropin.beta subunit [Units/volume] in Serum or Plasma"
          },
          {
            "code": "2110-5",
            "system": "http://loinc.org",
            "display": "Choriogonadotropin.beta subunit (pregnancy test) [Presence] in Serum or Plasma"
          },
          {
            "code": "2118-8",
            "system": "http://loinc.org",
            "display": "Choriogonadotropin (pregnancy test) [Presence] in Serum or Plasma"
          },
          {
            "code": "20404-0",
            "system": "http://loinc.org",
            "display": "Fibronectin.fetal [Presence] in Vaginal fluid"
          },
          {
            "code": "30243-0",
            "system": "http://loinc.org",
            "display": "Choriogonadotropin.intact [Units/volume] in Serum or Plasma"
          }
        ]
      },
      {
        "code": "Prenatal Screen",
        "type": "emr-defined",
        "target": [
          {
            "code": "33069-6",
            "system": "http://loinc.org",
            "display": "Fetal Neck.soft tissue Translucency width US"
          },
          {
            "code": "1834-1",
            "system": "http://loinc.org",
            "display": "Alpha-1-Fetoprotein [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "21484-1",
            "system": "http://loinc.org",
            "display": "Mother's race"
          },
          {
            "code": "48803-1",
            "system": "http://loinc.org",
            "display": "Neural tube defect risk in Fetus"
          },
          {
            "code": "21299-3",
            "system": "http://loinc.org",
            "display": "Gestational age method"
          },
          {
            "code": "18185-9",
            "system": "http://loinc.org",
            "display": "Gestational age"
          },
          {
            "code": "23811-3",
            "system": "http://loinc.org",
            "display": "Alpha-1-Fetoprotein [Multiple of the median] adjusted in Serum or Plasma"
          },
          {
            "code": "44877-9",
            "system": "http://loinc.org",
            "display": "Insulin dependent diabetes mellitus [Presence]"
          },
          {
            "code": "2250-9",
            "system": "http://loinc.org",
            "display": "Estriol (E3).unconjugated [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "49090-4",
            "system": "http://loinc.org",
            "display": "Trisomy 21 risk based on maternal age in Fetus"
          },
          {
            "code": "49092-0",
            "system": "http://loinc.org",
            "display": "Second trimester quad maternal screen [interpretation] in Serum or Plasma Narrative"
          },
          {
            "code": "43994-3",
            "system": "http://loinc.org",
            "display": "Trisomy 18 risk in Fetus"
          },
          {
            "code": "43995-0",
            "system": "http://loinc.org",
            "display": "Trisomy 21 risk in Fetus"
          },
          {
            "code": "21264-7",
            "system": "http://loinc.org",
            "display": "Estriol (E3).unconjugated [Multiple of the median] adjusted in Serum or Plasma"
          },
          {
            "code": "47223-3",
            "system": "http://loinc.org",
            "display": "Trisomy 18 risk based on maternal age in Fetus"
          },
          {
            "code": "23883-2",
            "system": "http://loinc.org",
            "display": "Inhibin A [Mass/volume] in Serum"
          },
          {
            "code": "36904-1",
            "system": "http://loinc.org",
            "display": "Inhibin A [Multiple of the median] adjusted in Serum"
          },
          {
            "code": "45371-2",
            "system": "http://loinc.org",
            "display": "Multiple pregancy"
          },
          {
            "code": "32166-1",
            "system": "http://loinc.org",
            "display": "Choriogonadotropin [Multiple of the median] adjusted in Serum or Plasma"
          },
          {
            "code": "32046-5",
            "system": "http://loinc.org",
            "display": "Pregnancy associated plasma protein A (PAPPA) [Units/volume] in Serum or Plasma"
          },
          {
            "code": "33248-6",
            "system": "http://loinc.org",
            "display": "Diabetes status [Identifier]"
          },
          {
            "code": "49053-2",
            "system": "http://loinc.org",
            "display": "History of neural tube defect Narrative"
          },
          {
            "code": "41274-2",
            "system": "http://loinc.org",
            "display": "Alpha-1-Fetoprotein interpretation [interpretation] in Serum or Plasma"
          },
          {
            "code": "11878-6",
            "system": "http://loinc.org",
            "display": "Number of fetuses by US"
          },
          {
            "code": "20450-3",
            "system": "http://loinc.org",
            "display": "Alpha-1-Fetoprotein [Multiple of the median] in Serum or Plasma"
          },
          {
            "code": "49051-6",
            "system": "http://loinc.org",
            "display": "Gestational age in weeks"
          },
          {
            "code": "20465-1",
            "system": "http://loinc.org",
            "display": "Choriogonadotropin [Multiple of the median] in Serum or Plasma"
          },
          {
            "code": "20466-9",
            "system": "http://loinc.org",
            "display": "Estriol (E3).unconjugated [Multiple of the median] in Serum or Plasma"
          },
          {
            "code": "23841-0",
            "system": "http://loinc.org",
            "display": "Choriogonadotropin.beta subunit [Multiple of the median] adjusted in Serum or Plasma"
          },
          {
            "code": "11778-8",
            "system": "http://loinc.org",
            "display": "Delivery date Estimated"
          },
          {
            "code": "11884-4",
            "system": "http://loinc.org",
            "display": "Gestational age Estimated"
          },
          {
            "code": "19171-8",
            "system": "http://loinc.org",
            "display": "Alpha-1-Fetoprotein [Units/volume] in Amniotic fluid"
          },
          {
            "code": "49572-1",
            "system": "http://loinc.org",
            "display": "Second trimester triple maternal screen [interpretation] in Serum or Plasma Narrative"
          },
          {
            "code": "2251-7",
            "system": "http://loinc.org",
            "display": "Estriol (E3) [Mass/volume] in Serum or Plasma"
          },
          {
            "code": "43993-5",
            "system": "http://loinc.org",
            "display": "Age at delivery"
          },
          {
            "code": "49588-7",
            "system": "http://loinc.org",
            "display": "First trimester maternal screen with nuchal translucency [interpretation] Narrative"
          },
          {
            "code": "49838-6",
            "system": "http://loinc.org",
            "display": "Neural tube defect risk in population"
          },
          {
            "code": "77018-0",
            "system": "http://loinc.org",
            "display": "Noninvasive prenatal fetal 13 and 18 and 21 aneuploidy panel - Plasma cell-free DNA by Sequencing"
          },
          {
            "code": "77019-8",
            "system": "http://loinc.org",
            "display": "Noninvasive prenatal fetal 18 and 21 aneuploidy panel - Plasma cell-free DNA by Sequencing"
          },
          {
            "code": "73967-2",
            "system": "http://loinc.org",
            "display": "Noninvasive prenatal fetal aneuploidy test panel - Plasma cell-free DNA"
          },
          {
            "code": "75547-0",
            "system": "http://loinc.org",
            "display": "Noninvasive prenatal fetal aneuploidy and microdeletion panel based on Plasma cell-free+WBC DNA by Dosage of chromosome-specific circulating cell free (ccf) DNA"
          }
        ]
      },
      {
        "code": "Glucose challenge",
        "type": "emr-defined",
        "target": [
          {
            "code": "1504-0",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --1 hour post 50 g glucose PO"
          },
          {
            "code": "1518-0",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --2 hours post 75 g glucose PO"
          },
          {
            "code": "1501-6",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --1 hour post 100 g glucose PO"
          },
          {
            "code": "1507-3",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --1 hour post 75 g glucose PO"
          },
          {
            "code": "20437-0",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --3 hours post dose glucose"
          },
          {
            "code": "20436-2",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --2 hours post dose glucose"
          },
          {
            "code": "1514-9",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --2 hours post 100 g glucose PO"
          },
          {
            "code": "1530-5",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --3 hours post 100 g glucose PO"
          },
          {
            "code": "20438-8",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --1 hour post dose glucose"
          },
          {
            "code": "1521-4",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --2 hours post meal"
          },
          {
            "code": "1527-1",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --30 minutes post 75 g glucose PO"
          },
          {
            "code": "10449-7",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --1 hour post meal"
          },
          {
            "code": "1549-5",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Serum or Plasma --pre 100 g glucose PO"
          }
        ]
      },
      {
        "code": "Newborn Screen",
        "type": "emr-defined",
        "target": [
          {
            "code": "46744-9",
            "system": "http://loinc.org",
            "display": "Organic acidemias newborn screen interpretation"
          },
          {
            "code": "46737-3",
            "system": "http://loinc.org",
            "display": "Galactosemias newborn screen interpretation"
          },
          {
            "code": "46733-2",
            "system": "http://loinc.org",
            "display": "Amino acidemias newborn screen interpretation"
          },
          {
            "code": "46736-5",
            "system": "http://loinc.org",
            "display": "Fatty acid oxidation defects newborn screen interpretation"
          },
          {
            "code": "38478-4",
            "system": "http://loinc.org",
            "display": "Biotinidase [Presence] in Dried blood spot"
          },
          {
            "code": "29574-1",
            "system": "http://loinc.org",
            "display": "Thyrotropin [Presence] in Dried blood spot"
          },
          {
            "code": "32854-2",
            "system": "http://loinc.org",
            "display": "17-Hydroxyprogesterone [Presence] in Dried blood spot"
          },
          {
            "code": "29571-7",
            "system": "http://loinc.org",
            "display": "Phenylalanine [Presence] in Dried blood spot"
          },
          {
            "code": "38486-7",
            "system": "http://loinc.org",
            "display": "Homocystine [Presence] in Dried blood spot"
          },
          {
            "code": "38479-2",
            "system": "http://loinc.org",
            "display": "Branched chain keto-acid dehydrogenase complex [Presence] in Dried blood spot"
          },
          {
            "code": "46779-5",
            "system": "http://loinc.org",
            "display": "Medium/Short chain acyl-CoA dehydrogenase deficiency newborn screen interpretation"
          },
          {
            "code": "46765-4",
            "system": "http://loinc.org",
            "display": "Sickle cell anemia newborn screen interpretation"
          },
          {
            "code": "49048-2",
            "system": "http://loinc.org",
            "display": "Protein feed time"
          },
          {
            "code": "46769-6",
            "system": "http://loinc.org",
            "display": "Cystic fibrosis newborn screen interpretation"
          },
          {
            "code": "46740-7",
            "system": "http://loinc.org",
            "display": "Hemoglobin disorders newborn screen interpretation"
          },
          {
            "code": "31144-9",
            "system": "http://loinc.org",
            "display": "Thyroxine (T4) [Mass/volume] in Dried blood spot"
          },
          {
            "code": "49544-0",
            "system": "http://loinc.org",
            "display": "Newborn screening recommended follow-up [interpretation]"
          },
          {
            "code": "46735-7",
            "system": "http://loinc.org",
            "display": "Endocrine disorders newborn screen interpretation"
          },
          {
            "code": "38506-2",
            "system": "http://loinc.org",
            "display": "Thyroxine (T4) [Presence] in Dried blood spot"
          },
          {
            "code": "2077-6",
            "system": "http://loinc.org",
            "display": "Chloride [Moles/volume] in Sweat"
          },
          {
            "code": "29573-3",
            "system": "http://loinc.org",
            "display": "Phenylalanine [Moles/volume] in Dried blood spot"
          },
          {
            "code": "35572-7",
            "system": "http://loinc.org",
            "display": "Phenylalanine/Tyrosine [Molar ratio] in Dried blood spot"
          },
          {
            "code": "35571-9",
            "system": "http://loinc.org",
            "display": "Tyrosine [Moles/volume] in Dried blood spot"
          },
          {
            "code": "19111-4",
            "system": "http://loinc.org",
            "display": "Mother's hospital number"
          },
          {
            "code": "53347-1",
            "system": "http://loinc.org",
            "display": "11-Deoxycorticosterone [Mass/volume] in Dried blood spot"
          },
          {
            "code": "53338-0",
            "system": "http://loinc.org",
            "display": "11-Deoxycortisol [Mass/volume] in Dried blood spot"
          },
          {
            "code": "38473-5",
            "system": "http://loinc.org",
            "display": "17-Hydroxyprogesterone [Mass/volume] in Dried blood spot"
          },
          {
            "code": "53336-4",
            "system": "http://loinc.org",
            "display": "17-Hydroxyprogesterone+Androstenedione/Cortisol [Mass Ratio] in Dried blood spot"
          },
          {
            "code": "53341-4",
            "system": "http://loinc.org",
            "display": "21-Deoxycortisol [Mass/volume] in Dried blood spot"
          },
          {
            "code": "53182-2",
            "system": "http://loinc.org",
            "display": "3-Hydroxydecenoylcarnitine (C10:1-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53189-7",
            "system": "http://loinc.org",
            "display": "3-Hydroxydodecanoylcarnitine (C12-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53188-9",
            "system": "http://loinc.org",
            "display": "3-Hydroxydodecenoylcarnitine (C12:1-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "50106-4",
            "system": "http://loinc.org",
            "display": "3-Hydroxyisovalerylcarnitine (C5-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53171-5",
            "system": "http://loinc.org",
            "display": "3-Hydroxyisovalerylcarnitine (C5-OH)/Carnitine.free (C0) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53172-3",
            "system": "http://loinc.org",
            "display": "3-Hydroxyisovalerylcarnitine (C5-OH)/Octanoylcarnitine (C8) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "50109-8",
            "system": "http://loinc.org",
            "display": "3-Hydroxylinoleoylcarnitine (C18:2-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "50113-0",
            "system": "http://loinc.org",
            "display": "3-Hydroxyoleoylcarnitine (C18:1-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "50121-3",
            "system": "http://loinc.org",
            "display": "3-Hydroxypalmitoleylcarnitine (C16:1-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "50125-4",
            "system": "http://loinc.org",
            "display": "3-Hydroxypalmitoylcarnitine (C16-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53201-0",
            "system": "http://loinc.org",
            "display": "3-Hydroxypalmitoylcarnitine (C16-OH)/Palmitoylcarnitine (C16) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "50132-0",
            "system": "http://loinc.org",
            "display": "3-Hydroxystearoylcarnitine (C18-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53196-2",
            "system": "http://loinc.org",
            "display": "3-Hydroxytetradecadienoylcarnitine (C14:2-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "50281-5",
            "system": "http://loinc.org",
            "display": "3-Hydroxytetradecanoylcarnitine (C14-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53197-0",
            "system": "http://loinc.org",
            "display": "3-Hydroxytetradecenoylcarnitine (C14:1-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "50157-7",
            "system": "http://loinc.org",
            "display": "Acetylcarnitine (C2) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53152-5",
            "system": "http://loinc.org",
            "display": "Alloisoleucine+Isoleucine+Leucine+Hydroxyproline [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53154-1",
            "system": "http://loinc.org",
            "display": "Alloisoleucine+Isoleucine+Leucine+Hydroxyproline/Alanine [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53153-3",
            "system": "http://loinc.org",
            "display": "Alloisoleucine+Isoleucine+Leucine+Hydroxyproline/Phenylalanine [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53393-5",
            "system": "http://loinc.org",
            "display": "Alloisoleucine+Isoleucine+Leucine+Hydroxyproline+Valine/Phenylalanine+Tyrosine [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53343-0",
            "system": "http://loinc.org",
            "display": "Androstenedione [Mass/volume] in Dried blood spot"
          },
          {
            "code": "47562-4",
            "system": "http://loinc.org",
            "display": "Arginine [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53398-4",
            "system": "http://loinc.org",
            "display": "Arginine/Phenylalanine [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53062-6",
            "system": "http://loinc.org",
            "display": "Argininosuccinate [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53200-2",
            "system": "http://loinc.org",
            "display": "Argininosuccinate/Arginine [Molar ratio] in Dried blood spot"
          },
          {
            "code": "75217-0",
            "system": "http://loinc.org",
            "display": "Biotinidase [Enzymatic activity/volume] in Dried blood spot"
          },
          {
            "code": "38481-8",
            "system": "http://loinc.org",
            "display": "Carnitine free (C0) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53236-6",
            "system": "http://loinc.org",
            "display": "Carnitine.free (C0)+Acetylcarnitine (C2)+Propionylcarnitine (C3)+Palmitoylcarnitine (C16)+Oleoylcarnitine (C18:1)+Stearoylcarnitine (C18)/Citrulline [Molar ratio] in Dried blood spot"
          },
          {
            "code": "73700-7",
            "system": "http://loinc.org",
            "display": "CCHD newborn screening interpretation"
          },
          {
            "code": "73697-5",
            "system": "http://loinc.org",
            "display": "CCHD newborn screening protocol used [Type]"
          },
          {
            "code": "54083-1",
            "system": "http://loinc.org",
            "display": "CFTR gene mutations found [Identifier] in Dried blood spot Nominal"
          },
          {
            "code": "42892-0",
            "system": "http://loinc.org",
            "display": "Citrulline [Moles/volume] in Dried blood spot"
          },
          {
            "code": "54092-2",
            "system": "http://loinc.org",
            "display": "Citrulline/Arginine [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53157-4",
            "system": "http://loinc.org",
            "display": "Citrulline/Phenylalanine [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53399-2",
            "system": "http://loinc.org",
            "display": "Citrulline/Tyrosine [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53345-5",
            "system": "http://loinc.org",
            "display": "Cortisol [Mass/volume] in Dried blood spot"
          },
          {
            "code": "45197-1",
            "system": "http://loinc.org",
            "display": "Decanoylcarnitine (C10) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "45198-9",
            "system": "http://loinc.org",
            "display": "Decenoylcarnitine (C10:1) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "45199-7",
            "system": "http://loinc.org",
            "display": "Dodecanoylcarnitine (C12) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "45200-3",
            "system": "http://loinc.org",
            "display": "Dodecenoylcarnitine (C12:1) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "64121-7",
            "system": "http://loinc.org",
            "display": "Fifth most predominant hemoglobin in Dried blood spot"
          },
          {
            "code": "64120-9",
            "system": "http://loinc.org",
            "display": "Fourth most predominant hemoglobin in Dried blood spot"
          },
          {
            "code": "54084-9",
            "system": "http://loinc.org",
            "display": "Galactose [Mass/volume] in Dried blood spot"
          },
          {
            "code": "42906-8",
            "system": "http://loinc.org",
            "display": "Galactose 1 phosphate uridyl transferase [Enzymatic activity/volume] in Dried blood spot"
          },
          {
            "code": "33288-2",
            "system": "http://loinc.org",
            "display": "Galactose 1 phosphate uridyl transferase [Presence] in Dried blood spot"
          },
          {
            "code": "53183-0",
            "system": "http://loinc.org",
            "display": "Glutarylcarnitine (C5-DC)+3-Hydroxydecanoylcarnitine (C10-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53184-8",
            "system": "http://loinc.org",
            "display": "Glutarylcarnitine (C5-DC)+3-Hydroxydecanoylcarnitine (C10-OH)/3-Hydroxyisovalerylcarnitine (C5-OH) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53403-2",
            "system": "http://loinc.org",
            "display": "Glutarylcarnitine (C5-DC)+3-Hydroxydecanoylcarnitine (C10-OH)/Butyrylcarnitine+Isobutyrylcarnitine (C4) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53185-5",
            "system": "http://loinc.org",
            "display": "Glutarylcarnitine (C5-DC)+3-Hydroxydecanoylcarnitine (C10-OH)/Octanoylcarnitine (C8) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53186-3",
            "system": "http://loinc.org",
            "display": "Glutarylcarnitine (C5-DC)+3-Hydroxydecanoylcarnitine (C10-OH)/Palmitoylcarnitine (C16) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "67710-4",
            "system": "http://loinc.org",
            "display": "Glutarylcarnitine (C5-DC)+3-Hydroxyhexanoylcarnitine (C6-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "67711-2",
            "system": "http://loinc.org",
            "display": "Glutarylcarnitine (C5-DC)+3-Hydroxyhexanoylcarnitine (C6-OH)/Octanoylcarnitine (C8) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "67701-3",
            "system": "http://loinc.org",
            "display": "Glutarylcarnitine (C5-DC)+3-Hydroxyhexanoylcarnitine (C6-OH)/Palmitoylcarnitine (C16) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "64122-5",
            "system": "http://loinc.org",
            "display": "Hemoglobins that can be presumptively identified based on available controls in Dried blood spot"
          },
          {
            "code": "45211-0",
            "system": "http://loinc.org",
            "display": "Hexanoylcarnitine (C6) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "45216-9",
            "system": "http://loinc.org",
            "display": "Isovalerylcarnitine+Methylbutyrylcarnitine (C5) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53239-0",
            "system": "http://loinc.org",
            "display": "Isovalerylcarnitine+Methylbutyrylcarnitine (C5)/Acetylcarnitine (C2) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53238-2",
            "system": "http://loinc.org",
            "display": "Isovalerylcarnitine+Methylbutyrylcarnitine (C5)/Carnitine.free (C0) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53401-6",
            "system": "http://loinc.org",
            "display": "Isovalerylcarnitine+Methylbutyrylcarnitine (C5)/Octanoylcarnitine (C8) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53240-8",
            "system": "http://loinc.org",
            "display": "Isovalerylcarnitine+Methylbutyrylcarnitine (C5)/Propionylcarnitine (C3) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "45217-7",
            "system": "http://loinc.org",
            "display": "Linoleoylcarnitine (C18:2) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "47700-0",
            "system": "http://loinc.org",
            "display": "Methionine [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53397-6",
            "system": "http://loinc.org",
            "display": "Methionine/Alloisoleucine+Isoleucine+Leucine+Hydroxyproline [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53156-6",
            "system": "http://loinc.org",
            "display": "Methionine/Phenylalanine [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53187-1",
            "system": "http://loinc.org",
            "display": "Methylglutarylcarnitine (C6-DC) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "45222-7",
            "system": "http://loinc.org",
            "display": "Methylmalonylcarnitine (C4-DC) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53181-4",
            "system": "http://loinc.org",
            "display": "Methylmalonylcarnitine (C4-DC)/3-Hydroxyisovalerylcarnitine (C5-OH) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "67709-6",
            "system": "http://loinc.org",
            "display": "Methylmalonylcarnitine (C4-DC)+3-Hydroxyisovalerylcarnitine (C5-OH) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "64117-5",
            "system": "http://loinc.org",
            "display": "Most predominant hemoglobin in Dried blood spot"
          },
          {
            "code": "54106-0",
            "system": "http://loinc.org",
            "display": "Newborn hearing screen method"
          },
          {
            "code": "54108-6",
            "system": "http://loinc.org",
            "display": "Newborn hearing screen of Ear - left"
          },
          {
            "code": "54109-4",
            "system": "http://loinc.org",
            "display": "Newborn hearing screen of Ear - right"
          },
          {
            "code": "53175-6",
            "system": "http://loinc.org",
            "display": "Octanoylcarnitine (C8) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53176-4",
            "system": "http://loinc.org",
            "display": "Octanoylcarnitine (C8)/Acetylcarnitine (C2) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53177-2",
            "system": "http://loinc.org",
            "display": "Octanoylcarnitine (C8)/Decanoylcarnitine (C10) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53202-8",
            "system": "http://loinc.org",
            "display": "Oleoylcarnitine (C18:1) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "59418-4",
            "system": "http://loinc.org",
            "display": "Oxygen saturation in Blood Postductal by Pulse oximetry"
          },
          {
            "code": "59407-7",
            "system": "http://loinc.org",
            "display": "Oxygen saturation in Blood Preductal by Pulse oximetry"
          },
          {
            "code": "73696-7",
            "system": "http://loinc.org",
            "display": "Oxygen saturation.preductal-oxygen saturation.postductal [Mass fraction difference] in Bld.preductal and Bld.postductal"
          },
          {
            "code": "53198-8",
            "system": "http://loinc.org",
            "display": "Palmitoleylcarnitine (C16:1) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53199-6",
            "system": "http://loinc.org",
            "display": "Palmitoylcarnitine (C16) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53160-8",
            "system": "http://loinc.org",
            "display": "Propionylcarnitine (C3) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53163-2",
            "system": "http://loinc.org",
            "display": "Propionylcarnitine (C3)/Acetylcarnitine (C2) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53162-4",
            "system": "http://loinc.org",
            "display": "Propionylcarnitine (C3)/Carnitine.free (C0) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53164-0",
            "system": "http://loinc.org",
            "display": "Propionylcarnitine (C3)/Palmitoylcarnitine (C16) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "75211-3",
            "system": "http://loinc.org",
            "display": "Propionylcarnitine (C3)+Palmitoylcarnitine (C16) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "64118-3",
            "system": "http://loinc.org",
            "display": "Second most predominant hemoglobin in Dried blood spot"
          },
          {
            "code": "53241-6",
            "system": "http://loinc.org",
            "display": "Stearoylcarnitine (C18) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53231-7",
            "system": "http://loinc.org",
            "display": "Succinylacetone [Moles/volume] in Dried blood spot"
          },
          {
            "code": "62320-7",
            "system": "http://loinc.org",
            "display": "T-cell receptor excision circle [#/volume] in Dried blood spot by Probe and target amplification method"
          },
          {
            "code": "53190-5",
            "system": "http://loinc.org",
            "display": "Tetradecadienoylcarnitine (C14:2) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53192-1",
            "system": "http://loinc.org",
            "display": "Tetradecanoylcarnitine (C14) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53191-3",
            "system": "http://loinc.org",
            "display": "Tetradecenoylcarnitine (C14:1) [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53193-9",
            "system": "http://loinc.org",
            "display": "Tetradecenoylcarnitine (C14:1)/Acetylcarnitine (C2) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53194-7",
            "system": "http://loinc.org",
            "display": "Tetradecenoylcarnitine (C14:1)/Dodecenoylcarnitine (C12:1) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "53195-4",
            "system": "http://loinc.org",
            "display": "Tetradecenoylcarnitine (C14:1)/Palmitoylcarnitine (C16) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "70159-9",
            "system": "http://loinc.org",
            "display": "Tetradecenoylcarnitine (C14:1)/Tetradecanoylcarnitine (C14) [Molar ratio] in Dried blood spot"
          },
          {
            "code": "64119-1",
            "system": "http://loinc.org",
            "display": "Third most predominant hemoglobin in Dried blood spot"
          },
          {
            "code": "47784-4",
            "system": "http://loinc.org",
            "display": "Threonine [Moles/volume] in Dried blood spot"
          },
          {
            "code": "29575-8",
            "system": "http://loinc.org",
            "display": "Thyrotropin [Units/volume] in Dried blood spot"
          },
          {
            "code": "48633-2",
            "system": "http://loinc.org",
            "display": "Trypsinogen I Free [Mass/volume] in Dried blood spot"
          },
          {
            "code": "53159-0",
            "system": "http://loinc.org",
            "display": "Tryptophan [Moles/volume] in Dried blood spot"
          },
          {
            "code": "47799-2",
            "system": "http://loinc.org",
            "display": "Valine [Moles/volume] in Dried blood spot"
          },
          {
            "code": "53151-7",
            "system": "http://loinc.org",
            "display": "Valine/Phenylalanine [Molar ratio] in Dried blood spot"
          }
        ]
      },
      {
        "code": "Fetal lung maturity",
        "type": "emr-defined",
        "target": [
          {
            "code": "30165-5",
            "system": "http://loinc.org",
            "display": "Phosphatidylcholine/Albumin [Mass ratio] in Amniotic fluid"
          },
          {
            "code": "47226-6",
            "system": "http://loinc.org",
            "display": "Fetal lung maturity [interpretation] in Amniotic fluid"
          },
          {
            "code": "19125-4",
            "system": "http://loinc.org",
            "display": "Meconium [Presence] in Amniotic fluid"
          },
          {
            "code": "14976-5",
            "system": "http://loinc.org",
            "display": "Lecithin/Sphingomyelin [Ratio] in Amniotic fluid"
          },
          {
            "code": "20499-0",
            "system": "http://loinc.org",
            "display": "Phosphatidylglycerol/Surfactant.total in Amniotic fluid"
          }
        ]
      },
      {
        "code": "Heme-Hemoglobinopathies",
        "type": "emr-defined",
        "target": [
          {
            "code": "6864-3",
            "system": "http://loinc.org",
            "display": "Hemoglobin S [Presence] in Blood by Solubility test"
          },
          {
            "code": "4546-8",
            "system": "http://loinc.org",
            "display": "Hemoglobin A/Hemoglobin.total in Blood"
          },
          {
            "code": "4576-5",
            "system": "http://loinc.org",
            "display": "Hemoglobin F/Hemoglobin.total in Blood"
          },
          {
            "code": "4625-0",
            "system": "http://loinc.org",
            "display": "Hemoglobin S/Hemoglobin.total in Blood"
          },
          {
            "code": "4563-3",
            "system": "http://loinc.org",
            "display": "Hemoglobin C/Hemoglobin.total in Blood"
          },
          {
            "code": "12710-0",
            "system": "http://loinc.org",
            "display": "Hemoglobin pattern [interpretation] in Blood"
          },
          {
            "code": "34660-1",
            "system": "http://loinc.org",
            "display": "Hemoglobin A2/Hemoglobin.total in Blood by Chromatography column"
          },
          {
            "code": "4552-6",
            "system": "http://loinc.org",
            "display": "Hemoglobin A2/Hemoglobin.total in Blood by Electrophoresis"
          },
          {
            "code": "42247-7",
            "system": "http://loinc.org",
            "display": "Hemoglobin pattern [interpretation] in Blood by HPLC Narrative"
          },
          {
            "code": "13514-5",
            "system": "http://loinc.org",
            "display": "Hemoglobin pattern [interpretation] in Blood by Electrophoresis Narrative"
          },
          {
            "code": "4547-6",
            "system": "http://loinc.org",
            "display": "Hemoglobin A1/Hemoglobin.total in Blood"
          },
          {
            "code": "32140-6",
            "system": "http://loinc.org",
            "display": "Hemoglobin F [Presence] in Blood by Kleihauer-Betke method"
          },
          {
            "code": "48343-8",
            "system": "http://loinc.org",
            "display": "Hemoglobin.other/Hemoglobin.total in Blood"
          },
          {
            "code": "4621-9",
            "system": "http://loinc.org",
            "display": "Hemoglobin S [Presence] in Blood"
          },
          {
            "code": "24469-9",
            "system": "http://loinc.org",
            "display": "Hemoglobin XXX/Hemoglobin.total in Blood by Electrophoresis"
          },
          {
            "code": "4575-7",
            "system": "http://loinc.org",
            "display": "Hemoglobin E/Hemoglobin.total in Blood"
          },
          {
            "code": "35127-0",
            "system": "http://loinc.org",
            "display": "Hemoglobin A2.prime/Hemoglobin.total in Blood"
          },
          {
            "code": "31156-3",
            "system": "http://loinc.org",
            "display": "Hemoglobin Barts/Hemoglobin.total in Blood"
          },
          {
            "code": "4569-0",
            "system": "http://loinc.org",
            "display": "Hemoglobin D/Hemoglobin.total in Blood"
          },
          {
            "code": "33593-5",
            "system": "http://loinc.org",
            "display": "Hemoglobin G - Coushatta/Hemoglobin.total in Blood"
          },
          {
            "code": "35125-4",
            "system": "http://loinc.org",
            "display": "Hemoglobin Lepore/Hemoglobin.total in Blood"
          },
          {
            "code": "35126-2",
            "system": "http://loinc.org",
            "display": "Hemoglobin O - Arab/Hemoglobin.total in Blood"
          },
          {
            "code": "4551-8",
            "system": "http://loinc.org",
            "display": "Hemoglobin A2/Hemoglobin.total in Blood"
          },
          {
            "code": "4633-4",
            "system": "http://loinc.org",
            "display": "Hemoglobin F/Hemoglobin.total in Blood by Kleihauer-Betke method"
          }
        ]
      },
      {
        "code": "Occult Blood",
        "type": "emr-defined",
        "target": [
          {
            "code": "2335-8",
            "system": "http://loinc.org",
            "display": "Hemoglobin.gastrointestinal [Presence] in Stool"
          },
          {
            "code": "14564-9",
            "system": "http://loinc.org",
            "display": "Hemoglobin.gastrointestinal [Presence] in Stool --2nd specimen"
          },
          {
            "code": "14565-6",
            "system": "http://loinc.org",
            "display": "Hemoglobin.gastrointestinal [Presence] in Stool --3rd specimen"
          },
          {
            "code": "14563-1",
            "system": "http://loinc.org",
            "display": "Hemoglobin.gastrointestinal [Presence] in Stool --1st specimen"
          },
          {
            "code": "29771-3",
            "system": "http://loinc.org",
            "display": "Hemoglobin.gastrointestinal [Presence] in Stool by Immunologic method"
          },
          {
            "code": "56490-6",
            "system": "http://loinc.org",
            "display": "Hemoglobin.gastrointestinal [Presence] in Stool by Immunologic method --2nd specimen"
          },
          {
            "code": "56491-4",
            "system": "http://loinc.org",
            "display": "Hemoglobin.gastrointestinal [Presence] in Stool by Immunologic method --3rd specimen"
          },
          {
            "code": "57804-7",
            "system": "http://loinc.org",
            "display": "Number of occult blood specimens recommended by testing kit protocol [#] in Stool"
          },
          {
            "code": "59841-7",
            "system": "http://loinc.org",
            "display": "Vendor name [Identifier] in Unspecified specimen"
          }
        ]
      },
      {
        "code": "Dialysis Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "13451-0",
            "system": "http://loinc.org",
            "display": "Creatinine dialysis fluid clearance"
          },
          {
            "code": "11064-3",
            "system": "http://loinc.org",
            "display": "Urea nitrogen [Mass/volume] in Serum or Plasma --post dialysis"
          },
          {
            "code": "11065-0",
            "system": "http://loinc.org",
            "display": "Urea nitrogen [Mass/volume] in Serum or Plasma --pre dialysis"
          }
        ]
      },
      {
        "code": "Mol Path + Cytogenetics",
        "type": "emr-defined",
        "target": [
          {
            "code": "21667-1",
            "system": "http://loinc.org",
            "display": "F5 gene mutations found [Identifier] in Blood or Tissue by Molecular genetics method Nominal"
          },
          {
            "code": "21654-9",
            "system": "http://loinc.org",
            "display": "CFTR gene mutations found [Identifier] in Blood or Tissue by Molecular genetics method Nominal"
          },
          {
            "code": "24475-6",
            "system": "http://loinc.org",
            "display": "F2 gene p.G20210A [Presence] in Blood or Tissue by Molecular genetics method"
          },
          {
            "code": "29770-5",
            "system": "http://loinc.org",
            "display": "Karyotype [Identifier] in Blood or Tissue Nominal"
          },
          {
            "code": "28005-7",
            "system": "http://loinc.org",
            "display": "MTHFR gene p.C677T [Presence] in Blood or Tissue by Molecular genetics method"
          },
          {
            "code": "24476-4",
            "system": "http://loinc.org",
            "display": "F2 gene mutations found [Identifier] in Blood or Tissue by Molecular genetics method Nominal"
          },
          {
            "code": "33773-3",
            "system": "http://loinc.org",
            "display": "Karyotype [Identifier] in Amniotic fluid Nominal"
          },
          {
            "code": "38404-0",
            "system": "http://loinc.org",
            "display": "CFTR gene mutation analysis in Blood or Tissue by Molecular genetics method Narrative"
          },
          {
            "code": "21709-1",
            "system": "http://loinc.org",
            "display": "MTHFR gene mutations found [Identifier] in Blood or Tissue by Molecular genetics method Nominal"
          },
          {
            "code": "38415-6",
            "system": "http://loinc.org",
            "display": "MTHFR gene mutation analysis in Blood or Tissue by Molecular genetics method Narrative"
          },
          {
            "code": "34519-9",
            "system": "http://loinc.org",
            "display": "HFE gene mutation analysis in Blood or Tissue by Molecular genetics method Narrative"
          },
          {
            "code": "21619-2",
            "system": "http://loinc.org",
            "display": "APOE gene mutations found [Identifier] in Blood or Tissue by Molecular genetics method Nominal"
          },
          {
            "code": "21695-2",
            "system": "http://loinc.org",
            "display": "HFE gene p.C282Y [Presence] in Blood or Tissue by Molecular genetics method"
          },
          {
            "code": "36913-2",
            "system": "http://loinc.org",
            "display": "FMR1 gene mutation analysis in Blood or Tissue by Molecular genetics method Narrative"
          },
          {
            "code": "21760-4",
            "system": "http://loinc.org",
            "display": "FRAXE gene CGG repeats [Presence] in Blood or Tissue by Molecular genetics method"
          },
          {
            "code": "36922-3",
            "system": "http://loinc.org",
            "display": "TPMT gene mutation analysis in Blood or Tissue by Molecular genetics method Narrative"
          },
          {
            "code": "43399-5",
            "system": "http://loinc.org",
            "display": "JAK2 gene p.V617F [Presence] in Blood or Tissue by Molecular genetics method"
          },
          {
            "code": "32632-2",
            "system": "http://loinc.org",
            "display": "HEXA gene mutations found [Identifier] in Blood or Tissue by Molecular genetics method Nominal"
          },
          {
            "code": "21821-4",
            "system": "http://loinc.org",
            "display": "t(9,22)(ABL1,BCR) Translocation [Presence] in Blood or Tissue by Molecular genetics method"
          },
          {
            "code": "33893-9",
            "system": "http://loinc.org",
            "display": "Karyotype [Identifier] in Bone marrow Nominal"
          },
          {
            "code": "22070-7",
            "system": "http://loinc.org",
            "display": "HP gene mutations found [Identifier] in Blood or Tissue by Molecular genetics method Nominal"
          }
        ]
      },
      {
        "code": "Blood Bank",
        "type": "emr-defined",
        "target": [
          {
            "code": "925-8",
            "system": "http://loinc.org",
            "display": "Blood product disposition [Type]"
          },
          {
            "code": "934-0",
            "system": "http://loinc.org",
            "display": "Blood product unit ID [#]"
          },
          {
            "code": "882-1",
            "system": "http://loinc.org",
            "display": "ABO & Rh group [Type] in Blood"
          },
          {
            "code": "933-2",
            "system": "http://loinc.org",
            "display": "Blood product type"
          },
          {
            "code": "890-4",
            "system": "http://loinc.org",
            "display": "Blood group antibody screen [Presence] in Serum or Plasma"
          },
          {
            "code": "883-9",
            "system": "http://loinc.org",
            "display": "ABO group [Type] in Blood"
          },
          {
            "code": "1003-3",
            "system": "http://loinc.org",
            "display": "Indirect antiglobulin test.complement specific reagent [Presence] in Serum or Plasma"
          },
          {
            "code": "1250-0",
            "system": "http://loinc.org",
            "display": "Major crossmatch [interpretation]"
          },
          {
            "code": "10331-7",
            "system": "http://loinc.org",
            "display": "Rh [Type] in Blood"
          },
          {
            "code": "14578-9",
            "system": "http://loinc.org",
            "display": "ABO group [Type] in Blood from Blood product unit"
          },
          {
            "code": "14907-0",
            "system": "http://loinc.org",
            "display": "Rh [Type] in Blood from Blood product unit"
          },
          {
            "code": "1305-2",
            "system": "http://loinc.org",
            "display": "D Ag [Presence] in Blood"
          },
          {
            "code": "1006-6",
            "system": "http://loinc.org",
            "display": "Direct antiglobulin test.IgG specific reagent [interpretation] on Red Blood Cells"
          },
          {
            "code": "19066-0",
            "system": "http://loinc.org",
            "display": "Blood bank comment"
          },
          {
            "code": "19057-9",
            "system": "http://loinc.org",
            "display": "ABO & Rh group [Type] in Blood from newborn"
          },
          {
            "code": "14604-3",
            "system": "http://loinc.org",
            "display": "Blood group antibodies present [Identifier] in Serum or Plasma from Blood product unit"
          },
          {
            "code": "931-6",
            "system": "http://loinc.org",
            "display": "Blood product source [Type]"
          },
          {
            "code": "49542-4",
            "system": "http://loinc.org",
            "display": "Date and time of pheresis procedure"
          },
          {
            "code": "49540-8",
            "system": "http://loinc.org",
            "display": "Acid citrate dextrose [Volume] in Blood product unit"
          },
          {
            "code": "936-5",
            "system": "http://loinc.org",
            "display": "Blood product unit [Identifier]"
          },
          {
            "code": "14906-2",
            "system": "http://loinc.org",
            "display": "Rh [Type] in Cord blood"
          },
          {
            "code": "51892-8",
            "system": "http://loinc.org",
            "display": "ABO group [Type] in Cord blood"
          },
          {
            "code": "14869-2",
            "system": "http://loinc.org",
            "display": "Pathologist review of Blood tests"
          },
          {
            "code": "1007-4",
            "system": "http://loinc.org",
            "display": "Direct antiglobulin test.poly specific reagent [Presence] on Red Blood Cells"
          },
          {
            "code": "888-8",
            "system": "http://loinc.org",
            "display": "Blood group antibodies identified in Serum or Plasma"
          },
          {
            "code": "10386-1",
            "system": "http://loinc.org",
            "display": "Albumin given [Volume]"
          },
          {
            "code": "50970-3",
            "system": "http://loinc.org",
            "display": "XXX blood group Ab [Titer] in Serum or Plasma by Antihuman globulin"
          },
          {
            "code": "46268-9",
            "system": "http://loinc.org",
            "display": "ABO & Rh group [Type] in Blood from Blood product unit--after transfusion reaction"
          },
          {
            "code": "38168-1",
            "system": "http://loinc.org",
            "display": "Major crossmatch [interpretation] by Low ionic strenght saline (LISS)"
          }
        ]
      },
      {
        "code": "HLA",
        "type": "emr-defined",
        "target": [
          {
            "code": "46995-7",
            "system": "http://loinc.org",
            "display": "HLA-DP+DQ+DR (class II) Ab in Serum"
          },
          {
            "code": "46994-0",
            "system": "http://loinc.org",
            "display": "HLA-A+B+C (class I) Ab in Serum"
          },
          {
            "code": "26043-0",
            "system": "http://loinc.org",
            "display": "HLA-B27 [Presence] by Probe & target amplification method"
          },
          {
            "code": "4821-5",
            "system": "http://loinc.org",
            "display": "HLA-B27 [Presence]"
          }
        ]
      },
      {
        "code": "Allergy - Food",
        "type": "emr-defined",
        "target": [
          {
            "code": "6206-7",
            "system": "http://loinc.org",
            "display": "Peanut IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6276-0",
            "system": "http://loinc.org",
            "display": "Wheat IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6248-9",
            "system": "http://loinc.org",
            "display": "Soybean IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7258-7",
            "system": "http://loinc.org",
            "display": "Cow milk IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6087-1",
            "system": "http://loinc.org",
            "display": "Corn IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6106-9",
            "system": "http://loinc.org",
            "display": "Egg white IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6039-2",
            "system": "http://loinc.org",
            "display": "Beef IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7291-8",
            "system": "http://loinc.org",
            "display": "Whole Egg IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6073-1",
            "system": "http://loinc.org",
            "display": "Chocolate IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6219-0",
            "system": "http://loinc.org",
            "display": "Pork IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6273-7",
            "system": "http://loinc.org",
            "display": "Walnut IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "15218-1",
            "system": "http://loinc.org",
            "display": "Food Allergen Mix 2 (Cod+Blue Mussel+Shrimp+Salmon+Tuna) IgE Ab [Presence] in Serum by Multidisk"
          },
          {
            "code": "6246-3",
            "system": "http://loinc.org",
            "display": "Shrimp IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6082-2",
            "system": "http://loinc.org",
            "display": "Codfish IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6019-4",
            "system": "http://loinc.org",
            "display": "Almond IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6107-7",
            "system": "http://loinc.org",
            "display": "Egg yolk IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6718-1",
            "system": "http://loinc.org",
            "display": "Cashew Nut IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6208-3",
            "system": "http://loinc.org",
            "display": "Pecan or Hickory Nut IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6076-4",
            "system": "http://loinc.org",
            "display": "Clam IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7691-9",
            "system": "http://loinc.org",
            "display": "Scallop IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6136-6",
            "system": "http://loinc.org",
            "display": "Hazelnut IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6092-1",
            "system": "http://loinc.org",
            "display": "Crab IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "35538-8",
            "system": "http://loinc.org",
            "display": "Baker's yeast IgG Ab [Mass/volume] in Serum"
          },
          {
            "code": "6165-5",
            "system": "http://loinc.org",
            "display": "Lobster IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "31032-6",
            "system": "http://loinc.org",
            "display": "Baker's yeast IgA Ab [Units/volume] in Serum"
          },
          {
            "code": "47320-7",
            "system": "http://loinc.org",
            "display": "Baker's yeast IgA Ab [Units/volume] in Serum by Immunoassay"
          },
          {
            "code": "6050-9",
            "system": "http://loinc.org",
            "display": "Brazil Nut IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6266-1",
            "system": "http://loinc.org",
            "display": "Tomato IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6174-7",
            "system": "http://loinc.org",
            "display": "Milk IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6242-2",
            "system": "http://loinc.org",
            "display": "Sesame Seed IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6190-3",
            "system": "http://loinc.org",
            "display": "Oat IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6230-7",
            "system": "http://loinc.org",
            "display": "Rice IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6021-0",
            "system": "http://loinc.org",
            "display": "Apple IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6270-3",
            "system": "http://loinc.org",
            "display": "Tuna IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7613-3",
            "system": "http://loinc.org",
            "display": "Pistachio IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "15643-0",
            "system": "http://loinc.org",
            "display": "Clam IgE Ab RAST class in Serum"
          },
          {
            "code": "6257-0",
            "system": "http://loinc.org",
            "display": "Strawberry IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6209-1",
            "system": "http://loinc.org",
            "display": "Pecan or Hickory Tree IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6237-2",
            "system": "http://loinc.org",
            "display": "Salmon IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6035-0",
            "system": "http://loinc.org",
            "display": "Banana IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6194-5",
            "system": "http://loinc.org",
            "display": "Orange IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6137-4",
            "system": "http://loinc.org",
            "display": "Hazelnut Pollen IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6062-4",
            "system": "http://loinc.org",
            "display": "Casein IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6220-8",
            "system": "http://loinc.org",
            "display": "Potato IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7558-0",
            "system": "http://loinc.org",
            "display": "Oyster IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "15917-8",
            "system": "http://loinc.org",
            "display": "Peanut IgE Ab RAST class in Serum"
          },
          {
            "code": "7774-3",
            "system": "http://loinc.org",
            "display": "Cow whey IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6037-6",
            "system": "http://loinc.org",
            "display": "Barley IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "16074-7",
            "system": "http://loinc.org",
            "display": "Walnut IgE Ab RAST class in Serum"
          },
          {
            "code": "25383-1",
            "system": "http://loinc.org",
            "display": "Cow milk IgE Ab RAST class in Serum"
          },
          {
            "code": "11183-1",
            "system": "http://loinc.org",
            "display": "Macadamia IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7445-0",
            "system": "http://loinc.org",
            "display": "Lactalbumin alpha IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6061-6",
            "system": "http://loinc.org",
            "display": "Carrot IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6081-4",
            "system": "http://loinc.org",
            "display": "Coconut IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "16085-3",
            "system": "http://loinc.org",
            "display": "Wheat IgE Ab RAST class in Serum"
          },
          {
            "code": "15568-9",
            "system": "http://loinc.org",
            "display": "Soybean IgE Ab RAST class in Serum"
          },
          {
            "code": "6125-9",
            "system": "http://loinc.org",
            "display": "Gluten IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6287-7",
            "system": "http://loinc.org",
            "display": "Baker's yeast IgE Ab [Units/volume] in Serum"
          }
        ]
      },
      {
        "code": "Allergy - Environmental",
        "type": "emr-defined",
        "target": [
          {
            "code": "6095-4",
            "system": "http://loinc.org",
            "display": "American house dust mite IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6020-2",
            "system": "http://loinc.org",
            "display": "Alternaria alternata IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6096-2",
            "system": "http://loinc.org",
            "display": "European house dust mite IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6025-1",
            "system": "http://loinc.org",
            "display": "Aspergillus fumigatus IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6099-6",
            "system": "http://loinc.org",
            "display": "Dog epithelium IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6833-8",
            "system": "http://loinc.org",
            "display": "Cat dander IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6189-5",
            "system": "http://loinc.org",
            "display": "White Oak IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6075-6",
            "system": "http://loinc.org",
            "display": "Cladosporium herbarum IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6041-8",
            "system": "http://loinc.org",
            "display": "Bermuda grass IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6212-5",
            "system": "http://loinc.org",
            "display": "Penicillium notatum IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6085-5",
            "system": "http://loinc.org",
            "display": "Common Ragweed IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6110-1",
            "system": "http://loinc.org",
            "display": "English Plantain IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "13183-9",
            "system": "http://loinc.org",
            "display": "White Elm IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "30170-5",
            "system": "http://loinc.org",
            "display": "American Cockroach IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7155-5",
            "system": "http://loinc.org",
            "display": "Boxelder IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6182-0",
            "system": "http://loinc.org",
            "display": "Mucor racemosus IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6152-3",
            "system": "http://loinc.org",
            "display": "Johnson grass IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6252-1",
            "system": "http://loinc.org",
            "display": "Stemphylium botryosum IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6034-3",
            "system": "http://loinc.org",
            "display": "Bahia grass IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6244-8",
            "system": "http://loinc.org",
            "display": "Sheep Sorrel IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6153-1",
            "system": "http://loinc.org",
            "display": "Kentucky blue grass IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6265-3",
            "system": "http://loinc.org",
            "display": "Timothy IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6233-1",
            "system": "http://loinc.org",
            "display": "Rough Pigweed IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6281-0",
            "system": "http://loinc.org",
            "display": "White mulberry IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6178-8",
            "system": "http://loinc.org",
            "display": "Mountain Juniper IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6156-4",
            "system": "http://loinc.org",
            "display": "Goosefoot IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6186-1",
            "system": "http://loinc.org",
            "display": "Nettle IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7407-0",
            "system": "http://loinc.org",
            "display": "White Hickory IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "41874-9",
            "system": "http://loinc.org",
            "display": "White Birch IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6183-8",
            "system": "http://loinc.org",
            "display": "Mugwort IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6263-8",
            "system": "http://loinc.org",
            "display": "American Sycamore IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6098-8",
            "system": "http://loinc.org",
            "display": "Dog dander IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6278-6",
            "system": "http://loinc.org",
            "display": "White Ash IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7369-2",
            "system": "http://loinc.org",
            "display": "Perennial rye grass IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "15761-0",
            "system": "http://loinc.org",
            "display": "Sweetgum IgE Ab RAST class in Serum"
          },
          {
            "code": "15530-9",
            "system": "http://loinc.org",
            "display": "Alternaria alternata IgE Ab RAST class in Serum"
          },
          {
            "code": "6164-8",
            "system": "http://loinc.org",
            "display": "Virginia Live Oak IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6113-5",
            "system": "http://loinc.org",
            "display": "Gum-Tree IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "33536-4",
            "system": "http://loinc.org",
            "display": "Miscellaneous allergen IgE Ab RAST class in Serum"
          },
          {
            "code": "6158-0",
            "system": "http://loinc.org",
            "display": "Latex IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "15283-5",
            "system": "http://loinc.org",
            "display": "Silver Birch IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6222-4",
            "system": "http://loinc.org",
            "display": "Queen Palm IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6151-5",
            "system": "http://loinc.org",
            "display": "Italian Cypress IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7287-6",
            "system": "http://loinc.org",
            "display": "Dog Fennel IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6109-3",
            "system": "http://loinc.org",
            "display": "White Elm IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7124-1",
            "system": "http://loinc.org",
            "display": "Bayberry Pollen IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7477-3",
            "system": "http://loinc.org",
            "display": "Mango Pollen IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7110-0",
            "system": "http://loinc.org",
            "display": "Groundsel Tree IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6195-2",
            "system": "http://loinc.org",
            "display": "Cocksfoot IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6078-0",
            "system": "http://loinc.org",
            "display": "Cockroach IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6059-0",
            "system": "http://loinc.org",
            "display": "Candida albicans IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6138-2",
            "system": "http://loinc.org",
            "display": "Helminthosporium halodes IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7632-3",
            "system": "http://loinc.org",
            "display": "Privet IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6234-9",
            "system": "http://loinc.org",
            "display": "Saltwort IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "7415-3",
            "system": "http://loinc.org",
            "display": "Cladosporium sphaerospermum IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "19734-3",
            "system": "http://loinc.org",
            "display": "Chicken droppings IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "24139-8",
            "system": "http://loinc.org",
            "display": "Cockroach IgG Ab [Units/volume] in Serum"
          },
          {
            "code": "6239-8",
            "system": "http://loinc.org",
            "display": "Lenscale IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6286-9",
            "system": "http://loinc.org",
            "display": "Wormwood IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6029-3",
            "system": "http://loinc.org",
            "display": "Aureobasidium pullulans IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6733-0",
            "system": "http://loinc.org",
            "display": "Pigeon serum Ab [Presence] in Serum by Immune diffusion (ID)"
          },
          {
            "code": "6038-4",
            "system": "http://loinc.org",
            "display": "American Beech IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6121-8",
            "system": "http://loinc.org",
            "display": "Fusarium moniliforme IgE Ab [Units/volume] in Serum"
          },
          {
            "code": "6090-5",
            "system": "http://loinc.org",
            "display": "Cottonwood IgE Ab [Units/volume] in Serum"
          }
        ]
      },
      {
        "code": "Body Fluid Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "6824-7",
            "system": "http://loinc.org",
            "display": "Color of Body fluid"
          },
          {
            "code": "14725-6",
            "system": "http://loinc.org",
            "display": "[Type] of Body fluid"
          },
          {
            "code": "9335-1",
            "system": "http://loinc.org",
            "display": "Appearance of Body fluid"
          },
          {
            "code": "2881-1",
            "system": "http://loinc.org",
            "display": "Protein [Mass/volume] in Body fluid"
          },
          {
            "code": "1795-4",
            "system": "http://loinc.org",
            "display": "Amylase [Enzymatic activity/volume] in Body fluid"
          },
          {
            "code": "2344-0",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Body fluid"
          },
          {
            "code": "2529-6",
            "system": "http://loinc.org",
            "display": "Lactate dehydrogenase [Enzymatic activity/volume] in Body fluid"
          },
          {
            "code": "20513-8",
            "system": "http://loinc.org",
            "display": "Turbidity [Presence] of Body fluid"
          },
          {
            "code": "2748-2",
            "system": "http://loinc.org",
            "display": "pH of Body fluid"
          },
          {
            "code": "1747-5",
            "system": "http://loinc.org",
            "display": "Albumin [Mass/volume] in Body fluid"
          },
          {
            "code": "12190-5",
            "system": "http://loinc.org",
            "display": "Creatinine [Mass/volume] in Body fluid"
          },
          {
            "code": "15212-4",
            "system": "http://loinc.org",
            "display": "Lipase [Enzymatic activity/volume] in Body fluid"
          },
          {
            "code": "14664-7",
            "system": "http://loinc.org",
            "display": "Color of Synovial fluid"
          },
          {
            "code": "48053-3",
            "system": "http://loinc.org",
            "display": "Turbidity [Presence] of Synovial fluid"
          },
          {
            "code": "3093-2",
            "system": "http://loinc.org",
            "display": "Urea nitrogen [Mass/volume] in Body fluid"
          },
          {
            "code": "2749-0",
            "system": "http://loinc.org",
            "display": "pH of Gastric fluid"
          },
          {
            "code": "1974-5",
            "system": "http://loinc.org",
            "display": "Bilirubin [Mass/volume] in Body fluid"
          },
          {
            "code": "2334-1",
            "system": "http://loinc.org",
            "display": "Hemoglobin.gastrointestinal [Presence] in Gastric fluid    *NOTE: from occult blood"
          }
        ]
      },
      {
        "code": "Heme-Body Fluid Cell Count",
        "type": "emr-defined",
        "target": [
          {
            "code": "26487-9",
            "system": "http://loinc.org",
            "display": "Monocytes/100 leukocytes in Body fluid"
          },
          {
            "code": "11031-2",
            "system": "http://loinc.org",
            "display": "Lymphocytes/100 leukocytes in Body fluid"
          },
          {
            "code": "12238-2",
            "system": "http://loinc.org",
            "display": "Neutrophils/100 leukocytes in Body fluid by Manual count"
          },
          {
            "code": "26452-3",
            "system": "http://loinc.org",
            "display": "Eosinophils/100 leukocytes in Body fluid"
          },
          {
            "code": "26510-8",
            "system": "http://loinc.org",
            "display": "Neutrophils.band form/100 leukocytes in Body fluid"
          },
          {
            "code": "26455-6",
            "system": "http://loinc.org",
            "display": "Erythrocytes [#/volume] in Body fluid"
          },
          {
            "code": "57845-0",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/volume] in Body fluid by Automated count"
          },
          {
            "code": "13518-6",
            "system": "http://loinc.org",
            "display": "Lymphocytes Variant/100 leukocytes in Body fluid by Manual count"
          },
          {
            "code": "12179-8",
            "system": "http://loinc.org",
            "display": "Basophils/100 leukocytes in Body fluid by Manual count"
          },
          {
            "code": "26473-9",
            "system": "http://loinc.org",
            "display": "Leukocytes other/100 leukocytes in Body fluid"
          },
          {
            "code": "26466-3",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/volume] in Body fluid"
          },
          {
            "code": "11153-4",
            "system": "http://loinc.org",
            "display": "Hematocrit [Volume Fraction] of Body fluid"
          },
          {
            "code": "6741-3",
            "system": "http://loinc.org",
            "display": "Erythrocytes [#/volume] in Body fluid by Manual count"
          },
          {
            "code": "30457-6",
            "system": "http://loinc.org",
            "display": "Nonhematic cells/100 leukocytes in Body fluid"
          },
          {
            "code": "34985-2",
            "system": "http://loinc.org",
            "display": "Unidentified cells/100 leukocytes in Body fluid"
          },
          {
            "code": "26513-2",
            "system": "http://loinc.org",
            "display": "Neutrophils/100 leukocytes in Body fluid"
          },
          {
            "code": "12230-9",
            "system": "http://loinc.org",
            "display": "Macrophages/100 leukocytes in Body fluid by Manual count"
          },
          {
            "code": "13530-1",
            "system": "http://loinc.org",
            "display": "Nucleated erythrocytes [#/volume] in Body fluid by Manual count"
          },
          {
            "code": "13522-8",
            "system": "http://loinc.org",
            "display": "Blasts/100 leukocytes in Body fluid by Manual count"
          },
          {
            "code": "26518-1",
            "system": "http://loinc.org",
            "display": "Polymorphonuclear cells/100 leukocytes in Body fluid"
          },
          {
            "code": "19076-9",
            "system": "http://loinc.org",
            "display": "Cells Counted Total [#] in Unspecified specimen"
          },
          {
            "code": "5781-0",
            "system": "http://loinc.org",
            "display": "Crystals [type] in Synovial fluid by Light microscopy"
          },
          {
            "code": "6825-4",
            "system": "http://loinc.org",
            "display": "Crystals [type] in Body fluid by Light microscopy"
          },
          {
            "code": "12234-1",
            "system": "http://loinc.org",
            "display": "Mesothelial cells/100 leukocytes in Body fluid by Manual count"
          },
          {
            "code": "30427-9",
            "system": "http://loinc.org",
            "display": "Macrophages/100 leukocytes in Body fluid"
          },
          {
            "code": "19077-7",
            "system": "http://loinc.org",
            "display": "Cells identified in Body fluid"
          },
          {
            "code": "26458-0",
            "system": "http://loinc.org",
            "display": "Erythrocytes [#/volume] in Synovial fluid"
          },
          {
            "code": "20999-9",
            "system": "http://loinc.org",
            "display": "Cell Fractions/Differential [interpretation] in Body fluid"
          },
          {
            "code": "38256-4",
            "system": "http://loinc.org",
            "display": "Cells Counted Total [#] in Body fluid"
          },
          {
            "code": "20473-5",
            "system": "http://loinc.org",
            "display": "Polymorphonuclear cells [Presence] in Unspecified specimen by Wright stain"
          },
          {
            "code": "28543-7",
            "system": "http://loinc.org",
            "display": "Basophils/100 leukocytes in Body fluid"
          },
          {
            "code": "48051-7",
            "system": "http://loinc.org",
            "display": "Erythrocytes [Presence] in Vaginal fluid"
          },
          {
            "code": "32164-6",
            "system": "http://loinc.org",
            "display": "Cells [#/volume] in Synovial fluid by Manual count"
          },
          {
            "code": "30437-8",
            "system": "http://loinc.org",
            "display": "Monocytes+Macrophages/100 leukocytes in Body fluid"
          },
          {
            "code": "808-6",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/volume] in Pleural fluid by Manual count"
          },
          {
            "code": "35051-2",
            "system": "http://loinc.org",
            "display": "Leukocytes other [#/volume] in Body fluid"
          },
          {
            "code": "23860-0",
            "system": "http://loinc.org",
            "display": "Erythrocytes [#/volume] in Body fluid by Automated count"
          },
          {
            "code": "19098-3",
            "system": "http://loinc.org",
            "display": "Erythrocytes [Presence] in Amniotic fluid"
          },
          {
            "code": "13941-0",
            "system": "http://loinc.org",
            "display": "Lymphocytes/100 leukocytes in Body fluid by Manual count"
          },
          {
            "code": "12209-3",
            "system": "http://loinc.org",
            "display": "Eosinophils/100 leukocytes in Body fluid by Manual count"
          }
        ]
      },
      {
        "code": "Stool Studies",
        "type": "emr-defined",
        "target": [
          {
            "code": "38526-0",
            "system": "http://loinc.org",
            "display": "Number of specimens tested of Stool"
          },
          {
            "code": "38527-8",
            "system": "http://loinc.org",
            "display": "Number of specimens received of Stool"
          }
        ]
      },
      {
        "code": "Heme-Stool Cell Count",
        "type": "emr-defined",
        "target": [
          {
            "code": "13655-6",
            "system": "http://loinc.org",
            "display": "Leukocytes [Presence] in Stool by Light microscopy"
          },
          {
            "code": "48050-9",
            "system": "http://loinc.org",
            "display": "Neutrophils [Presence] in Stool by Wright stain"
          },
          {
            "code": "13349-6",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/volume] in Stool by Manual count"
          },
          {
            "code": "48049-1",
            "system": "http://loinc.org",
            "display": "Eosinophils [Presence] in Stool by Wright stain"
          }
        ]
      },
      {
        "code": "CSF Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "10335-8",
            "system": "http://loinc.org",
            "display": "Color of Cerebral spinal fluid"
          },
          {
            "code": "2880-3",
            "system": "http://loinc.org",
            "display": "Protein [Mass/volume] in Cerebral spinal fluid"
          },
          {
            "code": "2342-4",
            "system": "http://loinc.org",
            "display": "Glucose [Mass/volume] in Cerebral spinal fluid"
          },
          {
            "code": "19157-7",
            "system": "http://loinc.org",
            "display": "Tube number of Cerebral spinal fluid"
          },
          {
            "code": "13532-7",
            "system": "http://loinc.org",
            "display": "Xanthochromia [Presence] of Cerebral spinal fluid"
          },
          {
            "code": "10333-3",
            "system": "http://loinc.org",
            "display": "Appearance of Cerebral spinal fluid"
          },
          {
            "code": "20512-0",
            "system": "http://loinc.org",
            "display": "Turbidity [Presence] of Cerebral spinal fluid"
          },
          {
            "code": "11135-1",
            "system": "http://loinc.org",
            "display": "Appearance of Spun Cerebral spinal fluid"
          },
          {
            "code": "5290-2",
            "system": "http://loinc.org",
            "display": "Reagin Ab [Presence] in Cerebral spinal fluid by VDRL"
          },
          {
            "code": "2873-8",
            "system": "http://loinc.org",
            "display": "Gamma globulin [Mass/volume] in Cerebral spinal fluid by Electrophoresis"
          },
          {
            "code": "17607-3",
            "system": "http://loinc.org",
            "display": "Volume of Cerebral spinal fluid"
          },
          {
            "code": "12782-9",
            "system": "http://loinc.org",
            "display": "Protein fractions.oligoclonal bands [interpretation] in Cerebral spinal fluid by Electrophoresis"
          },
          {
            "code": "2464-6",
            "system": "http://loinc.org",
            "display": "IgG [Mass/volume] in Cerebral spinal fluid"
          },
          {
            "code": "1746-7",
            "system": "http://loinc.org",
            "display": "Albumin [Mass/volume] in Cerebral spinal fluid"
          },
          {
            "code": "49295-9",
            "system": "http://loinc.org",
            "display": "Protein Fractions [interpretation] in Cerebral spinal fluid by Electrophoresis Narrative"
          },
          {
            "code": "2638-5",
            "system": "http://loinc.org",
            "display": "Myelin basic protein [Mass/volume] in Cerebral spinal fluid"
          }
        ]
      },
      {
        "code": "Heme - CSF Cell Count",
        "type": "emr-defined",
        "target": [
          {
            "code": "806-0",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/volume] in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "26454-9",
            "system": "http://loinc.org",
            "display": "Erythrocytes [#/volume] in Cerebral spinal fluid"
          },
          {
            "code": "10328-3",
            "system": "http://loinc.org",
            "display": "Lymphocytes/100 leukocytes in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "792-2",
            "system": "http://loinc.org",
            "display": "Erythrocytes [#/volume] in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "13516-0",
            "system": "http://loinc.org",
            "display": "Neutrophils/100 leukocytes in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "48035-0",
            "system": "http://loinc.org",
            "display": "Hemoglobin [Presence] in Cerebral spinal fluid"
          },
          {
            "code": "13527-7",
            "system": "http://loinc.org",
            "display": "Unidentified cells/100 leukocytes in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "26486-1",
            "system": "http://loinc.org",
            "display": "Monocytes/100 leukocytes in Cerebral spinal fluid"
          },
          {
            "code": "12208-5",
            "system": "http://loinc.org",
            "display": "Eosinophils/100 leukocytes in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "12278-8",
            "system": "http://loinc.org",
            "display": "Neutrophils.band form/100 leukocytes in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "13519-4",
            "system": "http://loinc.org",
            "display": "Basophils/100 leukocytes in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "13517-8",
            "system": "http://loinc.org",
            "display": "Lymphocytes Variant/100 leukocytes in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "13529-3",
            "system": "http://loinc.org",
            "display": "Nucleated erythrocytes [#/volume] in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "10329-1",
            "system": "http://loinc.org",
            "display": "Monocytes/100 leukocytes in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "26472-1",
            "system": "http://loinc.org",
            "display": "Leukocytes other/100 leukocytes in Cerebral spinal fluid"
          },
          {
            "code": "13508-7",
            "system": "http://loinc.org",
            "display": "Hematocrit [Volume Fraction] of Cerebral spinal fluid by Centrifugation"
          },
          {
            "code": "13525-1",
            "system": "http://loinc.org",
            "display": "Nonhematic cells/100 leukocytes in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "19075-1",
            "system": "http://loinc.org",
            "display": "Cells Counted Total [#] in Cerebral spinal fluid"
          },
          {
            "code": "26512-4",
            "system": "http://loinc.org",
            "display": "Neutrophils/100 leukocytes in Cerebral spinal fluid"
          },
          {
            "code": "26447-3",
            "system": "http://loinc.org",
            "display": "Blasts/100 leukocytes in Cerebral spinal fluid"
          },
          {
            "code": "26451-5",
            "system": "http://loinc.org",
            "display": "Eosinophils/100 leukocytes in Cerebral spinal fluid"
          },
          {
            "code": "26479-6",
            "system": "http://loinc.org",
            "display": "Lymphocytes/100 leukocytes in Cerebral spinal fluid"
          },
          {
            "code": "26517-3",
            "system": "http://loinc.org",
            "display": "Polymorphonuclear cells/100 leukocytes in Cerebral spinal fluid"
          },
          {
            "code": "12229-1",
            "system": "http://loinc.org",
            "display": "Macrophages/100 leukocytes in Cerebral spinal fluid by Manual count"
          },
          {
            "code": "26509-0",
            "system": "http://loinc.org",
            "display": "Neutrophils.band form/100 leukocytes in Cerebral spinal fluid"
          },
          {
            "code": "30374-3",
            "system": "http://loinc.org",
            "display": "Basophils/100 leukocytes in Cerebral spinal fluid"
          }
        ]
      },
      {
        "code": "Sweat tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "2077-6",
            "system": "http://loinc.org",
            "display": "Chloride [Moles/volume] in Sweat"
          },
          {
            "code": "33247-8",
            "system": "http://loinc.org",
            "display": "Weight of Sweat"
          }
        ]
      },
      {
        "code": "Saliva Tests",
        "type": "emr-defined",
        "target": [
          {
            "code": "2142-8",
            "system": "http://loinc.org",
            "display": "Cortisol [Mass/volume] in Saliva"
          }
        ]
      },
      {
        "code": "Fertility Male",
        "type": "emr-defined",
        "target": [
          {
            "code": "3160-9",
            "system": "http://loinc.org",
            "display": "Volume of Semen"
          },
          {
            "code": "9780-8",
            "system": "http://loinc.org",
            "display": "Spermatozoa [#/volume] in Semen"
          },
          {
            "code": "6800-7",
            "system": "http://loinc.org",
            "display": "Spermatozoa Motile/100 spermatozoa in Semen"
          },
          {
            "code": "9631-3",
            "system": "http://loinc.org",
            "display": "Viscosity of Semen"
          },
          {
            "code": "10585-8",
            "system": "http://loinc.org",
            "display": "Round cells [#/volume] in Semen"
          },
          {
            "code": "33217-1",
            "system": "http://loinc.org",
            "display": "Spermatozoa Agglutinated [Presence] in Semen"
          },
          {
            "code": "2752-4",
            "system": "http://loinc.org",
            "display": "pH of Semen"
          },
          {
            "code": "38544-3",
            "system": "http://loinc.org",
            "display": "Spermatozoa [#/volume] in Semen --pre washing"
          },
          {
            "code": "38540-1",
            "system": "http://loinc.org",
            "display": "Spermatozoa Motile/100 spermatozoa in Semen --pre washing"
          },
          {
            "code": "13358-7",
            "system": "http://loinc.org",
            "display": "Collection time of Semen"
          },
          {
            "code": "9704-8",
            "system": "http://loinc.org",
            "display": "Spermatozoa [Morphology] in Semen"
          },
          {
            "code": "10587-4",
            "system": "http://loinc.org",
            "display": "Sexual abstinence duration"
          },
          {
            "code": "14194-5",
            "system": "http://loinc.org",
            "display": "Spermatozoa Progressive/100 spermatozoa in Semen"
          },
          {
            "code": "10579-1",
            "system": "http://loinc.org",
            "display": "Leukocytes [#/volume] in Semen"
          },
          {
            "code": "40692-6",
            "system": "http://loinc.org",
            "display": "Volume of Semen--pre washing"
          },
          {
            "code": "13943-6",
            "system": "http://loinc.org",
            "display": "Fructose [Presence] in Semen"
          },
          {
            "code": "34441-6",
            "system": "http://loinc.org",
            "display": "Spermatozoa [Velocity] in Semen"
          },
          {
            "code": "13942-8",
            "system": "http://loinc.org",
            "display": "Spermatozoa Motile [Presence] in Semen by Light microscopy"
          },
          {
            "code": "10622-9",
            "system": "http://loinc.org",
            "display": "Spermatozoa Normal/100 spermatozoa in Semen"
          },
          {
            "code": "10580-9",
            "system": "http://loinc.org",
            "display": "Liquefaction [Time] in Semen"
          },
          {
            "code": "34696-5",
            "system": "http://loinc.org",
            "display": "Collection method [Type] of Semen"
          },
          {
            "code": "13627-5",
            "system": "http://loinc.org",
            "display": "Erythrocytes [Presence] in Semen by Light microscopy"
          },
          {
            "code": "32789-0",
            "system": "http://loinc.org",
            "display": "Viscosity of Semen Qualitative"
          }
        ]
      },
      {
        "code": "Stone Analysis",
        "type": "emr-defined",
        "target": [
          {
            "code": "14638-1",
            "system": "http://loinc.org",
            "display": "Calculus analysis [interpretation] in Stone"
          },
          {
            "code": "9795-6",
            "system": "http://loinc.org",
            "display": "Composition in Stone"
          },
          {
            "code": "16264-4",
            "system": "http://loinc.org",
            "display": "Calcium oxalate monohydrate crystals [Presence] in Stone by Infrared spectroscopy"
          },
          {
            "code": "9796-4",
            "system": "http://loinc.org",
            "display": "Color of Stone"
          },
          {
            "code": "9802-0",
            "system": "http://loinc.org",
            "display": "Size [Entitic volume] of Stone"
          },
          {
            "code": "16268-5",
            "system": "http://loinc.org",
            "display": "Calcium phosphate crystals [Presence] in Stone by Infrared spectroscopy"
          },
          {
            "code": "9804-6",
            "system": "http://loinc.org",
            "display": "Weight of Stone"
          },
          {
            "code": "16263-6",
            "system": "http://loinc.org",
            "display": "Calcium oxalate dihydrate crystals [Presence] in Stone by Infrared spectroscopy"
          },
          {
            "code": "42192-5",
            "system": "http://loinc.org",
            "display": "Nidus [Presence] in Stone"
          }
        ]
      },
      {
        "code": "Misc",
        "type": "emr-defined",
        "target": [
          {
            "code": "19146-0",
            "system": "http://loinc.org",
            "display": "Reference lab test results"
          },
          {
            "code": "19145-2",
            "system": "http://loinc.org",
            "display": "Reference lab test name"
          },
          {
            "code": "49541-6",
            "system": "http://loinc.org",
            "display": "Fasting status [Presence] - Reported"
          },
          {
            "code": "21612-7",
            "system": "http://loinc.org",
            "display": "Age - Reported"
          },
          {
            "code": "42216-2",
            "system": "http://loinc.org",
            "display": "Reference lab name [Identifier]"
          },
          {
            "code": "45353-0",
            "system": "http://loinc.org",
            "display": "Date of analysis of unspecified specimen"
          },
          {
            "code": "8251-1",
            "system": "http://loinc.org",
            "display": "Service comment"
          },
          {
            "code": "30525-0",
            "system": "http://loinc.org",
            "display": "Age"
          },
          {
            "code": "49581-2",
            "system": "http://loinc.org",
            "display": "Reference lab test identifier and name [Identifier]"
          },
          {
            "code": "21112-8",
            "system": "http://loinc.org",
            "display": "Birth date"
          }
        ]
      },
      {
        "code": "Micro",
        "type": "emr-defined",
        "target": [
          {
            "code": "6463-4",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Unspecified specimen by Culture"
          },
          {
            "code": "630-4",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Urine by Culture"
          },
          {
            "code": "600-7",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Blood by Culture"
          },
          {
            "code": "664-3",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Unspecified specimen by Gram stain"
          },
          {
            "code": "6462-6",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Wound by Culture"
          },
          {
            "code": "624-7",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Sputum by Respiratory culture"
          },
          {
            "code": "634-6",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Unspecified specimen by Aerobe culture"
          },
          {
            "code": "580-1",
            "system": "http://loinc.org",
            "display": "Fungus identified in Unspecified specimen by Culture"
          },
          {
            "code": "635-3",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Unspecified specimen by Anaerobe culture"
          },
          {
            "code": "547-0",
            "system": "http://loinc.org",
            "display": "Streptococcus.beta-hemolytic [Presence] in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "10352-3",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Genital specimen by Aerobe culture"
          },
          {
            "code": "543-9",
            "system": "http://loinc.org",
            "display": "Mycobacterium sp identified in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "584-3",
            "system": "http://loinc.org",
            "display": "Streptococcus agalactiae [Presence] in Vaginal fluid by Organism specific culture"
          },
          {
            "code": "625-4",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Stool by Culture"
          },
          {
            "code": "610-6",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Body fluid by Aerobe culture"
          },
          {
            "code": "546-2",
            "system": "http://loinc.org",
            "display": "Streptococcus.beta-hemolytic [Presence] in Throat by Organism specific culture"
          },
          {
            "code": "17898-8",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Throat by Aerobe culture"
          },
          {
            "code": "673-4",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Unspecified specimen by Ova & Parasite Preparation"
          },
          {
            "code": "606-4",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Cerebral spinal fluid by Culture"
          },
          {
            "code": "43371-4",
            "system": "http://loinc.org",
            "display": "Salmonella sp/Shigella sp identified in Stool by Organism specific culture"
          },
          {
            "code": "6331-3",
            "system": "http://loinc.org",
            "display": "Campylobacter sp identified in Stool by Organism specific culture"
          },
          {
            "code": "626-2",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Throat by Culture"
          },
          {
            "code": "6584-7",
            "system": "http://loinc.org",
            "display": "Virus identified in Unspecified specimen by Culture"
          },
          {
            "code": "5859-4",
            "system": "http://loinc.org",
            "display": "Herpes simplex virus identified in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "655-1",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Unspecified specimen by Acid fast stain.Kinyoun modified"
          },
          {
            "code": "17948-1",
            "system": "http://loinc.org",
            "display": "Fungus # 3 identified in Unspecified specimen by Culture"
          },
          {
            "code": "17947-3",
            "system": "http://loinc.org",
            "display": "Fungus # 2 identified in Unspecified specimen by Culture"
          },
          {
            "code": "17949-9",
            "system": "http://loinc.org",
            "display": "Fungus # 4 identified in Unspecified specimen by Culture"
          },
          {
            "code": "11545-1",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Unspecified specimen by Acid fast stain"
          },
          {
            "code": "6473-3",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Tissue by Trichrome stain"
          },
          {
            "code": "19128-8",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Catheter tip by Culture"
          },
          {
            "code": "9822-8",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Dialysis fluid by Culture"
          },
          {
            "code": "667-6",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Unspecified specimen by KOH preparation"
          },
          {
            "code": "681-7",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Unspecified specimen by Wright stain"
          },
          {
            "code": "21020-3",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Unspecified specimen by Anaerobe+Aerobe culture"
          },
          {
            "code": "6604-3",
            "system": "http://loinc.org",
            "display": "Influenza virus identified in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "20474-3",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Tissue by Biopsy culture"
          },
          {
            "code": "11261-5",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Vaginal fluid by Aerobe culture"
          },
          {
            "code": "10704-5",
            "system": "http://loinc.org",
            "display": "Ova and parasites identified in Stool by Light microscopy"
          },
          {
            "code": "19126-2",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Bone marrow by Aerobe culture"
          },
          {
            "code": "575-1",
            "system": "http://loinc.org",
            "display": "Fungus identified in Skin by Culture"
          },
          {
            "code": "601-5",
            "system": "http://loinc.org",
            "display": "Fungus identified in Blood by Culture"
          },
          {
            "code": "10353-1",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Nose by Aerobe culture"
          },
          {
            "code": "10728-4",
            "system": "http://loinc.org",
            "display": "Trichomonas sp identified in Genital specimen by Organism specific culture"
          },
          {
            "code": "560-3",
            "system": "http://loinc.org",
            "display": "Chlamydia sp identified in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "10355-6",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Bone marrow by Wright Giemsa stain"
          },
          {
            "code": "609-8",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Eye by Aerobe culture"
          },
          {
            "code": "698-1",
            "system": "http://loinc.org",
            "display": "Neisseria gonorrhoeae [Presence] in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "43441-5",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Bronchoalveolar lavage by Aerobe culture"
          },
          {
            "code": "17852-5",
            "system": "http://loinc.org",
            "display": "Ureaplasma urealyticum [Presence] in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "15388-2",
            "system": "http://loinc.org",
            "display": "Mycoplasma hominis [Presence] in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "6460-0",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Sputum by Culture"
          },
          {
            "code": "20431-3",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Unspecified specimen by Smear"
          },
          {
            "code": "611-4",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Body fluid by Culture"
          },
          {
            "code": "5838-8",
            "system": "http://loinc.org",
            "display": "Cytomegalovirus [Presence] in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "666-8",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Unspecified specimen by India ink prep"
          },
          {
            "code": "18482-0",
            "system": "http://loinc.org",
            "display": "Yeast [Presence] in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "533-0",
            "system": "http://loinc.org",
            "display": "Mycobacterium sp identified in Blood by Organism specific culture"
          },
          {
            "code": "10853-0",
            "system": "http://loinc.org",
            "display": "Isospora belli [Presence] in Unspecified specimen by Acid fast stain.Kinyoun modified"
          },
          {
            "code": "6349-5",
            "system": "http://loinc.org",
            "display": "Chlamydia trachomatis [Presence] in Unspecified specimen by Organism specific culture"
          }
        ]
      },
      {
        "code": "Antibacterial Susceptibility",
        "type": "emr-defined",
        "target": [
          {
            "code": "23658-8",
            "system": "http://loinc.org",
            "display": "Other Antibiotic [Susceptibility]"
          },
          {
            "code": "13317-3",
            "system": "http://loinc.org",
            "display": "Methicillin resistant Staphylococcus aureus [Presence] in Unspecified specimen by Organism specific culture"
          },
          {
            "code": "18998-5",
            "system": "http://loinc.org",
            "display": "Trimethoprim+Sulfamethoxazole [Susceptibility]"
          },
          {
            "code": "18928-2",
            "system": "http://loinc.org",
            "display": "Gentamicin [Susceptibility]"
          },
          {
            "code": "20629-2",
            "system": "http://loinc.org",
            "display": "Levofloxacin [Susceptibility]"
          },
          {
            "code": "18878-9",
            "system": "http://loinc.org",
            "display": "Cefazolin [Susceptibility]"
          },
          {
            "code": "18906-8",
            "system": "http://loinc.org",
            "display": "Ciprofloxacin [Susceptibility]"
          },
          {
            "code": "18865-6",
            "system": "http://loinc.org",
            "display": "Ampicillin+Sulbactam [Susceptibility]"
          },
          {
            "code": "18864-9",
            "system": "http://loinc.org",
            "display": "Ampicillin [Susceptibility]"
          },
          {
            "code": "18955-5",
            "system": "http://loinc.org",
            "display": "Nitrofurantoin [Susceptibility]"
          },
          {
            "code": "19000-9",
            "system": "http://loinc.org",
            "display": "Vancomycin [Susceptibility]"
          },
          {
            "code": "18893-8",
            "system": "http://loinc.org",
            "display": "Ceftazidime [Susceptibility]"
          },
          {
            "code": "18970-4",
            "system": "http://loinc.org",
            "display": "Piperacillin+Tazobactam [Susceptibility]"
          },
          {
            "code": "18932-4",
            "system": "http://loinc.org",
            "display": "Imipenem [Susceptibility]"
          },
          {
            "code": "18943-1",
            "system": "http://loinc.org",
            "display": "Meropenem [Susceptibility]"
          },
          {
            "code": "18879-7",
            "system": "http://loinc.org",
            "display": "Cefepime [Susceptibility]"
          },
          {
            "code": "18895-3",
            "system": "http://loinc.org",
            "display": "Ceftriaxone [Susceptibility]"
          },
          {
            "code": "18993-6",
            "system": "http://loinc.org",
            "display": "Tetracycline [Susceptibility]"
          },
          {
            "code": "18996-9",
            "system": "http://loinc.org",
            "display": "Tobramycin [Susceptibility]"
          },
          {
            "code": "18886-2",
            "system": "http://loinc.org",
            "display": "Cefotaxime [Susceptibility]"
          },
          {
            "code": "35492-8",
            "system": "http://loinc.org",
            "display": "Methicillin resistant Staphylococcus aureus (MRSA) DNA [Presence] by Probe & target amplification method"
          },
          {
            "code": "18969-6",
            "system": "http://loinc.org",
            "display": "Piperacillin [Susceptibility]"
          },
          {
            "code": "18860-7",
            "system": "http://loinc.org",
            "display": "Amikacin [Susceptibility]"
          },
          {
            "code": "18961-3",
            "system": "http://loinc.org",
            "display": "Oxacillin [Susceptibility]"
          },
          {
            "code": "18919-1",
            "system": "http://loinc.org",
            "display": "Erythromycin [Susceptibility]"
          },
          {
            "code": "18908-4",
            "system": "http://loinc.org",
            "display": "Clindamycin [Susceptibility]"
          },
          {
            "code": "18964-7",
            "system": "http://loinc.org",
            "display": "Penicillin [Susceptibility]"
          },
          {
            "code": "18868-0",
            "system": "http://loinc.org",
            "display": "Aztreonam [Susceptibility]"
          },
          {
            "code": "18887-0",
            "system": "http://loinc.org",
            "display": "Cefotetan [Susceptibility]"
          },
          {
            "code": "18862-3",
            "system": "http://loinc.org",
            "display": "Amoxicillin+Clavulanate [Susceptibility]"
          },
          {
            "code": "18965-4",
            "system": "http://loinc.org",
            "display": "Penicillin G [Susceptibility]"
          },
          {
            "code": "18974-6",
            "system": "http://loinc.org",
            "display": "Rifampin [Susceptibility]"
          },
          {
            "code": "51724-3",
            "system": "http://loinc.org",
            "display": "Cefuroxime [Susceptibility]"
          },
          {
            "code": "18929-0",
            "system": "http://loinc.org",
            "display": "Gentamicin.high potency [Susceptibility]"
          },
          {
            "code": "18983-7",
            "system": "http://loinc.org",
            "display": "Streptomycin.high potency [Susceptibility]"
          },
          {
            "code": "20460-2",
            "system": "http://loinc.org",
            "display": "Cefuroxime Oral [Susceptibility] by Minimum inhibitory concentration (MIC)"
          },
          {
            "code": "33332-8",
            "system": "http://loinc.org",
            "display": "Linezolid [Susceptibility] by Gradient strip"
          },
          {
            "code": "35789-7",
            "system": "http://loinc.org",
            "display": "Daptomycin [Susceptibility]"
          },
          {
            "code": "33333-6",
            "system": "http://loinc.org",
            "display": "Colistin [Susceptibility] by Gradient strip"
          },
          {
            "code": "42803-7",
            "system": "http://loinc.org",
            "display": "Bacteria identified in Isolate"
          },
          {
            "code": "7041-7",
            "system": "http://loinc.org",
            "display": "Penicillin G [Susceptibility] by Gradient strip"
          },
          {
            "code": "7042-5",
            "system": "http://loinc.org",
            "display": "Penicillin V [Susceptibility] by Gradient strip"
          },
          {
            "code": "31036-7",
            "system": "http://loinc.org",
            "display": "Gatifloxacin [Susceptibility] by Minimum inhibitory concentration (MIC)"
          },
          {
            "code": "6998-9",
            "system": "http://loinc.org",
            "display": "Ceftriaxone [Susceptibility] by Gradient strip"
          },
          {
            "code": "18903-5",
            "system": "http://loinc.org",
            "display": "Chloramphenicol [Susceptibility]"
          },
          {
            "code": "7059-9",
            "system": "http://loinc.org",
            "display": "Vancomycin [Susceptibility] by Gradient strip"
          }
        ]
      },
      {
        "code": "Antiviral susceptibility",
        "type": "emr-defined",
        "target": [
          {
            "code": "23641-4",
            "system": "http://loinc.org",
            "display": "Quinupristin+Dalfopristin [Susceptibility] by Minimum inhibitory concentration (MIC)"
          },
          {
            "code": "49573-9",
            "system": "http://loinc.org",
            "display": "HIV genotype [Susceptibility] in Isolate by Genotype method Narrative"
          },
          {
            "code": "33630-5",
            "system": "http://loinc.org",
            "display": "HIV protease gene mutations detected [Identifier] in Isolate"
          }
        ]
      },
      {
        "code": "Cytology",
        "type": "emr-defined",
        "target": [
          {
            "code": "47527-7",
            "system": "http://loinc.org",
            "display": "Cytology report of Cervical or vaginal smear or scraping Cyto stain.thin prep"
          },
          {
            "code": "19764-0",
            "system": "http://loinc.org",
            "display": "Statement of adequacy [interpretation] of Cervical or vaginal smear or scraping by Cyto stain"
          },
          {
            "code": "19767-3",
            "system": "http://loinc.org",
            "display": "Cytologist who read Cyto stain of Cervical or vaginal smear or scraping"
          },
          {
            "code": "19763-2",
            "system": "http://loinc.org",
            "display": "Specimen source [Identifier] in Cervical or vaginal smear or scraping by Cyto stain"
          },
          {
            "code": "19773-1",
            "system": "http://loinc.org",
            "display": "Recommended follow-up [Identifier] in Cervical or vaginal smear or scraping by Cyto stain"
          },
          {
            "code": "19769-9",
            "system": "http://loinc.org",
            "display": "Pathologist who read Cyto stain of Cervical or vaginal smear or scraping"
          },
          {
            "code": "27045-4",
            "system": "http://loinc.org",
            "display": "Microscopic exam [interpretation] of Urine by Cytology"
          },
          {
            "code": "10524-7",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Cervix by Cyto stain"
          },
          {
            "code": "49050-8",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Endocervical brush by Cyto stain"
          },
          {
            "code": "33716-2",
            "system": "http://loinc.org",
            "display": "Non-gynecological cytology method study"
          },
          {
            "code": "47528-5",
            "system": "http://loinc.org",
            "display": "Cytology report of Cervical or vaginal smear or scraping Cyto stain"
          },
          {
            "code": "8665-2",
            "system": "http://loinc.org",
            "display": "Date last menstrual period"
          },
          {
            "code": "33718-8",
            "system": "http://loinc.org",
            "display": "Cytology report of Tissue fine needle aspirate Cyto stain"
          },
          {
            "code": "19774-9",
            "system": "http://loinc.org",
            "display": "Cytology study comment Cervical or vaginal smear or scraping Cyto stain"
          },
          {
            "code": "18500-9",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Cervix by Cyto stain.thin prep"
          },
          {
            "code": "11070-0",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Urine by Cyto stain"
          },
          {
            "code": "10525-4",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Unspecified specimen by Cyto stain"
          },
          {
            "code": "19768-1",
            "system": "http://loinc.org",
            "display": "Reviewing cytologist who read Cyto stain of Cervical or vaginal smear or scraping"
          },
          {
            "code": "10526-2",
            "system": "http://loinc.org",
            "display": "Microscopic observation [Identifier] in Sputum by Cyto stain"
          }
        ]
      },
      {
        "code": "Pathology",
        "type": "emr-defined",
        "target": [
          {
            "code": "22637-3",
            "system": "http://loinc.org",
            "display": "Pathology report final diagnosis"
          },
          {
            "code": "22636-5",
            "system": "http://loinc.org",
            "display": "Pathology report relevant history"
          },
          {
            "code": "22638-1",
            "system": "http://loinc.org",
            "display": "Pathology report comments"
          },
          {
            "code": "22639-9",
            "system": "http://loinc.org",
            "display": "Pathology report supplemental reports"
          },
          {
            "code": "11529-5",
            "system": "http://loinc.org",
            "display": "Surgical pathology study"
          },
          {
            "code": "22634-0",
            "system": "http://loinc.org",
            "display": "Pathology report gross observation"
          },
          {
            "code": "22633-2",
            "system": "http://loinc.org",
            "display": "Pathology report site of origin"
          },
          {
            "code": "19139-5",
            "system": "http://loinc.org",
            "display": "Pathologist name"
          },
          {
            "code": "22635-7",
            "system": "http://loinc.org",
            "display": "Pathology report microscopic observation Other stain"
          },
          {
            "code": "21026-0",
            "system": "http://loinc.org",
            "display": "Pathologist interpretation of Blood tests"
          },
          {
            "code": "10459-6",
            "system": "http://loinc.org",
            "display": "Alpha-1-Fetoprotein Ag [Presence] in Tissue by Immune stain"
          },
          {
            "code": "34574-4",
            "system": "http://loinc.org",
            "display": "Pathology report final diagnosis"
          },
          {
            "code": "21024-5",
            "system": "http://loinc.org",
            "display": "Pathologist interpretation of Cerebral spinal fluid tests"
          },
          {
            "code": "33719-6",
            "system": "http://loinc.org",
            "display": "Flow cytometry study"
          },
          {
            "code": "33720-4",
            "system": "http://loinc.org",
            "display": "Blood bank consult"
          },
          {
            "code": "33721-2",
            "system": "http://loinc.org",
            "display": "Bone marrow Pathology biopsy report"
          },
          {
            "code": "48038-4",
            "system": "http://loinc.org",
            "display": "Pathologist interpretation of Synovial fluid tests"
          },
          {
            "code": "65757-7",
            "system": "http://loinc.org",
            "display": "Pathology biopsy report in Kidney Narrative"
          },
          {
            "code": "65752-8",
            "system": "http://loinc.org",
            "display": "Pathology biopsy report in Liver Narrative"
          },
          {
            "code": "65751-0",
            "system": "http://loinc.org",
            "display": "Pathology biopsy report in Muscle Narrative"
          },
          {
            "code": "65754-4",
            "system": "http://loinc.org",
            "display": "Pathology biopsy report in Skin Narrative"
          },
          {
            "code": "18743-5",
            "system": "http://loinc.org",
            "display": "Autopsy report"
          }
        ]
      },
      {
        "code": "Specimen",
        "type": "emr-defined",
        "target": [
          {
            "code": "31208-2",
            "system": "http://loinc.org",
            "display": "Specimen source [Identifier] of Unspecified specimen"
          },
          {
            "code": "49049-0",
            "system": "http://loinc.org",
            "display": "Collection time of Unspecified specimen"
          },
          {
            "code": "20506-2",
            "system": "http://loinc.org",
            "display": "Specimen drawn from"
          },
          {
            "code": "19803-6",
            "system": "http://loinc.org",
            "display": "Specimen site"
          }
        ]
      },
      {
        "code": "Body measurements",
        "type": "emr-defined",
        "target": [
          {
            "code": "8310-5",
            "system": "http://loinc.org",
            "display": "Body temperature"
          },
          {
            "code": "29463-7",
            "system": "http://loinc.org",
            "display": "Body weight"
          },
          {
            "code": "8338-6",
            "system": "http://loinc.org",
            "display": "Body weight Measured --ante partum"
          },
          {
            "code": "3141-9",
            "system": "http://loinc.org",
            "display": "Body weight Measured"
          },
          {
            "code": "8277-6",
            "system": "http://loinc.org",
            "display": "Body surface area"
          }
        ]
      },
      {
        "code": "Vent Settings",
        "type": "emr-defined",
        "target": [
          {
            "code": "19994-3",
            "system": "http://loinc.org",
            "display": "Oxygen/Inspired gas setting [Volume Fraction] Ventilator"
          },
          {
            "code": "20124-4",
            "system": "http://loinc.org",
            "display": "Ventilation mode [Identifier] Ventilator"
          },
          {
            "code": "19839-0",
            "system": "http://loinc.org",
            "display": "Breath rate spontaneous --on ventilator"
          },
          {
            "code": "19835-8",
            "system": "http://loinc.org",
            "display": "Breath rate setting Ventilator synchronized intermittent mandatory"
          },
          {
            "code": "20112-9",
            "system": "http://loinc.org",
            "display": "Tidal volume setting Ventilator"
          }
        ]
      },
      {
        "code": "Survey RFC",
        "type": "emr-defined",
        "target": [
          {
            "code": "46640-9",
            "system": "http://loinc.org",
            "display": "Secondary diagnosis RFC"
          }
        ]
      },
      {
        "code": "Ungrouped",
        "type": "emr-defined",
        "target": []
      }
    ]
  };
}

export function getTablePanelPortDefaultFilterData() {
  return {}
}
