<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>
    <table class="diagnosis-search-header">
      <tr>
        <td class="diagnosis-search-left">
          <mat-form-field class="lvs-input-wide" appearance="fill">
            <mat-label>Date Of Service</mat-label>
            <input matInput type="text" size="50" [(ngModel)]="dateOfService" appearance="outline">
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="diagnosis-search-left">
          <mat-form-field class="lvs-input-wide" appearance="fill">
            <mat-label>Text to be Searched</mat-label>
            <input matInput type="text" size="50" [(ngModel)]="testCode" appearance="outline">
          </mat-form-field>
        </td>
        <td class="diagnosis-search-right">
          <button mat-raised-button (click)="update()">Update</button>
        </td>
      </tr>
      <tr>
        <td class="diagnosis-search-left">
          <mat-label>Response code: {{statusCode}}</mat-label>
        </td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
