<mat-card>
  <mat-card-title>Patient Search</mat-card-title>
  <mat-card-content>
    Patient Search shows results of default patient search and allows retrieval of data for a patient
    for the panel view
    <div class="display-type">
      <mat-radio-group [(ngModel)]="displayMode" aria-label="Select an option">
        <mat-radio-button [value]="DisplayMode.PATIENT_SEARCH_JSON"
                          matTooltip="This shows the raw JSON response returned from the call to GET /lvs-rest/lvs-ui/legacy/patient-search/default-search endpoint">
          Patient Search JSON
        </mat-radio-button>
        <mat-radio-button [value]="DisplayMode.PANEL_VIEW_JSON"
                          matTooltip="This shows the raw JSON response returned from the call to GET /lvs-rest/lvs-ui/legacy/patient-search/panel-view?lpid=<lpid> endpoint">
          Panel View JSON
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="displayMode===DisplayMode.PATIENT_SEARCH_JSON">
    <div class="display-type-container">
      <div class="update-button">
        <button mat-raised-button (click)="getPatientData()"
                matTooltip="Make a new call to GET /lvs-rest/lvs-ui/legacy/patient-search/default-search using the above pagination options">
          Update
        </button>
      </div>
      <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
      <app-json-widget [readonly]="true" [data]="patientData"></app-json-widget>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="displayMode===DisplayMode.PANEL_VIEW_JSON">
    <mat-form-field class="lvs-input-wide" appearance="fill">
      <mat-label>Select a Patient</mat-label>
      <mat-select multiple [(ngModel)]="activePatients" appearance="outline">
        <mat-option *ngFor="let patientCur of patientData?.entry"
                    [value]="patientCur.resource">{{humanNameAsString(patientCur.resource)}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="display-type-container">
      <div class="update-button">
        <button mat-raised-button (click)="getPanelData()"
                matTooltip="Make a new call to POST /lvs-rest/lvs-ui/legacy/patient-search/panel-view endpoint">
          Update
        </button>
      </div>
      <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
      <app-json-widget [readonly]="true" [data]="panelData"></app-json-widget>
    </div>
  </mat-card-content>
  <mat-card-content>
    <mat-card-subtitle>Active Patient</mat-card-subtitle>
    <app-json-widget [readonly]="true" [data]="activePatient"></app-json-widget>
  </mat-card-content>

</mat-card>

