<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div>
      <button mat-raised-button (click)="getEndpointData()">Submit</button>
      <div *ngIf="data">
        <app-json-widget [readonly]="true" [data]="data" ></app-json-widget>
      </div>
    </div>
  </mat-card-content>
</mat-card>
