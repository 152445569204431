import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const jwt = this.authService.getJwt();

    if (jwt) {
      const cloned = req.clone({
        headers: req.headers
          .set('Authorization', 'Bearer ' + jwt)
          .set('Content-Type', 'application/json')
      });

      return next.handle(cloned);
    } else {
      const cloned = req.clone({
        headers: req.headers
          .delete('Authorization')
          .set('Content-Type', 'application/json')
      });
      return next.handle(cloned);
    }
  }
}
