<mat-card>
  <mat-card-title>{{ title }} {{mode}}</mat-card-title>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div *ngIf="mode==='list'">
      <button mat-raised-button (click)="mode='add-practitioner-tag'">Add/Update Practitioner Tag</button>
      <button mat-raised-button (click)="mode='delete-practitioner-tag'">Delete Practitioner Tag</button>
      <app-copy-to-clipboard [data]="data">Copy to clipboard</app-copy-to-clipboard>
      <div>
        <pre>{{data | json}}</pre>
      </div>
    </div>
    <div *ngIf="mode==='add-practitioner-tag'">
      <div>
        <div>Source System: <input type="text" size="50" [(ngModel)]="sourceSystem"></div>
        <div>Resource Type: <input type="text" size="50" [(ngModel)]="resourceType"></div>
        <div>Resource Id: <input type="text" size="50" [(ngModel)]="resourceId"></div>
        <div>Name: <input type="text" size="50" [(ngModel)]="name"></div>
        <div>Value: <input type="text" size="50" [(ngModel)]="value"></div>

        <button mat-raised-button (click)="upsertPractitionerTag()">Add/Update</button>
        <button mat-raised-button (click)="mode ='list'">Cancel</button>
      </div>
    </div>
    <div *ngIf="mode==='delete-practitioner-tag'">
      <div>
        <div>Source System: <input type="text" size="50" [(ngModel)]="sourceSystem"></div>
        <div>Resource Type: <input type="text" size="50" [(ngModel)]="resourceType"></div>
        <div>Resource Id: <input type="text" size="50" [(ngModel)]="resourceId"></div>
        <div>Name: <input type="text" size="50" [(ngModel)]="name"></div>
        <button mat-raised-button (click)="deletePractitionerTag()">Delete</button>
        <button mat-raised-button (click)="mode ='list'">Cancel</button>
      </div>
    </div>

  </mat-card-content>
</mat-card>
