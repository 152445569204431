import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-clinical-reasoning-dashboard',
  templateUrl: './clinical-reasoning-dashboard.component.html',
  styleUrls: ['./clinical-reasoning-dashboard.component.scss']
})
export class ClinicalReasoningDashboardComponent implements OnInit {
  data: any = null;
  title: string;
  endpoint: string;
  timeoutMillis = '20000';
  loading = true;

  constructor(private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private routerOutlet: RouterOutlet) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
  }

  ngOnInit() {
    this.getEndpointData();
  }

  update() {
    this.getEndpointData();
  }

  getEndpointData() {
    this.data = null;
    const endpoint = `${this.endpoint}?timeoutMillis=${this.timeoutMillis}`;
    this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
      this.data = data || {};
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
  }

}

