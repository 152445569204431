<mat-card>
  <mat-card-title>{{ title }} {{mode}}</mat-card-title>

  <mat-card-content>
    <mat-form-field class="lvs-input-wide" appearance="fill">
      <mat-label>Select a Resource</mat-label>
      <mat-select [(ngModel)]="resourceAvailable" appearance="outline" (selectionChange)="update(resourceAvailable)">
        <mat-option *ngFor="let item of resourcesAvailable" [value]="item">{{item}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <mat-form-field class="lvs-input-wide" appearance="fill">
        <mat-label>Query Parameters</mat-label>
        <input matInput type="text" size="50" [(ngModel)]="queryparam" appearance="outline">
      </mat-form-field>
      <br/>
      <button mat-raised-button (click)="getResource()">Submit</button>
      <div *ngIf="data">
        <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
      </div>
    </div>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
  </mat-card-content>
</mat-card>
