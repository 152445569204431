import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-read-resources',
  templateUrl: './read-resources.component.html',
  styleUrls: ['./read-resources.component.scss'],
})
export class ReadResourcesComponent implements OnInit {
  data: any = null;
  title: string;
  endpoint: string;
  loading = true;
  mode = 'list';

  requestedReferences = JSON.stringify([
    {
      processor: 'MergeDROService',
      sourceSystem: 'Demo EMR',
      resourceType: 'DiagnosticReport',
      id: '7af2858b-6838-40b0-a7c3-4fefe7f1e560'
    },
    {
      processor: 'Default',
      sourceSystem: 'Demo EMR',
      resourceType: 'DiagnosticReport',
      id: '7af2858b-6838-40b0-a7c3-4fefe7f1e560'
    },
    {
      processor: 'MergeDROService',
      sourceSystem: 'Labcorp',
      resourceType: 'DiagnosticReport',
      id: '03006908800.2019.LCLS.32422995.2019-01-30.322000'
    },
    {
      processor: 'Default',
      sourceSystem: 'Labcorp',
      resourceType: 'DiagnosticReport',
      id: '03006908800.2019.LCLS.32422995.2019-01-30.322000'
    },
    {
      processor: 'MergeDROService',
      sourceSystem: 'Labcorp',
      resourceType: 'Observation',
      id: '07106908550.2018.LCLS.32422995.2018-03-12.322000.1'
    },
    {
      processor: 'MergeDROService',
      sourceSystem: 'Demo EMR',
      resourceType: 'Observation',
      id: '96852'
    },
    {
      processor: 'Default',
      sourceSystem: 'Demo EMR',
      resourceType: 'Observation',
      id: '96852'
    },
    {
      processor: 'Default',
      resourceType: 'Encounter',
      id: '92166',
      sourceSystem: 'Demo EMR'
    },
  ], null, 2);

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }

  ngOnInit() {
    this.getEndpointData();
  }

  getEndpointData() {
    this.data = null;
    this.loading = false;
  }

  getResource(): void {
    this.data = null;
    this.loading = true;
    // get rid of prettyprints which are illegal in header string
    const requestedReferences = JSON.stringify(JSON.parse(this.requestedReferences));
    const headers = new HttpHeaders()
      .set('Requested-References', requestedReferences);

    this.dataService.getEndpointData(this.endpoint, null, headers).subscribe((data: {}) => {
      console.log('success', data);
      this.data = data;
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
    this.mode = 'list';
  }
}
