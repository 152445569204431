  <mat-card-title>Unable to login</mat-card-title>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div>
      <div>Something went wrong</div>
      <div>nonce :  {{nonce | json}}</div>
      <div>base64Config :  {{base64Config | json}}</div>
      <div>elvisRestAPI :  {{elvisRestAPI | json}}</div>
      <div>lvsAuthInfo :  {{lvsAuthInfo | json}}</div>
      <div>error :  {{error | json}}</div>
    </div>
  </mat-card-content>

