import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";

@Component({
  selector: 'app-orders-search',
  templateUrl: './orders-search.component.html',
  styleUrls: ['./orders-search.component.scss'],
})
export class OrdersSearchComponent implements OnInit {
  data: any = null;
  title: string;
  endpoint: string;
  loading = false;
  statusCode?: number;

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
  }

  ngOnInit(): void {
    // do nothing
  }

  update() {
    this.getEndpointData();
  }

  getEndpointData() {
    this.data = null;
    try {
      this.dataService.getEndpointData(this.endpoint).subscribe((data: {}) => {
        this.data = data || {};
        this.statusCode = 200;
        this.loading = false;
      }, (error) => {
        this.data = (error && error.error) || error || {};
        this.statusCode = (error && error.status) || undefined;
        this.loading = false;
      });
    } catch (error) {
      this.data = (error && error.message) || error || {};
    }
  }
}
