<div *ngIf="data">
  <table class="json-widget-header">
    <tr>
      <td class="json-widget-header-left">
        <mat-radio-group [(ngModel)]="mode" (change)="onModeChange()" aria-label="Select an option">
          <mat-radio-button value="editor">{{ getEditorLabel() }}&nbsp;</mat-radio-button>
          <mat-radio-button value="raw">Raw Text&nbsp;</mat-radio-button>
        </mat-radio-group>
        <button *ngIf="saveFn" (click)="onSave()">Save Changes</button>
      </td>
      <td class="json-widget-header-right">
        <app-copy-to-clipboard [data]="data">Copy to clipboard</app-copy-to-clipboard>
      </td>
    </tr>
  </table>
  <div *ngIf="mode==='editor'">
    <json-editor #testName [data]="data" [options]="jsonEditorOptions">Hello</json-editor>
  </div>
  <div *ngIf="mode==='raw'">
    <pre>{{data | json}}</pre>
  </div>
</div>
