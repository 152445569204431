<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div>
      <app-copy-to-clipboard [data]="data">Copy to clipboard</app-copy-to-clipboard>
      <table>
        <tr>
          <th>Error</th>
          <th>Ids</th>
        </tr>
        <tr *ngFor="let assessmentResult of data">
          <td>{{assessmentResult.error}}</td>
          <td>{{assessmentResult.ids}}</td>
        </tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
