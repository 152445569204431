import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './components/root/app.component';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from '@angular/cdk/layout';
// import {
//   MatToolbarModule,
//   MatFormFieldModule,
//   MatButtonModule,
//   MatMenuModule,
//   MatProgressSpinnerModule,
//   MatSidenavModule,
//   MatIconModule,
//   MatListModule,
//   MatCardModule, MatInputModule, MatSlideToggleModule, MatCheckboxModule
// } from '@angular/material';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {HomeComponent} from './components/home/home.component';
import {JwtDetailsComponent} from './components/jwt-details/jwt-details.component';
import {JwtModule} from '@auth0/angular-jwt';
import {httpInterceptorProviders} from './http-interceptors';
import {ShowEndpointComponent} from './components/show-endpoint/show-endpoint.component';
import {ShowPdfComponent} from './components/show-pdf/show-pdf.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CodeGroupsComponent} from './components/code-groups/code-groups.component';
import {SettingsComponent} from './components/settings/settings.component';
import {ShowCachedDataComponent} from './components/show-cached-data/show-cached-data.component';
import {CacheManagerComponent} from './components/cache-manager/cache-manager.component';
import {ShowPagedDataComponent} from './components/show-paged-data/show-paged-data.component';
import {PractitionerTagsComponent} from './components/practitioner-tags/practitioner-tags.component';
import {ClinicalReasoningByContextComponent} from './components/clinical-reasoning-by-context/clinical-reasoning-by-context.component';
import {ClinicalReasoningByPlanDefinitionComponent} from './components/clinical-reasoning-by-plan-definition/clinical-reasoning-by-plan-definition.component';
import {ReadResourcesComponent} from './components/read-resources/read-resources.component';
import {ReadResourceComponent} from './components/read-resource/read-resource.component';
import {CopyToClipboardComponent} from './components/copy-to-clipboard/copy-to-clipboard.component';
import {StatisticsComponent} from './components/statistics/statistics.component';
import {LvsAuthComponent} from "./components/lvs-auth/lvs-auth.component";
import {EapResultsComponent} from "./components/eap-results/eap-results.component";
import {ErsDirectComponent} from "./components/ers-direct/ers-direct.component";
import { CtraGuidanceComponent } from './components/ctra-guidance/ctra-guidance.component';
import {AssessmentResultsComponent} from "./components/assessment-results/assessment-results.component";
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {NgJsonEditorModule} from "ang-jsoneditor";
import {JsonWidgetComponent} from "./components/json-widget/json-widget.component";
import {MatRadioModule} from "@angular/material/radio";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatIconModule} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatOptionModule} from "@angular/material/core";
import {BaseSubscriber} from "../utils/base-subscriber";
import {EventHooksComponent} from "./components/event-hooks/event-hooks.component";
import { CtraPractitionerRoleComponent } from './components/ctra-practitioner-role/ctra-practitioner-role.component';
import { CtraResourcePathComponent } from './components/ctra-resource-path/ctra-resource-path.component';
import { CtraResourceQueryComponent } from './components/ctra-resource-query/ctra-resource-query.component';
import {TemplateCommentDirective} from "./components/template-comment/template-comment.directive";
import {LvsUiPanelViewComponent} from "./components/lvs-ui-panel-view/lvs-ui-panel-view.component";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {LvsUiClinicalViewComponent} from "./components/lvs-ui-clinical-view/lvs-ui-clinical-view.component";
import {CodePopUpComponent} from "./components/code-popup/code-pop-up.component";
import {MatDialogModule} from "@angular/material/dialog";
import {JsonWidgetPopupComponent} from "./components/json-widget-popup/json-widget-popup.component";
import {JwtChangePopupComponent} from "./components/jwt-change-popup/jwt-change-popup.component";
import {BookmarkComponent} from "./components/boomark/bookmark.component";
import {LvsUiPanelViewPortComponent} from "./components/lvs-ui/legacy/lvs-ui-panel-view-port/lvs-ui-panel-view-port.component";
import {APP_BASE_HREF, LocationStrategy, PathLocationStrategy} from "@angular/common";
import {
  LvsUiChemistryPanelPortComponent
} from "./components/lvs-ui/legacy/lvs-ui-clinical-view-port/lvs-ui-chemistry-panel-port.component";
import {
  LvsUiTablePanelPortComponent
} from "./components/lvs-ui/legacy/lvs-ui-table-panel-port/lvs-ui-table-panel-port.component";
import {PatientSearchComponent} from "./components/patient-search/patient-search.component";
import {
  LvsUiNoStreamPanelViewProgressComponent
} from "./components/lvs-ui-no-stream-panel-view-progress/lvs-ui-no-stream-panel-view-progress.component";
import {
  LvsUiResultTypeProgressComponent
} from "./components/lvs-ui-result-type-progress/lvs-ui-result-type-progress.component";
import { OncologyDataComponent } from './components/oncology-data/oncology-data.component';
import { ClinicalReasoningByPlanDefinitionNamedQueriesComponent } from './components/clinical-reasoning-by-plan-definition-named-queries/clinical-reasoning-by-plan-definition-named-queries.component';
import { ClinicalReasoningDashboardComponent } from './components/clinical-reasoning-dashboard/clinical-reasoning-dashboard.component';
import { SearchPlanDefinitionByCodingComponent } from './components/search-plan-definition-by-coding/search-plan-definition-by-coding.component';
import { AddonsCreateComponent } from "./components/add-on-testing/addons-create/addons-create.component";
import { AccessionValidateComponent } from "./components/add-on-testing/accession-validate/accession-validate.component";
import { DiagnosisSearchComponent } from "./components/add-on-testing/diagnosis-search/diagnosis-search.component";
import { DiagnosisValidateComponent } from "./components/add-on-testing/diagnosis-validate/diagnosis-validate.component";
import { PicklistComponent } from "./components/add-on-testing/picklist/picklist.component";
import { SaveLinkUserComponent } from "./components/add-on-testing/save-link-user/save-link-user.component";
import { ShowAddOnComponent } from "./components/add-on-testing/show-add-on/show-add-on.component";
import { TestMenuDetailComponent } from "./components/add-on-testing/test-menu-detail/test-menu-detail.component";
import { TestMenuSearchComponent } from "./components/add-on-testing/test-menu-search/test-menu-search.component";
import { TestsNotAllowedComponent } from "./components/add-on-testing/tests-not-allowed/tests-not-allowed.component";
import { UserAccountsComponent } from "./components/add-on-testing/user-accounts/user-accounts.component";
import { OrdersPanelViewComponent } from "./components/order-tracking/orders-panel-view/orders-panel-view.component";
import { OrdersSearchComponent } from "./components/order-tracking/orders-search/orders-search.component";
import { OrderDetailComponent } from "./components/order-tracking/order-detail/order-detail.component";

// ...
export function tokenGetter() {
  return localStorage.getItem('access_token');
}

console.log("window['base-href']", window['base-href']);

@NgModule({
  declarations: [
    BaseSubscriber,
    AppComponent,
    AssessmentResultsComponent,
    BookmarkComponent,
    CacheManagerComponent,
    ClinicalReasoningByContextComponent,
    ClinicalReasoningByPlanDefinitionComponent,
    CodeGroupsComponent,
    CodePopUpComponent,
    EapResultsComponent,
    ErsDirectComponent,
    EventHooksComponent,
    HomeComponent,
    JsonWidgetComponent,
    JsonWidgetPopupComponent,
    JwtChangePopupComponent,
    JwtDetailsComponent,
    LvsUiClinicalViewComponent,
    LvsUiChemistryPanelPortComponent,
    LvsUiPanelViewComponent,
    LvsUiPanelViewPortComponent,
    LvsUiTablePanelPortComponent,
    LvsAuthComponent,
    PageNotFoundComponent,
    PatientSearchComponent,
    PractitionerTagsComponent,
    ReadResourceComponent,
    ReadResourcesComponent,
    SettingsComponent,
    ShowEndpointComponent,
    ShowCachedDataComponent,
    LvsUiNoStreamPanelViewProgressComponent,
    LvsUiResultTypeProgressComponent,
    ShowPagedDataComponent,
    ShowPdfComponent,
    StatisticsComponent,
    TemplateCommentDirective,
    CopyToClipboardComponent,
    CtraGuidanceComponent,
    CtraPractitionerRoleComponent,
    CtraResourcePathComponent,
    CtraResourceQueryComponent,
    OncologyDataComponent,
    SearchPlanDefinitionByCodingComponent,
    ClinicalReasoningDashboardComponent,
    ClinicalReasoningByPlanDefinitionNamedQueriesComponent,
    AccessionValidateComponent,
    AddonsCreateComponent,
    DiagnosisSearchComponent,
    DiagnosisValidateComponent,
    PicklistComponent,
    SaveLinkUserComponent,
    ShowAddOnComponent,
    TestMenuDetailComponent,
    TestMenuSearchComponent,
    TestsNotAllowedComponent,
    UserAccountsComponent,
    OrderDetailComponent,
    OrdersPanelViewComponent,
    OrdersSearchComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        // whitelistedDomains: ['localhost:8080'],
        // blacklistedRoutes: []
      }
    }),
    LayoutModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTooltipModule,
    NgJsonEditorModule,
    NgxJsonViewerModule,
    MatSelectModule,
    MatOptionModule,
    MatTableModule
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: APP_BASE_HREF,
      useValue: window['base-href']
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
