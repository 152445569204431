<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div *ngIf="mode==='list'">
      <mat-card-subtitle>Code Group Order</mat-card-subtitle>
      <p>Below is the Code Group Order for this Practitioner</p>
      <button mat-raised-button (click)="mode='reorder-list'">Re-order List</button>
      <button mat-raised-button (click)="showCodeGroup(getRandomId())">Retrieve Non Existant</button>
      <mat-divider></mat-divider>
      <app-copy-to-clipboard [data]="data">Copy to clipboard</app-copy-to-clipboard>
      <div class="clickable" *ngFor="let codeGroup of data"
           (click)="showCodeGroup(codeGroup.code)">{{codeGroup.code}}  ({{codeGroup.type}})
      </div>
    </div>
    <div *ngIf="mode==='reorder-list'">
      <mat-card-subtitle>Change Code Group Order</mat-card-subtitle>
      <p>Change Code Group Order for this Practitioner</p>
      <button mat-raised-button (click)="showList()">Show List</button>
      <app-copy-to-clipboard [data]="groupOrderListString">Copy to clipboard</app-copy-to-clipboard>
      <div>
        <textarea class="active-code-group-string" [(ngModel)]="groupOrderListString"></textarea>
      </div>

      <div>
        <button mat-raised-button (click)="saveGroupOrderList()">Save</button>
        <button mat-raised-button (click)="deleteGroupOrderList()">Delete</button>
        <button mat-raised-button (click)="mode ='list'">Cancel</button>
      </div>
    </div>
    <div *ngIf="mode==='displayActive'">
      <button mat-raised-button (click)="showList()">Show List</button>
      <button mat-raised-button (click)="editActiveCodeGroup()">Edit</button>
      <button mat-raised-button (click)="deleteActiveCodeGroup()">Delete</button>
      <button mat-raised-button (click)="deleteNonExistantCodeGroup()">Delete Non Existant</button>
      <app-copy-to-clipboard [data]="activeCodeGroup">Copy to clipboard</app-copy-to-clipboard>
      <pre>{{activeCodeGroup | json}}</pre>
    </div>
    <div *ngIf="mode==='editActive'">
      <button mat-raised-button (click)="showList()">Show List</button>
      <div *ngIf="activeCodeGroup.type==='emr-defined'">
        <mat-error>{{activeCodeGroup.code}} is an emr defined group.</mat-error>
      </div>
      <div>
        <textarea class="active-code-group-string" [(ngModel)]="activeCodeGroupString"></textarea>
      </div>
      <div>
        <button mat-raised-button (click)="saveActiveCodeGroupString()">Save</button>
        <button mat-raised-button (click)="mode ='displayActive'">Cancel</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
