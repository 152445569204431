<mat-card *ngIf="mode==='list'">
  <mat-card-title>Manual</mat-card-title>

  <mat-card-content>
    <div>content-disposition: <select [(ngModel)]="contentDisposition">
      <option value="inline">inline</option>
      <option value="attachment">attachment</option>
    </select>
    </div>
    <div>diagnosticReportId: <input type="text" size="100" [(ngModel)]="diagnosticReportId"></div>
    <table>
      <tr>
        <td><a [href]="elvisRESTAPI + getPdfUrl(diagnosticReportId)"
               (click)="openPdf($event, diagnosticReportId)">{{diagnosticReportId}}</a></td>
        <td><a (click)="renderPdf(diagnosticReportId)">[JSON]</a></td>
        <td></td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="mode==='list'">
  <mat-card-title>LabCorp PDFs for current Patient</mat-card-title>

  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div><app-copy-to-clipboard [data]="tableData">Copy to clipboard</app-copy-to-clipboard></div>
    <table>
      <tr *ngFor="let resource of tableData">
        <td><a [href]="elvisRESTAPI + getPdfUrl(resource.id)"
               (click)="openPdf($event, resource.id)">{{resource.id}}</a></td>
        <td><a (click)="renderPdf(resource.id)">[JSON]</a></td>
        <td>{{resource.code.text}}</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="mode==='view'">
  <mat-card-content>
    <div><a (click)="mode = 'list'">Back to List</a></div>
    <div><app-copy-to-clipboard [data]="pdfData">Copy to clipboard</app-copy-to-clipboard></div>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div>
      <pre>{{ pdfData| json}}</pre>
    </div>
  </mat-card-content>

</mat-card>
