<mat-card>
  <mat-card-title>Show all GuidanceResponses by useContext</mat-card-title>
  <mat-card-content>
    <div>
      <mat-form-field class="lvs-input-wide" appearance="fill">
        <mat-label>System</mat-label>
        <input matInput type="text" size="50" [(ngModel)]="system" appearance="outline">
      </mat-form-field>
      <br/>
      <mat-form-field class="lvs-input-wide" appearance="fill">
        <mat-label>Code</mat-label>
        <input matInput type="text" size="50" [(ngModel)]="code" appearance="outline">
      </mat-form-field>
      <br/>
      <mat-form-field class="lvs-input-wide" appearance="fill">
        <mat-label>Timeout (millis)</mat-label>
        <input matInput type="number" size="50" [(ngModel)]="timeoutMillis" appearance="outline">
      </mat-form-field>
      <br/>
      <button mat-raised-button (click)="update()">Update</button>
    </div>
  </mat-card-content>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div>
      <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
    </div>
  </mat-card-content>
</mat-card>
