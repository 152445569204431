import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Resource} from "fhir/r4";
import {PractitionerTagsService} from "../../services/practitionerTags.service";

@Component({
  selector: 'app-bookmark',
  templateUrl: './bookmark.html',
  styleUrls: ['./bookmark.scss'],
})
export class BookmarkComponent {
  @Input()
  resource: Resource;

  @Input()
  readOnly: boolean = false;

  @Output()
  onSuccess: EventEmitter<Resource> = new EventEmitter();

  @Output()
  onFail: EventEmitter<any> = new EventEmitter();

  constructor(private practitionerTagsService: PractitionerTagsService) {
  }

  isBookmarked(resource: Resource): boolean {
    return this.practitionerTagsService.isBookmarked(resource);
  }

  toggleBookmark(resource: Resource) {
    if (!this.readOnly) {
      this.practitionerTagsService.toggleBookmarked(
        resource,
        this.onSuccess,
        this.onFail
      );
    }
  }
}


