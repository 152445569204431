import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-clinical-reasoning-by-context',
    templateUrl: './clinical-reasoning-by-context.component.html',
    styleUrls: ['./clinical-reasoning-by-context.component.scss'],
})
export class ClinicalReasoningByContextComponent implements OnInit {
    data: any = null;
    title: string;
    endpoint: string;
    code = 'CDSREV';
    system = 'http://terminology.hl7.org/CodeSystem/v3-ActCode';
    timeoutMillis = '20000';
    loading = true;

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private routerOutlet: RouterOutlet) {
        this.title = routerOutlet.activatedRouteData['title'];
        this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    }

    ngOnInit() {
        this.getEndpointData();
    }

    update() {
        this.getEndpointData();
    }

    getEndpointData() {
        this.data = null;
        const endpoint = `${this.endpoint}?system=${encodeURIComponent(this.system)}&code=${this.code}&timeoutMillis=${this.timeoutMillis}`;
        this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
            this.data = data || {};
            this.loading = false;
        }, (error) => {
            this.data = (error && error.error) || error || {};
            this.loading = false;
        });
    }

}
