<mat-card>
  <mat-card-title>Change lvs-rest JWT</mat-card-title>
  <mat-card-content>
    <div>
      <textarea rows="5" cols="80" (ngModelChange)="onLvsRestJwtChange($event)" [ngModel]="lvsRestJwt"></textarea>
    </div>
    <div>{{jwtChangeStatus}}</div>
    <button mat-raised-button (click)="save()" [disabled]="saveDisabled">Change JWT</button>
    <div *ngIf="decodedJwt">
      <app-json-widget [readonly]="true" [data]="decodedJwt"></app-json-widget>
    </div>
  </mat-card-content>
</mat-card>
