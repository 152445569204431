<mat-card>
  <mat-card-content>
    <mat-radio-group [(ngModel)]="selectionMode" aria-label="Select an option">
      <mat-radio-button [value]="SelectionMode.BY_USE_CONTEXT"> Search for Plan Definitions by Use Context</mat-radio-button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<mat-radio-button [value]="SelectionMode.MANUAL"> Enter Plan Definition Manually</mat-radio-button>
    </mat-radio-group>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="selectionMode===SelectionMode.BY_USE_CONTEXT">
  <mat-card-title>Search For Plan Definitions By useContext</mat-card-title>
  <mat-card-content>
    <div>Leave System and Code Fields Empty to be able to retrieve all public Plan Definitions, regardless of UseContext</div>
    <div>
      <mat-form-field class="lvs-input-wide" appearance="fill">
        <mat-label>System</mat-label>
        <input matInput type="text" size="50" [(ngModel)]="system" appearance="outline">
      </mat-form-field>
      <mat-form-field class="lvs-input-wide" appearance="fill">
        <mat-label>Code</mat-label>
        <input matInput type="text" size="50" [(ngModel)]="code" appearance="outline">
      </mat-form-field>
      <br/>
      <button mat-raised-button (click)="getPlanDefinitions()">
        <mat-icon>search</mat-icon>
        Get Plan Definitions
      </button>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="selectionMode===SelectionMode.BY_USE_CONTEXT">
  <app-search-plan-definition-by-coding [planDefinitions]="planDefinitions"></app-search-plan-definition-by-coding>
</div>


<mat-card *ngIf="selectionMode===SelectionMode.MANUAL">
  <mat-card-title>Enter Plan Definition ID directly</mat-card-title>
  <mat-card-content>

    <mat-form-field class="lvs-input-wide" appearance="fill">
      <mat-label>Enter Plan Definition ID</mat-label>
      <input matInput type="text" [(ngModel)]="planDefinitionIdManual" appearance="outline">
    </mat-form-field>
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-raised-button [disabled]="isButtonDisabled()" (click)="updateGuidanceResponse()">Show GuidanceResponse</button>
    &nbsp;&nbsp;&nbsp;&nbsp;<button mat-raised-button [disabled]="isButtonDisabled()" (click)="updateInputs()">Show Inputs</button>
    &nbsp;&nbsp;&nbsp;&nbsp;<button mat-raised-button [disabled]="isButtonDisabled()" (click)="updateDataRequirements()">Show DataRequirements
    </button>
  </mat-card-content>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
