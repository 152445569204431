<div>
  <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
  <mat-card>
    <mat-card-title>Cache Manager</mat-card-title>
    <mat-card-content>
      Use this page to clear cache. This is usually done for testing purposes.
    </mat-card-content>
  </mat-card>


  <mat-card>
    <mat-card-title>Patient Cache</mat-card-title>
    <mat-card-content>
      <div>
        Patient Cache contains data stored at Patient scope. This includes data from EMR, ERS patient data and practitioner-patient settings.
        <div><button mat-raised-button (click)="clearPatientCache()">Clear Patient Cache</button></div>
      </div>
    </mat-card-content>
  </mat-card>


  <mat-card>
    <mat-card-title>Practitioner Cache</mat-card-title>
    <mat-card-content>
      <div>
        Clear Patient Cache clears data stored at Patient scope. This includes cached patient data and practitioner settings.
        <div><button mat-raised-button (click)="clearPractitionerCache()">Clear Practitioner Cache</button></div>
      </div>
    </mat-card-content>
  </mat-card>


  <mat-card>
    <mat-card-title>Application Cache</mat-card-title>
    <mat-card-content>
      <div>
        Clear Application Cache clears data stored at Application scope.
        <div><button mat-raised-button (click)="clearApplicationCache()">Clear Application Cache</button></div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>LvsRest Cache</mat-card-title>
    <mat-card-content>
      <div>
        Clear LvsRest Cache clears data stored at LvsRest scope. This includes cds-api plan definitions, and other artifacts that exceed application scope.
        <div><button mat-raised-button (click)="clearLvsRestCache()">Clear LvsRest Cache</button></div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <div>{{data}}</div>
    </mat-card-content>
  </mat-card>
</div>
