import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";

@Component({
    selector: 'app-save-link-user',
    templateUrl: './save-link-user.component.html',
    styleUrls: ['./save-link-user.component.scss'],
})
export class SaveLinkUserComponent implements OnInit {
    data: any = null;
    title: string;
    endpoint: string;
    loading = false;
    accessToken: string = "eyJraWQiOiItcWp4SUFlNUsyWDItT1ZmaTVXcm02VXVHdnJrNG1ZSHpqZWZxUndyT1JnIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULjh4bkYwcnplbms0Ym5mN0tVaTVBVlltQnU1dHJkYUpxZmJnMVZNbkxmTlUub2FyMmNpMmw4ZzlmTzN2VTcwaDciLCJpc3MiOiJodHRwczovL2xvZ2luLWRldi5sYWJjb3JwbGluay5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiJhcGk6Ly9kZWZhdWx0IiwiaWF0IjoxNjk4MTUyNDI5LCJleHAiOjE2OTgxOTU2MjksImNpZCI6IjBvYTF0cXN5eHJ3MnR2OFFrMGg4IiwidWlkIjoiMDB1MXV4YTdnenZMR1pvT3EwaDgiLCJzY3AiOlsib2ZmbGluZV9hY2Nlc3MiXSwiYXV0aF90aW1lIjoxNjk4MTUyNDEwLCJzdWIiOiJkZW5pc2VfY2xpbmljYWwxQHlvcG1haWwuY29tIn0.byWZT9XOPzcAqSExG597eqd-so5bJ9C5XoUJsGq7lmhHMLqgl5Sm_YMXG0jdQ0L9xQ15XpMzagkdVnVumzZUFz0VSxcZnWknLu3PqCmmE9dLKw1aC0nI5m5lUFU2toNgyi6azBijluR6NUdxqfUB1dHMzoRq5KJ-R-uVDY9pLw4Hcvz4RUceCjwF6Z3S4IUx6Xu6yTXJ0lS2fkMfxf8tUs9VhjrjgC5cHHaPfHJKx19PyM6lzXxLWkgcmtdOviIGgK77LlSuHIcmZYU1-tg-PKp0B5QNoCSW-x_RnmpK6fjrNQ5NMI4iP6W5ykkzEHWWrXj9kdUQLbvkEoEqTvJ2lQ";
    statusCode?: number;

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private routerOutlet: RouterOutlet) {
        this.title = routerOutlet.activatedRouteData['title'];
        this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    }

    ngOnInit(): void {
      // do nothing
    }

    update() {
        this.postEndpointData();
    }

    postEndpointData() {
        this.data = null;
        const endpoint = `${this.endpoint}?accessToken=${this.accessToken}`;
        try {
          this.dataService.postEndpointData(endpoint, null).subscribe((data: {}) => {
              this.data = data || {};
              this.statusCode = 200;
              this.loading = false;
          }, (error) => {
              this.data = (error && error.error) || error || {};
              this.statusCode = (error && error.status) || undefined;
              this.loading = false;
          });
        } catch (error) {
          this.data = (error && error.message) || error || {};
        }
    }
}
