<mat-card>
  <mat-card-title>{{ title }} {{mode}}</mat-card-title>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div>
      <div>
        <textarea class="active-code-group-string" [(ngModel)]="requestedReferences"></textarea>
      </div>
      <button mat-raised-button (click)="getResource()">Submit</button>
      <div *ngIf="data">
        <app-json-widget [readonly]="true" [data]="data" ></app-json-widget>
      </div>
    </div>
  </mat-card-content>
</mat-card>
