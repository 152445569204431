import packageInfo from '../../../../package.json';
import {Component} from '@angular/core';
import buildInfo from '../../../assets/build-info.json';
import {DataService} from '../../services/data.service';
import {JsonWidgetPopupComponent} from "../json-widget-popup/json-widget-popup.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  version = packageInfo.version;
  buildInfo = buildInfo;
  constructor(private dataService: DataService,
              private matDialog: MatDialog) {
  }

  showDialog(title, data) {
    this.matDialog.open(JsonWidgetPopupComponent, {data:{title:title, data: data}});
  }
}
