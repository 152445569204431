import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-show-endpoint',
  templateUrl: './lvs-ui-no-stream-panel-view-progress.component.html',
  styleUrls: ['./lvs-ui-no-stream-panel-view-progress.component.scss'],
})
export class LvsUiNoStreamPanelViewProgressComponent implements OnInit {
  data: any = null;
  loading: boolean = false;
  autoReload: boolean = true;
  loadCount: number = 0;
  mode: string;
  loadLogs: string[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.mode = this.activatedRoute?.snapshot?.queryParamMap?.get('mode');
    console.log(`mode=${this.mode}`)
    this.getEndpointData();
  }

  getEndpointData() {
    if(this.mode) {
      this.data = null;
      this.loading = true;
      const startTime = Date.now();
      const endpoint = `/lvs-ui/legacy/no-stream-panel-view-progress?mode=${this.mode}`;
      this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
        this.data = data || {};
        this.loadCount++;
        const elapsed = Date.now() - startTime;
        this.loadLogs.push(`mode=${this.mode} elapsed=${elapsed / 1000} seconds value=${JSON.stringify(this.data)}`);
        this.loading = false;
        this.maybeReload();
      }, (error) => {
        this.data = (error && error.error) || error || {};
        this.loading = false;
      });
    }
  }

  toggleAutoReload() {
    this.autoReload = !this.autoReload
  }

  maybeReload() {
    if (this.autoReload) {
      const nextMode  = this.getNextMode();
      if(nextMode) {
        this.mode = nextMode;
        this.loading = true;
        this.getEndpointData();
      }
    }
  }

  private isMergedDone() {
    return (this.data?.merged?.status === 'error' || this.data?.merged?.status === 'completed');
  }

  private isLcaDone() {
    return ((!this.data.lca) || this.data.lca.status === 'error' || this.data.lca.status === 'completed');
  }

  private getNextMode(): string {
    if(this.isMergedDone()) {
      // mode null with stop reloading.
      return null;
    } else {
      if (this.isLcaDone()) {
        return "mergedOnly";
      } else {
        return "subsequent";
      }
    }
  }
}
