<mat-card>
  <mat-card-title>{{title}}</mat-card-title>
  <mat-card-content>
    {{title}} mirrors what shows up in lvs-ui's panel view. It is not designed to be pretty, but allows us to
    debug
    filtered DiagnosticReport results, and compare to lvs-ui results.
  </mat-card-content>
  <mat-card-content>
    <mat-form-field class="lvs-input-wide" appearance="fill">
      <mat-label>Pagination Options</mat-label>
      <textarea matInput class="active-code-group-string" [(ngModel)]="filterDataString" rows="15"
                cols="120"></textarea>
    </mat-form-field>
    <br/>
    <div class="display-type-container">
      <div class="update-button">
        <button mat-raised-button (click)="getEndpointData()"
                matTooltip="Make a new call to POST /lvs-rest/data/DiagnosticReport using the above pagination options">
          Update
        </button>
      </div>
      <div class="display-type">
        <mat-radio-group [(ngModel)]="displayMode" aria-label="Select an option">
          <mat-radio-button [value]="DisplayMode.PANEL_VIEW_UI"
                            matTooltip="This shows a view designed to be easy to map to lvs-ui's Panel View">Panel View
            UI
          </mat-radio-button>
          <mat-radio-button [value]="DisplayMode.API_RESPONSE"
                            matTooltip="This shows the raw JSON response returned from the call to POST /lvs-rest/data/DiagnosticReport endpoint">
            API Response
          </mat-radio-button>
          <mat-radio-button [value]="DisplayMode.PANEL_VIEW_JSON"
                            matTooltip="This shows the JSON used to render 'Panel UI'. It's really just used for lvs-poc-ui development/debugging purposes">
            Panel View JSON
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="displayMode===DisplayMode.API_RESPONSE">
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
  <mat-card-content *ngIf="displayMode===DisplayMode.PANEL_VIEW_JSON">
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="panels"></app-json-widget>
  </mat-card-content>
  <mat-card-content *ngIf="displayMode===DisplayMode.PANEL_VIEW_UI">
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <h3>Panels</h3>
    <table class="mat-table mat-elevation-z8" width="100%">
      <tr class="mat-header-row">
        <th class="mat-header-cell">
          <span class="grab"><mat-icon class="grab" matTooltip="Expand All"
                                       (click)="setAllExpand(true)">expand_more</mat-icon></span>
          <span class="grab"><mat-icon class="grab" matTooltip="Collapse All"
                                       (click)="setAllExpand(false)">expand_less</mat-icon></span>
        </th>
        <th class="mat-header-cell" width="200px">Date Collected</th>
        <th class="mat-header-cell">Test Name</th>
        <th class="mat-header-cell">Status</th>
        <th class="mat-header-cell">Source</th>
        <th class="mat-header-cell">ID</th>
      </tr>
      <tbody>
      <ng-template let-panel ngFor [ngForOf]="panels">
        <tr class="mat-row">
          <td class="mat-cell">
            <span class="grab" *ngIf="!panel.expanded"><mat-icon class="grab" matTooltip="Expand Panel"
                                                                 (click)="panel.expanded = true">expand_more</mat-icon></span>
            <span class="grab" *ngIf="panel.expanded"><mat-icon class="grab" matTooltip="Collapse Panel"
                                                                (click)="panel.expanded = false">expand_less</mat-icon></span>
          </td>

          <td class="mat-cell">{{panel.date}}</td>
          <td class="mat-cell">{{panel.name}}</td>
          <td class="mat-cell">{{panel.status}}</td>
          <td class="mat-cell">{{panel.source}}</td>
          <td class="mat-cell">
            <span *ngIf="getDuplicates(panel.diagnosticReport).length>0"
                                     [matTooltip]="getDuplicatesAsString(panel.diagnosticReport)"><mat-icon>control_point_duplicate</mat-icon> </span>
            <span *ngIf="isNew(panel.diagnosticReport)" matTooltip="New DiagnosticReport"><mat-icon>mark_as_unread</mat-icon></span>
            <app-bookmark [resource]="panel.diagnosticReport" (onSuccess)="onBookmarkSuccess($event)" (onFail)="onBookmarkFail($event)" ></app-bookmark>
            {{panel?.diagnosticReport?.id}}
          </td>
          <td class="mat-cell">
            <app-code-pop-up [data]="panel?.diagnosticReport" title="Diagnostic Report"></app-code-pop-up>
          </td>
        </tr>
        <tr *ngIf="panel.expanded && panel.results.length>0">
          <td></td>
          <td colspan="6">
            <h3>Results</h3>
            <table class="mat-table mat-elevation-z8" width="100%">
              <tr class="mat-header-row">
                <th class="mat-header-cell" width="200px">Date</th>
                <th class="mat-header-cell">Name</th>
                <th class="mat-header-cell">Value</th>
                <th class="mat-header-cell">Units</th>
                <th class="mat-header-cell">Status</th>
                <th class="mat-header-cell">Source</th>
                <th class="mat-header-cell">ID</th>
                <td class="mat-cell"></td>
              </tr>
              <tbody>
              <tr *ngFor="let result of panel.results" class="mat-row">
                <td class="mat-cell">{{result.date}}</td>
                <td class="mat-cell">{{result.name}}</td>
                <td class="mat-cell">{{result.value}}</td>
                <td class="mat-cell">{{result.units}}</td>
                <td class="mat-cell">{{result.status}}</td>
                <td class="mat-cell">{{result.source}}</td>
                <td class="mat-cell">
                  <span *ngIf="isSearchMatch(result.observation)"
                        matTooltip="Resource matches search filter"><mat-icon>verified</mat-icon> </span>
                  <span *ngIf="getDuplicates(result.observation).length>0"
                        [matTooltip]="getDuplicatesAsString(result.observation)"><mat-icon>control_point_duplicate</mat-icon> </span>
                  <span *ngIf="isNew(result.observation)" matTooltip="New Observation"><mat-icon>mark_as_unread</mat-icon></span>
                  <span *ngIf="isAbnormal(result.observation)" matTooltip="Abnormal Observation"><mat-icon>warning_amber</mat-icon></span>
                  {{result.observation?.id}}</td>
                <td class="mat-cell">
                  <app-code-pop-up [data]="result?.observation" title="Observation"></app-code-pop-up>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr *ngIf="panel.expanded && panel.results.length>0">
          <td colspan="5">
            <mat-divider></mat-divider>
          </td>
        </tr>
      </ng-template>
      </tbody>
    </table>
  </mat-card-content>
  <!--    <div class="panel" *ngFor="let panel of panels">
        <div>diagnostic report id: {{panel.diagnosticReport.id}}</div>
        <div *ngFor="let result of panel.results">observation id: {{result.observation.id}}</div>
      </div>
      <div>End</div>
      <app-show-panel *ngFor="let panel of panels" [panel]="panel"></app-show-panel>
    </mat-card-content>
    -->
</mat-card>

