import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-json-widget-popup',
  templateUrl: './json-widget-popup.component.html',
  styleUrls: ['./json-widget-popup.component.scss'],
})
export class JsonWidgetPopupComponent {
  data: any = null;
  title: string;

  constructor(@Inject(MAT_DIALOG_DATA) public matDialogData) {
    this.title = matDialogData.title;
    this.data = matDialogData.data;
  }
}


