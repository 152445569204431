import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-oncology-data',
  templateUrl: './oncology-data.component.html',
  styleUrls: ['./oncology-data.component.scss']
})
export class OncologyDataComponent implements OnInit {

    data: any = null;
    title: string;
    endpoint: string;
    loading = true;
    pageSizes = [1, 10, 20, 50, 100, 9999999];
    pageSize = 10;
    pageNumber = 1;
    count: number = null;
    dxreportId: string = '';

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private routerOutlet: RouterOutlet) {
        this.title = routerOutlet.activatedRouteData['title'];
        this.endpoint = routerOutlet.activatedRouteData['endpoint'];
        console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
    }

    ngOnInit() {
        this.loading = false;
    }

    retrieve() {
        this.loading = true;
        this.getEndpointData();
    }

    getEndpointData() {
        this.data = null;
        const endpoint = `${this.endpoint}/${this.dxreportId}`;
        this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
            this.count = 0;
            this.data = data || {};
            if (this.data && Array.isArray(this.data.entry)) {
                this.count = this.data.entry.length;
            }
            this.loading = false;
        }, (error) => {
            this.count = null;
            this.data = (error && error.error) || error || {};
            this.loading = false;
        });
    }

}
