<mat-toolbar color="primary">
  <button class="mat-focus-indicator mat-menu-trigger mat-button mat-button-base"
          (click)="routeTo('/')"  >
    <span class="mat-button-wrapper"><mat-icon>home</mat-icon> {{title}}</span>
    <span matripple="" class="mat-ripple mat-button-ripple"></span>
    <span class="mat-button-focus-overlay"></span>
  </button>
  <button mat-button [matMenuTriggerFor]="resources">Resources</button>
  <mat-menu #resources="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="dynamicResources">By ID and Source System</button>
    <button mat-menu-item (click)="routeTo('/conditions')">Conditions</button>
    <button mat-menu-item [matMenuTriggerFor]="diagnosticReports">Diagnostic Reports</button>
    <button mat-menu-item (click)="routeTo('/encounters')">Encounters</button>
    <button mat-menu-item (click)="routeTo('/immunizations')">Immunizations</button>
    <button mat-menu-item [matMenuTriggerFor]="medication">Medication</button>
    <button mat-menu-item [matMenuTriggerFor]="observations">Observations</button>
    <button mat-menu-item (click)="routeTo('/procedures')">Procedures</button>
    <button mat-menu-item (click)="routeTo('/patient-info')">Patient Info</button>
    <button mat-menu-item (click)="routeTo('/phoenix-patient-info')">Phoenix Patient Info</button>
    <button mat-menu-item (click)="routeTo('/service-requests')">ServiceRequests</button>
    <button mat-menu-item (click)="routeTo('/user-info')">User Info</button>
  </mat-menu>
  <mat-menu #medication="matMenu">
    <button mat-menu-item (click)="routeTo('/medication-administrations')">Medication Administrations</button>
    <button mat-menu-item (click)="routeTo('/medication-dispenses')">Medication Dispenses</button>
    <button mat-menu-item (click)="routeTo('/medication-requests')">Medication Requests</button>
    <button mat-menu-item (click)="routeTo('/medication-statements')">Medication Statements</button>
  </mat-menu>
  <mat-menu #diagnosticReports="matMenu">
    <button mat-menu-item (click)="routeTo('/diagnostic-reports-combined')">Combined</button>
    <button mat-menu-item (click)="routeTo('/diagnostic-reports-labcorp')">LabCorp</button>
    <button mat-menu-item (click)="routeTo('/diagnostic-reports-emr')">EMR</button>
  </mat-menu>
  <mat-menu #observations="matMenu">
    <button mat-menu-item (click)="routeTo('/observations-combined')">Combined</button>
    <button mat-menu-item disabled (click)="routeTo('/observations-labcorp')">LabCorp</button>
    <button mat-menu-item (click)="routeTo('/observations-emr')">EMR</button>
  </mat-menu>
  <mat-menu #oncology="matMenu">
    <button mat-menu-item (click)="routeTo('/standard-report')">Standard Report</button>
  </mat-menu>
  <mat-menu #dynamicResources="matMenu">
    <button mat-menu-item (click)="routeTo('/read-resources')">By header (Many)</button>
    <button mat-menu-item (click)="routeTo('/read-resource')">By parameters (One)</button>
  </mat-menu>
  <mat-menu #addOnTesting="matMenu">
    <button mat-menu-item (click)="routeTo('/accession-validate')">Accession Validate</button>
    <button mat-menu-item (click)="routeTo('/addons-create')">Addons Create</button>
    <button mat-menu-item (click)="routeTo('/diagnosis-search')">Diagnosis Search</button>
    <button mat-menu-item (click)="routeTo('/diagnosis-validate')">Diagnosis Validate</button>
    <button mat-menu-item (click)="routeTo('/picklist')">Picklist</button>
    <button mat-menu-item (click)="routeTo('/save-link-user')">Save Link User</button>
    <button mat-menu-item (click)="routeTo('/show-add-on')">Show Add On</button>
    <button mat-menu-item (click)="routeTo('/test-menu-detail')">Test-menu Detail</button>
    <button mat-menu-item (click)="routeTo('/test-menu-search')">Test-menu Search</button>
    <button mat-menu-item (click)="routeTo('/tests-not-allowed')">Tests Not Allowed</button>
    <button mat-menu-item (click)="routeTo('/user-accounts')">User Accounts</button>
  </mat-menu>

  <mat-menu #orderTracking="matMenu">
    <button mat-menu-item (click)="routeTo('/orders-search')">Orders Search</button>
    <button mat-menu-item (click)="routeTo('/orders-panel-view')">Orders Panel View</button>
    <button mat-menu-item (click)="routeTo('/order-detail')">Order Detail</button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="clinicalReasoning">Clinical Reasoning</button>
  <mat-menu #clinicalReasoning="matMenu">
    <button mat-menu-item (click)="routeTo('/clinical-reasoning-by-context')">By Context</button>
    <button mat-menu-item (click)="routeTo('/clinical-reasoning-by-plan-definition')">By Plan Definition</button>
    <button mat-menu-item (click)="routeTo('/clinical-reasoning-by-plan-definition-named-queries')">By PlanDefinitionNamedQueries</button>
    <button mat-menu-item (click)="routeTo('/clinical-reasoning-dashboard')">Dashboard Cards</button>
  </mat-menu>
  <button mat-button [matMenuTriggerFor]="other">Other</button>
  <mat-menu #other="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="addOnTesting">Add On Testing</button>
    <button mat-menu-item (click)="routeTo('/assessment-results')">Assessment Results</button>
    <button mat-menu-item (click)="routeTo('/cache-manager')">Cache Manager</button>
    <button mat-menu-item (click)="routeTo('/eap-results')">EAP Results</button>
    <button mat-menu-item (click)="routeTo('/ers-direct')">ERS Direct</button>
    <button mat-menu-item (click)="routeTo('/event-hooks')">Event Hooks</button>
    <button mat-menu-item [matMenuTriggerFor]="lvsRest">lvs-rest Details</button>
    <button mat-menu-item [matMenuTriggerFor]="oncology">Oncology Reports</button>
    <button mat-menu-item [matMenuTriggerFor]="orderTracking">Order Tracking</button>
    <button mat-menu-item (click)="routeTo('/patient-search')">Patient Search</button>
    <button mat-menu-item (click)="routeTo('/pdf-report')">PDF Report</button>
    <button mat-menu-item (click)="routeTo('/show-cached-data')">Show Cached Data</button>
  </mat-menu>
  <mat-menu #lvsRest="matMenu">
    <button mat-menu-item (click)="routeTo('/build-info')">Build Info</button>
    <button mat-menu-item (click)="routeTo('/connection-health')">Connection Health</button>
    <button mat-menu-item (click)="routeTo('/jwt-details')">JWT Details</button>
    <button mat-menu-item (click)="routeTo('/statistics')">Statistics</button>
    <button mat-menu-item (click)="openSwagger()">Swagger</button>
  </mat-menu>
  <button mat-button [matMenuTriggerFor]="configSubMenu">Config</button>
  <mat-menu #configSubMenu="matMenu">
    <button mat-menu-item (click)="routeTo('/code-groups')">Code Groups</button>
    <button mat-menu-item (click)="routeTo('/code-group-order-verbose')">Code Group Order (Verbose)</button>
    <button mat-menu-item (click)="routeTo('/practitioner-tags')">Practitioner Tags</button>
    <button mat-menu-item [matMenuTriggerFor]="settingsSubMenu">Settings</button>
    <!-- button mat-menu-item (click)="routeTo('/practitioner-loinc-group-order')">Practitioner Loinc Group Order</button -->
  </mat-menu>
  <mat-menu #settingsSubMenu="matMenu">
    <button mat-menu-item (click)="routeTo('/settings-app')">App Settings</button>
    <button mat-menu-item (click)="routeTo('/settings-practitioner')">Practitioner Settings</button>
    <button mat-menu-item (click)="routeTo('/settings-practitioner-patient')">Practitioner Patient Settings</button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="lvsUi">LVS UI</button>
  <mat-menu #lvsUi="matMenu">
    <button mat-menu-item (click)="routeTo('/lvs-ui-clinical-view')">Clinical View</button>
    <button mat-menu-item (click)="routeTo('/lvs-ui-panel-view')">Panel View</button>
    <button mat-menu-item (click)="routeTo('/lvs-ui-clinical-group')">Clinical Group Port</button>
    <button mat-menu-item (click)="routeTo('/lvs-ui-chemistry-panel-port')">Chemistry Panel Port</button>
    <button mat-menu-item (click)="routeTo('/lvs-ui-table-panel-port')">Table Panel Port</button>
    <button mat-menu-item (click)="routeTo('/lvs-ui-legacy-panel-view-port')">Panel View Port</button>
    <button mat-menu-item (click)="routeTo('/lvs-ui-no-stream-panel-view-progress')">No Stream Panel View Progress</button>
    <button mat-menu-item (click)="routeTo('/lvs-ui-result-type-progress')">Result Type Progress</button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="ctra">CTRA Integration</button>
  <mat-menu #ctra="matMenu">
    <button mat-menu-item (click)="routeTo('/ctra-guidance')">Guidance Response</button>
    <button mat-menu-item (click)="routeTo('/ctra-organizations')">Organizations</button>
    <button mat-menu-item (click)="routeTo('/ctra-practitionerrole')">Practitioner Role</button>
    <button mat-menu-item (click)="routeTo('/ctra-researchstudies')">ResearchStudies</button>
    <button mat-menu-item (click)="routeTo('/ctra-researchsubjects')">ResearchSubjects</button>
    <button mat-menu-item (click)="routeTo('/ctra-resource-by-path')">Resource by path parameters</button>
    <button mat-menu-item (click)="routeTo('/ctra-resource-by-query')">Resource by query parameters</button>
    <button mat-menu-item (click)="routeTo('/ctra-valueset')">ValueSet covance therapeutic area</button>
  </mat-menu>
</mat-toolbar>

<router-outlet></router-outlet>
