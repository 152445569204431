<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>
    <div>
      If you leave LPID blank, EAP results will be returned for the CURRENT patient (assuming that patient has an lpid).
    </div>
    <div>

      <mat-form-field class="lvs-input-wide" appearance="fill">
        <mat-label>LPID</mat-label>
        <input matInput type="text" size="50" [(ngModel)]="lpid" appearance="outline">
      </mat-form-field>
      <br/>
      <button mat-raised-button (click)="getEndpointData()">Get EAP results</button>
    </div>
  </mat-card-content>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
