<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>
    <table class="user-accounts-header">
      <tr>
        <td class="user-accounts-left">
          <mat-form-field class="lvs-input-wide" appearance="fill">
            <mat-label>Context</mat-label>
            <input matInput type="text" size="50" [(ngModel)]="context" appearance="outline">
          </mat-form-field>
        </td>
        <td class="user-accounts-right">
          <button mat-raised-button (click)="update()">Update</button>
        </td>
      </tr>
      <tr>
        <td class="user-accounts-left">
          <mat-label>Response code: {{statusCode}}</mat-label>
        </td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
