import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import jwtDecode from 'jwt-decode';
import {DataService} from '../../services/data.service';
import {JsonWidgetPopupComponent} from "../json-widget-popup/json-widget-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {JwtChangePopupComponent} from "../jwt-change-popup/jwt-change-popup.component";

@Component({
  selector: 'app-jwt-details',
  templateUrl: './jwt-details.component.html',
  styleUrls: ['./jwt-details.component.scss'],
})
export class JwtDetailsComponent implements OnInit {
  lvsAuthInfo: any
  loading: boolean = true;

  lvsRestJwt: string;
  decodedLvsRestJwt: string;
  lvsRestUrl: string;

  emrJwt: string;
  decodedEmrJwt: string;
  emrUrl: string;
  error: string;

  constructor(private authService: AuthService,
              private dataService: DataService,
              private matDialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.updateUi();
  }

  showDialog(title, data) {
    this.matDialog.open(JsonWidgetPopupComponent, {data: {title: title, data: data}});
  }

  changeJwt() {
    this.matDialog.open(JwtChangePopupComponent, {
      data: {
        lvsRestJwt: this.lvsRestJwt,
        callbackFn: () => {
          this.updateUi();
        }
      }
    });
  }

  getEndpointData() {
    const endpoint = '/dev-tools/show-auth-info';
    let emrAuthInfo = null;
    this.loading = true;
    this.emrJwt = null;
    this.emrUrl = null;
    this.decodedEmrJwt = null;
    this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
      emrAuthInfo = data || {};
      this.emrJwt = emrAuthInfo.emrAuthorization;
      this.emrUrl = emrAuthInfo.fhirServiceApi;
      this.decodedEmrJwt = jwtDecode(this.emrJwt);
      this.loading = false;
    }, (error) => {
      emrAuthInfo = (error && error.error) || error || {};
      this.loading = false;
      this.error = JSON.stringify(error, null, 2);
    });
  }

  // updateUi is declared using arrow functions, because it us used as a callback, and this
  // format ties "this", to the JwtDetailsComponent instance.
  updateUi() {
    this.lvsRestJwt = this.authService.getJwt();
    this.lvsRestUrl = this.dataService.getElvisRestAPI();
    this.lvsAuthInfo = this.authService.getLvsAuthInfo();
    if (this.lvsRestJwt) {
      try {
        this.decodedLvsRestJwt = jwtDecode(this.lvsRestJwt);
      } catch (error) {
        this.decodedLvsRestJwt = '(error decoding JWT Token)';
      }
    } else {
      this.decodedLvsRestJwt = '(no JWT Token)';
    }
    if (this.dataService.getElvisRestAPI()) {
      this.getEndpointData();
    }
    this.loading = false;
  }
}

