<mat-card>
  <mat-card-title>{{title}}</mat-card-title>
  <mat-card-content>
    {{title}} mirrors what shows up in lvs-ui's clinical view. It is not designed to be pretty, but allows us to
    debug filtered Observation results, and compare to lvs-ui results.
  </mat-card-content>
  <mat-card-content>
    <mat-form-field class="lvs-input-wide" appearance="fill">
      <mat-label>Pagination Options</mat-label>
      <textarea matInput class="active-code-group-string" [(ngModel)]="filterDataString" rows="15"
                cols="120"></textarea>
    </mat-form-field>
    <div class="display-type-container">
      <div class="update-button">
        <button mat-raised-button (click)="getEndpointData()"
                matTooltip="Make a new call to POST /lvs-rest/data/Observation using the above pagination options">
          Update
        </button>
        <button mat-raised-button (click)="getNodeJsData()"
                matTooltip="Make a new call to POST /lvs-rest/data/Observation using the above pagination options">
          NodeJs
        </button>
        <mat-checkbox [(ngModel)]="showNoCodeGroup" (ngModelChange)="toggleShowNoCodeGroup()">Show Observations without a Codegroup
        </mat-checkbox>
      </div>
      <div class="display-type">
        <mat-radio-group [(ngModel)]="displayMode" aria-label="Select an option">
          <mat-radio-button  [disabled]="true" [value]="DisplayMode.CLINICAL_VIEW_UI"
                            matTooltip="This shows a view designed to be easy to map to lvs-ui's Panel View">Clinical
            View UI
          </mat-radio-button>
          <mat-radio-button [value]="DisplayMode.API_RESPONSE"
                            matTooltip="This shows the raw JSON response returned from the call to POST /lvs-rest/data/Observation endpoint">
            API Response
          </mat-radio-button>
          <mat-radio-button  [disabled]="true" [value]="DisplayMode.CLINICAL_VIEW_JSON"
                            matTooltip="This shows the JSON used to render 'Clinical View UI'. It's really just used for lvs-poc-ui development/debugging purposes">
            Clinical View JSON
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="displayMode===DisplayMode.API_RESPONSE">
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
  <mat-card-content *ngIf="displayMode === DisplayMode.CLINICAL_VIEW_JSON">
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="panels"></app-json-widget>
  </mat-card-content>
  <mat-card-content *ngIf="displayMode === DisplayMode.CLINICAL_VIEW_UI">
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <mat-card-title>Code Groups</mat-card-title>
    <mat-card>
      <span *ngFor="let codeGroup of codeGroups; let last = last"
            [class]="codeGroup?.type==='emr-defined' ? 'emr-defined' : 'practitioner-defined'">
        {{codeGroup.code}}({{codeGroup.count}})<span *ngIf="!last">,</span>
      </span>
      <app-code-pop-up [data]="codeGroups" title="Code Groups"></app-code-pop-up>
    </mat-card>
  </mat-card-content>
  <mat-card-content *ngIf="displayMode === DisplayMode.CLINICAL_VIEW_UI">
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <mat-card-title class="panels">Observations by Panel and Loinc</mat-card-title>
    <app-code-pop-up [data]="panelCodeGroupDatas" title="Panel Code Group Datas"></app-code-pop-up>
    <mat-card>
      <table class="mat-table mat-elevation-z8" width="100%" border="1">
        <tr class="mat-header-row">
          <th class="mat-header-cell">

          </th>
          <th class="mat-header-cell" *ngFor="let panelCodeGroupData of panelCodeGroupDatas">
            <app-bookmark [resource]="panelCodeGroupData.panel.diagnosticReport" (onSuccess)="onBookmarkSuccess($event)" (onFail)="onBookmarkFail($event)" ></app-bookmark>
            {{panelCodeGroupData.panel.date}}
            <br/>
            <app-code-pop-up [data]="panelCodeGroupData.panel.diagnosticReport" title="Diagnostic Report"></app-code-pop-up>
            <span *ngIf="getDuplicates(panelCodeGroupData.panel.diagnosticReport).length>0"
                  [matTooltip]="getDuplicatesAsString(panelCodeGroupData.panel.diagnosticReport)"><mat-icon>control_point_duplicate</mat-icon></span>
            <span *ngIf="isNew(panelCodeGroupData.panel.diagnosticReport)" matTooltip="New Diagnostic Report"><mat-icon>mark_as_unread</mat-icon></span>
          </th>
        </tr>
        <tr>
          <th [colSpan]="panelCodeGroupDatas.length+1">
            Stats: Panels ({{stats.panelCount}}), Code Groups ({{stats.codeGroupsCount}}), Loinc Codes
            ({{stats.loincCodesCount}}), Stats ({{stats | json}})
          </th>
        </tr>
        <tbody>

        <ng-template let-codeGroup ngFor [ngForOf]="getAllCodeGroups()">
          <tr class="mat-row code-group-header-row">
            <td class="mat-cell" [colSpan]="panelCodeGroupDatas.length+1">
              <h3>{{codeGroup}}</h3>
            </td>
          </tr>

          <ng-template let-loinc ngFor [ngForOf]="getAllLoincs(codeGroup)">
            <tr class="mat-row code-group-code-row">
              <td class="mat-cell">{{codingDisplayName(loinc)}}</td>
              <td class="mat-cell" *ngFor="let panelCodeGroupData of panelCodeGroupDatas">
              <span *ngFor="let result of getResults(panelCodeGroupData,codeGroup, loinc.code); let last = last">
                {{observationValue(result.observation)}}
                <app-code-pop-up [data]="result.observation" title="Observation"></app-code-pop-up>
                <span *ngIf="getDuplicates(result.observation).length>0"
                      [matTooltip]="getDuplicatesAsString(result.observation)"><mat-icon>control_point_duplicate</mat-icon> </span>
                <span *ngIf="isNew(result.observation)" matTooltip="New Observation"><mat-icon>mark_as_unread</mat-icon></span>
                <span *ngIf="isAbnormal(result.observation)" matTooltip="Abnormal Observation"><mat-icon>warning_amber</mat-icon></span>
                <span *ngIf="!last">,</span>
              </span>
              </td>
            </tr>
          </ng-template>

          <!--
                    <td class="mat-cell">{{panel.date}}</td>
                    <td class="mat-cell">{{panel.name}}</td>
                    <td class="mat-cell">{{panel.status}}</td>
                    <td class="mat-cell">{{panel.source}}</td>
                    <td class="mat-cell"><span *ngIf="getDuplicates(panel.diagnosticReport).length>0"
                                               [matTooltip]="getDuplicatesAsString(panel.diagnosticReport)"><mat-icon>control_point_duplicate</mat-icon> </span>
                      {{panel?.diagnosticReport?.id}}
                    </td>
                    <td class="mat-cell">
                      <span class="grab"><mat-icon class="grab" matTooltip="View JSON"
                                                   (click)="onJsonClick(panel?.diagnosticReport, $event)">code</mat-icon></span>
                    </td>
                  </tr>
                  <tr *ngIf="panel.expanded && panel.results.length>0">
                    <td></td>
                    <td colspan="6">
                      <h3>Results</h3>
                      <table class="mat-table mat-elevation-z8" width="100%">
                        <tr class="mat-header-row">
                          <th class="mat-header-cell" width="200px">Date</th>
                          <th class="mat-header-cell">Name</th>
                          <th class="mat-header-cell">Value</th>
                          <th class="mat-header-cell">Units</th>
                          <th class="mat-header-cell">Status</th>
                          <th class="mat-header-cell">Source</th>
                          <th class="mat-header-cell">ID</th>
                          <td class="mat-cell"></td>
                        </tr>
                        <tbody>
                        <tr *ngFor="let result of panel.results" class="mat-row">
                          <td class="mat-cell">{{result.date}}</td>
                          <td class="mat-cell">{{result.name}}</td>
                          <td class="mat-cell">{{result.value}}</td>
                          <td class="mat-cell">{{result.units}}</td>
                          <td class="mat-cell">{{result.status}}</td>
                          <td class="mat-cell">{{result.source}}</td>
                          <td class="mat-cell">
                            <span *ngIf="getDuplicates(result.observation).length>0"
                                  [matTooltip]="getDuplicatesAsString(result.observation)"><mat-icon>control_point_duplicate</mat-icon> </span>
                            {{result.observation?.id}}</td>
                          <td class="mat-cell">
                            <span class="grab"><mat-icon class="grab" matTooltip="View JSON"
                                                         (click)="onJsonClick(result?.observation, $event)">code</mat-icon></span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr *ngIf="panel.expanded && panel.results.length>0">
                    <td colspan="5">
                      <mat-divider></mat-divider>
                    </td>
                  </tr>
                  -->

        </ng-template>
        </tbody>
      </table>
    </mat-card>
  </mat-card-content>
</mat-card>

