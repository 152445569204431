<mat-card>
  <mat-card-title>Show Cached Data</mat-card-title>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div>
      <div>key:&nbsp;<input type="text" [(ngModel)]="key">&nbsp;&nbsp;&nbsp;scope&nbsp;:<select [(ngModel)]="scope">
          <option *ngFor="let scopeCur of scopes" [ngValue]="scopeCur">{{scopeCur}}</option>
        </select>
      </div>
      <div>
        <button [disabled]="!hasKey()" (click)="getData()">GET</button>
        <button [disabled]="!hasKey()" (click)="deleteData()">DELETE</button>
        <button [disabled]="!canPut()" (click)="putData()">PUT</button>
        <app-copy-to-clipboard [data]="cachedDataString">Copy to clipboard</app-copy-to-clipboard>
      </div>
    </div>
    <div>
      <textarea rows="30" cols="120" [(ngModel)]="cachedDataString"></textarea>
    </div>
    <mat-error class="error" *ngIf="error">
      <pre>{{error}}</pre>
    </mat-error>
  </mat-card-content>
</mat-card>
