import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnInit {
  data: any = null;
  title: string;
  endpoint: string;
  loading = false;
  orderId: string = "01J0RJJ2JM6N96YYGJY7D7HS2Y";
  orderCode: string = "001800";
  accessionKey: string = "157RCR51408.LCLS.2024-04-04.2024-04-04";
  statusCode?: number;

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
  }

  ngOnInit(): void {
    // do nothing
  }

  update() {
    this.getEndpointData();
  }

  getEndpointData() {
    this.data = null;
    const endpoint = `${this.endpoint}?orderId=${this.orderId}&orderCode=${this.orderCode}&accessionKey=${this.accessionKey}`;
    try {
      this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
        this.data = data || {};
        this.statusCode = 200;
        this.loading = false;
      }, (error) => {
        this.data = (error && error.error) || error || {};
        this.statusCode = (error && error.status) || undefined;
        this.loading = false;
      });
    } catch (error) {
      this.data = (error && error.message) || error || {};
    }
  }
}
