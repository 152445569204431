import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-ctra-guidance',
  templateUrl: './ctra-guidance.component.html',
  styleUrls: ['./ctra-guidance.component.less']
})
export class CtraGuidanceComponent implements OnInit {
  data: any = null;
  title: string;
  endpoint: string;
  loading = true;
  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }

  ngOnInit() {
    console.log('routerOutlet2', this.routerOutlet && this.routerOutlet.activatedRouteData && this.routerOutlet.activatedRouteData);
    this.getEndpointData();
  }

  getEndpointData() {
    this.data = null;
    this.dataService.getEndpointData(this.endpoint).subscribe((data: {}) => {
      this.data = data || {};
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
  }
}
