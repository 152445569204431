<mat-card>
  <mat-card-title>Statistics</mat-card-title>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <button mat-raised-button (click)="clearStatistics()">Clear Statistics</button>
  </mat-card-content>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
