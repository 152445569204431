import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";

@Component({
    selector: 'app-addons-create',
    templateUrl: './addons-create.component.html',
    styleUrls: ['./addons-create.component.scss'],
})
export class AddonsCreateComponent implements OnInit {
    data: any = null;
    title: string;
    endpoint: string;
    loading = false;
    statusCode?: number;
    payload: string = JSON.stringify({
      "accessionReportId": 1,
      "addOnTests": [
        {
          "testCode": "005025",
          "testName": "White Blood Cell (WBC) Count"
        }
      ],
      "contactInfo": {
        "name": "Venkat Reddy",
        "phone": "",
        "email": "venkateng@yopmail.com"
      },
      "diagnosisCodes": [
        "D72.818"
      ],
      "orderKey": "EAP.29999200110.LCLS.2023-10-26.2023-10-26",
      "requestingAccount": {
        "number": "01818355",
        "name": "American Fam Care-Forestdale  Interface"
      },
      "requestingUser": {
        "id": "venkateng@yopmail.com",
        "firstName": "Venkat",
        "lastName": "Reddy"
      }
    }, null, 2);

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private routerOutlet: RouterOutlet) {
        this.title = routerOutlet.activatedRouteData['title'];
        this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    }

    ngOnInit(): void {
      // do nothing
    }

    update() {
        this.putEndpointData();
    }

    putEndpointData() {
        this.data = null;
        try {
          const payload = JSON.stringify(JSON.parse(this.payload));
          this.dataService.putEndpointData(this.endpoint, payload).subscribe((data: {}) => {
              this.data = data || {};
              this.statusCode = 200;
              this.loading = false;
          }, (error) => {
              this.data = (error && error.error) || error || {};
              this.statusCode = (error && error.status) || undefined;
              this.loading = false;
          });
        } catch (error) {
          this.data = (error && error.message) || error || {};
        }
    }
}
