import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-statistics.component',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  data: any = null;
  loading = true;

  constructor(private dataService: DataService) {
  }

  ngOnInit() {
    this.getStatistics();
  }

  clearStatistics() {
    const endPoint = `/statistics`;
    this.loading = true;
    this.dataService.deleteEndpointData(endPoint).subscribe((data: {}) => {
      this.data = null || {};
      this.getStatistics();
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
  }

  getStatistics() {
    this.data = null;
    const endPoint = `/statistics`;
    this.dataService.getEndpointData(endPoint).subscribe((data: {}) => {
      this.data = data || {};
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
  }
}
