<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>
    <table class="order-detail-header">
      <tr>
        <td class="order-detail-left">
          <mat-form-field class="lvs-input-wide" appearance="fill">
            <mat-label>Accession Key</mat-label>
            <input matInput type="text" size="50" [(ngModel)]="accessionKey" appearance="outline">
          </mat-form-field>
          <mat-form-field class="lvs-input-wide" appearance="fill">
            <mat-label>Order Id</mat-label>
            <input matInput type="text" size="50" [(ngModel)]="orderId" appearance="outline">
          </mat-form-field>
          <mat-form-field class="lvs-input-wide" appearance="fill">
            <mat-label>Order Code</mat-label>
            <input matInput type="text" size="50" [(ngModel)]="orderCode" appearance="outline">
          </mat-form-field>
        </td>
        <td class="order-detail-right">
          <button mat-raised-button (click)="update()">Update</button>
        </td>
      </tr>
      <tr>
        <td class="order-detail-left">
          <mat-label>Response code: {{statusCode}}</mat-label>
        </td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
