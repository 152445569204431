<div>
  <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
  <div *ngIf="mode==='discover'">
    <mat-card>
      <mat-card-title>CDS Hooks</mat-card-title>
      <mat-card-content>See <a href="https://confluence.labcorp.com/display/DAT/5.0+lvs-event-hooks+POC">https://confluence.labcorp.com/display/DAT/5.0+lvs-event-hooks+POC</a>
      </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card>
      <mat-card-title>Discovery Response</mat-card-title>
      <mat-card-content>
      <app-json-widget [readonly]="true" [data]="discoveryResponse"></app-json-widget>
      </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card *ngFor="let service of discoveryResponse.services">
      <mat-card-title>{{ service.id }}</mat-card-title>
      <mat-card-subtitle>{{ service.hook }}</mat-card-subtitle>
      <mat-card-content>Title: {{service.title}}</mat-card-content>
      <mat-card-content>Description: {{service.description}}</mat-card-content>
      <mat-card-actions>
        <button mat-raised-button (click)="callCdsHook(service.id)">Call CDS Hook</button>
      </mat-card-actions>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card>
      <mat-card-title>SMART on FHIR Launch Hook</mat-card-title>
      <mat-card-actions>
        <button mat-raised-button (click)="callSmartOnFhirLaunchHook()">Call SMART on FHIR Hook</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div *ngIf="mode==='call-cds-hook'">
    <mat-card>
      <mat-card-title>{{ hookId }}</mat-card-title>
    </mat-card>
    <mat-card *ngFor="let card of cdsHookCallResponse.cards">
      <mat-card-title>Summary: {{ card.summary }}</mat-card-title>
      <mat-card-subtitle>Detail: {{ card.detail }}</mat-card-subtitle>
      <div *ngIf="card.source">
        <mat-card-content>Source Label: {{card.source.label}}</mat-card-content>
        <mat-card-content>Source URL: {{card.source.url}}</mat-card-content>
      </div>
      <mat-card-content *ngIf="card.links &&card.links.length > 0 ">
        <h4>Links</h4>
        <div *ngFor="let link of card.links">
          <div>Link Label: {{link.label}}</div>
          <div>Link URL: {{link.url}}</div>
          <div>Link Type: {{link.type}}</div>
          <div>Link AppContext: {{link.appContext}}</div>
        </div>
        <mat-divider></mat-divider>
      </mat-card-content>
      <mat-card-content *ngIf="card.suggestions &&card.suggestions.length > 0 ">
        <h4>Suggestions</h4>
        <app-json-widget [readonly]="true" [data]="card.suggestions"></app-json-widget>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <app-json-widget [readonly]="true" [data]="cdsHookCallResponse"></app-json-widget>
    </mat-card>
    <mat-card-actions>
      <button mat-raised-button (click)="discover()">Back to Discovery</button>
    </mat-card-actions>
  </div>

  <mat-error class="error" *ngIf="error">
    <pre>{{error}}</pre>
  </mat-error>

</div>
