<mat-card>
    <mat-card-title>Plan Definitions</mat-card-title>
    <div>
      Select a Plan Definition, then choose one of Show GuidanceResponse
      <li>Show GuidanceResponse - returns the Guidance Response from $apply, based on Data Requirements and matching patient data (if any)</li>
      <li>Show Inputs - returns the parameters object that would be sent to cds-api $apply, based on Data Requirements and matching patient data (if any)</li>
      <li>Show DataRequirements - returns the Data Requirements used to filter patient data</li>
    </div>
    <mat-card-content>
      <div *ngIf="hasPlanDefinitions()">
        <mat-form-field class="lvs-input-wide" appearance="fill">
          <mat-label>Select a Plan Definition</mat-label>
          <mat-select [(ngModel)]="planDefinition" appearance="outline">
            <mat-option *ngFor="let planDefinitionCur of planDefinitions"
                        [value]="planDefinitionCur">{{planDefinitionCur.id}}</mat-option>
          </mat-select>
        </mat-form-field>
        <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;<button mat-raised-button [disabled]="isButtonDisabled()" (click)="updateGuidanceResponse()">Show GuidanceResponse
      </button>
        &nbsp;&nbsp;&nbsp;&nbsp;<button mat-raised-button [disabled]="isButtonDisabled()" (click)="updateInputs()">Show Inputs</button>
        &nbsp;&nbsp;&nbsp;&nbsp;<button mat-raised-button [disabled]="isButtonDisabled()" (click)="updateDataRequirements()">Show DataRequirements
      </button>
      </div>
      <div *ngIf="!hasPlanDefinitions()">
        No Plan Definitions Found
      </div>
    </mat-card-content>
    <mat-card-content>
      <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
      <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
    </mat-card-content>
  </mat-card>