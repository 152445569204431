import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";

@Component({
    selector: 'app-test-menu-detail',
    templateUrl: './test-menu-detail.component.html',
    styleUrls: ['./test-menu-detail.component.scss'],
})
export class TestMenuDetailComponent implements OnInit {
    data: any = null;
    title: string;
    endpoint: string;
    loading = false;
    statusCode?: number;
    payload: string = JSON.stringify({
      "accountId": 90999999,
      "accountNumber": "01818355",
      "hospital": "false",
      "labSystemCode": "LCLS",
      "testCode": "005025"
    }, null, 2);

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private routerOutlet: RouterOutlet) {
        this.title = routerOutlet.activatedRouteData['title'];
        this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    }

    ngOnInit(): void {
      // do nothing
    }

    update() {
        this.postEndpointData();
    }

    postEndpointData() {
        this.data = null;
        try {
          const payload = JSON.stringify(JSON.parse(this.payload));
          this.dataService.postEndpointData(this.endpoint, payload).subscribe((data: {}) => {
              this.data = data || {};
              this.statusCode = 200;
              this.loading = false;
          }, (error) => {
              this.data = (error && error.error) || error || {};
              this.statusCode = (error && error.status) || undefined;
              this.loading = false;
          });
        } catch (error) {
          this.data = (error && error.message) || error || {};
        }
    }
}
