import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  data: any = {};
  title: string;
  endpoint: string;
  summary: string;
  allowEdits: boolean;
  loading = true;
  mode = 'list';
  key = '';
  curSetting: string;
  error: any;
  showHidden: boolean = false;

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet,
              private http: HttpClient) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    this.summary = routerOutlet.activatedRouteData['summary'];
    this.allowEdits = routerOutlet.activatedRouteData['allowEdits'];
  }

  ngOnInit() {
    this.getEndpointData();
  }

  getEndpointData() {
    this.data = {};
    const params =
    this.dataService.getEndpointData(this.endpoint, {"show-hidden": this.showHidden.toString()}).subscribe((data) => {
      this.data = data;
      console.log('data', this.data);
      this.loading = false;
    }, (error) => {
      this.error = (error && error.toString()) || error || {};
      // this.practitionerSettings = (error && error.error) || error || {};
      this.loading = false;
    });
  }

  showSetting(key: string) {
    if(this.allowEdits) {
      this.mode = 'show-setting';
      this.key = key;
      this.curSetting = JSON.stringify(this.data[key]);
    }
  }

  showAddSetting() {
    this.key = '';
    this.curSetting = '';
    this.mode = 'show-add-setting';
  }

  showJsonView() {
    this.key = '';
    this.curSetting = '';
    this.mode = 'json-view';
  }

  updateSetting() {
    this.loading = true;
    this.dataService.putEndpointData(this.endpoint,
      {value: JSON.parse(this.curSetting)},
      {name: this.key})
      .subscribe((newSetting) => {
        this.dataService.getEndpointData(this.endpoint).subscribe((data) => {
          this.data = data;
          this.mode = 'list';
          this.loading = false;
        });
      }, (error) => {
        this.data = (error && error.error) || error || {};
        this.loading = false;
      });
    this.mode = 'list';
  }

  deleteSetting() {
    this.loading = true;
    this.dataService.deleteEndpointData(this.endpoint,
      {name: this.key})
      .subscribe((ignoreMe) => {
        this.dataService.getEndpointData(this.endpoint).subscribe((data) => {
          this.data = data;
          this.mode = 'list';
          this.loading = false;
        });
      }, (error) => {
        this.data = (error && error.error) || error || {};
        this.loading = false;
      });
    this.mode = 'list';
  }

  keys(objIn: any) {
    if (typeof objIn === 'object' && objIn) {
      return Object.keys(objIn);
    } else {
      return [];
    }
  }

  toggleShowHidden() {
    this.showHidden = !this.showHidden;
    this.getEndpointData();
  }
}
