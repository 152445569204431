import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-show-pdf',
  templateUrl: './show-pdf.component.html',
  styleUrls: ['./show-pdf.component.scss'],
})
export class ShowPdfComponent implements OnInit {
  resourceId: string = null;
  pdfData: any = null;
  mode = 'list';
  tableData: any = null;
  title: string;
  loading = true;
  elvisRESTAPI: string;
  binaryEndpoint: string;
  documentReferenceEndpoint: string;
  diagnosticReportEndpoint: string;
  diagnosticReportId = '15999047000.2018.LCLS.01818355.2018-06-08.211193';
  contentDisposition = 'inline';


  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.documentReferenceEndpoint = routerOutlet.activatedRouteData['documentReferenceEndpoint'];
    this.binaryEndpoint = routerOutlet.activatedRouteData['binaryEndpoint'];
    this.diagnosticReportEndpoint = routerOutlet.activatedRouteData['diagnosticReportEndpoint'];
    this.elvisRESTAPI = dataService.getElvisRestAPI();

    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }


  getPdfUrl(diagnosticReportId: string): string {
    return this.binaryEndpoint
      + '/'
      + diagnosticReportId
      + '?content-disposition='
      + this.contentDisposition
      + '&filename='
      + 'report-'
      + diagnosticReportId
      + '.pdf';
  }

  ngOnInit() {
    this.getDiagnosticReports();
  }

  getDiagnosticReports() {
    this.tableData = [];
    const skipped = [];
    this.dataService.getEndpointData(this.diagnosticReportEndpoint).subscribe((data: any) => {
      console.log('data', data);
      if (data && data.entry) {
        data.entry.forEach((entry) => {
          try {
            if (entry.resource.resourceType === 'DiagnosticReport') {
              if (isLabCorpDiagnosticReport(entry.resource)) {
                this.tableData.push(entry.resource);
              } else {
                skipped.push(entry.resource);
              }
            }
          } catch (error) {
            console.error(error);
          }
        });
        console.log('tableData', this.tableData);
        console.log('skipped', skipped);
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }


  renderPdf(resourceId) {
    this.pdfData = null;
    this.resourceId = resourceId;
    this.mode = 'view';
    this.loading = true;
    const endPoint = this.documentReferenceEndpoint + '/' + resourceId;
    this.dataService.getEndpointData(endPoint).subscribe((data: {}) => {
      this.pdfData = data || {};
      this.loading = false;
    }, (error) => {
      this.pdfData = (error && error.error) || error || {};
      this.loading = false;
    });
  }


  openPdf($event: any, resourceId) {
    // Prevent default behavior when clicking a link
    $event.preventDefault();
    const pdfUrl = this.getPdfUrl(resourceId);
    const endPoint = this.documentReferenceEndpoint + '/' + resourceId;
    this.dataService.getEndpointPdf(pdfUrl).subscribe((data: Blob) => {
      const url = URL.createObjectURL(data);
      window.open(url);
    }, (error) => {
      console.log('error', error);
    });

    // $http.get(filename, {responseType: 'arraybuffer'})
    //     .success(function (data) {
    //         var file = new Blob([data], {type: 'application/pdf'});
    //         var fileURL = URL.createObjectURL(file);
    //
    //         // Open new windows and show PDF
    //         window.open(fileURL);
    //     });
  }
}

function isLabCorpDiagnosticReport(resource) {
  try {
    for (const tag of resource.meta.tag) {
      if (tag.system === 'http://labcorp.com/fhir/meta/tag/lvs-core/source-system'
        && tag.code === 'Labcorp') {
        return true;
      }
    }
    return false;
  } catch (err) {
    return false;
  }
}
