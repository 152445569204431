import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";

@Component({
    selector: 'app-user-accounts',
    templateUrl: './user-accounts.component.html',
    styleUrls: ['./user-accounts.component.scss'],
})
export class UserAccountsComponent implements OnInit {
    data: any = null;
    title: string;
    endpoint: string;
    loading = false;
    context: string = "ORDERS";
    statusCode?: number;

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private routerOutlet: RouterOutlet) {
        this.title = routerOutlet.activatedRouteData['title'];
        this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    }

    ngOnInit(): void {
      // do nothing
    }

    update() {
        this.getEndpointData();
    }

    getEndpointData() {
        this.data = null;
        const endpoint = `${this.endpoint}?context=${this.context}`;
        try {
          this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
              this.data = data || {};
              this.statusCode = 200;
              this.loading = false;
          }, (error) => {
              this.data = (error && error.error) || error || {};
              this.statusCode = (error && error.status) || undefined;
              this.loading = false;
          });
        } catch (error) {
          this.data = (error && error.message) || error || {};
        }
    }
}
