import {Component, OnInit} from '@angular/core';
import {DataService, LcaHttpParams} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {Patient, Practitioner} from "fhir/r4";
import {getDiagnosticReportDefaultFilterData} from "../../../utils/data-filter-utils";
import {MatDialog} from "@angular/material/dialog";
import {PractitionerTagsService} from "../../services/practitionerTags.service";

export enum DisplayMode {
  PATIENT_SEARCH_UI,
  PATIENT_SEARCH_JSON,
  PANEL_VIEW_JSON,
}

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss'],
})
export class PatientSearchComponent implements OnInit {

  patientData: any = null;
  panelData: any = null;
  activePatient: Patient = null;
  activePatients: any = null;
  loading = false;
  DisplayMode = DisplayMode;
  displayMode: DisplayMode = DisplayMode.PATIENT_SEARCH_JSON;

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet,
              private matDialog: MatDialog,
              private practitionerTagsService: PractitionerTagsService) {
    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }

  ngOnInit(): void {
    this.getPatientData();
  }

  getPatientData() {
    this.loading = true;
    this.patientData = null;
    const endpoint = "/lvs-ui/legacy/patient-search/default-search"
    this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
      this.patientData = data || {};
      this.loading = false;
    }, (error) => {
      this.patientData = (error && error.error) || error || {};
      this.loading = false;
    });
  }

  getPanelData() {
    const params: LcaHttpParams = {};
    this.panelData = null;
    const lpids: string[] = [];
    if(this.activePatients) {
      this.activePatients.forEach((activePatient: Patient)=> {
        if(activePatient?.id) {
          lpids.push(activePatient.id);
        }
      });
    }
    if(lpids.length > 0) {
      let filterData = getDiagnosticReportDefaultFilterData();
      filterData.lpids = lpids;
      this.loading = true;
      const endpoint = "/lvs-ui/legacy/patient-search/panel-view"
      this.dataService.postEndpointData(endpoint, filterData).subscribe((data: {}) => {
        this.panelData = data || {};
        this.loading = false;
      }, (error) => {
        this.panelData = (error && error.error) || error || {};
        this.loading = false;
      });
    }
  }

  humanNameAsString(practitionerOrPatient: Practitioner | Patient) {
    if (!practitionerOrPatient) {
      return '(empty)';
    }
    let name = practitionerOrPatient.name;

    if (!name) {
      name = [];
    } else if (!Array.isArray(name)) {
      name = [name];
    }
    if (!(name.length > 0)) {
      return `undefined (${practitionerOrPatient.id})`;
    }
    return `${name[0]?.family}, ${name[0]?.given?.join(" ")} (${practitionerOrPatient.id})`;
  }
}


