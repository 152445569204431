import {Component} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-cache-manager.component',
  templateUrl: './cache-manager.component.html',
  styleUrls: ['./cache-manager.component.scss'],
})
export class CacheManagerComponent {
  data: string = null;
  loading = false;

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }

  clearPatientCache() {
    this.clearCache('Patient');
  }

  clearPractitionerCache() {
    this.clearCache('Practitioner');
  }

  clearApplicationCache() {
    this.clearCache('App');
  }

  clearLvsRestCache() {
    this.clearCache('LvsRest');
  }

  clearCache(type: String) {
    const endPoint = `/cache/clear${type}Cache`;
    this.loading = true;
    this.dataService.getEndpointData(endPoint).subscribe((data: {}) => {
      this.data = `Cleared ${type} cache`;
      this.loading = false;
    }, (error) => {
      console.error(error);
      this.data = `Failed to clear ${type} cache`;
      this.loading = false;
    });
  }
}
