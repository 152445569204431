import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { SearchPlanDefinitionByCodingComponent } from '../search-plan-definition-by-coding/search-plan-definition-by-coding.component';

@Component({
  selector: 'app-clinical-reasoning-by-plan-definition-named-queries',
  templateUrl: './clinical-reasoning-by-plan-definition-named-queries.component.html',
  styleUrls: ['./clinical-reasoning-by-plan-definition-named-queries.component.scss'],
  providers: [SearchPlanDefinitionByCodingComponent]
})
export class ClinicalReasoningByPlanDefinitionNamedQueriesComponent implements OnInit {
  data: any = null;
  title: string;
  endpoint: string;
  loading = true;
  planDefinitionNamedQueries: any[] = [];
  selectedPlanDefinitionNamedQueries: any[] = [];
  planDefinitions: any[] = [];
  planDefinition: any;

  constructor(private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private routerOutlet: RouterOutlet,
    private searchPlanDefinitionByCoding: SearchPlanDefinitionByCodingComponent) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
  }

  ngOnInit(): void {
    this.getPlanDefinitionNamedQueries();
  }

  getPlanDefinitionNamedQueries() {
    this.selectedPlanDefinitionNamedQueries = [];
    const dashboardEndpoint = `/clinical-reasoning/cds-dashboard/NamedPlanDefinitionQueries`;
    this.dataService.getEndpointData(dashboardEndpoint).subscribe((data: string[]) => {
      data.forEach(entry => {
        this.selectedPlanDefinitionNamedQueries.push({ name: entry });
      });

    }, (error) => {});

    this.planDefinitionNamedQueries = [];
    const endpoint = `/clinical-reasoning/NamedPlanDefinitionQueries`;
    this.dataService.getEndpointData(endpoint).subscribe((data: any[]) => {
      data.forEach(entry => {
        this.planDefinitionNamedQueries.push({ name: entry.name });
      });

      this.data = null;
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });    
  }

  getPlanDefinitions() {
    this.planDefinitions = [];
    const endpoint = `/clinical-reasoning/PlanDefinitions`;
    const body = [];
    this.selectedPlanDefinitionNamedQueries.forEach(namedQuery => {
      body.push(namedQuery.name);
    })
    this.dataService.postEndpointData(endpoint, body).subscribe((data: any[]) => {
      data.forEach(planDefinitionId => {
        this.planDefinitions.push({id: planDefinitionId});
      });

      this.data = null;
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
  } 
  
  compareNamedQueries(o1: any, o2: any): boolean {
    return o1.name === o2.name;
  }
}


