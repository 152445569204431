<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>

    <table class="oncology-data-header">
      <tr>
        <td>
          <mat-form-field appearance="fill" style="width: 500px">
            <mat-label>Diagnostic Report Id</mat-label>
            <input matInput type="text" [(ngModel)]="dxreportId" name="dxreportId" >
          </mat-form-field>
          
        </td>
        <td>
         <button mat-raised-button (click)="retrieve()" [disabled]="dxreportId === ''">Retrieve</button>
        </td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
