import {getSource, panelDisplayDate, panelDisplayName} from '../../utils/resource-utils'
import {Observation} from "fhir/r4";
import {Panel} from "./Panel";

export class Result {
  date: string;
  name: string;
  value: string;
  units: string;
  status: string;
  source: string;
  observation: Observation;
  panels: Panel[] = [];

  constructor(observation: Observation) {
    this.observation = observation;
    this.date = panelDisplayDate(this.observation);
    this.name = panelDisplayName(this.observation);
    this.value = getValue(this.observation);
    this.units = getUnits(this.observation);
    this.status = this.observation.status;
    this.source = getSource(this.observation);

  }
}

function getValue(observation: Observation): string {
  return 'TODO';
}

function getUnits(observation: Observation): string {
  return 'TODO';
}
