<mat-card>
  <mat-card-title>{{ title }} {{mode}}</mat-card-title>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div>
      <mat-form-field class="lvs-input-wide" appearance="fill">
        <mat-label>Source System</mat-label>
        <input matInput type="text" size="50" [(ngModel)]="sourceSystem" appearance="outline">
      </mat-form-field>
      <br/>
      <mat-form-field class="lvs-input-wide" appearance="fill">
        <mat-label>Processor</mat-label>
        <input matInput type="text" size="50" [(ngModel)]="processor" appearance="outline">
      </mat-form-field>
      <br/>
      <mat-form-field class="lvs-input-wide" appearance="fill">
        <mat-label>Type</mat-label>
        <input matInput type="text" size="50" [(ngModel)]="resourceType" appearance="outline">
      </mat-form-field>
      <br/>
      <mat-form-field class="lvs-input-wide" appearance="fill">
        <mat-label>Id</mat-label>
        <input matInput type="text" size="50" [(ngModel)]="id" appearance="outline">
      </mat-form-field>
      <br/>
      <button mat-raised-button (click)="getResource()">Submit</button>
      <div *ngIf="data">
        <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
      </div>
    </div>
  </mat-card-content>
</mat-card>
