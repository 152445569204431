import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-show-endpoint',
  templateUrl: './lvs-ui-result-type-progress.component.html',
  styleUrls: ['./lvs-ui-result-type-progress.component.scss'],
})
export class LvsUiResultTypeProgressComponent implements OnInit {
  data: any = null;
  loading: boolean = false;
  autoReload: boolean = true;
  loadCount: number = 0;
  resultType: string = "clinicalDecisionSupport";
  loadLogs: string[] = [];

  constructor(private dataService: DataService) {
  }

  ngOnInit() {
    // this.getEndpointData();
  }

  getEndpointData() {
    this.data = null;
    this.loading = true;
    const startTime = Date.now();
    const endpoint = `/lvs-ui/legacy/result-type-progress?resultType=${this.resultType}`;
    this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
      this.data = data || {};
      this.loadCount++;
      const elapsed = Date.now() - startTime;
      this.loadLogs.push(`mode=${this.resultType} elapsed=${elapsed / 1000} seconds value=${JSON.stringify(this.data)}`);
      this.loading = false;
      this.maybeReload();
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
  }

  toggleAutoReload() {
    this.autoReload = !this.autoReload
  }

  maybeReload() {
    if (this.autoReload) {
      if(!this.isDone()) {
        this.loading = true;
        this.getEndpointData();
      }
    }
  }

  private isDone() {
    return (this.data.status === 'error' || this.data.status === 'completed');
  }
}
