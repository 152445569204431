<mat-card>
  <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
  <mat-card-title>lvs-rest Details</mat-card-title>
  <mat-card-subtitle>lvs-rest JWT</mat-card-subtitle>
  <mat-card-content>
    <div class="jwt">{{lvsRestJwt}}</div>
    <app-copy-to-clipboard [data]="lvsRestJwt">Copy to clipboard</app-copy-to-clipboard>
    <button mat-raised-button (click)="showDialog('lvs-rest JWT Token', decodedLvsRestJwt)">
      <mat-icon>code</mat-icon>
      Decode
    </button>
    <button mat-raised-button (click)="showDialog('lvs-rest JWT Token', lvsAuthInfo)">
      <mat-icon>code</mat-icon>
      Show Launch Parameters
    </button>
    <button mat-raised-button (click)="changeJwt()">
      <mat-icon>login</mat-icon>
      Change lvs-rest JWT
    </button>
  </mat-card-content>
  <mat-card-subtitle>lvs-rest URL</mat-card-subtitle>
  <mat-card-content>
    <div>{{lvsRestUrl}}</div>
  </mat-card-content>
  <mat-card-subtitle>EMR JWT</mat-card-subtitle>
  <mat-card-content>
    <div class="jwt">{{emrJwt}}</div>
    <app-copy-to-clipboard [data]="emrJwt">Copy to clipboard</app-copy-to-clipboard>
    <button mat-raised-button (click)="showDialog('lvs-rest JWT Token', decodedEmrJwt)"><mat-icon>code</mat-icon> Decode</button>
  </mat-card-content>
  <mat-card-subtitle>EMR URL</mat-card-subtitle>
  <mat-card-content>
    <div>{{emrUrl}}</div>
  </mat-card-content>
  <mat-card-content  *ngIf="error">
  <mat-error class="error">
    <pre>{{error}}</pre>
  </mat-error>
  </mat-card-content>
</mat-card>
