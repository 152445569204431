<mat-card>
  <mat-card-title>no stream panel view progress</mat-card-title>
  <mat-card-content>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <div class="display-type-container">
      resultType:&nbsp;<select [(ngModel)]="resultType">
      <option value="clinicalDecisionSupport">clinicalDecisionSupport</option>
      <option value="emr">emr</option>
      <option value="lca">lca</option>
      <option value="merged">merged</option>
    </select> &nbsp;
      <div class="update-button">
        <button mat-raised-button (click)="getEndpointData()"
                matTooltip="make a new call to GET /lvs-ui/legacy/result-type-progress?resultType=(selected resultType from above)">
          Update
        </button>&nbsp;&nbsp;&nbsp;&nbsp;<mat-checkbox [(ngModel)]="autoReload" (change)="toggleAutoReload()">Auto
        Reload
      </mat-checkbox>
      </div>
    </div>
    <app-json-widget [readonly]="true" [data]="data"></app-json-widget>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-title>Load info</mat-card-title>
  <mat-card-content>
    <div *ngFor="let loadLog of loadLogs">{{loadLog}}</div>
    <div class="display-type-container">
      Count:&nbsp;{{loadCount}}
    </div>
  </mat-card-content>
</mat-card>
